import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid'

import '../Pagination/style.scss';


var indexValue = '';
 
const PaginationComponent = ({
  postsPerPage,
  totalPosts,
  paginate,
  currentPage,
  
}) => {

  const pageNumbers = [];  
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    // for (let i = 1; i <= totalPages; i++) {
    if (i <= 9) {
      pageNumbers.push('0' + i);
    } else {
      pageNumbers.push(i);
    }
  }

 useEffect(() => {
    paginate(currentPage);
    indexValue = 1;     
    [...document.querySelectorAll('.page-link')].forEach(function(
      item,
    ) {
      item.classList.remove('active');
      if (indexValue == item.innerHTML) {
        item.classList.add('active');
        styleDeleteHandler()
      }
      // item.addEventListener('click', function(elem) {
      //   var getElemWithClass = document.querySelector('.active');
      //   if (getElemWithClass !== null) {
      //     getElemWithClass.classList.remove('active');
      //   }
      //   item.classList.add('active');
      // });
    });
  },[]);

  //function to delete page number style during onClick
  //Vanilla Js is used here to get element id and then delete each style property which was set initially
  const styleDeleteHandler = () => {
    var element = document.getElementById('itemDiv');
    element.style.color = null;
    element.style.backgroundColor = null;
    element.style.borderRadius = null;
    element.style.fontSize = null;
    element.style.width = null;
    element.style.height = null;
    element.style.padding = null;
    element.style.alignItems = null;
    element.style.textAlign = null;
  }

  //function for back button
  const getBack = () => {    
    indexValue--;
    if (indexValue >= 1) {
      paginate(indexValue);
      [...document.querySelectorAll('.page-link')].forEach(function(
        item,
      ) {
          styleDeleteHandler() 
        item.classList.remove('active');
        if (indexValue == item.innerHTML) {
          item.classList.add('active');
          styleDeleteHandler()
        }
      });
    }
  };

  //function for forward button
  const getForward = () => {    
    indexValue++;
    if (indexValue <= pageNumbers.length) {
      paginate(indexValue);
      [...document.querySelectorAll('.page-link')].forEach(function(
        item,
      ) {
        styleDeleteHandler()
        item.classList.remove('active');
        if (indexValue == item.innerHTML) {
          item.classList.add('active');
          styleDeleteHandler()
        }
      });
    }
  };

  //function for onClick of page number
  const ClickOnNumbers = (idNumber) => {
    console.log("clickNumber is: ",idNumber)
    paginate(idNumber);
    indexValue = idNumber;
    
    [...document.querySelectorAll('.page-link')].forEach(item=>
     {
      styleDeleteHandler()
      item.classList.remove('active');
      if (indexValue == item.innerHTML) {
        item.classList.add('active');
        styleDeleteHandler()
      }
      // console.log("item is: ",item)
      // item.addEventListener('click', function(elem) {
      //   var getElemWithClass = document.querySelector('.active');
      //   if (getElemWithClass !== null) {
      //     getElemWithClass.classList.remove('active');
      //     var element = document.getElementById('itemDiv');
      //     element.style.color = null;
      //   }
      //   var element = document.getElementById('itemDiv');
      //     element.style.color = null;
      //   item.classList.add('active');
      // });
    });
  };

  return (
    <nav className="pagiNation">
      <ul className="pagination">
        {
          indexValue > 1?
          <a onClick={getBack} className="page-prev">
            <Grid item>
              <Grid container 
                direction="row"
              >
                <Grid item>
                  &lt;
                </Grid>
                <Grid item style={{marginLeft:"0.1em"}}>
                  <p>Previous</p>
                </Grid>
              </Grid>
            </Grid>           
          </a>
          :
          null
        }
        {pageNumbers.map((item, index) => {
          
          return (
            <li key={index} className="page-item">
              <a
                
                id="itemDiv"
                onClick={() => ClickOnNumbers(item)}
                className="page-link"
                style={{
                  backgroundColor: item==="01"?"#e46a76":null,
                  color: item==="01"?"white":null,
                  fontSize: item==="01"?"18px":null,
                  borderRadius: item==="01"?"50%":null,
                  width: item==="01"?"65px":null,
                  height: item==="01"?"65px":null,
                  padding: item==="01"?"5px 10px 5px 10px":null,
                  alignItems: item==="01"?"center":null,
                  textAlign: item==="01"?"center":null,
                }}
              >
                {item}
              </a>
            </li>
          );
        })}
        {
          indexValue < pageNumbers.length?
          <a onClick={getForward} className="page-next">
            <Grid item>
              <Grid container 
                direction="row"
                justify="flex-end"
              >
                <Grid item style={{marginRight:"2px"}}>
                  <p>Next</p>
                </Grid>
                <Grid item >
                  &gt;
                </Grid>
              </Grid>
            </Grid>
          </a>
        :
        null
        }
      </ul>
    </nav>
  );
};

export default PaginationComponent;
