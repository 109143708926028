// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. 
import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Paper, MenuItem } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import "./style.scss";
import httpRequest from '../../../Utils/httpRequest'
import Loader from '../../../Components/Preloader/index'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
//@component
//Edit Account
const EditAccount = props => {
  const [mapsEnable, setMapsEnable] = useState('true');
  const [ isLoading, setIsLoading ] = useState(false)
  const [realms, setRealms]= useState([]);
  const [tenantId, setTenantId]= useState('')
  const [orgs, setOrgs]= useState([]);
  const [orgName, setOrgName]=useState('')
  const [orgId, setOrgId]=useState('')
  const [orgAcr, setOrgAcr]=useState('')
  const [orgAdr,setOrgAdr]=useState('')
  const [ adrError,setadrError ] = useState("")
  const [solFName, setSolFName]=useState('')
  const [ fnError,setfnError ] = useState("")
  const [solLName, setSolLName]=useState('')
  const [ lnError,setlnError ] = useState("")
  const [altName, setAltName]=useState("")
  const [ altNameError, setAltNameError ] = useState("")
  const [email, setEmail]=useState('')
  const [ emailError,setemailError ] = useState("")
  const [ emailDisable,setEmailDisable ] = useState("")
  const [contact, setContact]=useState('')
  const [ contactError,setcontactError ] = useState("")
  const [ contactDisable,setContactDisable ] = useState("")
  const [altContact, setAltContact]=useState("")
  const [ altcontactError,setaltcontactError ] = useState("")
  const [ altDisable,setAltDisable ] = useState("")
  const [whitelist, setWhitelist]=useState('')
  const [ domainError,setDomainError ] = useState("")
  const [ domainDisable,setDomainDisable ] = useState("")
  const [country, setCountry]=useState('India')
  const [city, setCity] = useState('')
  const [stateName, setStateName]=useState('')
  const [pincode, setPinCode]=useState('')
  const [ pinError,setpinError ] = useState("")
  const [ pinDisable,setPinDisable ] = useState("")
  const [IsdialogOpen, setIsdialogOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const[realmName,setRealmName] = useState("")
  const [mtAdmin, setMtAdmin] =  useState(false);

  const adminId = props&&props.location&&props.location.state&&props.location.state.id
  const orgId1 = props&&props.location&&props.location.state&&props.location.state.solOrgId
//function to generate data handle
 const generateDatatHandlerOrg = ()=>{ 
    httpRequest.get(
      'orgDetailsbyId',
      {
        'Content-Type': 'application/json' ,     
      },
      successCallbackOrgDetails,
      errorCallbackOrgDetails,
      {
        'orgId': orgId1
      },
    )
  }

  //success callback for list handler
  const successCallbackOrgDetails = jsonResponse => {
      if (jsonResponse){
        setWhitelist(jsonResponse.data?.emailDomains)
        setRealmName(jsonResponse.data?.tenant?.realmName)
        setMapsEnable(jsonResponse.data?.isGMapOpted.toString())
        
      }       
  }
  
  //error callback
  const errorCallbackOrgDetails = error => {
      if (error) {
        // error message here if any
      }             
  }  
  //function to get admin details    
  const generateDatatHandler = ()=>{ 
    httpRequest.get(
      'admindetails',
      {
        'Content-Type': 'application/json' ,     
      },
      successCallbackList,
      errorCallback,
      {
        'userId':adminId
      },
    )
  }
  //success callback for list handler
  const successCallbackList = jsonResponse => {
      if (jsonResponse){
        
        setSolFName(jsonResponse.data?.firstName)
        setSolLName(jsonResponse.data?.lastName)
        setAltName(jsonResponse.data?.altContactPerson)
        setAltContact(jsonResponse.data?.altContactNumber)
        setEmail(jsonResponse.data?.corpEmailId)
        setContact(jsonResponse.data?.mobileNumber)
        setCity(jsonResponse.data?.addressDetails?.city)
        setStateName(jsonResponse.data?.addressDetails?.state)
        setCountry(jsonResponse.data?.addressDetails?.country)
        setOrgAdr(jsonResponse.data?.addressDetails?.addressLine1)
        setOrgName(jsonResponse.data?.org?.name)
        setOrgId(jsonResponse.data?.org?.orgId)
        setOrgAcr(jsonResponse.data?.org?.orgAcronym)
        setCountry(jsonResponse.data?.addressDetails?.country)
        setPinCode(jsonResponse.data?.addressDetails?.pincode)
        jsonResponse.data?.adminTypeDto?.adminTypes==='Multitenant'?setMtAdmin(true):setMtAdmin(false);
        generateDatatHandlerOrg();
      } 
  }
  
//error callback
const errorCallback = error => {
    if (error) {
      // error message if any
    }             
}
  //alert toaster function
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //function for alert toaster
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  // variable for mediaquery and theme
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //function for dialog confirm button  
  const submitDetailsConfirm = () => {
    setIsdialogOpen(true);
  };
  //function to close dialog confirm
  const handleClose = () => {
    setIsdialogOpen(false);
  };
 
  //api call to get realm list.
  const realmListHandler = useCallback(() => {
    httpRequest.get(
      "realmList",
      {
        "Content-Type": "application/json",
      },
      successCallBackRealm,
      errorCallBackRealm,
      {}
    );
  }, []);
  //success callback
  const successCallBackRealm = (jsonResponse) => {
    if (jsonResponse) {
      setRealms(jsonResponse.data._embedded.results);
    }
  };
  //error callback
  const errorCallBackRealm = (error) => {
    if (error) {
      // error message if any
    }
  };
  //function for org api call
  const orgListHandler = useCallback(() => {
    httpRequest.get(
      "orgListdropdown",
      {
        "Content-Type": "application/json",
      },
      successCallBackOrg,
      errorCallBackOrg,
      {}
    );
  }, []);
  //success callback
  const successCallBackOrg = (jsonResponse) => {
    if (jsonResponse) {
      setOrgs(jsonResponse.data?._embedded.results);
    }
  };
  //error callback
  const errorCallBackOrg = (e) => {
    // error message if any
  };
  //function for pre-details submit api call
  const submitDetails = () => {
    setIsLoading(true);
    httpRequest.patch(
      "orgUpdate",
      {
        'orgId': orgId,
        'name': orgName,
        'isGMapOpted': mapsEnable,
        'orgAcronym': orgAcr,
        'emailDomains': whitelist,
        'tenant': {
          'tenantId': tenantId,
        },
      },
      {
        "Content-Type": "application/json",
      },
      successCallBackCreate1,
      errorCallBackCreate1,
      {
        'orgId': orgId,
      }
    );
  };
  //success callback
  const successCallBackCreate1 = (jsonResponse) => {
    if (jsonResponse) {
      setIsLoading(false);
      setIsdialogOpen(false);
      createPost();
    }
  };
  //error callback
  const errorCallBackCreate1 = (error) => {
    setIsLoading(false);
    if (error.message !== "Internal Server Error, Please try again later") {
      setIsdialogOpen(false);
      setOpenAlert(true);
      setAlertMessage(error.message);
      setSeverity("Error");
    }
  };
  //api call to Create Solution Admin.
  const createPost = () => {
    setIsLoading(true);
    mtAdmin?
    httpRequest.patch(
      "editAdmin",
      {
        // 'adminType': mtAdmin,
        'mobileNumber': contact,
        'firstName': solFName,
        'lastName': solLName,
        'corpEmailId': email,
        'altContactPerson': altName,
        'altContactNumber': altContact,
        'addressDetails': {
          'addressLine1': orgAdr,
          'addressLine2': ".",
          'city': city,
          'state': stateName,
          'pincode': pincode,
          'country': country,
        },
        'org': {
          'orgId': orgId,
        },
        'role': {
          'name': 'MULTITENANT_ADMIN',
        },
        'adminTypeDto':{
          'adminId':'9feb42c4-700f-483b-83fd-e0c7627c146b',
          'adminTypes':'Multitenant'
          }
      },
      {
        "Content-Type": "application/json",
      },
      successCallBackEdit,
      errorCallBackEdit,
      {
        'userId':adminId
      }
    )
    :
    httpRequest.patch(
      "editAdmin",
      {
        // 'adminType': mtAdmin,
        'mobileNumber': contact,
        'firstName': solFName,
        'lastName': solLName,
        'corpEmailId': email,
        'altContactPerson': altName,
        'altContactNumber': altContact,
        'addressDetails': {
          'addressLine1': orgAdr,
          'addressLine2': ".",
          'city': city,
          'state': stateName,
          'pincode': pincode,
          'country': country,
        },
        'org': {
          'orgId': orgId,
        },
        'role': {
          'name': 'CLOSED_GROUP_PARKING_PROVIDER',
        },
        'adminTypeDto':{
          'adminId':'29ae1c11-1d43-41d0-a0af-c9efcf95fe3b',
          'adminTypes':'Singletenant'
          },
      },
      {
        "Content-Type": "application/json",
      },
      successCallBackEdit,
      errorCallBackEdit,
      {
        'userId':adminId
      }
    )

  };
  //success callback
  const successCallBackEdit = (jsonResponse) => {
    if (jsonResponse) {
      setIsLoading(false);
      setOrgs(jsonResponse.data?._embedded.results);
      if (props.locatiom.state) {
        if (props.location.state.source === 'edit') {
          props.history.push({
            pathname: "/portal/viewaccountdetails",
            state: {
              destination: "edit"
            }
          })
        }
      }
      if (props.location.state) {
        if (props.location.state.source !== 'edit') {
          props.history.push({
            pathname: "/portal/viewaccount",
            state: {
              destination: "edit"
            }
          })
        }
      }      
      setOpenAlert(true);
      setAlertMessage("Solution Admin Account Changes Saved");
      setSeverity("Success");
    }
  };
  //error callback
  const errorCallBackEdit = (error) => {
    setIsLoading(false);
    if (error.message !== "Internal Server Error, Please try again later") {
      setOpenAlert(true);
      setAlertMessage(error.message);
      setSeverity("Error");
    }
    if (error.message === "Internal Server Error, Please try again later") {
      if (props.location.state) {
        if (props.location.state.source === 'edit') {
          props.history.push({
            pathname: "/portal/viewaccountdetails",
            state: {
              destination: "edit",
              viewId: props.location.state.id,
              editOrgId: props.location.state.solOrgId
            }
          })
        }
      }
      if (props.location.state) {
        if (props.location.state.source !== 'edit') {
          props.history.push({
            pathname: "/portal/viewaccount",
            state: {
              destination: "edit"
            }
          })
        }
      }
      setOpenAlert(true);
      setAlertMessage("Solution Admin Account Changes Saved");
      setSeverity("Success");
    }
  };

  //function call on component render/re-render
  useEffect(() => {  
    props.dispatch(updateBreadcrumbs("editaccount"))
    realmListHandler()
    orgListHandler()
    generateDatatHandler()
  },[])

  //functions to set state individually
  const mapEnableChange = (event) => {
    setMapsEnable(event.target.value);
  };
  //functions to set state individually
  const accountFNamehandle = (event) => {
    if (event.target.value.length > 50) {
      setfnError("Max.Char length is 50");
    }
    if (event.target.value.length <= 50) {
      setfnError("");
      setSolFName(event.target.value);
    }
  };
  //functions to set state individually
  const accountLNamehandle = (event) => {
    if (event.target.value.length > 50) {
      setlnError("Max.Char length is 50");
    }
    if (event.target.value.length <= 50) {
      setlnError("");
      setSolLName(event.target.value);
    }
  };
  //function for generic email regex validation
  const validateEmail = (email) => {
    const re =/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(String(email).toLowerCase());
  }
  //function for generic domain regex validation
  const validateDomain = (domain) => {
    const re = /@(.*)\.com|.co.in|.in$/i
    return re.test(String(domain).toLowerCase());
  }
  //functions to set state individually
  const emailHandle = (event) => {
    if (event.target.value.length > 100) {
      setemailError("Enter less than 100 charecters");
      setEmailDisable("disable")
    }
    if(event.target.value.length <= 100) {
      if(validateEmail(event.target.value)) {
        setEmailDisable("")
        setemailError("");
        setEmail(event.target.value);
      }
      if(!validateEmail(event.target.value)){
        setemailError("Enter valid Email Id")
        setEmail(event.target.value)
        setEmailDisable("disable")
      }
      if(event.target.value.length < 1) {
        setemailError("")
        setEmail(event.target.value)
        setEmailDisable("disable")
      }      
    }
  };

  //function to handle edit cancel button
  const cancelHandler = () => {
    if (props.location.state) {
      if (props.location.state.source === "edit") {
        props.history.push({
          pathname: "/portal/viewaccountdetails",
          state: {
            viewId: props.location.state.id,
            editOrgId: props.location.state.solOrgId
          }
        })
      }
      if (props.location.state.source !== 'edit') {
        props.history.push("/portal/viewaccount")
      }
    }
  }
  //functions to set state individually
  const contactHandle = (event) => {
    if (event.target.value.length < 10) {
      setcontactError("Enter 10 numbers only")
      setContactDisable("disable")
      setContact(event.target.value)
    }
    if (event.target.value.length > 10) {
      setcontactError("Enter 10 numbers only")
      setContactDisable("disable")
    }
    if (event.target.value.length === 10) {
      setcontactError("")
      setContact(event.target.value)
      setContactDisable("")
    }  
    if (event.target.value.length < 1) {
      setcontactError("")
      setContactDisable("disable")
      setContact(event.target.value)
    }  
  }
  //functions to set alternate contact person state
  const altContactHandle = event => {
    if (event.target.value.length < 10) {
      setaltcontactError("Enter 10 numbers only")
      setAltContact(event.target.value)
      setAltDisable("disable")
    }
    if (event.target.value.length > 10) {
      setaltcontactError("Enter 10 numbers only")
      setAltDisable("disable")
    }
    if (event.target.value.length === 10) {
      setaltcontactError("")
      setAltContact(event.target.value)
      setAltDisable("")
    }  
    if (event.target.value.length < 1) {
      setaltcontactError("")
      setAltContact(null)
      setAltDisable("")
    }    
  }
  //functions to set state individually
  const altNameHandle = event => {
    if (event.target.value.length > 50) {
      setAltNameError("Max.Char length is 50");
    }
    if (event.target.value.length <= 50) {
      setAltNameError("");
      setAltName(event.target.value)
    }
    if (event.target.value.length < 1) {
      setAltNameError("");
      setAltName(null)
    }
  }
  //functions to set state individually
  const postalHandle = event => {
    if (event.target.value.length < 6) {
      setpinError("Min. 6 and Max. 8 charecters allowed")
      setPinDisable("disable")
      setPinCode(event.target.value)
    }
    if (event.target.value.length > 8) {
      setpinError("Min. 6 and Max. 8 charecters allowed")
      setPinDisable("disable")
    }
    if (event.target.value.length < 1) {
      setpinError("")
      setPinDisable("disable")
      setPinCode(event.target.value)
    }
    if (event.target.value.length >= 6 && event.target.value.length <= 8) {
      setpinError("")
      setPinDisable("")
      setPinCode(event.target.value)
    }    
  }
  //functions to set state individually
  const orgAdrHandle = (event) => {
    if (event.target.value.length > 100) {
      setadrError("Enter less than 100 charecters");
    }
    if (event.target.value.length <= 100) {
      setadrError("");
      setOrgAdr(event.target.value);
    }
  };
  //functions to set state individually
  const countryHandle = (event) => {
    setCountry(event.target.value);
  };
  //functions to set state individually
  const stateHandle = (event) => {
    setStateName(event.target.value);
  };
  //functions to set state individually
  const cityHandle = (event) => {
    setCity(event.target.value);
  };
  //functions to set state individually
  const tenantHandle = (event) => {
    setTenantId(event.target.value);
  } 
  //functions to set state individually
  const whitelistHandler = event => {
    let splitArr=[];
    let holder=[];
    holder = splitArr.concat(event.target.value.split(","))  
    let whiteArr = [];
      if(event.target.value.length === 0){
        setDomainDisable("disable")
        setWhitelist(whiteArr.concat(event.target.value.split(",")))
        setDomainError("")
      }
      if (event.target.value.length > 0) {  
        holder.forEach(value=>{      
          if (validateDomain(value)) {
            setWhitelist(whiteArr.concat(event.target.value.split(",")))
            setDomainDisable("")
            setDomainError("")
          }   
          if (!validateDomain(value)) {
            setWhitelist(whiteArr.concat(event.target.value.split(",")))
            setDomainError("Enter valid whitelist domain Id")
          }
        })
      }      
  }
  //functions to set state individually
  const orgHandle = event => {
    setOrgName(event.target.value)
      orgs.map((option)=>{
      if (option.name === event.target.value) {
        setOrgAcr(option.orgAcronym)
        setOrgId(option.orgId)
      }
    })
  }
  
  return (
    <>
      {isLoading && <Loader />}
      <div className="root">
        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={5} className="container">
              <div className="header">
                <h2 className="heading-text">Edit Account Details</h2>
              </div>
              <form className="form-field" style={{padding:0}}>
                <div className="contactdetails__wrapper__container">
                  <div className="contactdetails__wrapper__container__leftside">
                  <label>Admin Type</label>
                  <br />
                  <Button variant="outlined" 
                  className ="admin-type"
                  onClick={()=>
                    setMtAdmin(false)
                  }
                 
                  style={{
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    padding: '5px 15px',
                    marginRight: '1em',
                    marginTop: '0.5em',
                    marginBottom: '1em',
                    width: '46%',
                    height: '6%',
                    backgroundColor: !mtAdmin?'#acecef':''
                  }} 
                  >Single Tenant</Button>
                  <Button variant="outlined"
                  onClick={
                   ()=> setMtAdmin(true)
                  }

                  
                  style ={{
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    padding: '5px 15px',
                    marginRight: '1em',
                    marginTop: '0.5em',
                    marginBottom: '1em',
                    width: '46%',
                    height: '6%',
                    backgroundColor: mtAdmin?'#acecef':''
                  }}
                  >Multi Tenant</Button>
                    <label>Solution Admin First Name*</label>

                    <TextField
                      fullWidth
                      type="text"
                      name="accountFName"
                      placeholder="Admin First Name"
                      value={solFName}
                      onChange={accountFNamehandle}
                    />
                    <p
                     
                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {fnError}
                      </span>
                    </p>
                    
                    <label>Solution Admin Last Name*</label>

                    <TextField
                      fullWidth
                      type="text"
                      name="accountLName"
                      placeholder="Admin Last Name"
                      value={solLName}
                      onChange={accountLNamehandle}
                    />
                    <p
                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {lnError}
                      </span>
                    </p>
                    
                    <label>Alternate Contact Person</label>
                    <TextField
                      fullWidth
                      type="text"
                      name="altName"
                      value={altName}
                      placeholder="Contact Phone Name"
                      onChange={altNameHandle}
                    />
                    <p
                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {altNameError}
                      </span>
                    </p>
                    <label>Contact Email*</label>
                    <TextField
                      fullWidth
                      type="email"
                      autoComplete="off"
                      name="contactEmail"
                      value={email}
                      placeholder="Email Address"
                      onChange={emailHandle}
                    />
                    <p>
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {emailError}
                      </span>
                    </p>
                    <label>Contact Number*</label>
                    <TextField
                      fullWidth
                      type="number"
                      className="phone_field"
                      name="contactNumber"
                      placeholder="Contact Number"
                      value={contact}
                      onChange={contactHandle}
                    />
                    <p
                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {contactError}
                      </span>
                    </p>
                    <label>Alternate Contact Number</label>
                    <TextField
                      fullWidth
                      type="number"
                      name="altNumber"
                      placeholder="Alt Contact Number"
                      value={altContact}
                      onChange={altContactHandle}
                    />
                    <p
                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {altcontactError}
                      </span>
                    </p>
                    <label>Realm Name*</label>
                    <TextField
                      fullWidth
                      type="text"
                      name="realm"
                      placeholder="Example\user1"
                      value={realmName}
                      onChange={tenantHandle}
                      select
                    >
                      {realms.map((option) => (
                        <MenuItem key={option.tenantId} value={option.realmName}>
                         <span  style={{fontSize:'16px'}}>{option.realmName}</span> 
                        </MenuItem>
                      ))}
                    </TextField>

                   
                    <label>Google Maps Activation</label>
                    <div className="button__wrapper_left">
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="googleMaps"
                          name="googleMaps"
                          value={mapsEnable}
                          style={{ flexDirection:'row', justifyContent:'space-between',width:'185px' }}
                          onChange={mapEnableChange}
                        >
                          <FormControlLabel
                            value='true'
                            labelPlacement="end"
                            control={<Radio color="primary" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value='false'
                            labelPlacement="end"
                            control={<Radio color="primary" />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                      
                    </div>
                  </div>
                  <div className="contactdetails__wrapper__container__leftside">
                  <label>Whitlist Domains*</label>
                    <TextField
                      fullWidth
                      type="text"
                      name="whiteList"
                      value={whitelist}
                      placeholder="@bosch.com, @bosch.in, …."
                      onChange={whitelistHandler}
                    />
                    <p
                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {domainError}
                      </span>
                    </p>
                    <label>Organization Name*</label>

                    <TextField
                      fullWidth
                      type="text"
                      name="orgName"
                      value={orgName}
                      select
                      disabled
                      onChange={orgHandle}
                    >
                      {orgs.map((option) => (
                        <MenuItem key={option.orgId} value={option.name}>
                         <span style={{fontSize:'16px'}}>{option.name}</span>
                        </MenuItem>
                      ))}
                    </TextField>

                    <label>Organization Acronym</label>
                    <TextField
                      fullWidth
                      type="text"
                      name="orgAcrr"
                      value={orgAcr}
                      disabled
                    />
                    {}
                    <label>Organization Address*</label>
                    <TextField
                      fullWidth
                      type="text"
                      name="contactEmail"
                      placeholder="Enter Org Address"
                      value={orgAdr}
                      onChange={orgAdrHandle}
                    />
                    <p
                      style={{
                        borderTop: adrError ? "1px solid red" : undefined,
                      }}
                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {adrError}
                      </span>
                    </p>
                    <label>Country*</label>
                    <TextField
                      fullWidth
                      disabled
                      type="text"
                      value="India"
                      onChange={countryHandle}
                    />
                    

                    <label>State*</label>
                    <TextField
                      fullWidth
                      type="text"
                      name="state"
                      value={stateName}
                      onChange={stateHandle}
                    />
                    <label>City*</label>
                    <TextField
                      fullWidth
                      type="text"
                      name="city"
                      value={city}
                      onChange={cityHandle}
                    />
                    <label>Postal Code*</label>
                    <TextField
                      fullWidth
                      type="number"
                      name="postalCode"
                      placeholder="Postal Code"
                      value={pincode}
                      onChange={postalHandle}
                    />
                    <p
                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {pinError}
                      </span>
                    </p>

          </div>
        </div>
              <div className="button-section">
                <Button
                   variant="contained"  
                   className="form-button submit" 
                   onClick={submitDetailsConfirm}
                   disabled={
                    contactDisable.length !== 0 ||
                    altDisable.length !== 0 ||
                    contactError.length !== 0 ||
                    altcontactError.length !== 0 ||
                    solLName.length === 0 ||
                    solFName.length === 0 ||
                    emailError.length !== 0 ||
                    emailDisable.length !== 0 ||
                    domainDisable.length !== 0 ||
                    domainError.length !== 0 ||
                    adrError.length !== 0 || 
                    orgAdr.length === 0 ||
                    stateName.length === 0 ||
                    city.length === 0 ||
                    pincode.length === 0 ||
                    pinError.length !== 0 ||
                    pinDisable.length !== 0 ||
                    altNameError.length !== 0
                  }
                >
                  Save
                </Button>
                <Button
                  variant="contained"  
                  className="form-button cancel"
                  onClick={cancelHandler}
                >
                  Cancel
                </Button>

                  <Dialog
                    fullScreen={fullScreen}
                    open={IsdialogOpen}
                    onClose={handleClose}
                    aria-labelledby="createadmin"
                  >
                    <DialogTitle
                      id="create admin dialog-header"
                      style={{ borderBottom: "1px solid #000" }}
                    >
                      Edit Admin
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Do you want to edit this Admin?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={submitDetails}
                        variant="contained"
                        className="confirm-button"
                        autoFocus
                      >
                        Confirm
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        onClick={handleClose}
                        className="cancel-button"
                      >
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Snackbar
                    open={openAlert}
                    autoHideDuration={10000}
                    onClose={handleCloseAlert}
                  >
                    <Alert onClose={handleCloseAlert} severity={severity}>
                      {alertMessage}
                    </Alert>
                  </Snackbar>
                </div>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  dispatch,
}); 
//method to connect react with redux
export default withRouter(
connect(state => {
  return {};
})(EditAccount),
);