/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import {  makeStyles } from '@material-ui/core/styles'



import UploadIcon from '../../Assets/Images/cloud-computing.svg'

const useStyles = makeStyles(() => ({
  uploadIcon: {
    width: "6.5em",
    height: "11.5em",
  },
  outerRow: {
    border: 0,
    textAlign: "center",
  },
  helperText: {
    color: "#808080",
    fontWeight: 400,
    fontSize: "1.45rem",
    lineHeight: "0.75em"
  },
}))

export default function FileUploader(props) {
  const classes = useStyles()
  // const theme = useTheme()
  // const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
  // const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    props.fileHandler(acceptedFiles)
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <React.Fragment>
      <div {...getRootProps()} className={classes.outerRow}>
        <img
          src={UploadIcon}
          alt="UploadIcon"
          className={classes.uploadIcon}
        />
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p className={classes.helperText} >Drag and Drop a file here or click</p> :
            <p className={classes.helperText}>You can add here and each file should be less than 2 mb </p>
        }
      </div>
    </React.Fragment>
  )
}