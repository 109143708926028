import { Promise } from 'es6-promise';
import httpRequest from '../../../Utils/httpRequest';


export const getAdminList = async (page) => {
    return new Promise((resolve, reject) => {
        const successCallback = (jsonResponse) => {
            resolve(jsonResponse.data)
        }
        const errorCallback = (error) => {
            reject(error)
        }

        httpRequest.get(
            'soladminlist', {}, successCallback, errorCallback,
            {
                page: page,
                size: 10
            }
        )
    })
}

export const deleteFacilityManager = async (deleteId) => {
    return new Promise((resolve, reject) => {
        const successCallback = (jsonResponse) => {
            resolve(jsonResponse)
        }
        const errorCallback = (error) => {
            reject(error)
        }

        httpRequest.deleteData(
            'deleteSecurity',
            {
              "userIds": deleteId,
              "adminRemarks": "delete this user"
            },
            {
              'Content-Type': 'application/json',
            },
            successCallback,
            errorCallback,
            {}
          )
    })
}
export const createFacilityManagerList = async (page) => {
    return new Promise((resolve, reject) => {
        const successCallback = (jsonResponse) => {
            resolve(jsonResponse.data)
        }
        const errorCallback = (error) => {
            reject(error)
        }

        httpRequest.get(
            'facilityManagerListView', {}, successCallback, errorCallback,
            {
                page: page,
                size: 10
            }
        )
    })
}
export const editFacilityManagerList = async (page) => {
    return new Promise((resolve, reject) => {
        const successCallback = (jsonResponse) => {
            resolve(jsonResponse.data)
        }
        const errorCallback = (error) => {
            reject(error)
        }

        httpRequest.get(
            'facilityManagerListView', {}, successCallback, errorCallback,
            {
                page: page,
                size: 10
            }
        )
    })
}








