/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Link,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from '@material-ui/core/';
import httpRequest from "../../Utils/httpRequest";
import {
  updateToasterConfig,
} from "../../redux/modules/app/actions";
import showIcon from '../../Assets/Images/eye for password.png';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

function Modal(props) {
  const [open, setOpen] = React.useState(false);
  const [currPwd, setcurrPwd] = useState('');
  const [newPwd, setnewPwd] = useState('');
  const [verifyPwd, setverifyPwd] = useState('');
  const [showValCurr, setshowValCurr] = useState(false);
  const [showValNew, setshowValNew] = useState(false);
  const [showValVer, setshowValVer] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [msgNewPass, setMsgNewPass] = useState(false);
  const [msgVerPass, setMsgVerPass] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setnewPwd('');
   setverifyPwd('');
   setcurrPwd('');
    setOpen(false);
    
  };
  const handleCancel = () => {
   setnewPwd('');
   setverifyPwd('');
   setcurrPwd('');
    setOpen(false);

  };

  const handleSubmit = () => {



    httpRequest.post(
      "changePassword",
      {
        "oldPassword": btoa(`${currPwd}`),
        "newPassword": btoa(`${newPwd}`),
        "confirmPassword": btoa(`${verifyPwd}`)
      },
      {},
      changeSuccess,
      errorCallback,

    )


    //setOpen(false);
  };
  useEffect(()=>{
    if (currPwd === newPwd && (currPwd!="" && newPwd!="")){
      setIsDisabled(true);
      setMsgNewPass(true);
    }
    else if (!(currPwd && newPwd && verifyPwd)) {
      setIsDisabled(true);
      setMsgVerPass(false)
      setMsgNewPass(false)
      
     
    }
    else if (newPwd!=verifyPwd)
    {
      setIsDisabled(true);
      setMsgVerPass(true)
    }
    
    else {
      setIsDisabled(false)
      setMsgVerPass(false)
      setMsgNewPass(false)
    
    }
  

  });

  const changeSuccess=()=>{
    props.updateToasterConfig({
      show: true,
      message: 'Password has been changed successfully',
      color: "#02C194",
    });
  }
  const errorCallback = error => {
    // console.log("**********************", error?.message);

    props.updateToasterConfig({
      show: true,
      message: error?.message,
      color: "#FF0000",
    });
  };


  const toggleShowCurr = (event) => {
    event.preventDefault();
    setshowValCurr(!showValCurr);
  };

  const toggleShowNew = (event) => {
    event.preventDefault();
    setshowValNew(!showValNew);
  };

  const toggleShowVerify = (event) => {
    event.preventDefault();
    setshowValVer(!showValVer);
  };
  return (
    <div>
      <div className="pwdLink">
        <Link onClick={handleClickOpen}>Change Password</Link>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Change Password'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form id= "change-pass-form">
              <div className="current">
                <label>
                  <p>Current Password*</p>
                  <input
                    className="inputBox"
                    type={showValCurr ? 'text' : 'password'}
                    value={currPwd}
                    onChange={(e) => setcurrPwd(e.target.value)}
                  />
                </label>
                <button
                  className="btnCustom"
                  onClick={toggleShowCurr}
                >
                  <img alt="showIcon" style={{ width: '75%' }} src={showIcon} />
                </button>
              </div>
              <div className="new">
                <label>
                  <p>New Password*</p>
                  <input
                    className="inputBox"
                    type={showValNew ? 'text' : 'password'}
                    value={newPwd}
                    onChange={(e) => setnewPwd(e.target.value)}
                  />
                </label>
                <button className="btnCustom" onClick={toggleShowNew}>
                  <img alt="showIcon" style={{ width: '75%' }} src={showIcon} />
                </button>
              </div>

              <div className="verify">
                <label>
                  <p>Confirm New Password*</p>
                  <input
                    className="inputBox"
                    type={showValVer ? 'text' : 'password'}
                    value={verifyPwd}
                    onChange={(e) => setverifyPwd(e.target.value)}
                  />
                </label>
                <button
                  className="btnCustom"
                  onClick={toggleShowVerify}
                >
                  <img style={{ width: '75%' }} alt="showIcon" src={showIcon} />
                </button>
              </div>
            </form>
           { msgVerPass &&  <span style={{color:'red', padding:10}}>New Password and Confirm Password must be same</span> }<br />
           

           { msgNewPass &&  <span style={{color:'red', padding:10}}>Current Password and New Password must be different</span>}
            
            <div className="strength">
              <p>Strength (Why?) :&nbsp;</p>
              <PasswordStrengthMeter password={newPwd} />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item md={6} >
              <Button onClick={() => {
                handleCancel();
              }} className="pwdbtn">
                Cancel
          </Button>
            </Grid>
            <Grid item md={6} >
              <Button
                style={{ float: 'right' }}
                disabled={isDisabled}
                onClick={() => {
                  handleSubmit();
                }} className="pwdbtn">
                Submit
          </Button>
            </Grid></Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default withRouter(
  connect(
    state => ({
      auth: state.app.auth,
    }),
    { updateToasterConfig },
  )(Modal),
);
