/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { Component } from 'react';
import { updateToasterConfig } from './../../../src/redux/modules/app/actions';
import httpRequest from '../../Utils/httpRequest';
import TokenStorage from '../../Utils/tokenstorage'


import { connect } from 'react-redux';
import { updateBreadcrumbs } from '../../redux/modules/app/actions';
import {
  CardContent,
  Link,
  CardHeader,
  Card,
  IconButton,
  Button,
  Grid,
  Container,
} from '@material-ui/core';
import {
  getCookie,
} from '../../Utils/cookies';
import Modal from './Modal';
import Avatar from '@material-ui/core/Avatar';
import './Profile.scss';
import edit from '../../Assets/Images/edit-icon.png';
import profile from '../../Assets/Images/user@2x.png';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userImg: '',
      userName: '',
      userOrg: '',
      userEmail: '',
      profileImage: '',
      imgLink: '',
      userId: '',
      imgData: '',
      usrName:'',
    };
  }
  logout = () => {
    let cliSec = getCookie('cliSec');
    let cliId = getCookie('cliId');
    httpRequest.patch(
      'logout',
      {
        refreshToken: getCookie('refresh'),
        clientId: cliId,
        clientSecret: cliSec,
      },
      this.logoutSuccess,
      this.errorCallbacklg,
    );
  };
  errorCallbacklg = () => {
    this.props.updateToasterConfig({
      show: true,
      message: 'Successfully logged out',
      color: '#E5676A',
    });
    TokenStorage.clear()
    /* deleteCookie('auth');
    deleteCookie('refresh');
    deleteCookie('userId'); */
    window.location.href = '/login';
  };
  logoutSuccess = () => { };



  modalRef = ({ handleShow }) => {
    this.showModal = handleShow;
  };

  onLoginClick = () => {
    this.showModal();
  };
  UserDataSuccess = (jsonResult) => {
    this.setState({
      imgLink: jsonResult.data._links.profileImage.href,
      userId: jsonResult.data.userId,
    });
    // httpRequest.get(
    //   'userProfile',
    //   {},
    //   this.imageSuccess,
    //   this.errorCallback,
    //   {
    //     userId: this.state.userId,
    //   },
    // );
    this.setState({
      userName: jsonResult.data?.org?.name,
      userOrg: jsonResult.data?.org?.orgAcronym,
      userEmail: jsonResult.data?.corpEmailId,
      userImg: jsonResult.data?._links?.profileImage?.href,
      usrName: jsonResult.data?.userName
    });
  };
  imageSuccess = (jsonResult) => {
    httpRequest.get(
      'detailsImgTwo',
      {
        Accept: 'image/png',
      },
      this.imageSuccessTwo,
      this.errorCallback,
      {
        detailsImgTwo:
          jsonResult.data._links.profileImage.href +
          '&responseType=BASE64_ENCODE',
      },
    );
  };
  imageSuccessTwo = (jsonResult) => {
    this.setState({
      imgData: jsonResult.data,
    });
  };
  errorCallback = () => { };
  componentDidMount() {
    httpRequest.get(
      'userProfile',
      {},
      this.UserDataSuccess,
      this.errorCallback,
      {
        userId: getCookie('userId'),
      },
    );
    this.props.updateBreadcrumbs('profile');
  }

  render() {
    return (
      <>
        {this.state.userName !== ' ' && (
          <Container style={{ paddingTop: '5px' }}>
            <Card raised={true}>
              <CardHeader
                id="headerColor"
                action={
                  <div className="topPad"
                    style={{ 'visibility': 'hidden' }}>
                    <Link to="portal/profile/edit">
                      <IconButton className="editButton">
                        <img
                          style={{ width: '20px' }}
                          src={edit}
                          alt="icon"
                        />
                        <span style={{ paddingLeft: '10px' }}>
                          Edit
                        </span>
                      </IconButton>
                    </Link>
                  </div>
                }
                title="User Profile"
                titleTypographyProps={{ variant: 'h6' }}
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item md={3} sm={12}>
                    <Avatar
                    style={{width:"10em", height:"10em"}}
                      variant="round"
                      src={profile}
                      className="picture"
                    ></Avatar>
                    <div className="btnContainer">
                      <Button
                        style={{ 'visibility': 'hidden' }}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          this.logout();
                        }}
                      >
                        Logout
                      </Button>
                    </div>
                  </Grid>
                  <Grid item md={9} sm={12}>
                    <p className="profileName">
                      {this.state.userName}
                    </p>
                    <div className="info">
                      <div className="left">
                        <p>Role</p>
                        <p>Company</p>
                        <p>Username</p>
                        <p>Contact Number</p>
                        <p>Contact Email</p>
                      </div>
                      <div className="right">
                        <p>: Parking Super admin</p>
                        <p>: {this.state.userOrg}</p>
                        <p>: {this.state.usrName}</p>
                        <p>: +91 XXXXXXXXXXXX</p>
                        <p>: {this.state.userEmail}</p>
                      </div>
                    </div>
                    <Modal>
                      {/* <div className="pwdLink">
                                                <Link onClick={this.onLoginClick}>Change Password</Link>
                                            </div> */}
                    </Modal>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Container>
        )}
      </>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.app.auth,
  }),
  { updateBreadcrumbs, updateToasterConfig },
)(Profile);
