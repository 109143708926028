/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
const uploadImage = (uploadedImageFile, callBack, props) => {
  for (let i = 0; i < uploadedImageFile.length; i++) {
    var imageFile = uploadedImageFile[i];
    var reader = new FileReader();
    reader.onload = (function () {
      return function (evt) {
        var image = new Image();
        image.name = uploadedImageFile[i].name;
        image.src = evt.target.result;
        image.onload = function () {
          let imageData = {
            name: image.name,
            file: uploadedImageFile[i],
            src: image.src,
            newImg: true,
          };
          callBack(imageData, props);
        };
      };
    })(imageFile);
    reader.readAsDataURL(imageFile);
  }
};

function dataURItoBlob(dataURI, type, fileName) {
  let byteString = atob(dataURI.split(',')[1]);
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  let bb = new Blob([ab], {
    type: type,
  });
  let file = new File([bb], fileName, {
    type: type,
    lastModified: Date.now(),
  });
  return file;
}

const uploadImageService = {
  uploadImage: uploadImage,
  dataURItoBlob: dataURItoBlob,
};

export default uploadImageService;
