import { makeStyles} from '@material-ui/core/styles'


const gatewayUseStyles = makeStyles(theme=>({
    selectInput: {
        marginTop:"1.1em",
        width:"40em",
        height:"3em",
    },
    uploadDialogTitle:{
        fontWeight:"700",borderBottom:"1px solid #808080",textAlign:"left",
    },
    assignButton: {
        ...theme.typography.view,
        borderRadius: 50,
        backgroundColor: "#008ECF",
        height: 35,
        width: 135,
    },
    dialogGrid:{
        // padding:"5em",
        zIndex: 0,
        width:"80%",
        height:"50%",
    },
    gateway_main:{
        color:"#01C0C8",
        fontWeight:600,
        fontSize:"1.5rem",
        lineHeight:"1em"
    },
    title:{
        fontWeight:700,
        fontSize:"1rem",
        color:"black",
        lineHeight:"1em"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    assignDialogTitle:{
        fontWeight:"700",textAlign:"left",marginBottom:"0.5em"
    },
    assignSubtitle:{
        fontWeight:"400",textAlign:"left",marginBottom:"0.5em",fontSize:"0.75rem",
    },
    fileUpload:{
        ...theme.typography.view,
        borderRadius: 50,
        backgroundColor: "#008ECF",
        height: "3.5em",
        width: "20em"
    },    
    bulkUploadTopRow:{
        backgroundColor:"#e6e6fa",
        width:"100%",
        height:"50%",
    },
    InputRow:{
        padding:"32px",
        border: "2px solid black",
        margin:"32px",
        width:"90%",
        height:"25%"
    },
    ButtonRow:{
        padding:"32px",
    },
    bulkUploadCard: {
        zIndex: 0,
        boxShadow: theme.shadows[10],
        marginTop:"16px",
        width: "96%",
        height: "50%",
    },   
    assignNewGateway:{
        marginBottom:"2em",fontSize:"0.71rem",lineHeight:"1.25em",color:"#fff",backgroundColor:"#e46a76",borderRadius: '20px'
    },
    gridDeviceLogo:{
        verticalAlign:"center",width:0,marginLeft:"-20px"
    },
}));

export default gatewayUseStyles;