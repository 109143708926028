/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { Component } from "react";

import "./style.scss";

export default class index extends Component {
  render() {
    return (
      <div
        style={{
          top:
            `${window.location.pathname}` === "/portal/viewaccountdetails"
              ? "130vh"
              : "",
        }}
        className="pz-loader"
      >
        <div className="div-spread"></div>
        <div className="div-spread"></div>
        <div className="div-spread"></div>
      </div>
    );
  }
}
