/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import httpRequest from './../../../../../Utils/httpRequest';
import { Scrollbars } from 'react-custom-scrollbars';
import './style.scss';

export default class index extends Component {
  state = {
    checkAgree: false,
    eulaTextMessage: null,
  };
  componentDidMount = () => {
    this.getEulaText();
  };

  getEulaText = () => {
    httpRequest.get(
      'eulaText',
      {
        'content-type': 'application/json',
      },
      this.UserDataSuccess,
      this.errorCallback,
    );
  };
  UserDataSuccess = response => {
    this.setState({
      eulaTextMessage: response.data.eulaText,
    });
  };
  errorCallback = () => {};
  updateParentState = (parentKey, childKey, value) => {
    let obj = { ...this.props.data[parentKey] };
    obj[childKey] = value;
    this.props.updateChildrenState('step3', parentKey, obj);
  };
  unCheckEula = () => {
    this.updateParentState('eula', 'eulaAccepted', false, this.props);
    this.props.updateChildrenState(
      'step3',
      'isHardwareInterested',
      false,
    );
    this.props.cancal();
  };
  agreeMethod = () => {
    this.updateParentState(
      'eula',
      'eulaAccepted',
      !this.props.data.eula.eulaAccepted,
      this.props,
    );
  };
  interestedMethod = () => {
    this.props.updateChildrenState(
      'step3',
      'isHardwareInterested',
      !this.props.data.isHardwareInterested,
    );
  };

  render() {
    if (this.state.eulaTextMessage !== null) {
      document.getElementById(
        'message',
      ).innerHTML = this.state.eulaTextMessage;
    }
    return (
      <div className="terms">
        <div className="popUp-overlay"></div>
        <div className="terms__wrapper">
          <h2>Terms & Conditions</h2>
          <Scrollbars style={{ height: 250 }}>
            <p className="terms__text" id="message"></p>
          </Scrollbars>
          <div className="terms__buttons">
            <div className="terms__buttons_checkbox">
              <p>
                <Checkbox
                  color="default"
                  checked={this.props.data.eula.eulaAccepted}
                  onClick={this.agreeMethod}
                />
                <span>I Agree to Terms & Conditions</span>
              </p>
              <p>
                {
                  <Checkbox
                    color="default"
                    checked={this.props.data.isHardwareInterested}
                    onClick={this.interestedMethod}
                  />
                }
                <span>
                  I am Interested in Parking Hardware Installation
                </span>
              </p>
            </div>
            <div className="terms__buttons_submit">
              <Button
                variant="contained"
                onClick={this.unCheckEula}
                className="Cancel"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={!this.props.data.eula.eulaAccepted}
                onClick={this.props.next}
                color="primary"
              >
                Update My Parking Space
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
