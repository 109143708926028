import { makeStyles} from '@material-ui/core/styles'

const complaintUseStyles = makeStyles(()=>({
    selectNavCard:{
        height:"4em",width:"5em",border:0,borderRadius:"22px",
    },
    tableGrid:{
        marginTop:"2em",marginBottom:"2em",width:"96%",height:"50%",marginLeft:"1.5em"
    },
    table: {
        minWidth: 650,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    tableHeader: {
        fontSize: "12px",
        fontWeight: "bolder",
        // width: 17%;
    },
    viewButton: {
        color:'#03a9f3',fontSize:"0.70rem"
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      },
      divCurrentPage:{
        marginRight:"6em",marginTop:"1.4em"
      },
      styleCurrentPage:{
        backgroundColor: "#e46a76",
        color: "white",
        fontSize: "18px",
        borderRadius: "50%",
        width: "45px",
        height: "35px",
        padding: "5px 10px 5px 10px",
        alignItems: "center",
        textAlign: "center",
        marginTop:"-0.5em"
      },
}));

export default complaintUseStyles;