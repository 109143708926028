import React, { Component } from 'react';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';

//import Routes from './Routes';
import AdminRoutes from './../../../Routes/AdminRoutes';
import httpRequest from '../../../Utils/httpRequest';
import { updateToasterConfig } from '../../../../src/redux/modules/app/actions';
import { getCookie } from '../../../Utils/cookies';
import tokenstorage from '../../../Utils/tokenstorage';

class SolutionAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      userOrg: '',
      timeout: 900000,
      userLoggedIn: false,
      isTimedOut: false
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }

  logout = () => {
    let cliSec = getCookie('cliSec');
    let cliId = getCookie('cliId');
    httpRequest.patch(
      'logout',
      {
        refreshToken: getCookie('refresh'),
        clientId: cliId,
        clientSecret: cliSec,
      },
      this.logoutSuccess,
      this.errorCallback,
    );
  };

  logoutSuccess(){

  }
  errorCallback(){

  }

  _onAction() {
    this.setState({isTimedOut: false})
  }
 
  _onActive() {
    this.setState({isTimedOut: false})
  }
 
  _onIdle() {
    const isTimedOut = this.state.isTimedOut
    if (isTimedOut) {
      this.logout();
      tokenstorage.clear();
        window.location.href = '/login';
        this.props.updateToasterConfig({
          show: true,
          message: 'Your Session Expired',
          color: '#00FF00',
        });
    } else {
      this.idleTimer.reset();
      this.setState({isTimedOut: true})
    }
  }

  // constructor(props) {
  //     super(props);

  //     // axios.interceptors.request.use( (request) =>{
  //     //     console.log(request);
  //     // })
  //     //Need to find suitable place to host this
  //     axios.interceptors.response.use((response) => {
  //         // Return a successful response
  //         // console.log('interceptor ', response);
  //         return response;

  //     }, (error) => {
  //         // Return any error which is not due to authentication back to the calling service

  //         if (error && error?.response?.status === 400 && error?.response?.data?.error === 'invalid_grant') {
  //             ;
  //             console.log('logout here --- 400 & invalid_grant', error);
  //             //clean all cookie / session
  //             TokenStorage.clear();
  //             error.response.data = ' Session Expired , Kindly login again !';
  //             this.props.history.push('/');

  //             return new Promise((resolve, reject) => {

  //                 console.log('400 & invalid_grant Session Expired , Kindly login again', error);
  //                 reject(error);
  //             });
  //         }

  //         else if (error && error?.response?.status === 400 && error?.response?.data?.error !== 'invalid_grant') {
  //             ;
  //             return new Promise((resolve, reject) => {
  //                 console.log('400 !invalid_grant ', error);
  //                 reject(error);
  //             });
  //         }

  //         else if (error && error?.response?.status === 403) {
  //
  //             //clean all cookie / session
  //             TokenStorage.clear();
  //             return new Promise((resolve, reject) => {
  //                 console.log('403 : role not mapped', error);
  //                 error.response.data = ' Session Expired , Kindly login again !';
  //                 this.props.history.push('/');
  //                 reject(error);
  //             });
  //         }

  //         else if (error && error?.response?.status === 500) {
  //
  //             return new Promise((resolve, reject) => {
  //                 console.log('500', error);
  //                 reject(error);
  //             });

  //         }

  //         else if (error && error?.response?.status === 401 && error?.response?.data?.error !== 'invalid_token') {
  //
  //             return new Promise((resolve, reject) => {
  //                 console.log('login flow : 401 invalid creditin', error);
  //                 reject(error);
  //             });

  //         }

  //         // Try request again with new token
  //         return httpRequest.getNewToken()

  //             .then((token) => {
  //                 // console.log('resolve',token);

  //                 // New request with new token
  //                 const config = error.config;
  //                 config.headers['Authorization'] = `Bearer ${token}`;
  //                 return new Promise((resolve, reject) => {
  //                     axios.request(config).then(response => {
  //                         Promise.resolve(response);
  //                     }).catch((error) => {
  //                         console.log('error', error);
  //                         Promise.reject(error);
  //                     })
  //                 });
  //             })
  //             .catch((error) => {
  //                 console.log('error getNewToken', error);
  //                 Promise.reject(error);
  //             });
  //     });
  // }

  render() {
    return (
    <>
    <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout} 
        />
      <AdminRoutes />
    </>
    );
  }
}

export default connect(null, { updateToasterConfig})(SolutionAdmin);