/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddParkingStepOne from './../AddParkingStepOne';
import AddParkingStepTwo from './../AddParkingStepTwo';
import AddParkingStepThree from './../AddParkingStepThree';
import httpRequest from '../../../../../Utils/httpRequest';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { updateToasterConfig } from './../../../../../../src/redux/modules/app/actions';
import { updateBreadcrumbs } from '../../../../../redux/modules/app/actions';
import { getCookie } from '../../../../../Utils/cookies';
import Terms from '../../../../SolutionAdmin/ParkingManagement/AddParkingModule/Termsconditions';
import { useEffect } from 'react';
// import removeImageService from '../../../../../Utils/removeImageService';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    'Parking Space Details',
    'Tariff & Time',
    'Facilities & Services',
  ];
}

function getStepContent(step, props) {
  switch (step) {
    case 0:
      return (
        <AddParkingStepOne
          search={props.location.search}
          data={props.addParkinSteps.step1}
          updateRootState={props.updateRootState}
          updateChildrenState={props.updateChildrenState}
        />
      );
    case 1:
      return (
        <AddParkingStepTwo
          search={props.location.search}
          data={props.addParkinSteps.step2}
          updateRootState={props.updateRootState}
          updateChildrenState={props.updateChildrenState}
        />
      );
    case 2:
      return (
        <AddParkingStepThree
          search={props.location.search}
          data={props.addParkinSteps.step3}
          updateRootState={props.updateRootState}
          updateChildrenState={props.updateChildrenState}
        />
      );
    default:
      return 'Unknown step';
  }
}

const AddParking = props => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const [skipped, setSkipped] = React.useState(new Set());
  const [showResults, setShowResults] = React.useState(false);
  const [stepTwoNavigation, setAccessStepTwo] = React.useState(false);
  const [stepThreeNavigation, setAccessStepThree] = React.useState(
    false,
  );
  const steps = getSteps();
  useEffect(() => {
    if (props.addParkinSteps.step1 !== undefined) {
      props.addParkinSteps.step1.parkingName.length >= 5 &&
        props.addParkinSteps.step1.description &&
        props.addParkinSteps.step1.contactNumber &&
        props.addParkinSteps.step1.contactEmail &&
        props.addParkinSteps.step1.parkingBusinessType.uid &&
        props.addParkinSteps.step1.location.lat !== 12.9716 &&
        props.addParkinSteps.step1.location.lng !== 77.5946
        ? setAccessStepTwo(true)
        : setAccessStepTwo(false);
    }
    if (props.addParkinSteps.step2 !== undefined) {
      if (
        props.addParkinSteps.step2.twoWheeler.paid === true ||
        props.addParkinSteps.step2.fourWheeler.paid === true ||
        props.addParkinSteps.step2.fleet.paid === true
      ) {
        if (
          props.addParkinSteps.step2.timings.FRIDAY.isChecked ||
          props.addParkinSteps.step2.timings.SATURDAY.isChecked ||
          props.addParkinSteps.step2.timings.SUNDAY.isChecked ||
          props.addParkinSteps.step2.timings.MONDAY.isChecked ||
          props.addParkinSteps.step2.timings.TUESDAY.isChecked ||
          props.addParkinSteps.step2.timings.WEDNESDAY.isChecked ||
          props.addParkinSteps.step2.timings.THURSDAY.isChecked
        ) {
          
            for (var k in props.addParkinSteps.step2) {
              if (props.addParkinSteps.step2[k].paid === true) {
                if (
                  props.addParkinSteps.step2[k].basePrice > 0 &&
                  props.addParkinSteps.step2[k].basePrice !== '' &&
                  props.addParkinSteps.step2[k].additionalPrice > 0 &&
                  props.addParkinSteps.step2[k].additionalPrice !== ''
                ) {
                  setAccessStepThree(true);
                } else {
                  setAccessStepThree(false);
                }
              }
            }
          
        } else {
          setAccessStepThree(false);
        }
      } else {
        props.addParkinSteps.step2.twoWheeler.paid ||
          props.addParkinSteps.step2.fourWheeler.paid ||
          props.addParkinSteps.step2.fleet.paid ||
          props.addParkinSteps.step2.timings.FRIDAY.isChecked ||
          props.addParkinSteps.step2.timings.SATURDAY.isChecked ||
          props.addParkinSteps.step2.timings.SUNDAY.isChecked ||
          props.addParkinSteps.step2.timings.MONDAY.isChecked ||
          props.addParkinSteps.step2.timings.TUESDAY.isChecked ||
          props.addParkinSteps.step2.timings.WEDNESDAY.isChecked ||
          props.addParkinSteps.step2.timings.THURSDAY.isChecked
          ? setAccessStepThree(true)
          : setAccessStepThree(false);
      }
    }
  }, [steps]);

  const totalSteps = () => {
    return getSteps().length;
  };
  const cancalPopup = () => {
    setShowResults(false);
  };
  const isStepOptional = step => {
    return step === 1;
  };

  const skippedSteps = () => {
    return skipped.size;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps() - skippedSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const UserDataSuccess = jsonResult => {
    let key = 'parkingId';
    var imageIdKey = [];
    let value = jsonResult.data.parkingId;
    props.updateChildrenState('step1', key, value);
    
      try {
        activeStep == 2 && uploadImage(value);

        if (
          props.addParkinSteps.step1.deleteImagesFromSpace.length > 0
        ) {
          props.addParkinSteps.step1.imageId.map((item, index) => {
            props.addParkinSteps.step1.deleteImagesFromSpace.map(
              (image, index) => {
                if (image === item.data) {
                  imageIdKey.push(item.config.url);
                }
                return <div key={index}></div>;
              },
            );

            return <div key={index}></div>;
          });

          imageIdKey.map((item, index) => {
            let res = item.split('/');
            let imageId = res[9].split('?');
            activeStep == 2 && deleteImage(value, imageId[0]);
            return <div key={index}></div>;
          });
        }
      } catch (err) {
        // console.log(err);
      }
    
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
        // find the first step that has been completed
        props.history.push('/portal/success')
        : activeStep + 1;

    //this.submitStepOne();

    setActiveStep(newActiveStep);
  };

  const getvehicleTypeInfo = step2Data => {
    let result = [];
    for (let vehicleType in step2Data) {
      let data = {};

      if (step2Data[vehicleType].totalSlots) {
        const currentData = step2Data[vehicleType];
        data = {
          totalSlots: currentData.totalSlots,
          vehicleType: {
            uid: currentData.uid,
          },
          paid: currentData.paid,
        };
        if (currentData.paid) {
          data.priceInfo = {
            baseHour: Number(currentData.baseHour),
            basePrice: currentData.basePrice,
            additionalHour: Number(currentData.additionalHour),
            additionalPrice: currentData.additionalPrice,
            pricePerDay: currentData.pricePerDay,
            pricePerWeek: currentData.pricePerWeek,
            pricePerMonth: currentData.pricePerMonth,
            selectedLongterm: currentData.selectedLongterm,
          };
        }
      }
      if (Object.keys(data).length) {
        result.push(data);
      }
    }
    return result;
  };

  const getcustomOperatingHoursInfo = customParam => {
    let result = [];
    customParam.customData.map(item => {
      result.push({
        startDate: item.startDate,
        endDate: item.endDate,
        openingTime: item.openingTime,
        closingTime: item.closingTime,
      });
    });
    return result;
  };

  const getServices = param => {
    let result = [];
    param.services.map(item => {
      result.push({
        uid: item.uid,
        distanceInMeters: item.distanceInMeters,
      });
    });
    // console.log(result);
    return result;
  };
  const getOperatingHoursInfo = params => {
    let result = {};
    for (let day in params) {
      if (params[day].isChecked && day !== 'applyForAll') {
        result[day] = [
          {
            openingTime: params[day].time.openingTime,
            closingTime: params[day].time.closingTime,
          },
        ];
      }
    }
    return result;
  };

  const createRequestBody = params => {
    switch (activeStep) {
      case 0:
        {
          return {
            parkingId: params.step1.parkingId || '',
            parkingBusinessType: params.step1.parkingBusinessType,
            addressDetails: {
              addressLine1: params.step1.addressDetails.addressLine1,
              addressLine2:
                params.step1.addressDetails.addressLine2 !== ''
                  ? params.step1.addressDetails.addressLine2
                  : '.',
              city: params.step1.addressDetails.city,
              state: params.step1.addressDetails.state,
              pincode: params.step1.addressDetails.pincode,
            },

            location: params.step1.location,
            name: params.step1.parkingName,
            description: params.step1.description,
            contactNumber: params.step1.contactNumber,
            contactEmail: params.step1.contactEmail,
          };
        }
        
      case 1:
        {
          return {
            parkingId: params.step1.parkingId,
            parkingBusinessType: params.step1.parkingBusinessType,
            addressDetails: {
              addressLine1: params.step1.addressDetails.addressLine1,
              addressLine2: 'addressline2',
              city: params.step1.addressDetails.city,
              state: params.step1.addressDetails.state,
              pincode: params.step1.addressDetails.pincode,
            },
            location: params.step1.location,
            name: params.step1.parkingName,
            description: params.step1.description,
            contactNumber: params.step1.contactNumber,
            contactEmail: params.step1.contactEmail,
            vehicleTypeInfo: getvehicleTypeInfo(params.step2),
            operatingHoursInfo: getOperatingHoursInfo(
              params.step2.timings,
            ),
            customOperatingHoursInfo: getcustomOperatingHoursInfo(
              params.step2,
            ),
          };
        }
        
      case 2:
        {
          return {
            parkingId: params.step1.parkingId,
            parkingBusinessType: params.step1.parkingBusinessType,
            addressDetails: {
              addressLine1: params.step1.addressDetails.addressLine1,
              addressLine2: 'addressline2',
              city: params.step1.addressDetails.city,
              state: params.step1.addressDetails.state,
              pincode: params.step1.addressDetails.pincode,
            },
            location: params.step1.location,
            name: params.step1.parkingName,
            description: params.step1.description,
            contactNumber: params.step1.contactNumber,
            contactEmail: params.step1.contactEmail,
            vehicleTypeInfo: getvehicleTypeInfo(params.step2),
            operatingHoursInfo: getOperatingHoursInfo(
              params.step2.timings,
            ),
            customOperatingHoursInfo: getcustomOperatingHoursInfo(
              params.step2,
            ),
            facilities: params.step3.facilities,
            services: getServices(params.step3),
            eula: params.step3.eula,
            isHardwareInterested: params.step3.isHardwareInterested,
          };
        }
        default: return
    }
  };

  const errorCallback = error => {
    if (error.message) {
      props.updateToasterConfig({
        show: true,
        message: error.message,
        color: '#FF0000',
      });
    }
  };

  const uploadImage = value => {
    // console.log("Image number: ",props.addParkinSteps.step1.images.length);
    let imges = props.addParkinSteps.step1.images.filter(
      item => item.name,
    );
    let formData = new FormData();
    for (let i = 0; i < imges.length; i++) {
      formData.append('file', imges[i].file);
    }
    // console.log(formData);

    imges.length &&
      httpRequest
        .post(
          'uploadsImages',
          formData,
          {
            'content-type': 'multipart/form-data',
          },
          UserImageSuccess,
          errorCallback,
          { parkingId: value },
        )

        .then(() => { })
        .catch(() => { });
  };
  const deleteImage = (value, imageId) => {
    httpRequest
      .delete(
        'deleteImages',
        {
          'content-type': 'multipart/form-data',
          Authorization: 'bearer ' + getCookie('auth'),
        },
        UserImageSuccess,
        errorCallback,
        {
          parkingId: props.addParkinSteps.step1.parkingId,
          imageId: imageId,
        },
      )

      .then(() => { })
      .catch(() => { });
  };
  const UserImageSuccess = () => { };
  const handleNext = () => {
    if (activeStep === 1) {
      if (
        props.addParkinSteps.step2.twoWheeler.totalSlots >= 1 ||
        props.addParkinSteps.step2.fleet.totalSlots >= 1 ||
        props.addParkinSteps.step2.fourWheeler.totalSlots >= 1
      ) {
        // if statement here
      } else {
        props.updateToasterConfig({
          show: true,
          message: 'Parking slot of any vehicle should be atleast 1',
          color: '#FF0000',
        });
        return false;
      }
    }
    if (activeStep === 1) {
      var anyOneCustomDate = props.addParkinSteps.step2.customData.some(
        function (e) {
          return (
            e.startDate === '0000-00-00' && e.endDate === '0000-00-00'
          );
        },
      );
      if (anyOneCustomDate) {
        props.updateToasterConfig({
          show: true,
          message: 'Custom dates should not be 0000-00-00',
          color: '#FF0000',
        });
      }
    }
    if (
      activeStep === 2 &&
      !props.addParkinSteps.step3.eula.eulaAccepted
    ) {
      var lessThanOne = props.addParkinSteps.step3.services.some(
        function (e) {
          return e.distanceInMeters < 1;
        },
      );
      if (lessThanOne) {
        setTimeout(() => {
          props.updateToasterConfig({
            show: true,
            message: 'Service distance should be greater then 0',
            color: '#FF0000',
          });
        }, 6000);
      } else {
        setShowResults(true);
        return false;
      }
    }
    if (
      !props.addParkinSteps.step1.contactEmail ||
      !props.addParkinSteps.step1.parkingName ||
      !props.addParkinSteps.step1.parkingBusinessType ||
      !props.addParkinSteps.step1.location ||
      !props.addParkinSteps.step1.contactNumber ||
      !props.addParkinSteps.step1.description
      // !props.addParkinSteps.step1.images.length
    ) {
      props.updateToasterConfig({
        show: true,
        message:
          'Please fill all the input fields from the Step 1 to submit your parking',
        color: '#FF0000',
      });
    } else {
      if (props.location.search !== '') {
        // console.log(props.addParkinSteps);
        httpRequest.patch(
          'editParking',
          createRequestBody({ ...props.addParkinSteps }),
          {},
          UserDataSuccess,
          errorCallback,
          {
            userId: getCookie('userId'),
            parkingId: props.addParkinSteps.step1.parkingId,
          },
        );
      } else {
        // console.log(props.addParkinSteps);
        httpRequest.post(
          'addParking',
          createRequestBody({ ...props.addParkinSteps }),
          {},
          UserDataSuccess,
          errorCallback,
          { userId: getCookie('userId') },
        );
      }
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStep = step => () => {
    if (stepTwoNavigation && step <= 1) {
      setActiveStep(step);
    }
    if (stepThreeNavigation && stepTwoNavigation) {
      if (activeStep === 1) {
        if (
          props.addParkinSteps.step2.twoWheeler.totalSlots >= 1 ||
          props.addParkinSteps.step2.fleet.totalSlots >= 1 ||
          props.addParkinSteps.step2.fourWheeler.totalSlots >= 1
        ) {
          // if statement here
        } else {
          props.updateToasterConfig({
            show: true,
            message:
              'Parking slot of any vehicle should be atleast 1',
            color: '#FF0000',
          });
          return false;
        }
      }
      setActiveStep(step);
    }
  };

  // const handleComplete = () => {
  //   const newCompleted = new Set(completed);
  //   newCompleted.add(activeStep);
  //   setCompleted(newCompleted);

  //   if (completed.size !== totalSteps() - skippedSteps()) {
  //     handleNext();
  //   }
  // };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted(new Set());
    setSkipped(new Set());
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  function isStepComplete(step) {
    return completed.has(step);
  }

  return (
    <div className="addparking">
      <div className="addparking__wrapper">
        <div className={classes.root}>
          <div className="stepper">
            <Stepper
              alternativeLabel
              nonLinear
              activeStep={activeStep}
            >
              {steps.map((label, index) => {
                const stepProps = {};
                const buttonProps = {};
                if (isStepOptional(index)) {
                  buttonProps.optional = (
                    <Typography variant="caption"></Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepButton
                      onClick={handleStep(index)}
                      completed={isStepComplete(index)}
                      icon={'0' + (index + 1)}
                      {...buttonProps}
                    >
                      {label}
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>

            {allStepsCompleted() ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
                <div>
                  <Typography className={classes.instructions}>
                    {getStepContent(activeStep, props)}
                  </Typography>
                  <div className="addparking__wrapper_bottombuttons">
                    <div className="next">
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Previous
                    </Button>
                    </div>
                    <div>
                      {activeStep === 1 ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          disabled={
                            stepThreeNavigation && stepTwoNavigation
                              ? false
                              : true
                          }
                          className={classes.button}
                        >
                          Next
                        </Button>
                      ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            disabled={stepTwoNavigation ? false : true}
                            className={classes.button}
                          >
                            Next
                          </Button>
                        )}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      {showResults ? (
        <Terms
          data={props.addParkinSteps.step3}
          updateRootState={props.updateRootState}
          updateChildrenState={props.updateChildrenState}
          next={handleNext}
          cancal={cancalPopup}
        />
      ) : null}
    </div>
  );
};

export default withRouter(
  connect(
    state => ({
      auth: state.app.auth,
    }),
    { updateToasterConfig, updateBreadcrumbs },
  )(AddParking),
);
