/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { Component, Suspense, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import { Redirect } from "react-router";
import Login from "./../Pages/Login/LoginDashboard";
import ForgotPassword from "./../Pages/Login/ForgotPassword";
import DataPrivacy from "../Pages/DataPrivacy/DataPrivacy";
import ParkingManagement from "./../Pages/SolutionAdmin/ParkingManagement";
import { connect } from "react-redux";
import { updateAuth } from "../redux/modules/app/actions";
import { withRouter } from "react-router-dom";

class Routes extends Component {
  componentDidMount() {
    // if (getCookie("auth")) {
    //   this.props.updateAuth(getCookie("auth"));
    // } else {
    //   this.props.history.push("/");
    // }
  }
  render() {
    return (
      <Suspense fallback={null}>
        <Switch>
          <Route
            exact
            path="/"
            component={() => {
              return <Redirect to="/login"></Redirect>;
            }}
          />
          <Route exact path="/login" component={Login} />
          <Route exact path="/dataprivacynotice" component={DataPrivacy}/>
          <Route path="/forgot_password" component={ForgotPassword} />
          {this.props.auth && (
            <Fragment>
              <Route exact path="/portal" component={ParkingManagement} />
              <Route exact path="/portal/*" component={ParkingManagement} />
            </Fragment>
          )}
        </Switch>
      </Suspense>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      auth: state.app.auth,
    }),
    { updateAuth }
  )(Routes)
);
