/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import httpRequest from './../../../../../Utils/httpRequest';
import { DebounceInput } from 'react-debounce-input';
import SearchIcon from '@material-ui/icons/Search';
import Checkbox from '@material-ui/core/Checkbox';

import './style.scss';
var selectedFacility = [];

const isFacilitySelected = (data, uid) => {
  const result = data.filter(item => item.uid == uid);
  return result.length ? true : false;
};

const ParkingFacilities = props => {
  const facilitySuccess = jsonResult => {
    setFacility(jsonResult.data.facilities.results);
    setParkingList(jsonResult.data.facilities.results);
  };
  const errorCallback = () => { };
  const [facility, setFacility] = useState([]);
  const [parkingList, setParkingList] = useState([]);
  const [searchFacility, setSearchFacility] = useState(false);
  useEffect(() => {
    httpRequest.get('facility', {}, facilitySuccess, errorCallback);
  }, []);

  const handleSearchKeyChange = value => {
    if (!value) {
      setParkingList([...facility]);
      setSearchFacility(false);
    } else {
      setSearchFacility(true);
      let result = parkingList.filter(item => {
        return item.name.toLowerCase().includes(value.toLowerCase());
      });

      setParkingList([...result]);
    }
  };

  return (
    <div className="ParkingFacilities">
      <div className="ParkingFacilities__wrapper">
        <div className="ParkingFacilities__wrapper_heading">
          <p> Parking Facilities</p>
        </div>
        <div className="ParkingFacilities__wrapper_box">
          <div className="ParkingFacilities__wrapper_box_search">
            <SearchIcon />
            <div className="ParkingOverview-navbar__wrapper_nav_left_search">
              <DebounceInput
                style={{ fontSize: '13px' }}
                placeholder="Search and add parking facilities"
                minLength={1}
                debounceTimeout={500}
                onChange={event =>
                  handleSearchKeyChange(event.target.value)
                }
              />
            </div>
          </div>

          <Scrollbars style={{ height: 420, width: 340 }}>
            {!searchFacility &&
              facility.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="ParkingFacilities__wrapper_box_facilities"
                  >
                    <div className="ParkingFacilities__wrapper_box_facilities_left">
                      <div className="ParkingFacilities__wrapper_box_facilities_left">
                        <div className="icon_container">
                          <img
                            src={item._links.image.href}
                            alt="facilityicon"
                          />
                        </div>
                        <p className="ParkingFacilities__wrapper_box_facilities_left_text">
                          {item.name}
                        </p>
                      </div>
                      <div className="ParkingFacilities__wrapper_box_facilities_right">
                        <Checkbox
                          color="default"
                          checked={isFacilitySelected(
                            props.data.facilities,
                            item.uid,
                          )}
                          onChange={() => {
                            const indexValue = selectedFacility.indexOf(
                              parseInt(item.uid),
                            );

                            indexValue > -1
                              ? selectedFacility.splice(
                                selectedFacility.findIndex(
                                  e => e === parseInt(item.uid),
                                ),
                                1,
                              )
                              : selectedFacility.push(
                                parseInt(item.uid),
                              );

                            const setID = item => {
                              var fullObject = { uid: +item };
                              return fullObject;
                            };
                            var output = selectedFacility.map(setID);

                            props.updateChildrenState(
                              'step3',
                              'facilities',
                              output,
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            {searchFacility &&
              parkingList.map((item, index) => {
                return (
                  <div
                    className="ParkingFacilities__wrapper_box_facilities"
                    key={index}
                  >
                    <div className="ParkingFacilities__wrapper_box_facilities_left">
                      <div className="ParkingFacilities__wrapper_box_facilities_left">
                        <div className="icon_container">
                          <img
                            src={item._links.image.href}
                            alt="facilityicon"
                          />
                        </div>
                        <p className="ParkingFacilities__wrapper_box_facilities_left_text">
                          {item.name}
                        </p>
                      </div>
                      <div className="ParkingFacilities__wrapper_box_facilities_right">
                        <Checkbox
                          color="default"
                          checked={isFacilitySelected(
                            props.data.facilities,
                            item.uid,
                          )}
                          onChange={() => {
                            const indexValue = selectedFacility.indexOf(
                              parseInt(item.uid),
                            );

                            indexValue > -1
                              ? selectedFacility.splice(
                                selectedFacility.findIndex(
                                  e => e === parseInt(item.uid),
                                ),
                                1,
                              )
                              : selectedFacility.push(
                                parseInt(item.uid),
                              );

                            const setID = item => {
                              var fullObject = { uid: +item };
                              return fullObject;
                            };
                            var output = selectedFacility.map(setID);

                            props.updateChildrenState(
                              'step3',
                              'facilities',
                              output,
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </Scrollbars>
        </div>
        <p
          style={{
            color: '#03A9F3',
            textDecoration: 'underline',
            fontSize: '16px',
            paddingTop: '15px',
          }}
        >
          My Parking Facility is not listed here
        </p>
      </div>
    </div>
  );
};

export default ParkingFacilities;
