/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import {getCookie} from './../Utils/cookies'
const UrlConfig = function(key, params) {
  let urlsEndPoints = {
    configUrl: "user-vehicle-service/api/v1/config",
    loginUrl:
      "auth/realms/"+getCookie('realmName')
      +"/protocol/openid-connect/token",
    createUser: "user-vehicle-service/api/v1/user",
    userData: "user-vehicle-service/api/v1/user/",
    forgotPassword:
      "aaaservice/api/v1/realms/"+getCookie('realmName')+"/users/forgetPassword",
    changePassword: "user-vehicle-service/api/v1/user/changePassword",
    dashboardData:
      "b2b-parking-service/api/v1/user/" +
      (params ? params.userId : "") +
      "/dashboardCount",
    services: "b2b-parking-service/api/v1/listOfValues",
    parkingListSearch:
      "b2b-parking-service/api/v1/parking/listView?size=" +
      (params ? params.sizeCount : "") +
      "&page=" +
      (params ? params.pageCount : "") +
      "&name=" +
      (params ? params.searchText : "") +
      "&sortBy=LAST_MODIFIED_DATE.DESC&selectAttributes=BASIC_INFO,ORG_INFO,BUSINESS_TYPE_INFO,STATUS_CHANGE_DATE",
    parkingList:
      "b2b-parking-service/api/v1/parking/listView?size=" +
      (params ? params.sizeCount : "") +
      "&page=" +
      (params ? params.pageCount : "") +
      "&status=" +
      (params ? params.filterStatus : "") +
      "&sortBy=LAST_MODIFIED_DATE.DESC&selectAttributes=BASIC_INFO,ORG_INFO,BUSINESS_TYPE_INFO,STATUS_CHANGE_DATE",
    parkingdetail:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "?selectAttributes=BASIC_INFO,ORG_INFO,SLOT_INFO,OPERATING_HOURS_INFO,PRICE_INFO,BUSINESS_TYPE_INFO,FACILITIES_INFO,SERVICES_INFO,PROVIDER_INFO,VENDOR_INFO",
    addParking:
      "b2b-parking-service/api/v1/user/" +
      (params ? params.userId : "") +
      "/parking",
    deleteParking:
      "b2b-parking-service/api/v1/parking/" + (params ? params.parkingId : ""),
    ratingData:
      "b2b-parking-service/api/v1/user/" +
      (params ? params.userId : "") +
      "/parking/rating",
    reviewData:
      "b2b-parking-service/api/v1/user/" +
      (params ? params.userId : "") +
      "/parking/review",
    uploadsImages:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/image",
    deleteImages:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/image/" +
      (params ? params.imageId : ""),
    detailReview:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/review?page=0&size=999",
    slotUtilization:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/slotUtilization?fromDate=" +
      (params ? params.date : "") +
      "",
    parkingdetailSlot:
      "b2b-parking-service/api/v1" +
      "/parking/" +
      (params ? params.parkingId : "") +
      "?selectAttributes=SLOT_INFO",
    detailsImg:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/image",
    detailsImgTwo: params ? params.detailsImgTwo : "",
    editParking:
      "b2b-parking-service/api/v1/user/" +
      (params ? params.userId : "") +
      "/parking/" +
      (params ? params.parkingId : ""),
    userProfile:
      "user-vehicle-service/api/v1/userManagement/" +
      (params ? params.userId : ""),
    deactivatePS:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/deActivate/",
    activatePS:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/activate/",
    gatewayDisplay:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/device-management",
    logout: "user-vehicle-service/api/v1/user/logout",
    facility: "b2b-parking-service/api/v1/listOfValues",
    parkingStatus:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/statusUpdate",
    eulaText:
      "user-vehicle-service/api/v1/role/CLOSED_GROUP_PARKING_PROVIDER/eulaText",
    coupleDevices:
      "b2b-parking-service/api/v1/parking/device-management/" +
      (params ? params.deviceId : "") +
      "/couple",
    approvedParkingList:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.orgId : "") +
      "/devicenotCoupled?status=APPROVED&currentStatus=APPROVED",
    regDeviceList:
      "b2b-parking-service/api/v1/parking/device-management?status=COMMISSIONED,UNCOMMISSIONED,PROVISIONED&isPaginationRequired=false",
    mtaOrgList:
      "user-vehicle-service/api/v1/org?size=10&page=0&selectAttributes=EMAIL_DOMAINS&displayMultitenantOrg=true",
    bulkUpload:
      "b2b-parking-service/api/v1/parking/bulkUpload?templateType=FLATTEN_TEMPLATE_V1",
    userManagement:
      "user-vehicle-service/api/v1/userManagement?size=99999&page=0&roles=OPEN_GROUP_END_USER",
    parkingRequestStatusFlow:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/parkingRequestStatusFlow",
    parkingDeviceAssigned:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/device-management",
    parkingDeviceProvisioned:
      "b2b-parking-service/api/v1/parking/device-management?status=PROVISIONED&isPaginationRequired=false&selectAttributes=NAME",
    parkingDeviceCoupled:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/device-management/" +
      (params ? params.deviceId : "") +
      "/couple",
    parkingDeviceDecoupled:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/device-management/" +
      (params ? params.deviceId : "") +
      "/decouple",
    updateVerifyParking:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/verifiedTagUpdate?value=" +
      (params ? params.valueA : ""),
    reportParkingListMt:
      "b2b-parking-service/api/v1/parking/listView?selectAttributes=NAME&status=APPROVED&isPaginationRequired=false&mtaSecurityFlag=true",
    currentVersionGetter: "user-vehicle-service/api/v1/appVersion",
    complaint: "b2b-parking-service/api/v1/complaint?page=0&size=99999",
    complaintSort:
      "b2b-parking-service/api/v1/complaint?sortBy=CREATED_DATE." +
      (params ? params.status : "") +
      "&page=0&size=99999",
    complaintStatus:
      "b2b-parking-service/api/v1/complaint/" +
      (params ? params.complaintId : ""),
    soladminlist:
      "user-vehicle-service/api/v1/userManagement?size=10&page=" +
      (params ? params.page : "") +
      "&selectAttributes=ORG_INFO,ADDRESS_INFO&sortBy=LAST_MODIFIED_DATE.DESC&roles=CLOSED_GROUP_PARKING_PROVIDER,MULTITENANT_ADMIN,RESIDENTIAL_GROUP_PARKING_PROVIDER",
    admindetails:
      "user-vehicle-service/api/v1/userManagement/" +
      (params ? params.userId : ""),
    realmList: "user-vehicle-service/api/v1/tenant",
    orgList:
      "user-vehicle-service/api/v1/org?size=10&page=" +
      (params ? params.page : "") +
      "&sortBy=LAST_MODIFIED_DATE.DESC",
    createAdmin: "user-vehicle-service/api/v1/userManagement",
    orgUpdate:
      "user-vehicle-service/api/v1/org/" + (params ? params.orgId : ""),
    orgListdropdown: "user-vehicle-service/api/v1/org?page=0&size=999",
    subListdropdown: "subscription-service/api/v1/parking/subscription",
    //deactivate subscription
    deactivateSubscription:
      "subscription-service/api/v1/deActivateSubscription",
    activateSubscription: "subscription-service/api/v1/activateSubscription",
    superAdminView:
      "subscription-service/api/v1/getsubscription/?organizationId=" +
      (params ? params.orgId : "") +
      "&roleName=" +
      (params ? params.role : ""),
    ///----------save subscription-----------------------

    orgDelete:
      "user-vehicle-service/api/v1/org/" + (params ? params.orgId : ""),
    addOrg: "user-vehicle-service/api/v1/org",
    editOrg:
      "user-vehicle-service/api/v1/userManagement/" +
      (params ? params.userId : ""),
    orgDetailsbyId:
      "user-vehicle-service/api/v1/org/" + (params ? params.orgId : ""),
    adminDelete: "user-vehicle-service/api/v1/userManagement",
    editAdmin:
      "user-vehicle-service/api/v1/userManagement/" +
      (params ? params.userId : ""),
    orgUserCount:
      "user-vehicle-service/api/v1/org/user/count?orgIds=" +
      (params ? params.orgId : ""),
    coupleDeviceListView:
      "b2b-parking-service/api/v1/device-management-mta-coupled?isPaginationRequired=true&size=1&page=" +
      (params ? params.pageNumber : ""),
    decoupleParking:
      "b2b-parking-service/api/v1/parking/" +
      (params ? params.parkingId : "") +
      "/device-management-mta/" +
      (params ? params.deviceId : "") +
      "/decouple",
  };
  //DEV ENV
  if (
    window.location.host === "localhost:3000" ||
    window.location.host === "dev-superadmin.parkzeus.in"
  ) {
    const baseUrlPZ = "https://dev.parkzeus.in/";
    const baseUrlMCP = "https://qa-aaa.boschindia-mobilitysolutions.com/";
    const baseUrlMCPForgotpw = "https://qa.boschindia-mobilitysolutions.com/";
    if (key === "forgotPassword") {
      // MCP base url
      return baseUrlMCPForgotpw + urlsEndPoints[key];
    } else if (key === "loginUrl") {
      // MCP base url
      return baseUrlMCP + urlsEndPoints[key];
    } else {
      // all other PZ
      return baseUrlPZ + urlsEndPoints[key];
    }
  }
  //QA ENV
  else if (
    window.location.host === "localhost:3001" ||
    window.location.host === "beta-superadmin.parkzeus.in"
  ) {
    const baseUrlPZ = "https://beta.parkzeus.in/";
    const baseUrlMCP = "https://qa-aaa.boschindia-mobilitysolutions.com/";
    const baseUrlMCPForgotpw = "https://qa.boschindia-mobilitysolutions.com/";
    if (key === "forgotPassword") {
      // MCP base url
      return baseUrlMCPForgotpw + urlsEndPoints[key];
    } else if (key === "loginUrl") {
      // MCP base url
      return baseUrlMCP + urlsEndPoints[key];
    } else {
      // all other PZ
      return baseUrlPZ + urlsEndPoints[key];
    }
  }
  //PRODUCTION ENV
  else if (window.location.host === "superadmin.parkzeus.in") {
    const baseUrlPZ = "https://services.parkzeus.in/";
    const baseUrlMCP = "https://aaa.boschindia-mobilitysolutions.com/";
    const baseUrlMCPForgotpw = "https://boschindia-mobilitysolutions.com/";
    if (key === "forgotPassword") {
      // MCP base url
      return baseUrlMCPForgotpw + urlsEndPoints[key];
    } else if (key === "loginUrl") {
      // MCP base url
      return baseUrlMCP + urlsEndPoints[key];
    } else {
      // all other PZ
      return baseUrlPZ + urlsEndPoints[key];
    }
  }
};

export default UrlConfig;
