/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
const parkingTabData = {
  baseHour: 1,
  basePrice: '',
  additionalHour: 1,
  additionalPrice: '',
  pricePerDay: 0,
  pricePerWeek: 0,
  pricePerMonth: 0,
  paid: false,
  totalSlots: 0,
  selectedLongterm: 'pricePerDay',
};

export const addParkinSteps = {
  step1: {
    parkingId: '',
    parkingName: '',
    contactNumber: '',
    contactEmail: '',
    parkingBusinessType: {
      uid: '',
    },
    description: '',
    images: [],
    addressDetails: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      pincode: '',
    },
    location: {
      lat: '',
      lng: '',
    },
  },

  step2: {
    twoWheeler: {
      ...parkingTabData,
      uid: 2,
    },
    fourWheeler: {
      ...parkingTabData,
      uid: 1,
    },
    fleet: {
      ...parkingTabData,
      uid: 3,
    },

    timings: {
      applyForAll: {
        isChecked: false,
        time: {
          openingTime: '09:00:00',
          closingTime: '21:00:00',
        },
      },

      MONDAY: {
        isChecked: false,
        time: {
          openingTime: '09:00:00',
          closingTime: '21:00:00',
        },
      },
      TUESDAY: {
        isChecked: false,
        time: {
          openingTime: '09:00:00',
          closingTime: '21:00:00',
        },
      },
      WEDNESDAY: {
        isChecked: false,
        time: {
          openingTime: '09:00:00',
          closingTime: '21:00:00',
        },
      },
      THURSDAY: {
        isChecked: false,
        time: {
          openingTime: '09:00:00',
          closingTime: '21:00:00',
        },
      },
      FRIDAY: {
        isChecked: false,
        time: {
          openingTime: '09:00:00',
          closingTime: '21:00:00',
        },
      },
      SATURDAY: {
        isChecked: false,
        time: {
          openingTime: '09:00:00',
          closingTime: '21:00:00',
        },
      },
      SUNDAY: {
        isChecked: false,
        time: {
          openingTime: '09:00:00',
          closingTime: '21:00:00',
        },
      },
    },
    customData: [],
  },
  step3: {
    services: [],
    facilities: [],
    selectedCheckbox: [],
    servicesNearBy: {
      busStand: {
        isChecked: false,
        distance: '100:00',
      },
      metroStation: {
        isChecked: false,
        distance: '0',
      },
      autoStand: {
        isChecked: false,
        distance: '0',
      },
      cabPick: {
        isChecked: false,
        distance: '0',
      },
      sharedMobility: {
        isChecked: false,
        distance: '0',
      },
    },
    eula: {
      eulaAccepted: false,
      eulaVersion: '1',
    },
    isHardwareInterested: false,
  },
};
export const breadcrumbs = {
  parkingManagement: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Parking Management' },
  ],
  profile: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Profile' },
  ],
  listParkingSpace: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Parking Management', link: '/portal/parkingmanagment' },
    { name: 'List My Parking Space' },
  ],
  listParkingSpaceStep1: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Parking Management', link: '/portal/parkingmanagment' },
    { name: 'List My Parking Space - Step 1' },
  ],
  listParkingSpaceStep2: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Parking Management', link: '/portal/parkingmanagment' },
    { name: 'List My Parking Space - Step 2' },
  ],
  listParkingSpaceStep3: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Parking Management', link: '/portal/parkingmanagment' },
    { name: 'List My Parking Space - Step 3' },
  ],
  EditParkingSpaceStep1: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Parking Management', link: '/portal/parkingmanagment' },
    { name: 'Edit My Parking Space ' },
  ],
  EditParkingSpaceStep2: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Parking Management', link: '/portal/parkingmanagment' },
    { name: 'Edit My Parking Space ' },
  ],
  EditParkingSpaceStep3: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Parking Management', link: '/portal/parkingmanagment' },
    { name: 'Edit My Parking Space ' },
  ],
  parkingDetails: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Parking Management', link: '/portal/parkingmanagment' },
    { name: 'Parking Profile' },
  ],
  roleManagement: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Role Management' },
  ],
  reports: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Reports' },
  ],
  reportsParkedUsers: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Reports', link: '/portal/report' },
    { name: 'Parked Users' }
  ],
  reportsRegisterUsers: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Reports', link: '/portal/report' },
    { name: 'Registered End Users' }
  ],
  reportsDeregisterUsers: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Reports', link: '/portal/report' },
    { name: 'De-Registered End Users' }
  ],
  reportsParkingSpace: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Reports', link: '/portal/report' },
    { name: 'Parking Space' }
  ],
  bulkUpload: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Parking Management', link: '/portal/parkingmanagment' },
    { name: 'Bulk Upload' },
  ],
  userManagement: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'User Management' },
  ],
  deviceManagement: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Device Management' }
  ],
  coupleDevice: [
    { name: 'Home', link: '/portal/parkingmanagment'},
    { name: 'Device Management', link:'/portal/devicemanagement' },
    { name: 'Couple Device'}
  ],
  forceUpdate: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Force Update' },
  ],
  complaint: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Complaint Management' },
  ],
  complaintdetails: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Complaint Management', link: '/portal/complaint' },
    { name: 'Complaint Details' },
  ],
  viewaccount: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Account Management', link: '/portal/viewaccount' },
    { name: 'View Account Management' }
  ],
  viewaccdetails: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Account Management', link: '/portal/viewaccount' },
    { name: 'View Details Account Management' },
  ],
  createaccount: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Account Management', link: '/portal/viewaccount' },
    { name: 'Create Admin Account' }
  ],
  editaccount: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Account Management', link: '/portal/viewaccount' },
    { name: 'Edit Admin Account' }
  ],
  addorganization: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Organization Management', link: '/portal/vieworganizations' },
    { name: 'Add Organization' }
  ],
  editorganization: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Organization Management', link: '/portal/vieworganizations' },
    { name: 'Edit Organization' }
  ],
  vieworganization: [
    { name: 'Home', link: '/portal/parkingmanagment' },
    { name: 'Organization Management', link: '/portal/vieworganizations' },
    { name: 'View Organization' }
  ],
};
