/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { Component } from 'react';
import './style.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import {
  Card,
  Paper,
  TableContainer,
  Grid,
  Tooltip,
  Button,
} from '@material-ui/core';


import StatusLog from '../StatusLog';
import DeviceLog from '../DeviceLog';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import address from '../../../../../Assets/Images/address.png';

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AssignmentIcon from '@material-ui/icons/Assignment';

import { updateAuth } from '../../../../../redux/modules/app/actions';
import { withRouter } from 'react-router';
import httpRequest from './../../../../../Utils/httpRequest';
import { connect } from 'react-redux';
import { updateBreadcrumbs } from '../../../../../redux/modules/app/actions';

import info from '../../../../../Assets/Images/info.png';

import {
  getCookie
} from '../../../../../Utils/cookies';
import { updateToasterConfig } from './../../../../../../src/redux/modules/app/actions';

import ParkingDetailsTab from '../ParkDetailsTab';
import TextField from '@material-ui/core/TextField';

import Loader from '../../../../../Components/Preloader'

let parkingId = null;

class ParkingDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading:false,
      imgLink: '',
      parkingDetail: { name: '' },
      pageImg: [],
      imgData: [],
      passPop: false,
      setopen: false,
      displayStatus: '',
      isActive: false,
      tabIndex: 1,
      passPopA: false,
      passPopD: false,
      orgData:{},
      imgYes:false,

    };
    this.handler = this.handler.bind(this)
    this.displayStatusHandler = this.displayStatusHandler.bind(this)
  }
  handler(nextState) {
    this.setState({
      displayStatus: nextState
    })
    
  }

  //function to set displayStatus state value
  displayStatusHandler(){
   /*  this.setState({
      displayStatus: value
    }) */
    //this.getDetails().bind(this);
    // console.log("parent value: ",value)
  }


  gatewayDisplaySuccess = () => {
    // console.log(gateWayresponse);
  };


  activatePSMethod = () => {
    parkingId = this.props.match.params.id;
    httpRequest
      .patch(
        'activatePS',
        {
          Authorization: 'Bearer ' + getCookie('auth'),
          realmname: getCookie('realmName'),
        },
        this.props.updateToasterConfig({
          show: true,
          message: 'The Parking has been activated',
          color: 'rgb(103, 181, 97)',
        }),
        console.log(this.props.match.params.id),
        this.errorCallback,
        {
          parkingId: this.props.match.params.id,
        },
      )
      .then(() => {
        this.setState({
          displayStatus: "Approved",
          isActive: true,
        })
        window.location.href='/portal/parking-details/'+this.props.match.params.id;
      })
      .catch(() => {
        this.props.updateToasterConfig({
          show: true,
          message: 'Something went wrong, try again',
          color: '#ff0000',
        })
       });
  };

  // updateMe = () => {
  //   this.interval = setInterval(() => {
  //     this.getDetails();
  //   }, 10000);
  // };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.updateBreadcrumbs('parkingDetails');
    this.getDetails();
  }
  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

 
  getDetails = () => {
    this.setState({isLoading:true})
    
    parkingId = this.props.match.params.id;
    httpRequest
      .get(
        'parkingdetail',
        {},
        this.UserDataSuccess,
        this.errorCallback,
        {
          parkingId: this.props.match.params.id,
          //userId: getCookie('userId'),
        },
      )
      .then(() => { })
      .catch(() => { this.props.updateToasterConfig({
        show: true,
        message: 'Something went wrong, please try again',
        color: '#03A9F3',
      });});
  };


  imageSuccess = jsonResult => {
    this.setState({
      pageImg: jsonResult.data.imageLinks,
      isLoading:false
    });
    if(this.state.pageImg?.length>0)
    {
      this.setState({
        imgYes:true
      })
    this.state.pageImg
      .forEach(function (each) {
        httpRequest.getImage(
          each.link + '?responseType=BASE64_ENCODE',
          {
            Accept: 'image/png',
          },
          this.imageSuccessTwo,
          this.errorCallback,
          {
            detailsImgTwo: each.link + '?responseType=BASE64_ENCODE',
          },
        );
      }, this)
      .then(() => { })
      .catch(() => { });
    }
    else {
      this.setState({
        imgYes:false
      })
    }
  };

  imageSuccessTwo = jsonResult => {
    // console.log(jsonResult);
    let imgData = this.state.imgData;
    imgData.indexOf(jsonResult.data) == -1 &&
      imgData.push(jsonResult.data);

    this.setState({
      imgData,
      
    });
  };

  UserDataSuccess = jsonResult => {
    // console.log("**********************",);
    this.setState({
      //isLoading:false,
      parkingDetail: jsonResult?.data,
      imgLink: jsonResult?.data?._links?.parkingImages?.href,
      displayStatus: jsonResult?.data?.currentStatus?.displayName,
      orgData:jsonResult?.data?.org
    });
    // console.log(this.state.imgLink)

    httpRequest
      .get(
        'detailsImg',
        {},
        this.imageSuccess,
        this.errorCallback,
        {
          parkingId: this.props.match.params.id,
        })
      .then(() => { })
      .catch(() => { });


  };
  errorCallback = error => { 
    if (error) {
      this.setState({isLoading:false})
      
    }
  };
  confirmDeactivateParking = () => {
    this.setState({
      passPopD: !this.state.passPopD,
    });
  };
  deActivateClickCancel = () => {
    this.setState({
      passPopD: !this.state.passPopD,
    });
  };
  confirmActivateParking = () => {
    this.setState({
      passPopA: !this.state.passPopA,
    });
  };
  activateClickCancel = () => {
    this.setState({
      passPopA: !this.state.passPopA,
    });
  };
  deleteClickCancel = () => {
    this.setState({
      passPop: !this.state.passPop,
    });
  };
  deleteClick = () => {
    httpRequest
      .delete(
        'deleteParking',
        {},
        this.deleteSuccess,
       this.deleteError,
        {
          parkingId: this.props.match.params.id,

        },
      )
      .then(() => { })
      .catch(error => { this.props.updateToasterConfig({
        show: true,
        message: error.message,
        color: '#03A9F3',
      });});
  };
  deleteParking = () => {
    this.setState({
      passPop: !this.state.passPop,
    });
  };
  deleteSuccess = () => {
    setTimeout(() => {
      this.props.updateToasterConfig({
        show: true,
        message: 'The Parking has been deleted',
        color: '#03A9F3',
      });
    }, 3000);
    this.props.history.push('/portal/parkingmanagment');
  };
  deleteError = error => {
    setTimeout(() => {
      this.props.updateToasterConfig({
        show: true,
        message: error.message,
        color: '#ff0000',
      });
    }, 3000);
    this.setState({
      passPop: !this.state.passPop,
    });
    
  };
  toggleButtonMethodActivate = () => {
    this.setState({
      passPopA:false
    })
    this.activatePSMethod();
  };
  toggleButtonMethodDeactivate = () => {
    this.setState({
      passPopD:false
    })
    this.deactivatePSMethod();
  };
  deactivatePSMethod = () => {
    parkingId = this.props.match.params.id;
    httpRequest
      .patch(
        'deactivatePS',
        {
          Authorization: 'Bearer ' + getCookie('auth'),
          realmname: getCookie('realmName'),
        },
        this.props.updateToasterConfig({
          show: true,
          message: 'The Parking has been deactivated',
          color: '#E46A76',
        }),
        console.log(this.props.match.params.id),
        this.errorCallback,
        {
          parkingId: this.props.match.params.id,
        },
      )
      .then(() => {
        this.setState({
          displayStatus: "Deactivated",
          isActive: true,
        })
        window.location.href='/portal/parking-details/'+this.props.match.params.id;
      })
      .catch(() => {
        this.props.updateToasterConfig({
          show: true,
          message: 'Something went wrong, try again',
          color: '#ff0000',
        })
       });
  };

  backHandler = () => {
    // let prevPageNumber = sessionStorage.getItem("prevPageNumber");
    let prevPageNumber = this.props.location.state;
    // console.log("Whats in prev: ",prevPageNumber)
    sessionStorage.setItem("backTrigger", true);
    this.props.history.push({ 
      pathname: "/portal/parkingmanagment",
      state: {
        prevPage: prevPageNumber || 0,
        backSendFlag: true
      }
     });
  }

  render() {

    const { parkingDetail } = this.state;
    

    return (

      <div className="content-hodler">
        {this.state.isLoading && <Loader/>}
        {

          parkingDetail?.name !== '' ? (
            <div className="DetailParking">
               
              <div className="DetailParking__wrapper">
                <div className="DetailParking__wrapper_title">
                  <Grid container direction="row" justify="center" alignItems="center">
                  <Grid item xs={6}>
                  <div style={{wordWrap:'break-word'}}>
                  <p className="DetailParking__wrapper_title_parking-name">
                    {parkingDetail.name}
                
                  </p>
                  </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="DetailParking__wrapper_title_actions" style={{textAlign:'right'}}>
                    {
                        this.props.location.state?.searchFlag ?
                        null
                        :
                        <Button
                        variant="contained"
                        className="activateBtn"
                        style={{marginLeft:"5em"}}
                        onClick={this.backHandler}
                      >
                        Back
                      </Button>   
                      }
                    {this.state.displayStatus === 'Deactivated' && (
                      <Button

                        variant="contained"
                        className="activateBtn"
                        onClick={this.confirmActivateParking}
                      >
                        Activate
                      </Button>
                    )}
                    {this.state.displayStatus === 'Approved' && (
                      <Button

                        variant="contained"
                        className="activateBtn"
                        onClick={this.confirmDeactivateParking}
                      >
                        Deactivate
                      </Button>
                    )}
                    {parkingDetail.vendorInfo && this.state.pageImg.length < 5 ?
                      <Button
                        variant="contained"

                        className="DetailParking__wrapper_title_actions_edit"
                        onClick={() => {
                          this.props.history.push({
                            pathname: '/portal/editparkingspace',
                            search: '?parkingId=' + parkingId,
                            state: { imagesparkingId: parkingId, imagesData: this.state.imgData }
                          }
                          );
                        }}
                      >
                        <AssignmentIcon /> Add Images
                    </Button>
                    :
                    null
                    }
              
                    <Button
                      variant="contained"
                      className="DetailParking__wrapper_title_actions_delete"
                      onClick={() =>
                        this.deleteParking(parkingDetail.parkingId)
                      }
                    >
                      <DeleteOutlinedIcon />
                      Delete
                  </Button>

                  </div>
                  </Grid>
                  </Grid>
                </div>
                <Paper className="pm-pso-content">
                  <div className="DetailParking__wrapper_basicdetails">
                    <div className="DetailParking__wrapper_basicdetails_left">
                      
                      <Card>
                      {this.state.isLoading && <Loader />}
                        {
                          
                        this.state.imgData?.length>0  ? (
                          <Carousel
                          className="DetailParking__wrapper_basicdetails_left_carousel-box"
                          showThumbs={false}
                        >
                          
                          {this.state.imgData.map((item, i) => (
                            <div key={i} className="carousel__images">
                              <img
                                alt="item"
                                src={`data:image/png;base64,${item}`}
                              />
                            </div>
                          ))}
                        </Carousel>
                         
                        ) : (
                          <Grid
                          className="noPreview"
                          item
                          md={6}
                          style={{ marginBottom: '1em' }}
                        >
                          <TableContainer>
                            <div
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <p
                                style={{
                                  fontSize: '14px',
                                  fontFamily: 'poppins',
                                  paddingTop: '20px',
                                }}
                              >
                                {this.state.imgYes?"loading...":"No image to preview for this parkingspace."}
                            </p>
                            </div>
                          </TableContainer>
                        </Grid>
                          )}
                      </Card>
                    </div>

                    <div className="DetailParking__wrapper_basicdetails_right">
                      <div className="DetailParking__wrapper_basicdetails_right_address">
                        <div className="DetailParking__wrapper_basicdetails_right_address_map_">
                          {/* <Link
                            to={
                              '/portal/parkingmanagment?' +
                              parkingDetail.parkingId
                            }
                          > */}
                            <img src={address} alt="map-img" />
                          {/* </Link> */}
                        </div>
                        <div className="DetailParking__wrapper_basicdetails_right_address_text">
                          <p className="DetailParking__wrapper_basicdetails_right_address_text_heading">
                            <b>Address</b>
                          </p>
                          <p className="DetailParking__wrapper_basicdetails_right_address_text_details">
                          {parkingDetail.address.search("addressline2")?parkingDetail.address.replace("addressline2,",""):parkingDetail.address}
                            
                          </p>
                        </div>
                      </div>
                      <div className="DetailParking__wrapper_basicdetails_right_moredetails">
                        <div className="DetailParking__wrapper_basicdetails_right_moredetails_box">
                          {parkingDetail.vendorInfo &&
                            <ul>
                              <li>
                                <b>Parking Id</b>
                              </li>

                              <li>


                                {parkingDetail && parkingDetail.vendorInfo && parkingDetail.vendorInfo.parkingId}



                              </li>
                            </ul>
                          }

                          <ul>


                            <li>
                              <b>Status</b>
                            </li>
                            {/* <li
                            style={{
                              color: parkingDetail.status.color,
                            }}
                          >
                            <b>{parkingDetail.status.displayName}</b>
                          </li> */}
                            <li>

                              <span style={{ color: '#F49D3E' }}>
                                {parkingDetail.parkingBusinessType.active && parkingDetail.currentStatus.name=="APPROVED"?'Active':this.state.displayStatus}
                              </span>


                            </li>
                            <li>
                              <Tooltip
                                disableFocusListener
                                title={this.state.displayStatus}
                                classes={{
                                  popper: 'statusInfoTooltip',
                                }}
                                arrow
                                placement="top-start"
                              >
                                <Button className="iIconstyle">
                                  <img alt="info" src={info} />
                                </Button>
                              </Tooltip>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <b>Type</b>
                            </li>
                            <li>
                              {parkingDetail && parkingDetail.parkingBusinessType && parkingDetail.parkingBusinessType?.name}
                            </li>
                          </ul>
                          <ul>
                            
                            <li>
                            {Object.keys(parkingDetail.org).length!==0?(<b>Organization</b>):(<b>Vendor</b>)}
                              
                            </li>
                            {Object.keys(parkingDetail.org).length!== 0?(<li>{parkingDetail&&parkingDetail.org&&parkingDetail.org.name}</li>):(<li>{parkingDetail&&parkingDetail.vendorInfo&&parkingDetail.vendorInfo.name}</li>)} 
                          </ul>
                          <ul>
                            <li>
                              <b>Description</b>
                            </li>
                            <li >
                            <TextField
                              // label="Description"
                              multiline
                              rows={3}
                              variant="outlined"
                              value={parkingDetail.description}
                            >
                              {/* {parkingDetail.description} */}
                            </TextField>
                            </li>
                          </ul>


                        </div>
                      </div>
                    </div>
                  </div>
                </Paper>
                <Grid container>
                  <Grid item md={12} style={{ paddingRight: '10px' }}>
                    <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}  >
                      <TabList style={{margin:'2rem',color:'#03a9f3',borderBottom:'2px solid #03a9f3'}}>
                        <Tab style={{ color: '#03a9f3', textAlign: 'center', width: '33.3%' }} ><strong>Super Admin Actions</strong> </Tab>
                        <Tab style={{ color: '#03a9f3', textAlign: 'center', width: '33.3%' }} ><strong>Parking Space Profile</strong> </Tab>
                        <Tab style={{ color: '#03a9f3', textAlign: 'center', width: '33.3%' }}  ><strong>Devices and Configuration</strong></Tab>
                      </TabList>

                      <TabPanel>
                        <StatusLog
                          //timings={parkingDetail?.operatingHoursInfo}
                          //customData={parkingDetail.customOperatingHoursInfo}
                          parkingId={this.props.match.params.id}
                          statusName={parkingDetail.currentStatus}
                          parentStatusName={parkingDetail.status}
                          statusDisplay={this.state.displayStatus}
                          verified={parkingDetail.isVerified}
                          handler={this.handler}
                          isActive={this.state.isActive}
                          displayStatusHandler={this.displayStatusHandler}
                        />

                      </TabPanel>
                      <TabPanel>
                        <ParkingDetailsTab
                          //timings={parkingDetail?.operatingHoursInfo}
                          //customData={parkingDetail.customOperatingHoursInfo}
                          solAdminId={parkingDetail.provider.userId}
                          parkingId={this.props.match.params.id}
                          statusName={parkingDetail.currentStatus}
                          vehicleTypeInfo={parkingDetail.vehicleTypeInfo}
                          gateway={parkingDetail.isGatewayOutOfSync}
                          facilities={parkingDetail.facilities}
                          services={parkingDetail.services}
                          timings={parkingDetail.operatingHoursInfo}
                          customData={parkingDetail.customOperatingHoursInfo}
                          address={parkingDetail.addressDetails}
                          orgData={this.state.orgData}

                        />
                      </TabPanel>
                      <TabPanel>

                        <DeviceLog parkDetails={parkingDetail} />
                      </TabPanel>
                    </Tabs>

                  </Grid>
                </Grid >



              </div>
            </div>
          ) : null}
        {this.state.passPop && (
          <div className="deletepup-wrapper">
            <div className="password-popup">
            {this.state.isLoadingPop && <Loader />}
              <div className="heading">
                <p className="popup-heading">Delete Parking</p>
                <span
                  className="cross"
                  onClick={() => this.deleteClickCancel()}
                >
                  X
                </span>
              </div>
              <p className="popup-text">
                Do you really want to delete the {parkingDetail.name} parking space?
              </p>
              <div className="popup-button">
                <Button
                  className="cancel"
                  variant="contained"
                  onClick={() => this.deleteClickCancel()}
                >
                  Cancel
                </Button>
                <Button
                  className="reset"
                  variant="contained"
                  onClick={() => this.deleteClick()}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        )}
        {this.state.passPopA && (
          <div className="deletepup-wrapper">
            <div className="password-popup">
              <div className="heading">
                <p className="popup-heading">Activate Parking</p>
                <span
                  className="cross"
                  onClick={() => this.activateClickCancel()}
                >
                  X
                </span>
              </div>
              <p className="popup-text">
                Do you really want to activate the {parkingDetail.name} parking space?
              </p>
              <div className="popup-button">
                <Button
                  className="cancel"
                  variant="contained"
                  onClick={() => this.activateClickCancel()}
                >
                  Cancel
                </Button>
                <Button
                  className="reset"
                  variant="contained"
                  onClick={() => this.toggleButtonMethodActivate()}
                >
                  Activate
                </Button>
              </div>
            </div>
          </div>
        )}
         {this.state.passPopD && (
          <div className="deletepup-wrapper">
            <div className="password-popup">
              <div className="heading">
                <p className="popup-heading">Deactivate Parking</p>
                <span
                  className="cross"
                  onClick={() => this.deActivateClickCancel()}
                >
                  X
                </span>
              </div>
              <p className="popup-text">
                Do you really want to deactivate the {parkingDetail.name} parking space?
              </p>
              <div className="popup-button">
                <Button
                  className="cancel"
                  variant="contained"
                  onClick={() => this.deActivateClickCancel()}
                >
                  Cancel
                </Button>
                <Button
                  className="reset"
                  variant="contained"
                  onClick={() => this.toggleButtonMethodDeactivate()}
                >
                  Deactivate
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(
  connect(
    state => ({
      auth: state.app.auth,
    }),
    { updateAuth, updateBreadcrumbs, updateToasterConfig },
  )(ParkingDetails),
);
