import React, { Component, Fragment } from 'react';
import {
  Grid,
  Card,
  CardActionArea,
  IconButton,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import httpRequest from './../../../../../Utils/httpRequest';
import { getCookie } from '../../../../../Utils/cookies';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/VisibilityOutlined';
import { updateToasterConfig } from './../../../../../../src/redux/modules/app/actions';
import Draft from '../../../../../Assets/Images/draft.png';
import verified from '../../../../../Assets/Images/verified tag.png';
import live from '../../../../../Assets/Images/live.png';
import outside from '../../../../../Assets/Images/outside.png';
import offline from '../../../../../Assets/Images/offline.png';
import './style.scss';

var curDay = new Date().getDay();
var curTime =
  new Date().getHours() +
  ':' +
  new Date().getMinutes() +
  ':' +
  new Date().getSeconds();
var dayArray = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];
class Parking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parkings: [],
      showDropdown: false,
      passPop: false,
      deleteParkingId: null,
    };
  }

  checkOpen() {
    var timings = this.props.timings[dayArray[curDay]];
    if (
      timings &&
      curTime > timings[0].openingTime &&
      curTime < timings[0].closingTime
    ) {
      return live;
    } else {
      return outside;
    }
  }
  clickButton = e => {
    e.stopPropagation();
    this.setState({
      showDropdown: !this.state.showDropdown,
    });
  };
  showEditPage = (e, id) => {
    e.stopPropagation();
    this.props.history.push(
      '/portal/editparkingspace?parkingId=' + id,
    );
  };
  deleteParking = (e, id) => {
    e.stopPropagation();

    this.setState({
      deleteParkingId: id,
      passPop: !this.state.passPop,
    });
  };
  errorCallback = () => {};

  deleteClickCancel = e => {
    e.stopPropagation();
    this.setState({
      passPop: !this.state.passPop,
    });
  };
  deleteClick = e => {
    e.stopPropagation();
    httpRequest.delete(
      'deleteParking',
      {},
      this.deleteSuccess,
      this.errorCallback,
      {
        parkingId: this.state.deleteParkingId,
        userId: getCookie('userId'),
      },
    );
  };
  deleteSuccess = () => {
    this.setState({
      passPop: false,
    });
    setTimeout(() => {
      this.props.updateToasterConfig({
        show: true,
        message: 'The Parking has been deleted',
        color: '#03A9F3',
      });
    }, 100);
    setTimeout(() => {
      window.location.reload(true);
    }, 1000);
  };
  render() {
    var operationTime = '';
    for (var i in this.props.timings) {
      // openTimeVariablees = this.props.timings[i][0].openingTime;
      // closeTimeVariables = this.props.timings[i][0].closingTime;
      if (i === dayArray[curDay]) {
        let info = this.props.timings[dayArray[curDay]];
        let openingTimeSplit = info[0].openingTime.split(':');
        let closingTimeSplit = info[0].closingTime.split(':');

        let openTime, closeTime, hrFmt;
        if (parseInt(openingTimeSplit[0]) >= 12) {
          if (parseInt(openingTimeSplit[0]) == 12) {
            openTime = '12:' + openingTimeSplit[1] + ' PM';
          } else {
            hrFmt = parseInt(openingTimeSplit[0]) - 12;
            openTime = hrFmt + ':' + openingTimeSplit[1] + ' PM';
          }
        } else {
          openTime =
            openingTimeSplit[0] + ':' + openingTimeSplit[1] + ' AM';
        }

        if (parseInt(closingTimeSplit[0]) >= 12) {
          if (parseInt(closingTimeSplit[0]) == 12) {
            closeTime = '12:' + closingTimeSplit[1] + ' PM';
          } else {
            hrFmt = parseInt(closingTimeSplit[0]) - 12;
            closeTime = hrFmt + ':' + closingTimeSplit[1] + ' PM';
          }
        } else {
          closeTime =
            closingTimeSplit[0] + ':' + closingTimeSplit[1] + ' AM';
        }

        operationTime = openTime + ' - ' + closeTime;
      }
    }

    return (
      <Fragment>
        {!this.state.passPop && this.props.status === 'DRAFT' ? (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            spacing={2}
            onClick={this.props.clicked}
          >
            <div className="parking__wrapper">
              <div className="parking__wrapper_card">
                <Card className="parking__wrapper_card_card">
                  <CardActionArea className="parking__wrapper_card_card_box">
                    <div className="parking__wrapper_card_card_box_data iconData">
                      <span className="parking__wrapper_card_card_box_data_parkingName">
                        {this.props.title.length > 50
                          ? `${this.props.title.slice(0, 50)}... `
                          : this.props.title}
                      </span>
                      <span>
                        {this.props.status === 'VERIFIED' ? (
                          <img src={this.checkOpen()} alt="image2" />
                        ) : this.props.status ===
                          'REQUEST_SUBMITTED' ? (
                          <img src={outside} alt="image2" />
                        ) : this.props.status === 'APPROVED' ? (
                          <img src={this.checkOpen()} alt="image2" />
                        ) : this.props.status ===
                          'REQUEST_REJECTED' ? (
                          <img src={offline} alt="image2" />
                        ) : (
                          <img src={offline} alt="image2" />
                        )}
                      </span>

                      <span className="dottedBtn">
                        <IconButton onClick={this.clickButton}>
                          <MoreVertIcon />
                        </IconButton>
                      </span>
                      <div
                        className="filter-box"
                        style={{
                          display: this.state.showDropdown
                            ? 'block'
                            : 'none',
                        }}
                      >
                        <li>
                          <Button onClick={this.props.clicked}>
                            <EditIcon />
                            Edit
                          </Button>
                        </li>
                        <hr />
                        <li>
                          <Button
                            variant="contained"
                            className="DetailParking__wrapper_title_actions_delete"
                            onClick={e => {
                              this.deleteParking(
                                e,
                                this.props.parkingId,
                              );
                            }}
                          >
                            <DeleteOutlinedIcon />
                            Delete
                          </Button>
                        </li>
                      </div>

                      <span>
                        {this.props.isVerified ? (
                          <img
                            src={verified}
                            alt="image5"
                            style={{
                              width: '70px',
                            }}
                          />
                        ) : null}
                      </span>
                    </div>
                    <span className="parking__wrapper_card_card_box_data_status">
                      {this.props.status === 'VERIFIED' ? (
                        <span
                          style={{
                            color: '#67B561',
                          }}
                        >
                          Verified
                        </span>
                      ) : this.props.status ===
                        'REQUEST_SUBMITTED' ? (
                        <span
                          style={{
                            color: '#F49D3E',
                          }}
                        >
                          Request Submitted
                        </span>
                      ) : this.props.status === 'APPROVED' ? (
                        <span
                          style={{
                            color: '#67B561',
                          }}
                        >
                          Approved
                        </span>
                      ) : this.props.status === 'REQUEST_REJECTED' ? (
                        <span
                          style={{
                            color: 'rgb(160, 21, 40)',
                          }}
                        >
                          Request Rejected
                        </span>
                      ) : (
                        <span
                          style={{
                            color: '#A2A2A2',
                          }}
                        >
                          Draft
                        </span>
                      )}
                    </span>
                    <div className="draft-icon">
                      <img src={Draft} alt="draft" />
                      <p>Continue Filling the Form</p>
                    </div>
                  </CardActionArea>
                </Card>
              </div>
            </div>
          </Grid>
        ) : (
          !this.state.passPop && (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              spacing={2}
              onClick={this.props.clicked}
            >
              <div className="parking__wrapper">
                <div className="parking__wrapper_card">
                  <Card className="parking__wrapper_card_card">
                    <CardActionArea className="parking__wrapper_card_card_box">
                      <div className="parking__wrapper_card_card_box_data iconData">
                        <span className="parking__wrapper_card_card_box_data_parkingName">
                          {this.props.title.length > 30
                            ? `${this.props.title.slice(0, 30)} ...`
                            : this.props.title}
                        </span>
                        <span>
                          {this.props.status === 'VERIFIED' ? (
                            <img
                              src={this.checkOpen()}
                              alt="image2"
                            />
                          ) : this.props.status ===
                            'REQUEST_SUBMITTED' ? (
                            <img src={offline} alt="image2" />
                          ) : this.props.status === 'APPROVED' ? (
                            <img
                              src={this.checkOpen()}
                              alt="image2"
                            />
                          ) : this.props.status ===
                            'REQUEST_REJECTED' ? (
                            <img src={offline} alt="image2" />
                          ) : (
                            <img src={offline} alt="image2" />
                          )}
                        </span>
                        <span className="dottedBtn">
                          <IconButton onClick={this.clickButton}>
                            <MoreVertIcon />
                          </IconButton>
                        </span>
                        <div
                          className="filter-box"
                          style={{
                            display: this.state.showDropdown
                              ? 'block'
                              : 'none',
                          }}
                        >
                          <li>
                            <Button onClick={this.props.clicked}>
                              <ViewIcon />
                              View
                            </Button>
                          </li>
                          <hr />
                          {this.props.status === 'APPROVED' && (
                            <span>
                              <li>
                                <Button
                                  onClick={e => {
                                    this.showEditPage(
                                      e,
                                      this.props.parkingId,
                                    );
                                  }}
                                >
                                  <EditIcon />
                                  Edit
                                </Button>
                              </li>
                              <hr />
                            </span>
                          )}
                          <li>
                            <Button
                              variant="contained"
                              className="DetailParking__wrapper_title_actions_delete"
                              onClick={e => {
                                this.deleteParking(
                                  e,
                                  this.props.parkingId,
                                );
                              }}
                            >
                              <DeleteOutlinedIcon />
                              Delete
                            </Button>
                          </li>
                        </div>

                        <span>
                          {this.props.isVerified ? (
                            <img
                              src={verified}
                              alt="image5"
                              style={{
                                width: '70px',
                              }}
                            />
                          ) : null}
                        </span>
                      </div>
                      <span className="parking__wrapper_card_card_box_data_status">
                        {this.props.status === 'VERIFIED' ? (
                          <span
                            style={{
                              color: '#67B561',
                            }}
                          >
                            Verified
                          </span>
                        ) : this.props.status ===
                          'REQUEST_SUBMITTED' ? (
                          <span
                            style={{
                              color: '#F49D3E',
                            }}
                          >
                            Request Submitted
                          </span>
                        ) : this.props.status === 'APPROVED' ? (
                          <span
                            style={{
                              color: '#67B561',
                            }}
                          >
                            Approved
                          </span>
                        ) : this.props.status ===
                          'REQUEST_REJECTED' ? (
                          <span
                            style={{
                              color: 'rgb(160, 21, 40)',
                            }}
                          >
                            Request Rejected
                          </span>
                        ) : this.props.status === 'DEACTIVATED' ? (
                          <span
                            style={{
                              color: '#A01528',
                            }}
                          >
                            Deactivated
                          </span>
                        ) : (
                          <span
                            style={{
                              color: '#A2A2A2',
                            }}
                          >
                            Draft
                          </span>
                        )}
                      </span>
                      <div className="parking__wrapper_card_card_box_data">
                        <span className="parking__wrapper_card_card_box_data_title">
                          <b>Type</b>
                        </span>
                        <span className="parking__wrapper_card_card_box_data_value">
                          : {this.props.type}
                        </span>
                      </div>
                      <div className="parking__wrapper_card_card_box_data">
                        <span className="parking__wrapper_card_card_box_data_title">
                          <b>Operation Time</b>
                        </span>
                        <span className="parking__wrapper_card_card_box_data_value">
                          :
                          {operationTime !== ''
                            ? operationTime
                            : '00:00 AM - 00:00 PM'}
                          {/* {this.displayOperatingHours(parking.operatingHoursInfo)} */}
                        </span>
                      </div>

                      {/*<div className="parking__wrapper_card_card_box_data">
                      <span className="parking__wrapper_card_card_box_data_title">
                        <b>Tarrif</b>
                      </span>
                      <span className="parking__wrapper_card_card_box_data_value">
                        
                        :{this.props.created}
                      </span>
                    </div>*/}
                      <div className="parking__wrapper_card_card_box_data">
                        <span className="parking__wrapper_card_card_box_data_title">
                          <b>Address</b>
                        </span>
                        <span className="parking__wrapper_card_card_box_data_value">
                          <span className="parking__wrapper_card_card_box_data_value_less">
                            : {this.props.address1} <br />
                            {this.props.address2}
                            <span className="parking__wrapper_card_card_box_data_value_more">
                              ...
                            </span>
                          </span>
                        </span>
                      </div>
                    </CardActionArea>
                  </Card>
                </div>
              </div>
            </Grid>
          )
        )}
        {this.state.passPop && (
          <div className="deletepup-wrapper">
            <div className="password-popup">
              <div className="heading">
                <p className="popup-heading">Delete Parking</p>
                <span
                  className="cross"
                  onClick={this.deleteClickCancel}
                >
                  X
                </span>
              </div>
              <p className="popup-text">
                If you want to delete this Parking Space then please
                press Delete button
              </p>
              <div className="popup-button">
                <Button
                  className="cancel"
                  variant="contained"
                  onClick={this.deleteClickCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="reset"
                  variant="contained"
                  onClick={this.deleteClick}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      auth: state.app.auth,
    }),
    { updateToasterConfig },
  )(Parking),
);
