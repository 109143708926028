// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { updateBreadcrumbs } from "../../redux/modules/app/actions";
import detailsUseStyles from "./styles";
import httpRequest from "../../Utils/httpRequest";
import Loader from "../../Components/Preloader";
import FlexiText from "../../Components/FlexiText";
import { Container } from "@material-ui/core";

//@component
//ComplaintDetails
function ComplaintDetails(props){
  const classes = detailsUseStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const [isLoading, setIsLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogButtonStatus, setDialogButtonStatus] = useState("");
  const [adminRemarks, setAdminRemarks] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  //alert toaster function
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //function for alert toaster
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  //api for resolve/invalid calls
  const buttonCaller = (event, data) => {
    setIsLoading(true);
    let status = "";
    // console.log("status: ", status);
    if (data === "Resolve") {
      status = "RESOLVED";
    }
    if (data === "Invalid") {
      status = "INVALID";
    }
    httpRequest.patch(
      "complaintStatus",
      {
        status: {
          name: status,
        },
        adminRemarks: adminRemarks,
      },
      {
        "Content-Type": "application/json",
      },
      successCallbackStatus,
      errorCallback,
      {
        complaintId: props.location.state.id,
      }
    );
  };
  //json success for invalid button
  const successCallbackStatus = (jsonResponse) => {
    if (jsonResponse) {
      setIsLoading(false);
      setOpen(false);
      setOpenAlert(true);
      setSeverity("Success");
      setAlertMessage("Successfully updated status.");
      setAdminRemarks("");
      setFilterList([]);
      setIsLoading(true);
      generateListHandler();
    }
  };
  //api for data filter list as per view complaint Id
  const generateListHandler = () => {
    // console.log("details props: ",props)
    setIsLoading(true);
    httpRequest.get(
      "complaint",
      {
        "Content-Type": "application/json",
      },
      successCallbackList,
      errorCallback,
      {
        pageNumber: props.location.state.currentPage - 1,
      }
    );
  };

  //success callback for list handler
  const successCallbackList = (jsonResponse) => {
    setIsLoading(false);
    if (jsonResponse) {
      const viewResult = jsonResponse.data._embedded.results.filter(
        (data) => data.complaintId === props.location.state.id
      );
      setFilterList(viewResult);
    }
    setIsLoading(false);
  };

  //error callback
  const errorCallback = (error) => {
    if (error) {
      setIsLoading(false);
      setOpen(false);
      setOpenAlert(true);
      setSeverity("Error");
      setAlertMessage("Error in status update-", error.message);
      setAdminRemarks("");
    }
  };
  // button handler function for both Invalid and Resolve status
  const buttonHandler = (data) => {
    setOpen(true);
    setDialogTitle("Update the Complaint");
    setDialogButtonStatus(data);
  };
 // function to handle previous page
  const backHandler = () => {
    props.history.push({
      pathname: "/portal/complaint",
      state: {
        currentPage: props.location.state.currentPage,
        pressed: true,
      },
    });
  };

  //adding component to breadcrumb and displaying filtered data as per view complaint id
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("complaintdetails"));
    generateListHandler();
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <Grid container direction="column" className={classes.container}>
        <Grid item>
          <Grid container direction="row">
            <Grid
              item
              container
              justify="flex-start"
              style={{ width: "70%", marginLeft: "2em" }}
            >
              {filterList.map((data,index) => {
                return (
                  <p key={index} style={{ fontWeight: "600", fontSize: "1rem" }}>
                    Complaint ID - {data.complaintId ? data.complaintId : ""}
                  </p>
                );
              })}
            </Grid>
            <Grid item container justify="flex-end" style={{ width: "10%" }}>
              {filterList.map((data, index) => {
                return (
                  <Button
                    key={index}
                    disabled={data.status.displayName !== "OPEN"}
                    className={classes.statusChangeButton}
                    variant="contained"
                    style={{
                      backgroundColor:
                        data.status.displayName === "OPEN"
                          ? "#67B561"
                          : "#E4E5E6",
                      color:
                        data.status.displayName === "OPEN"
                          ? "#fff"
                          : "rgba(0, 0, 0, 0.87)",
                      marginBottom: matchesSM ? "2em" : undefined,
                    }}
                    onClick={() => buttonHandler("Resolve")}
                  >
                    Resolved
                  </Button>
                );
              })}
            </Grid>
            <Grid
              item
              container
              justify="flex-end"
              style={{ width: "10%", marginLeft: "2em" }}
            >
              {filterList.map((data, index) => {
                return (
                  <Button
                    key={index}
                    disabled={data.status.displayName !== "OPEN"}
                    className={classes.statusChangeButton}
                    variant="contained"
                    style={{
                      backgroundColor:
                        data.status.displayName === "OPEN"
                          ? "#E46A76"
                          : "#E4E5E6",
                      color:
                        data.status.displayName === "OPEN"
                          ? "#fff"
                          : "rgba(0, 0, 0, 0.87)",
                      marginBottom: matchesSM ? "2em" : undefined,
                    }}
                    onClick={() => buttonHandler("Invalid")}
                  >
                    Invalid
                  </Button>
                );
              })}
            </Grid>
          </Grid>
        </Grid>

        <Card className={classes.ComplaintDetailCard}>
          <div className={classes.cardWrapper}>
            <Container>
              <Grid container direction="row">
                <Grid item xs={3}>
                  <Grid container spacing={5}>
                    <Grid item>
                      <p className={classes.heading}>Complaint Date</p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item>
                      <p className={classes.heading}>Initiator </p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item>
                      <p className={classes.heading}>Parking Space</p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item>
                      <p className={classes.heading}>Description</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={5}>
                    <Grid item>
                      {filterList.map((data, index) => {
                        return (
                          <p key={index} className={classes.value}>
                            {moment(
                              data.createdDate ? data.createdDate : "",
                              "x"
                            ).format("DD MMM YYYY hh:mm a") || ""}
                          </p>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item>
                      {filterList.map((data, index) => {
                        return (
                          <p className={classes.value}>
                            {data.user
                              ? data.user.corpEmailId
                                ? data.user.corpEmailId
                                : data.user.mobileNumber
                              : "--"}
                          </p>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item>
                      {filterList.map((data, index) => {
                        return (
                          <p key={index} className={classes.value}>
                            {data.parking ? data.parking.name : ""}
                          </p>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item>
                      <p>
                        {" "}
                        {filterList.map((data, index) => {
                          return (
                            <Grid item style={{ fontSize: "0.75rem" }}>
                              <FlexiText
                                text={data.complaint ? data.complaint : ""}
                                length={30}
                              />
                            </Grid>
                          );
                        })}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid container>
                    <Grid item>
                      <p className={classes.heading}>Current Status: </p>
                    </Grid>
                    <Grid item>
                      {filterList.map((data, index) => {
                        return (
                          <>
                            <Grid item key={index}>
                              <p className={classes.statusText}>
                                {data.status ? data.status.displayName : ""}
                              </p>
                            </Grid>
                          </>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid container style={{ marginTop: "1rem" }}>
                  <Grid item>
                    <p className={classes.heading}>Super Admin Comments </p>
                  </Grid>

                  <Grid item style={{ paddingLeft: "4.7rem" }}>
                    {filterList.map((data, index) => {
                      return (
                        // <p className={classes.complaintDescription}>{data.adminRemarks?data.adminRemarks:""}</p>

                        <Grid
                          key={index}
                          item
                          style={{
                            fontSize: "0.75rem",
                            maxWidth: "20em",
                            height: "5em",
                          }}
                        >
                          <FlexiText
                            text={
                              data.adminRemarks
                                ? data.adminRemarks.slice(0, 100)
                                : ""
                            }
                            length={30}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </div>
        </Card>
        <Grid item style={{ marginTop: "0.5em", marginLeft: "1.8em" }}>
          <Button
            variant="contained"
            color="primary"
            style={{
              borderRadius: "45px",
              fontSize: "0.45rem",
              width: "85px",
              height: "30px",
              margin: "5px",
              marginBottom: "5rem",
            }}
            onClick={backHandler}
          >
            Back
          </Button>
        </Grid>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          PaperProps={{
            style: {
              padding: matchesXS ? "8px" : matchesMD ? "16px" : "32px",
            },
          }}
          style={{ zIndex: 0 }}
        >
          {isLoading && <Loader />}
          <MuiDialogTitle className={classes.uploadDialogTitle}>
            <Grid item>
              <Grid container direction="row">
                <Grid item>{dialogTitle}</Grid>
                <Grid item style={{ position: "absolute", right: "0px" }}>
                  <Button
                    onClick={() => {
                      setOpen(false);
                      setAdminRemarks("");
                    }}
                  >
                    X
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </MuiDialogTitle>
          <DialogContent>
            <Grid item>
              <Grid container direction="row">
                <Grid item style={{ width: "100%" }}>
                  <textarea
                    placeholder="Text limit is 2 to 200"
                    className={classes.textArea}
                    value={adminRemarks}
                    onChange={(e) => setAdminRemarks(e.target.value)}
                  ></textarea>
                </Grid>
              </Grid>
            </Grid>
            {adminRemarks.length > 200 && (
              <p style={{ fontSize: "12px", color: "red" }}>
                Text limit is 2 to 200
              </p>
            )}
            {adminRemarks.length === 1 && (
              <p style={{ fontSize: "12px", color: "red" }}>
                Text limit is 2 to 200
              </p>
            )}
            <Grid item>
              <Grid container direction="row" justify="flex-end">
                <Grid item style={{ marginTop: "1rem" }}>
                  <Button
                    variant="contained"
                    className={classes.dialogButton}
                    style={{
                      backgroundColor: "#FA8072",
                      marginRight: "1em",
                    }}
                    onClick={() => {
                      setOpen(false);
                      setAdminRemarks("");
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item style={{ marginTop: "1rem" }}>
                  {dialogButtonStatus === "Resolve" ? (
                    <Button
                      variant="contained"
                      name="Resolve"
                      disabled={
                        adminRemarks.length <= 1 || adminRemarks.length > 200
                      }
                      className={classes.dialogButton}
                      style={{
                        backgroundColor: "#67B561",
                      }}
                      onClick={(event) => buttonCaller(event, "Resolve")}
                    >
                      Resolve
                    </Button>
                  ) : null}
                  {dialogButtonStatus === "Invalid" ? (
                    <Button
                      variant="contained"
                      name="Invalid"
                      disabled={
                        adminRemarks.length <= 2 || adminRemarks.length > 200
                      }
                      className={classes.dialogButton}
                      style={{
                        backgroundColor: "#67B561",
                      }}
                      onClick={(event) => buttonCaller(event, "Invalid")}
                    >
                      Invalid
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert onClose={handleCloseAlert} severity={severity}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </>
  );
};

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  dispatch,
});
//method to connect react with redux
export default withRouter(
  connect(null,mapDispatchToProps)(ComplaintDetails)
);
