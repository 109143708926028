import React, { Component } from 'react';
import Img from './../../../../../Assets/Images/parkingSpace.svg';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import './style.scss';

export default class NoParking extends Component {
  render() {
    return (
      <div className="NoParking">
        <div className="NoParking__wrapper">
          <img src={Img} alt="img data" />
          <p>
            You don&apos;t have any parking space added to your account.
          </p>
          <Link
            to={'/portal/addparkingspace'}
            style={{ textDecoration: 'none' }}
          >
            <Button
            style = {{visibility:'hidden'}}
              variant="contained"
              color="primary"
              className="parkingmodule-navbar__wrapper_nav_addparking"
            >
              List A Parking Space &nbsp; +
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}
