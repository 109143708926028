/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import { makeStyles} from '@material-ui/core/styles'


const forceUpdateUseStyles = makeStyles(theme=>({
    root: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
      },
    selectInput: {
        marginTop:"1.1em",
        width:"20em",
        height:"3em",
        marginRight:"1em"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    uploadDialogTitle:{
        fontWeight:"700",borderBottom:"1px solid #808080",color:"red",textAlign:"center"
    },
    typo:{
        cursor:"pointer"
    },
    fileUpload:{
        ...theme.typography.view,
        borderRadius: 50,
        backgroundColor: "#008ECF",
        height: "3.5em",
        width: "20em"
    },    
    bulkUploadTopRow:{
        backgroundColor:"#e6e6fa",
        width:"100%",
        height:"50%",
    },
    InputRow:{
        padding:"32px",
        border: "2px solid #BFC0C2",
        margin:"32px",
        width:"90%",
        height:"25%"
    },
    ButtonRow:{
        padding:"32px",
    },
    bulkUploadCard: {
        zIndex: 0,
        boxShadow: theme.shadows[10],
        marginTop:"16px",
        width: "96%",
        height: "50%",
    },
    gridRadioDisplayText: {
        marginTop:"1em",marginLeft:"26px" 
    },
    gridSubmitButton:{
        marginBottom:"1em",paddingRight:"6.3em",marginTop:"3em"
    },
    gridSelectList: {
        marginTop:"1em"
    },
    gridPopupButton:{
        marginTop: "1rem"
    },
}));

export default forceUpdateUseStyles;