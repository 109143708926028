/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { Component } from 'react';
import { Card } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import tick from '../../../../../Assets/Images/Tick.png';
import './style.scss';

export default class RequestSubmitted extends Component {
  render() {
    return (
      <div className="RequestSubmitted">
        <div className="RequestSubmitted_wrapper">
          <Card>
            <div className="RequestSubmitted_wrapper_right">
              <img alt="tick" src={tick} />
              {/* <span style={{fontSize:"100px"}}>&#10004;</span> */}
            </div>
            <h1>Request Submitted</h1>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            bore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea co</p>
                            <p>Cmmodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur</p> */}

            <div className="RequestSubmitted_wrapper_buttons">
              <Link
                to={'/portal/parkingmanagment'}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className="RequestSubmitted_wrapper_buttons_overview"
                >
                  Parking Space Overview
                </Button>
              </Link>

              <Link
                to={'/portal/addparkingspace'}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className="RequestSubmitted_wrapper_buttons_addparking"
                >
                  List my Parking Space
                </Button>
              </Link>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
