/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link, withRouter } from 'react-router-dom';
import {
  getCookie
} from './../../Utils/cookies';
import { updateToasterConfig } from './../../../src/redux/modules/app/actions';
// import avatar from '../../Assets/Images/avatar.png';
import girl from '../../Assets/Images/user.svg';
import { connect } from 'react-redux';
import httpRequest from './../../Utils/httpRequest';
import TokenStorage from '../../Utils/tokenstorage'
//import {stop} from './../../Utils/refreshToken';

const useStyles = makeStyles({
  listGroup: {
    paddingTop: '0',
  },
});

function MediaCard(props) {
  const [org, setOrg] = React.useState('');
  const [query, setQuery] = useState('');
  const [profileImg, setProfileImg] = useState('');
  const [passPop, setPassPop] = useState(false);



  const getProfileData = () => {
    httpRequest.get(
      'userProfile',
      {},
      UserDataSuccess,
      errorCallback,
      {
        userId: getCookie('userId'),
      },
    );
  };

  const UserDataSuccess = jsonResult => {
    // httpRequest.get(
    //   'detailsImgTwo',
    //   {

    //   },
    //   imageSuccessTwo,
    //   errorCallbackImage,
    //   {
    //     detailsImgTwo:
    //       jsonResult.data._links.profileImage.href +
    //       '&responseType=BASE64_ENCODE',
    //   },
    // );

    const value = jsonResult.data.org.name;
    const org = jsonResult.data.org.orgAcronym;
    // const img = jsonResult.data._links.profileImage.href;
    setQuery(value);
    setOrg(org);
  };

  const imageSuccessTwo = jsonResult => {
    setProfileImg(jsonResult.data);
  };
  const errorCallbackImage = () => { };
  const classes = useStyles();

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const handleCloser = props.handleCloser;

  const deleteClickCancel = () => {
   setPassPop(!passPop)
  };
  const confirmLogout = () => {
    setPassPop(!passPop)
  };
  const multiTabTrigger = () => {
    window.addEventListener('storage', function(event){
      if (event.key == 'logout-event') { 
          logout()
      }
    });
  }
  const logout = () => {
    let cliSec = getCookie('cliSec');
    let cliId = getCookie('cliId');
    localStorage.setItem('logout-event', 'logout' + Math.random());
    httpRequest.patch(
      'logout',
      {
        refreshToken: getCookie('refresh'),
        clientId: cliId,
        clientSecret: cliSec,
      },
      logoutSuccess,
      errorCallback,
    );
  };
  
  const logoutSuccess = () => { 

    

    
  };
  
  const errorCallback = () => {
    props.updateToasterConfig({
      show: true,
      message: 'Successfully logged out',
      color: '#02C194',
    });
    // console.log(" error is called")
    TokenStorage.clear();
    localStorage.clear()
    window.location.href = '/login';
  };
  
useEffect(()=>{    
  getProfileData();
  multiTabTrigger();
},[]);
   
  return (
    
    <div className={classes.listGroup}>

      <MenuItem>
        <img
          className="avatar"
          src={
            profileImg !== ''
              ? `data:image/png;base64,${profileImg}`
              : girl
          }
          alt="avatar"
          width="150"
          height="150"
        />

        {/* <img className="avatar" src={profileImg !== "" ? profileImg : girl } alt="avatar" width="150" height="150"/> */}
      </MenuItem>
      <MenuItem className="listItem" dense={true}>
        <Typography variant="h6" component="h6">
          {/* Jean Donas */}
        </Typography>
      </MenuItem>
      <MenuItem className="listItem">
        <Typography style={{ fontWeight: 'bold' }}>
          Parking Super Admin
        </Typography>
      </MenuItem>
      <MenuItem className="listItem">
        <Typography>
          <p>Name : {query}</p>
          <p> Organization: {org}</p>
        </Typography>
      </MenuItem>
      <MenuItem onClick={handleCloser}>
        <Typography className="profileViewer">
          {/* <Link className="link">View Profile</Link> */}
          <Link to="/portal/profile">
            <p className="link">View Profile </p>
          </Link>
        </Typography>
      </MenuItem>
      <MenuItem dense={true} disablePadding={true}>
        <Button
          variant="contained"
          className="logoutBtn"
          onClick={confirmLogout}
        >
          Logout
        </Button>
      </MenuItem>
      {passPop && (
          <div className="deletepup-wrapper">
            <div className="password-popup">
              <div className="heading">
                <p className="popup-heading">Logout Confirmation</p>
                <span
                  className="cross"
                  onClick={deleteClickCancel}
                >
                  X
                </span>
              </div>
              <p className="popup-text">
                Do you really want to logout?
              </p>
              <div className="popup-button">
                <Button
                  className="cancel"
                  variant="contained"
                  onClick={deleteClickCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="reset"
                  variant="contained"
                  onClick={logout}
                >
                  Yes
                </Button>
              </div>
            </div>
          </div>
        )}
      {/* </Menu> */}
    </div>
  );
}

//export default withRouter(MediaCard);
export default withRouter(
  connect(
    state => ({
      auth: state.app.auth,
    }),
    { updateToasterConfig },
  )(MediaCard),
);
