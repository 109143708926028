/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { useEffect, useState } from 'react';
import ImageUploader from 'react-images-upload';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Card } from '@material-ui/core';
import Map from '../../../../../Components/MapAddress';
import uploadImageService from '../../../../../Utils/uploadImageService';
import IconA from '../../../../../Assets/Images/IconA.png';
import IconB from '../../../../../Assets/Images/IconB.png';
import IconAA from '../../../../../Assets/Images/IconAA.png';
import IconBB from '../../../../../Assets/Images/IconBB.png';
import UploadIcon from '../../../../../Assets/Images/cloud-computing.svg';
import Tip from '../../../../../Assets/Images/info.svg';
import error from '../../../../../Assets/Images/error.png';
import add from '../../../../../Assets/Images/add.png';
import closemap from '../../../../../Assets/Images/deletemap.png';
import './style.scss';
import './../MainForm.scss';
var addImage = [];
var deleteImageList = [];
const updateParentState = (parentKey, childKey, value, props) => {
  let obj = { ...props.data[parentKey] };
  obj[childKey] = value;
  props.updateChildrenState('step1', parentKey, obj);
};
const updateRootState = (key, value, props) => {
  props.updateChildrenState('step1', key, value);
};
const handleDragOver = evt => {
  evt.preventDefault();
  return false;
};

const handleDrop = (evt, props) => {
  evt.preventDefault();
  evt.stopPropagation();

  if (evt.dataTransfer.files[0].size > 2084000) {
    document.getElementById('myDialog').showModal();
    setTimeout(() => {
      document.getElementById('myDialog').close();
    }, 3000);
  } else {
    let imagesData = evt.dataTransfer.files;

    uploadImageService.uploadImage(
      imagesData,
      uploadImageServiceCallBack,
      props,
    );
  }
};

const uploadImageServiceCallBack = (imageData, props) => {
  if (props.data.images.length >= 5) {
    return false;
  }

  addImage = [imageData, ...props.data.images];
  addImage.push(imageData);
  const unique = addImage
    .map(e => e.name)
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter(e => addImage[e])
    .map(e => addImage[e]);
  props.updateChildrenState('step1', 'images', unique);
};

const AddParkingStepOne = props => {
  props.updateRootState(0, 1);
  const [clearCache, setCache] = useState(false);
  useEffect(() => {
    for (var i = addImage.length; i > 0; i--) {
      addImage.pop();
    }
    setCache(true);
  }, [clearCache]);

  const onDrop = (evt, props) => {
    let imagesData = evt.slice(-1);
    if (imagesData.length === 0 || imagesData[0].size > 2084000) {
      document.getElementById('myDialog').showModal();
      setTimeout(() => {
        document.getElementById('myDialog').close();
      }, 3000);
    } else {
      uploadImageService.uploadImage(
        imagesData,
        uploadImageServiceCallBack,
        props,
      );
    }
  };
  const removeImage = (imageData, props) => {
    if (imageData[0].file === undefined) {
      deleteImageList.push(imageData[0]);
    }
    // console.log(imageData[0].file, imageData[0]);
    props.updateChildrenState(
      'step1',
      'deleteImagesFromSpace',
      deleteImageList,
    );
  };
  if (props.data.images.length > 0) {
    addImage = [...props.data.images];
  }

  return (
    <div className="contactdetails">
      <Card>
        <div className="contactdetails__wrapper">
          <div className="contactdetails__wrapper__heading">
            <p className="contactdetails__wrapper__heading__title">
              Parking Space and Contact Details
              {props.search !== '' ? (
                <span className="notedit">
                  <img src={error} alt="error" />
                  This Information change will be subjected to
                  approval
                </span>
              ) : (
                  ''
                )}
            </p>
            <span>
              <img
                src={Tip}
                style={{
                  marginBottom: '-3px',
                  marginTop: '10px',
                  marginLeft: '10px',
                  width: '15px',
                  display: 'inline',
                }}
                alt=""
              />
            </span>
          </div>
          <div className="contactdetails__wrapper__container">
            <div className="contactdetails__wrapper__container__leftside">
              <label>Parking Space Name*</label>

              <TextField
                fullWidth
                type="text"
                name="parkingName"
                value={props.data.parkingName}
                onChange={evt => {
                  if (evt.target.value.length <= 100)
                    props.updateChildrenState(
                      'step1',
                      'parkingName',
                      evt.target.value,
                    );
                }}
              />
              {props.data.parkingName.length < 5 &&
                props.data.parkingName.length > 0 ? (
                  <p className="errorClass">
                    Minimum 5 characters required
                  </p>
                ) : (
                  <p></p>
                )}
              <br />
              <label>Description*</label>
              <TextField
                className="descpBoxtext"
                fullWidth
                multiline
                placeholder="Enter Description of your parking space [500 characters max]"
                rows="5"
                type="textarea"
                name="description"
                value={props.data.description}
                onChange={evt =>
                  props.updateChildrenState(
                    'step1',
                    'description',
                    evt.target.value,
                  )
                }
              />
              <label>Contact Number*</label>
              <TextField
                fullWidth
                type="text"
                className="phone_field"
                name="contactNumber"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <p className="num_prefix">+91</p>
                    </InputAdornment>
                  ),
                }}
                value={props.data.contactNumber}
                onChange={evt => {
                  if (
                    (evt.target.value === '' ||
                      /^[0-9\b]+$/.test(evt.target.value)) &&
                    evt.target.value.length <= 10
                  ) {
                    props.updateChildrenState(
                      'step1',
                      'contactNumber',
                      evt.target.value,
                    );
                  }
                }}
              />
              <label>Contact Email*</label>
              <TextField
                fullWidth
                type="email"
                name="contactEmail"
                value={props.data.contactEmail}
                onChange={evt =>
                  props.updateChildrenState(
                    'step1',
                    'contactEmail',
                    evt.target.value,
                  )
                }
              />
              <label>Parking Type*</label>
              <br />
              <div
                className={
                  props.data.parkingBusinessType.uid == 1
                    ? 'button__wrapper active'
                    : 'button__wrapper'
                }
                onClick={() =>
                  updateParentState(
                    'parkingBusinessType',
                    'uid',
                    1,
                    props,
                  )
                }
              >
                <div className="button__wrapper_left">
                  <img
                    src={
                      props.data.parkingBusinessType.uid == 1
                        ? IconA
                        : IconB
                    }
                    alt="Icon"
                  ></img>
                </div>
                <div className="button__wrapper_right">
                  <p className="desc">Corporate Parking</p>
                  <p className="desc1">Companies, Tech Park, etc.</p>
                </div>
              </div>
              <div
                className={
                  props.data.parkingBusinessType.uid == 2
                    ? 'button__wrapper active'
                    : 'button__wrapper'
                }
                onClick={() =>
                  updateParentState(
                    'parkingBusinessType',
                    'uid',
                    2,
                    props,
                  )
                }
              >
                <div className="button__wrapper_left">
                  <img
                    src={
                      props.data.parkingBusinessType.uid == 2
                        ? IconAA
                        : IconBB
                    }
                    alt="Icon"
                  ></img>
                </div>
                <div className="button__wrapper_right">
                  <p className="desc">Commercial Parking</p>
                  <p className="desc1">
                    Malls, Hospitals, Private parking, etc.
                  </p>
                </div>
              </div>
            </div>
            <div className="contactdetails__wrapper__container__rightside">
              <label>Add Parking Space Photos</label>
              <dialog id="myDialog">Image size is too big !!!</dialog>
              <div className="contactdetails__wrapper__container__rightside__box">
                <div
                  className="drag__and_drop"
                  onDragOver={evt => handleDragOver(evt)}
                  onDrop={evt => handleDrop(evt, props)}
                >
                  <p className="contactdetails__wrapper__container__rightside__box_upimg">
                    <ImageUploader
                      withIcon={true}
                      buttonText="Choose images"
                      onChange={evt => onDrop(evt, props)}
                      imgExtension={['.jpg', '.gif', '.png']}
                    ></ImageUploader>
                    <img
                      src={UploadIcon}
                      alt="UploadIcon"
                      className="UploadIcon"
                    />
                  </p>
                  <p className="contactdetails__wrapper__container__rightside__box_text">
                    Drag and Drop a file here or click
                  </p>
                  <p className="contactdetails__wrapper__container__rightside__box_text1">
                    You can upload upto 5 photos each should be less
                    than 2mb
                  </p>
                </div>

                <div className="contactdetails__wrapper__container__rightside__box_imagesbox">
                  {addImage.map((item, index) => {
                    return (
                      <div className="images" key={index}>
                        <img
                          src={
                            item.newImg
                              ? item.src
                              : `data:image/png;base64,${item}`
                          }
                          alt="imgSrc"
                        />

                        <span
                          className="delete-img"
                          onClick={() => {
                            var dataImage = addImage.splice(index, 1);
                            props.updateChildrenState(
                              'step1',
                              'images',
                              addImage,
                            );
                            removeImage(dataImage, props);
                          }}
                        >
                          X
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Card>
        {/* <AddressDetails /> */}
        <div className="addressdetails">
          <div className="addressdetails__wrapper">
            <div className="addressdetails__wrapper__heading">
              <p className="addressdetails__wrapper__heading__title">
                Address Details *
                {props.search !== '' ? (
                  <span className="notedit">
                    <img src={error} alt="error" />
                    This Information cannot be changed
                  </span>
                ) : (
                    ''
                  )}
              </p>
              <span>
                <img
                  src={Tip}
                  style={{
                    marginBottom: '-3px',
                    marginLeft: '10px',
                    width: '15px',
                    display: 'inline',
                  }}
                  alt=""
                />
              </span>
            </div>
            <div className="addressdetails__wrapper__container">
              <img className="add-icon" src={add} alt="add" />
              <input
                type="text"
                className={
                  props.search !== '' ? 'editAdd' : 'show__address'
                }
                disabled
                value={
                  props.data.addressDetails
                    ? props.data.addressDetails.addressLine1 +
                    ' ' +
                    props.data.addressDetails.addressLine2 +
                    ' ' +
                    props.data.addressDetails.city +
                    ' ' +
                    props.data.addressDetails.pincode +
                    ' ' +
                    props.data.addressDetails.state
                    : ''
                }
              ></input>

              <dialog id="myDialogBox">
                Pincode should not be empty!!!
              </dialog>

              {props.data.addressDetails.addressLine1 && (
                <p
                  className={
                    props.search !== '' ? 'editcross' : 'input__cross'
                  }
                  onClick={() => {
                    updateRootState(
                      'addressDetails',
                      {
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        state: '',
                        pincode: '',
                      },
                      props,
                    );
                  }}
                >
                  <img alt="closemap" src={closemap}></img>
                </p>
              )}
              <div
                className={
                  props.search !== '' ? 'editcursor' : 'edit'
                }
                style={{
                  margin: '20px 20px 50px 20px',
                  overflow: 'hidden',
                  borderRadius: '10px',
                  border: '1px solid',
                }}
              >
                <Map
                  google={props.google}
                  center={
                    props.data.location.lat === '' &&
                      props.data.location.lng === ''
                      ? { lat: 12.9716, lng: 77.5946 }
                      : props.data.location
                  }
                  height="400px"
                  zoom={15}
                  location={props.data.location}
                  updateRootState={(key, value) => {
                    // console.log(value);
                    if (value.pincode === '') {
                      document
                        .getElementById('myDialogBox')
                        .showModal();
                      setTimeout(() => {
                        document
                          .getElementById('myDialogBox')
                          .close();
                      }, 3000);
                    }
                    updateRootState(key, value, props);
                  }}
                />
                {/* } */}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AddParkingStepOne;
