/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React from "react";
import Routes from "./Routes/Routes";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Toaster from "../src/Components/Toster";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import theme from './ui/Theme/Theme'

const App = (props) => {
 
  
    return (
      <>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes></Routes>
            {props.showToaster && <Toaster></Toaster>}
          </BrowserRouter>
        </ThemeProvider>
        {
          props.location.pathname !== '/dataprivacynotice' ? 
          (
            <div className="footer-bottom">
              <Footer />
            </div>
          )
          :
          null
        } 

      </>
    );
 
}

export default withRouter( connect(
  (state) => ({
    showToaster: state.app.toasterConfig.show,
  }),
  {}
)(App));
