/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React from 'react';
const dashboard = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.5"
    height="25.5"
    viewBox="0 0 25.5 25.5"
  >
    <g
      id="Dashboard_Deactive_state"
      data-name="Dashboard Deactive state"
      transform="translate(0.75 0.75)"
    >
      <path
        id="Path_315"
        data-name="Path 315"
        d="M0,21H24v3H0ZM0,10.5H24V18H0ZM10.5,0H24V7.5H10.5ZM0,0H7.5V7.5H0Z"
        fill="none"
        stroke="#8c9b9e"
        stroke-width="1.5"
        fill-rule="evenodd"
      />
    </g>
  </svg>
);

const accountManagement = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36" height="18" viewBox="0 0 36 18"
  >
    <g
      id="Account_Mgmt_Active_State"
      data-name="Account Mgmt Active State"
      transform="translate(0.758 0.751)"
    >
      
      <path id="Path_1374" data-name="Path 1374" d="M49.3,56.9a2.7,2.7,0,0,0-2.542,1.8H40.3A2.7,2.7,0,0,0,37.6,56H30.352a6,6,0,0,0-1.4.313l-7.8,2.169A2.7,2.7,0,0,0,16,59.6v8.1a2.7,2.7,0,0,0,5.242.9H22.91l6.867,4.93A2.686,2.686,0,0,0,31.3,74a2.737,2.737,0,0,0,.763-.11l6.328-1.741.278-.076a2.615,2.615,0,0,0,1.362-.981L45.012,68.6h1.745A2.7,2.7,0,0,0,52,67.7V59.6A2.7,2.7,0,0,0,49.3,56.9ZM18.7,68.6a.9.9,0,0,1-.9-.9V59.6a.9.9,0,0,1,1.8,0v8.1A.9.9,0,0,1,18.7,68.6Zm19.444,1.75L31.632,72.14l-.084.026a.919.919,0,0,1-.738-.11L23.489,66.8H21.4V60.284l8.062-2.243.058-.018a4.542,4.542,0,0,1,.908-.223H37.6a.9.9,0,0,1,.9.9h-.9a5.414,5.414,0,0,0-1.1.151l-2.178.448a4.078,4.078,0,0,0-.743.227,4.97,4.97,0,0,0-.676.445l-5.071,3.8a2.7,2.7,0,0,0,2.852,4.551l4.909-3.04,3.06,3.825h0l.041.052-.041-.052a.963.963,0,0,1,.069.389A.846.846,0,0,1,38.144,70.35Zm6.444-3.55-4.132,2.066a2.241,2.241,0,0,0-.326-.792L37.672,65H39.4V63.2H35.543l-5.683,3.525a.887.887,0,0,1-.361.075.9.9,0,0,1-.9-.9.889.889,0,0,1,.331-.7l5.055-3.791c.122-.092.3-.215.371-.259.066-.023.227-.066.345-.093l2.179-.448.034-.008a3.732,3.732,0,0,1,.685-.1h9v6.3Zm5.612.9a.9.9,0,1,1-1.8,0V59.6a.9.9,0,0,1,1.8,0Z" transform="translate(-16 -56)" fill="#102e35" opacity="0.48"/>
       </g>
     
  </svg>
);
const accountCol = (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="18" viewBox="0 0 36 18">
  <path id="Path_704" data-name="Path 704" d="M49.3,56.9a2.7,2.7,0,0,0-2.542,1.8H40.3A2.7,2.7,0,0,0,37.6,56H30.352a6,6,0,0,0-1.4.313l-7.8,2.169A2.7,2.7,0,0,0,16,59.6v8.1a2.7,2.7,0,0,0,5.242.9H22.91l6.867,4.93A2.686,2.686,0,0,0,31.3,74a2.737,2.737,0,0,0,.763-.11l6.328-1.741.278-.076a2.615,2.615,0,0,0,1.362-.981L45.012,68.6h1.745A2.7,2.7,0,0,0,52,67.7V59.6A2.7,2.7,0,0,0,49.3,56.9ZM18.7,68.6a.9.9,0,0,1-.9-.9V59.6a.9.9,0,0,1,1.8,0v8.1A.9.9,0,0,1,18.7,68.6Zm19.444,1.75L31.632,72.14l-.084.026a.919.919,0,0,1-.738-.11L23.489,66.8H21.4V60.284l8.062-2.243.058-.018a4.542,4.542,0,0,1,.908-.223H37.6a.9.9,0,0,1,.9.9h-.9a5.414,5.414,0,0,0-1.1.151l-2.178.448a4.078,4.078,0,0,0-.743.227,4.97,4.97,0,0,0-.676.445l-5.071,3.8a2.7,2.7,0,0,0,2.852,4.551l4.909-3.04,3.06,3.825h0l.041.052-.041-.052a.963.963,0,0,1,.069.389A.846.846,0,0,1,38.144,70.35Zm6.444-3.55-4.132,2.066a2.241,2.241,0,0,0-.326-.792L37.672,65H39.4V63.2H35.543l-5.683,3.525a.887.887,0,0,1-.361.075.9.9,0,0,1-.9-.9.889.889,0,0,1,.331-.7l5.055-3.791c.122-.092.3-.215.371-.259.066-.023.227-.066.345-.093l2.179-.448.034-.008a3.732,3.732,0,0,1,.685-.1h9v6.3Zm5.612.9a.9.9,0,1,1-1.8,0V59.6a.9.9,0,0,1,1.8,0Z" transform="translate(-16 -56)" fill="#01c0c8"/>
</svg>
);

const parkingManagement = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.5"
    height="25.5"
    viewBox="0 0 25.5 25.5"
  >
    <g
      id="Parking_Mgmt_Deactive_State"
      data-name="Parking Mgmt Deactive State"
      transform="translate(0.75 0.75)"
    >
      <g id="Group_910" data-name="Group 910" opacity="0.48">
        <path
          id="Path_270"
          data-name="Path 270"
          d="M32.242,12.8H17.558A4.68,4.68,0,0,0,12.9,17.458V32.142A4.68,4.68,0,0,0,17.558,36.8H32.242A4.68,4.68,0,0,0,36.9,32.142V17.49A4.645,4.645,0,0,0,32.242,12.8ZM30.948,24.153a4.474,4.474,0,0,1-1.035,1.617A4.817,4.817,0,0,1,28.231,26.9a5.493,5.493,0,0,1-2.232.42h-3.04v5.11H19.628v-15.2h6.4a5.544,5.544,0,0,1,2.264.453,4.751,4.751,0,0,1,1.65,1.164A5.22,5.22,0,0,1,31.3,22.277,5.362,5.362,0,0,1,30.948,24.153Z"
          transform="translate(-12.9 -12.8)"
          fill="none"
          stroke="#102e35"
          stroke-width="1.5"
        />
        <path
          id="Path_271"
          data-name="Path 271"
          d="M47.04,36.5H44v3.687h3.04a1.919,1.919,0,0,0,.744-.162,1.607,1.607,0,0,0,.615-.42,2.072,2.072,0,0,0,.42-.615,1.919,1.919,0,0,0,.162-.744,1.645,1.645,0,0,0-.55-1.294A2.053,2.053,0,0,0,47.04,36.5Z"
          transform="translate(-33.941 -28.834)"
          fill="none"
          stroke="#102e35"
          stroke-width="1.5"
        />
      </g>
    </g>
  </svg>
);

const deviceManagement = (
  <svg 
    xmlns="http://www.w3.org/2000/svg"
    width="192"
    height="50" 
    viewBox="0 0 192 192">
  <path d="M176 136V32H16v104h64v16H64v8h64v-8h-16v-16h64zM24 40h144v88H24V40zm80 112H88v-16h16v16zm-36-52H36V68h32v32zm-24-8h16V76H44v16zm68 8H80V68h32v32zm-24-8h16V76H88v16zm68 8h-32V68h32v32zm-24-8h16V76h-16v16z"
    opacity="0.48"
  />
</svg>
);

const userManagement = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.102"
    height="26.102"
    viewBox="0 0 26.102 26.102"
  >
    <g
      id="user_management_deactive_state"
      data-name="user management deactive state"
      transform="translate(0.75 0.75)"
    >
      <path
        id="Union_47"
        data-name="Union 47"
        d="M0,24.6V21.527c0-3.383,5.535-6.151,12.3-6.151s12.3,2.768,12.3,6.151V24.6ZM6.15,6.151A6.15,6.15,0,1,1,12.3,12.3,6.151,6.151,0,0,1,6.15,6.151Z"
        fill="none"
        stroke="#102e35"
        stroke-width="1.5"
        opacity="0.48"
      />
    </g>
  </svg>
);



const roleManagement = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35.786"
    height="25.5"
    viewBox="0 0 35.786 25.5"
  >
    <g
      id="role_management_deactive_state"
      data-name="role management deactive state"
      transform="translate(0.75 0.75)"
    >
      <path
        id="Path_291"
        data-name="Path 291"
        d="M15.074,20.809c-4.011,0-12,2.006-12,6v3h24v-3C27.074,22.815,19.085,20.809,15.074,20.809Zm0-3a6,6,0,1,0-6-6A6.007,6.007,0,0,0,15.074,17.809Zm12.069,3.1a7.187,7.187,0,0,1,3.36,5.9v3H37.36v-3C37.36,23.346,31.36,21.375,27.143,20.912Zm-1.783-3.1a6,6,0,1,0,0-12,5.907,5.907,0,0,0-2.571.6,9.362,9.362,0,0,1,0,10.8A5.907,5.907,0,0,0,25.36,17.809Z"
        transform="translate(-3.074 -5.809)"
        fill="none"
        stroke="#102e35"
        stroke-width="1.5"
        opacity="0.48"
      />
    </g>
  </svg>
);

const serviceRequest = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.922"
    height="25.547"
    viewBox="0 0 22.922 25.547"
  >
    <g
      id="services_requested_deactive_state"
      data-name="services requested deactive state"
      transform="translate(0.758 0.751)"
    >
      <path
        id="Path_273"
        data-name="Path 273"
        d="M24.155,13.784a.214.214,0,0,0,.179-.175,7.414,7.414,0,0,0,0-2.461.214.214,0,0,0-.179-.175l-1.828-.287a.531.531,0,0,1-.347-.838l1.09-1.494a.215.215,0,0,0,0-.25,7.383,7.383,0,0,0-1.741-1.741.215.215,0,0,0-.25,0l-1.494,1.09a.532.532,0,0,1-.838-.347l-.287-1.828a.214.214,0,0,0-.175-.179,7.412,7.412,0,0,0-2.461,0,.214.214,0,0,0-.175.179l-.287,1.828a.532.532,0,0,1-.839.347l-1.493-1.09a.215.215,0,0,0-.25,0A7.384,7.384,0,0,0,11.043,8.1a.215.215,0,0,0,0,.25l1.09,1.494a.531.531,0,0,1-.347.838l-1.828.287a.215.215,0,0,0-.179.175,7.428,7.428,0,0,0,0,2.461.215.215,0,0,0,.179.175l1.828.287a.531.531,0,0,1,.347.838L11.046,16.4a.215.215,0,0,0,0,.25,7.378,7.378,0,0,0,1.741,1.741.215.215,0,0,0,.25,0l1.493-1.09a.532.532,0,0,1,.839.347l.287,1.828a.214.214,0,0,0,.175.179,7.412,7.412,0,0,0,2.461,0,.214.214,0,0,0,.175-.179l.287-1.828a.532.532,0,0,1,.839-.347l1.493,1.09a.215.215,0,0,0,.25,0,7.383,7.383,0,0,0,1.741-1.741.215.215,0,0,0,0-.25l-1.09-1.494a.532.532,0,0,1,.116-.743Zm-7.1,1.464a2.869,2.869,0,1,1,2.87-2.869h0A2.87,2.87,0,0,1,17.058,15.248Zm12.053,7.4a8.014,8.014,0,0,1-.85.644c0,.32-1.855,1.6-2.13,1.813a25.168,25.168,0,0,1-2.877,1.926,25.861,25.861,0,0,1-6.1,1.941,37.48,37.48,0,0,1-7.273-.718,8.347,8.347,0,0,0-2.155-.015V22.752c0-.012,0-.023,0-.035a6.4,6.4,0,0,1,2.262-1.056,10.416,10.416,0,0,1,3.8.015,9.732,9.732,0,0,0,3.078.018,18.849,18.849,0,0,0,2.5-.429c.86-.28,1.41-.241,1.724.323a.886.886,0,0,1-.366,1.173,7.4,7.4,0,0,1-2.077,1.123,25.836,25.836,0,0,1-3.341.7,22.02,22.02,0,0,0,2.158.539,16.042,16.042,0,0,0,4.546-.237c1.6-.382,4.007-2.459,5.017-2.825a2.08,2.08,0,0,1,1.69-.137C28.958,22.048,29.208,22.52,29.111,22.648Z"
        transform="translate(-7.726 -5)"
        fill="none"
        stroke="#102e35"
        stroke-width="1.5"
        opacity="0.48"
      />
    </g>
  </svg>
);

const reports = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.52"
    height="25.52"
    viewBox="0 0 25.52 25.52"
  >
    <g
      id="reports_deactive_state"
      data-name="reports deactive state"
      transform="translate(0.75 0.77)"
    >
      <g id="Group_913" data-name="Group 913" opacity="0.48">
        <g id="Group_914" data-name="Group 914">
          <path
            id="Path_276"
            data-name="Path 276"
            d="M33.155,30.922a10.725,10.725,0,1,1-11-11V30.639a.283.283,0,0,0,.283.283H33.155Z"
            transform="translate(-11.708 -17.365)"
            fill="none"
            stroke="#102e35"
            stroke-width="1.5"
          />
          <path
            id="Path_277"
            data-name="Path 277"
            d="M65.382,22.217H54.943V11.778A10.739,10.739,0,0,1,65.382,22.217Z"
            transform="translate(-41.382 -11.778)"
            fill="none"
            stroke="#102e35"
            stroke-width="1.5"
          />
        </g>
      </g>
    </g>
  </svg>
);

const payments = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.5"
    height="18.802"
    viewBox="0 0 25.5 18.802"
  >
    <g
      id="payments_deactive_state"
      data-name="payments deactive state"
      transform="translate(0.75 0.75)"
    >
      <g id="Group_915" data-name="Group 915" opacity="0.48">
        <path
          id="Path_278"
          data-name="Path 278"
          d="M21.842,45.12v8.8a1.926,1.926,0,0,0,1.926,1.926H43.914a1.926,1.926,0,0,0,1.926-1.926v-8.8Z"
          transform="translate(-21.842 -38.548)"
          fill="none"
          stroke="#102e35"
          stroke-width="1.5"
        />
        <path
          id="Path_279"
          data-name="Path 279"
          d="M45.842,33.026v-1.4A1.926,1.926,0,0,0,43.916,29.7H23.77a1.926,1.926,0,0,0-1.928,1.924v1.4Z"
          transform="translate(-21.842 -29.7)"
          fill="none"
          stroke="#102e35"
          stroke-width="1.5"
        />
      </g>
    </g>
  </svg>
);

const settings = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.038"
    height="25.5"
    viewBox="0 0 25.038 25.5"
  >
    <g
      id="settings_deactive_state"
      data-name="settings deactive state"
      transform="translate(0.919 0.75)"
    >
      <g id="Group_1751" data-name="Group 1751">
        <rect
          id="Rectangle_193"
          data-name="Rectangle 193"
          width="24"
          height="24"
          fill="none"
        />
        <path
          id="Path_104"
          data-name="Path 104"
          d="M271.447,15a3,3,0,1,0-3.053-3A3.036,3.036,0,0,0,271.447,15Zm-6.258-9.6a8.655,8.655,0,0,1,3.663-1.95L270.074,0h3.053l1.221,3.45A10.718,10.718,0,0,1,278.01,5.4l3.663-.75,1.526,2.7-2.442,2.7A8.408,8.408,0,0,1,280.91,12c0,.6-.153,1.35-.153,1.95l2.442,2.7-1.526,2.7-3.663-.75a8.655,8.655,0,0,1-3.663,1.95L273.126,24h-3.053l-1.221-3.45a10.718,10.718,0,0,1-3.663-1.95l-3.663.75L260,16.65l2.442-2.7a8.408,8.408,0,0,1-.153-1.95c0-.6.153-1.35.153-1.95L260,7.35l1.526-2.7Z"
          transform="translate(-260)"
          fill="none"
          stroke="#8c9a9d"
          stroke-width="1.5"
          fill-rule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const dashboardCol = (
  <svg
    id="Dashboard_Collaspsed_deactive"
    data-name="Dashboard Collaspsed deactive"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="Path_1265"
      data-name="Path 1265"
      d="M0,21H24v3H0ZM0,10.5H24V18H0ZM10.5,0H24V7.5H10.5ZM0,0H7.5V7.5H0Z"
      fill="#869599"
      fill-rule="evenodd"
    />
  </svg>
);

const parkingCol = (
  <svg
    id="Parking_Mgmt_Collapsed_Deactive"
    data-name="Parking Mgmt Collapsed Deactive"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Group_1895" data-name="Group 1895" opacity="0.5">
      <path
        id="Path_270"
        data-name="Path 270"
        d="M32.242,12.8H17.558A4.68,4.68,0,0,0,12.9,17.458V32.142A4.68,4.68,0,0,0,17.558,36.8H32.242A4.68,4.68,0,0,0,36.9,32.142V17.49A4.645,4.645,0,0,0,32.242,12.8ZM30.948,24.153a4.474,4.474,0,0,1-1.035,1.617A4.817,4.817,0,0,1,28.231,26.9a5.493,5.493,0,0,1-2.232.42h-3.04v5.11H19.628v-15.2h6.4a5.544,5.544,0,0,1,2.264.453,4.751,4.751,0,0,1,1.65,1.164A5.22,5.22,0,0,1,31.3,22.277,5.362,5.362,0,0,1,30.948,24.153Z"
        transform="translate(-12.9 -12.8)"
        fill="#102e35"
      />
      <path
        id="Path_271"
        data-name="Path 271"
        d="M47.04,36.5H44v3.687h3.04a1.919,1.919,0,0,0,.744-.162,1.607,1.607,0,0,0,.615-.42,2.072,2.072,0,0,0,.42-.615,1.919,1.919,0,0,0,.162-.744,1.645,1.645,0,0,0-.55-1.294A2.053,2.053,0,0,0,47.04,36.5Z"
        transform="translate(-33.941 -28.834)"
        fill="#102e35"
      />
    </g>
  </svg>
);

const deviceCol = (
  <svg 
    xmlns="http://www.w3.org/2000/svg"
    width="192"
    height="50" 
    viewBox="0 0 192 192">
  <path 
  d="M176 136V32H16v104h64v16H64v8h64v-8h-16v-16h64zM24 40h144v88H24V40zm80 112H88v-16h16v16zm-36-52H36V68h32v32zm-24-8h16V76H44v16zm68 8H80V68h32v32zm-24-8h16V76H88v16zm68 8h-32V68h32v32zm-24-8h16V76h-16v16z"
  />
</svg>
);

const userCol = (
  <svg
    id="user_management_Collapsed_deactive"
    data-name="user management Collapsed deactive"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="Union_47"
      data-name="Union 47"
      d="M0,24.6V21.527c0-3.383,5.535-6.151,12.3-6.151s12.3,2.768,12.3,6.151V24.6ZM6.15,6.151A6.15,6.15,0,1,1,12.3,12.3,6.151,6.151,0,0,1,6.15,6.151Z"
      fill="#869599"
    />
  </svg>
);

const roleCol = (
  <svg
    id="role_management_Collapsed_deactive"
    data-name="role management Collapsed deactive"
    xmlns="http://www.w3.org/2000/svg"
    width="34.286"
    height="24"
    viewBox="0 0 34.286 24"
  >
    <path
      id="Path_291"
      data-name="Path 291"
      d="M15.074,20.809c-4.011,0-12,2.006-12,6v3h24v-3C27.074,22.815,19.085,20.809,15.074,20.809Zm0-3a6,6,0,1,0-6-6A6.007,6.007,0,0,0,15.074,17.809Zm12.069,3.1a7.187,7.187,0,0,1,3.36,5.9v3H37.36v-3C37.36,23.346,31.36,21.375,27.143,20.912Zm-1.783-3.1a6,6,0,1,0,0-12,5.907,5.907,0,0,0-2.571.6,9.362,9.362,0,0,1,0,10.8A5.907,5.907,0,0,0,25.36,17.809Z"
      transform="translate(-3.074 -5.809)"
      fill="#869599"
    />
  </svg>
);

const serviceCol = (
  <svg
    id="services_requested_collapsed_deactive"
    data-name="services requested collapsed deactive"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="Path_273"
      data-name="Path 273"
      d="M24.155,13.784a.214.214,0,0,0,.179-.175,7.414,7.414,0,0,0,0-2.461.214.214,0,0,0-.179-.175l-1.828-.287a.531.531,0,0,1-.347-.838l1.09-1.494a.215.215,0,0,0,0-.25,7.383,7.383,0,0,0-1.741-1.741.215.215,0,0,0-.25,0l-1.494,1.09a.532.532,0,0,1-.838-.347l-.287-1.828a.214.214,0,0,0-.175-.179,7.412,7.412,0,0,0-2.461,0,.214.214,0,0,0-.175.179l-.287,1.828a.532.532,0,0,1-.839.347l-1.493-1.09a.215.215,0,0,0-.25,0A7.384,7.384,0,0,0,11.043,8.1a.215.215,0,0,0,0,.25l1.09,1.494a.531.531,0,0,1-.347.838l-1.828.287a.215.215,0,0,0-.179.175,7.428,7.428,0,0,0,0,2.461.215.215,0,0,0,.179.175l1.828.287a.531.531,0,0,1,.347.838L11.046,16.4a.215.215,0,0,0,0,.25,7.378,7.378,0,0,0,1.741,1.741.215.215,0,0,0,.25,0l1.493-1.09a.532.532,0,0,1,.839.347l.287,1.828a.214.214,0,0,0,.175.179,7.412,7.412,0,0,0,2.461,0,.214.214,0,0,0,.175-.179l.287-1.828a.532.532,0,0,1,.839-.347l1.493,1.09a.215.215,0,0,0,.25,0,7.383,7.383,0,0,0,1.741-1.741.215.215,0,0,0,0-.25l-1.09-1.494a.532.532,0,0,1,.116-.743Zm-7.1,1.464a2.869,2.869,0,1,1,2.87-2.869h0A2.87,2.87,0,0,1,17.058,15.248Zm12.053,7.4a8.014,8.014,0,0,1-.85.644c0,.32-1.855,1.6-2.13,1.813a25.168,25.168,0,0,1-2.877,1.926,25.861,25.861,0,0,1-6.1,1.941,37.48,37.48,0,0,1-7.273-.718,8.347,8.347,0,0,0-2.155-.015V22.752c0-.012,0-.023,0-.035a6.4,6.4,0,0,1,2.262-1.056,10.416,10.416,0,0,1,3.8.015,9.732,9.732,0,0,0,3.078.018,18.849,18.849,0,0,0,2.5-.429c.86-.28,1.41-.241,1.724.323a.886.886,0,0,1-.366,1.173,7.4,7.4,0,0,1-2.077,1.123,25.836,25.836,0,0,1-3.341.7,22.02,22.02,0,0,0,2.158.539,16.042,16.042,0,0,0,4.546-.237c1.6-.382,4.007-2.459,5.017-2.825a2.08,2.08,0,0,1,1.69-.137C28.958,22.048,29.208,22.52,29.111,22.648Z"
      transform="translate(-7.726 -5)"
      fill="#869599"
    />
  </svg>
);

const reportCol = (
  <svg
    id="reports_Collapsed_deactive"
    data-name="reports Collapsed deactive"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Group_914" data-name="Group 914">
      <path
        id="Path_276"
        data-name="Path 276"
        d="M33.155,30.922a10.725,10.725,0,1,1-11-11V30.639a.283.283,0,0,0,.283.283H33.155Z"
        transform="translate(-11.708 -17.365)"
        fill="#869599"
      />
      <path
        id="Path_277"
        data-name="Path 277"
        d="M65.382,22.217H54.943V11.778A10.739,10.739,0,0,1,65.382,22.217Z"
        transform="translate(-41.382 -11.778)"
        fill="#869599"
      />
    </g>
  </svg>
);

const paymentCol = (
  <svg
    id="payments_collapsed_deactive"
    data-name="payments collapsed deactive"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="Path_278"
      data-name="Path 278"
      d="M21.842,45.12v8.8a1.926,1.926,0,0,0,1.926,1.926H43.914a1.926,1.926,0,0,0,1.926-1.926v-8.8Z"
      transform="translate(-21.842 -38.548)"
      fill="#869599"
    />
    <path
      id="Path_279"
      data-name="Path 279"
      d="M45.842,33.026v-1.4A1.926,1.926,0,0,0,43.916,29.7H23.77a1.926,1.926,0,0,0-1.928,1.924v1.4Z"
      transform="translate(-21.842 -29.7)"
      fill="#869599"
    />
  </svg>
);

const settingCol = (
  <svg
    id="settings_collapsed_deactive"
    data-name="settings collapsed deactive"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="Path_1266"
      data-name="Path 1266"
      d="M271.447,15a3,3,0,1,0-3.053-3A3.036,3.036,0,0,0,271.447,15Zm-6.258-9.6a8.655,8.655,0,0,1,3.663-1.95L270.074,0h3.053l1.221,3.45A10.718,10.718,0,0,1,278.01,5.4l3.663-.75,1.526,2.7-2.442,2.7A8.408,8.408,0,0,1,280.91,12c0,.6-.153,1.35-.153,1.95l2.442,2.7-1.526,2.7-3.663-.75a8.655,8.655,0,0,1-3.663,1.95L273.126,24h-3.053l-1.221-3.45a10.718,10.718,0,0,1-3.663-1.95l-3.663.75L260,16.65l2.442-2.7a8.408,8.408,0,0,1-.153-1.95c0-.6.153-1.35.153-1.95L260,7.35l1.526-2.7Z"
      transform="translate(-260)"
      fill="#869599"
      fill-rule="evenodd"
    />
  </svg>
);

export const getSVG = (type) => {
  switch (type) {
    case 'dashboard':
      return dashboard;
    case 'accountManagement':
      return accountManagement;
    case 'parkingManagement':
      return parkingManagement;
    case 'deviceManagement':
      return deviceManagement;
    case 'userManagement':
      return userManagement;
    case 'roleManagement':
      return roleManagement;
    case 'serviceRequest':
      return serviceRequest;
    case 'reports':
      return reports;
    case 'payments':
      return payments;
    case 'settings':
      return settings;
    case 'dashboardCol':
      return dashboardCol;
    case 'accountCol':
      return accountCol;
    case 'parkingCol':
      return parkingCol;
    case 'deviceCol':
      return deviceCol;
    case 'userCol':
      return userCol;
    case 'roleCol':
      return roleCol;
    case 'serviceCol':
      return serviceCol;
    case 'reportCol':
      return reportCol;
    case 'paymentCol':
      return paymentCol;
    case 'settingCol':
      return settingCol;
    default: return
  }
};
