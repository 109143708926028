// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. 
import React, { useState, useEffect} from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { updateBreadcrumbs } from '../../redux/modules/app/actions';
import userManagementUseStyles from './styles'
import Loader from '../../Components/Preloader'
import PaginationComponent from '../../Components/Pagination/index'
import httpRequest from '../../Utils/httpRequest';

//@component
//User Management 
const UserManagement = React.memo(props => {
  const classes = userManagementUseStyles()

  const [userManagementList, setUserManagementList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [tableDataNotFound, setTableDataNotFound] = useState("")
  const [responseDataNotFound, setResponseDataNotFound] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage, setPostsPerPage] = useState(10)

  //api call to generate table list  
  const generateListHandler = () => {
    httpRequest.get(
      'userManagement',
      {
        'Content-Type': 'application/json',
      },
      successCallbackList,
      errorCallback,
      {
        pageNumber: currentPage-1
      },
    )
    if (userManagementList.length === 0) {
      setTableDataNotFound("")
    }
  }
  //success callback for table list
  const successCallbackList = jsonResponse => {
    if (jsonResponse) {
      setUserManagementList(jsonResponse.data._embedded.results)
    } 
    setIsLoading(false)
  }

  //error callback for table list
  const errorCallback = error => {
    if (error) {
      setIsLoading(false)
      setResponseDataNotFound("No Users Available")
    }    
  }

  // function to perform pagination
  const paginate = pageNumber => {
    setCurrentPage(pageNumber)
  }
  //to set array reference connect for pagination table
  const indexOfLastPage =
    currentPage * postsPerPage;
  const indexOfFirstPage =
    indexOfLastPage - postsPerPage;
  const currentPost = userManagementList.slice(
    indexOfFirstPage,
    indexOfLastPage,
  );

  
  const showSelectedNavCards = (e) => {
    setPostsPerPage(e.target.value );
  };

  //updating breadcrumb and list function call
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("userManagement"))
    setIsLoading(true)
    generateListHandler()
  }, [])
  //data for row header
  const rowHeader = [
    "User Mobile Number",
    "Registration DateTime", 
    "Status"  
  ]

  return (
    <>
    {isLoading && <Loader />}
    <Grid container
      direction="column"
      className={classes.container}
    >
      {
        responseDataNotFound.length === 0 ?
          <React.Fragment>
            <Grid item>
              <Grid container
                direction="row"
                justify="center"
                className={classes.tableGrid}
              >
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead style={{backgroundColor: "rgba(226, 220, 242, 0.38)"}}>
                      <TableRow>
                        {
                          rowHeader.map((header, index) => (
                            <React.Fragment key={index}>
                              <TableCell
                                align="left"
                                className={classes.tableHeader}
                              >
                                {header}
                              </TableCell>
                            </React.Fragment>
                          ))
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        currentPost.length !== 0 ?
                          currentPost.map((row, index) => {
                            return (
                              <TableRow key={index} >
                                <TableCell align="left">{row.mobileNumber?row.mobileNumber:"--"}</TableCell>
                                <TableCell align="left">{moment(row.createdDate?row.createdDate:"", "x").format("DD MMM YYYY hh:mm a") || "--"}</TableCell>
                                <TableCell align="left">{row.active?"Active":"Inactive"}</TableCell>
                              </TableRow>
                            )
                          })
                          :
                          <Grid item>
                            <Grid container
                              direction="row"
                              justify="center"
                            >
                              <Typography variant="body1">{tableDataNotFound}</Typography>
                            </Grid>
                          </Grid>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid item style={{ marginBottom: "10em" }}>
              <Grid container
                direction="row"
                justify="flex-end"
              >
                <div style={{ marginRight: "7em" }}>
                  <select
                    name="selectNavcards"
                    id="selectNavcards"
                    className={classes.selectNavCard}
                    onChange={showSelectedNavCards}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                    <option value={50}>50</option>
                  </select>
                </div>
                <PaginationComponent
                  postsPerPage={postsPerPage}
                  // totalPosts={this.props.cardData.length}
                  totalPosts={userManagementList.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              </Grid>
            </Grid>
          </React.Fragment>
          :
          <Grid item>
            <Grid container
              direction="row"
              justify="center"
            >
              <Typography variant="body1">{responseDataNotFound}</Typography>
            </Grid>
          </Grid>
      }
    </Grid>
    </>
  )
})
//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  dispatch,
});
//method to connect react with redux   
export default withRouter(
  connect(state => {
    return {};
  })(UserManagement),
);