/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import { makeStyles } from '@material-ui/core/styles'


const bulkUploadUseStyles = makeStyles(theme=>({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    uploadDialogTitle:{
        fontWeight:"700",borderBottom:"1px solid #808080",textAlign:"left",
    },
    fileUpload:{
        ...theme.typography.view,
        borderRadius: 50,
        backgroundColor: "#008ECF",
        height: "3.5em",
        width: "20em"
    },    
    bulkUploadTopRow:{
        backgroundColor:"#e6e6fa",
        width:"100%",
        height:"50%",
    },
    InputRow:{
        padding:"32px",
        border: "2px solid #BFC0C2",
        margin:"32px",
        width:"90%",
        height:"25%"
    },
    ButtonRow:{
        padding:"32px",
    },
    bulkUploadCard: {
        zIndex: 0,
        boxShadow: theme.shadows[10],
        marginTop:"16px",
        width: "96%",
        height: "50%",
    },   
}));

export default bulkUploadUseStyles;