import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import {
  Grid,
  IconButton,
  Card,
  CardContent,
  Button,
} from "@material-ui/core/";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/VisibilityOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  getCookie
} from "../../../../../Utils/cookies";
import { updateToasterConfig } from "./../../../../../../src/redux/modules/app/actions";
import httpRequest from "../../../../../Utils/httpRequest";
import { updateAuth } from "../../../../../redux/modules/app/actions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import InfoWindowEx from "./indexWindowEx";
import "./style.scss";

import marker from "../../../../../Assets/Images/pin.png";
import live from "../../../../../Assets/Images/live.png";
import outside from "../../../../../Assets/Images/outside.png";
import offline from "../../../../../Assets/Images/offline.png";

var weekdays = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY"
];
var today = new Date().getDay();
var list = [];
var listOperatingHour = [];
var operationTime = "";
class ParkingSpaceMapView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stores: [],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      showMarkerFromDetail: false,
      dataFromDetails: null,
      setopen: false,
      showDropdown: false,
      passPop: false,
      deleteParkingId: null,
    };
  }

  UserDataSuccess = () => {};
  errorCallback = () => {
    // this.props.history.push('/admin');
  };

  onMarkerClick = (props, marker) => {
    var curTime =
      new Date().getHours() +
      ":" +
      new Date().getMinutes() +
      ":" +
      new Date().getSeconds();

    this.props.cardData.map((item, index) => {
      if (item.parkingId === props.parkingId) {
        for (var i in item.operatingHoursInfo) {
          list.push(i);
          listOperatingHour.push(item.operatingHoursInfo[i]);

          if (i === weekdays[today]) {
            let info = item.operatingHoursInfo[i];
            let openingTimeSplit = info[0].openingTime.split(":");
            let closingTimeSplit = info[0].closingTime.split(":");
            let openTime, closeTime, hrFmt;
            if (parseInt(openingTimeSplit[0]) >= 12) {
              if (parseInt(openingTimeSplit[0]) == 12) {
                openTime = "12:" + openingTimeSplit[1] + " PM";
              } else {
                hrFmt = parseInt(openingTimeSplit[0]) - 12;
                openTime = hrFmt + ":" + openingTimeSplit[1] + " PM";
              }
            } else {
              openTime =
                openingTimeSplit[0] + ":" + openingTimeSplit[1] + " AM";
            }
            if (parseInt(closingTimeSplit[0]) >= 12) {
              if (parseInt(closingTimeSplit[0]) == 12) {
                closeTime = "12:" + closingTimeSplit[1] + " PM";
              } else {
                hrFmt = parseInt(closingTimeSplit[0]) - 12;
                closeTime = hrFmt + ":" + closingTimeSplit[1] + " PM";
              }
            } else {
              closeTime =
                closingTimeSplit[0] + ":" + closingTimeSplit[1] + " AM";
            }
            operationTime = openTime + " - " + closeTime;
          }
        }
      }
      return <div key={index}></div>;
    });

    list.map((item, index) => {
      item === weekdays[today] &&
        curTime > listOperatingHour[index][0].openingTime &&
        curTime < listOperatingHour[index][0].closingTime &&
        this.setState({ setopen: true });
      return <div key={index}></div>;
    });
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };
  onMapClicked = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };
  showDetails = (e) => {
    if (e.status === "DRAFT") {
      this.props.history.push(
        "/portal/addparkingspace?parkingId=" + e.parkingId
      );
    } else {
      this.setState({ selectedParkingId: e.parkingId });
      // this.setState({matchingDay:})
      this.props.history.push("/portal/parking-details/" + e.parkingId);
    }
  };

  showEditPage = (e) => {
    if (e.status === "DRAFT") {
      this.props.history.push(
        "/portal/addparkingspace?parkingId=" + e.parkingId
      );
    } else {
      this.setState({ selectedParkingId: e.parkingId });
      this.props.history.push(
        "/portal/editparkingspace?parkingId=" + e.parkingId
      );
    }
  };

  deleteParking = (e) => {
    this.setState({
      deleteParkingId: e.parkingId,
      passPop: !this.state.passPop,
    });
  };
  clickButton = (e) => {
    e.stopPropagation();
    this.setState({
      showDropdown: !this.state.showDropdown,
    });
  };

  errorCallback = () => {};

  deleteClickCancel = (e) => {
    e.stopPropagation();
    this.setState({
      passPop: !this.state.passPop,
    });
  };
  deleteClick = (e) => {
    e.stopPropagation();
    httpRequest.delete(
      "deleteParking",
      {},
      this.deleteSuccess,
      this.errorCallback,
      {
        parkingId: this.state.deleteParkingId,
        userId: getCookie("userId"),
      }
    );
  };
  deleteSuccess = () => {
    this.setState({
      passPop: false,
    });
    setTimeout(() => {
      this.props.updateToasterConfig({
        show: true,
        message: "The Parking has been deleted",
        color: "#03A9F3",
      });
    }, 10);
    setTimeout(() => {
      window.location.reload(true);
    }, 3000);
  };

  render() {
    var selectedParkingId = this.props.history.location.search.split("?");

    return (
      <div
        className="mapContainer"
        style={{
          margin: "auto",
          width: "calc(100% - 40px)",
          height: "100vh",
        }}
      >
        <Map
          google={this.props.google}
          onClick={this.onMapClicked}
          zoom={12}
          initialCenter={{ lat: 12.9198958, lng: 77.6232865 }}
        >
          {this.props.cardData.map((store, index) => {
            if (selectedParkingId[1] === store.parkingId) {
              return (
                <Marker
                  key={index}
                  id={index}
                  position={{
                    lat: store.location.lat,
                    lng: store.location.lng,
                  }}
                  icon={{
                    url: marker,
                  }}
                  onClick={this.onMarkerClick}
                  name={store.name}
                  status={store.status.name}
                  type={store.parkingBusinessType.name}
                  address={store.addressDetails.addressLine1}
                  slotsVacant={store.slotUtilization.vacantSlots}
                  slotsUnavailable={store.slotUtilization.unavailableSlots}
                  slotsBooked={store.slotUtilization.bookedSlots}
                  slotsOccupied={store.slotUtilization.occupiedSlots}
                  parkingId={selectedParkingId[1]}
                />
              );
            }
            if (selectedParkingId.length === 1) {
              return (
                <Marker
                  key={index}
                  id={index}
                  position={{
                    lat: store.location.lat,
                    lng: store.location.lng,
                  }}
                  icon={{
                    url: marker,
                  }}
                  onClick={this.onMarkerClick}
                  name={store.name}
                  status={store.status.name}
                  type={store.parkingBusinessType.name}
                  address={store.addressDetails.addressLine1}
                  slotsVacant={store.slotUtilization.vacantSlots}
                  slotsUnavailable={store.slotUtilization.unavailableSlots}
                  slotsBooked={store.slotUtilization.bookedSlots}
                  slotsOccupied={store.slotUtilization.occupiedSlots}
                  parkingId={store.parkingId}
                />
              );
            }
          })}

          <InfoWindowEx
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
          >
            <Grid container>
              <Grid item xs={12}>
                <Card className="mapAvtr">
                  <div className="card_head" style={{ marginTop: "10px" }}>
                    <p>
                      {this.state.selectedPlace.name !== undefined &&
                      this.state.selectedPlace.name.length > 50
                        ? `${this.state.selectedPlace.name.slice(0, 35)}...`
                        : this.state.selectedPlace.name}
                    </p>
                    <p>
                      {this.state.selectedPlace.status === "VERIFIED" ? (
                        this.state.setopen ? (
                          <img src={live} alt="image3" />
                        ) : (
                          <img src={outside} alt="image3" />
                        )
                      ) : this.state.selectedPlace.status ===
                        "REQUEST_SUBMITTED" ? (
                        <img src={offline} alt="image2" />
                      ) : this.state.selectedPlace.status ===
                        "REQUEST_REJECTED" ? (
                        <img src={offline} alt="image2" />
                      ) : this.state.selectedPlace.status === "APPROVED" ? (
                        this.state.setopen ? (
                          <img src={live} alt="image3" />
                        ) : (
                          <img src={outside} alt="image3" />
                        )
                      ) : (
                        <img src={offline} alt="image2" />
                      )}
                    </p>

                    <p
                      style={{
                        position: "relative",
                        left: "13px",
                      }}
                    >
                      <span className="dottedBtn">
                        <IconButton onClick={this.clickButton}>
                          <MoreVertIcon />
                        </IconButton>
                      </span>
                      <div
                        className="filter-box"
                        style={{
                          display: this.state.showDropdown ? "block" : "none",
                        }}
                      >
                        {(this.state.selectedPlace.status === "APPROVED" ||
                          this.state.selectedPlace.status ===
                            "REQUEST_SUBMITTED") && (
                          <span>
                            <li>
                              <Button
                                onClick={this.showDetails.bind(
                                  this,
                                  this.state.selectedPlace
                                )}
                              >
                                <ViewIcon />
                                View
                              </Button>
                            </li>
                            <hr />
                          </span>
                        )}
                        {(this.state.selectedPlace.status === "APPROVED" ||
                          this.state.selectedPlace.status === "DRAFT") && (
                          <span>
                            <li>
                              <Button
                                onClick={this.showEditPage.bind(
                                  this,
                                  this.state.selectedPlace
                                )}
                              >
                                <EditIcon />
                                Edit
                              </Button>
                            </li>
                            <hr />
                          </span>
                        )}
                        <li>
                          <Button
                            variant="contained"
                            className="DetailParking__wrapper_title_actions_delete"
                            onClick={this.deleteParking.bind(
                              this,
                              this.state.selectedPlace
                            )}
                          >
                            <DeleteOutlinedIcon />
                            Delete
                          </Button>
                        </li>
                      </div>
                    </p>
                  </div>
                  <span className="parking__wrapper_card_card_box_data_status">
                    {this.state.selectedPlace.status === "VERIFIED" ? (
                      <span style={{ color: "#67B561" }}>Verified</span>
                    ) : this.state.selectedPlace.status ===
                      "REQUEST_SUBMITTED" ? (
                      <span style={{ color: "#F49D3E" }}>
                        Request Submitted
                      </span>
                    ) : this.state.selectedPlace.status ===
                      "REQUEST_REJECTED" ? (
                      <span style={{ color: "rgb(160, 21, 40)" }}>
                        Request Rejected
                      </span>
                    ) : this.state.selectedPlace.status === "APPROVED" ? (
                      <span style={{ color: "#67B561" }}>Approved</span>
                    ) : this.state.selectedPlace.status === "DEACTIVATED" ? (
                      <span style={{ color: "#A01528" }}>Deactivated</span>
                    ) : (
                      <span style={{ color: "#A2A2A2" }}>Draft</span>
                    )}
                  </span>

                  <CardContent>
                    <div
                      className="directionVertical"
                      onClick={this.showDetails.bind(
                        this,
                        this.state.selectedPlace
                      )}
                    >
                      <div className="info_map_container">
                        <div className="info_map_label">
                          <p>Type</p>
                          <p>Operation Time</p>
                          <p>Address</p>
                        </div>
                        <div className="info_map_value">
                          <p>:{this.state.selectedPlace.type}</p>
                          <p>
                            :
                            {operationTime !== ""
                              ? operationTime
                              : "00:00 AM - 00:00 PM"}
                          </p>

                          <p>:{this.state.selectedPlace.address}</p>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </InfoWindowEx>
          {this.state.passPop && (
            <div className="deletepup-wrapper">
              <div className="password-popup">
                <div className="heading">
                  <p className="popup-heading">Delete Parking</p>
                  <span className="cross" onClick={this.deleteClickCancel}>
                    X
                  </span>
                </div>
                <p className="popup-text">
                  If you want to delete this Parking Space then please press
                  Delete button
                </p>
                <div className="popup-button">
                  <Button
                    className="cancel"
                    variant="contained"
                    onClick={this.deleteClickCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="reset"
                    variant="contained"
                    onClick={this.deleteClick}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Map>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      auth: state.app.auth,
    }),
    { updateAuth, updateToasterConfig }
  )(
    GoogleApiWrapper({
      // apiKey: 'AIzaSyCq1D3cnZycxn5PCwiahpHscgep3yoN35U'
      apiKey: "AIzaSyAYYH3O5Ce4tb6IYuy8UQhYGGGJIkq63nI",
    })(ParkingSpaceMapView)
  )
);
