/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { Component, Fragment } from 'react';
import ParkingSpaceListView from './../ParkingSpace/ParkingSpaceListView';
import ParkingSpaceMapView from './../ParkingSpace/ParkingSpaceMapView';
import ParkingSpace from './../ParkingSpace/ParkingSpace';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import './../ParkingSpace/ParkingSpace/style.scss';
import './style.scss';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import httpRequest from '../../../../Utils/httpRequest';
import ClearIcon from '@material-ui/icons/Clear';
import { connect } from 'react-redux';
import {
  updateBreadcrumbs,
  updateFilterData,
  updateAppliedFilterData,
} from '../../../../redux/modules/app/actions';
import cardviewIcon from '../../../../Assets/Images/card view deactive.png';
import listviewIcon from '../../../../Assets/Images/list view deactive.png';
import mapviewIcon from '../../../../Assets/Images/map view deactive.png';
import cardviewIconActive from '../../../../Assets/Images/card view active.png';
import listviewIconActive from '../../../../Assets/Images/list view active.png';
import mapviewIconActive from '../../../../Assets/Images/map view active.png';
import live from '../../../../Assets/Images/live.png';
import outside from '../../../../Assets/Images/outside.png';
import offline from '../../../../Assets/Images/offline.png';
import Filtericon from './../../../../Assets/Images/funnel-hz.png';
import Clickedfilter from './../../../../Assets/Images/Path 1482.png';

import Checkbox from '@material-ui/core/Checkbox';
import Loader from './../../../../Components/Preloader';
import { getCookie} from '../../../../Utils/cookies';
import NoParking from './../ParkingSpace/NoParking';
var classData = '';
class ParkingOverview extends Component {
  state = {
    card: false,
    list: true,
    map: false,
    parkingList: [],
    totalCount: 0,
    pageCount: 0,
    selectedParkingId: null,
    searchKey: '',
    showDiv: false,
    filter: [],
    searchPs: null,
    isLoading: false,
    filterActive: false,
    status: "",
    pageSize: 0,
    pageNumber: 0,
    postsPerPage: 10,
    searchFlag: false,
    searchKey: "",
    curPage: 1,
    backFlag: false,
    prevPageNumber: 0
  };

  componentDidMount() {
    this.setState({
      isLoading: !this.state.isLoading,
    });
    
      this.getParkingList();
   
    
    this.props.updateBreadcrumbs('parkingManagement');
    if (
      this.props.location.search !== undefined &&
      this.props.location.search !== ''
    ) {
      this.changeHandler('three');
    }
  }
  // function to set prev page number
  
  // let backSendFlag = this.props.location.state.backSendFlag;
  // if(backSendFlag) {
  //   this.curPageSetter(prevPageNumber)
  // }
  
  // callback for current page setter
  curPageSetter = (pageValue) => {
    this.setState({
      curPage: pageValue
    })
  }
  // callback for pagination number click event
  pageCountHandler = (number,size) => {
    
    let filterStatus = this.state.status;
    this.setState({
      pageSize: size,
      pageNumber: number
    })
      httpRequest.get(
      'parkingList',
      {},
      this.parkingListSuccess,
      this.errorCallback,
      {
        userId: getCookie('userId'),
        pageCount: number,
        sizeCount: size,
        filterStatus: filterStatus || ""
      },
    );
  }

  // api function for getting parking space list
  getParkingList = () => {
    let filterStatus = this.state.status;
    
    // if(prevPageNumber === this.state.pageCount) {
    //   currentPage = this.state.pageCount
    // }
    // var prevNumber = sessionStorage.getItem("prevPageNumber");
    httpRequest.get(
      'parkingList',
      {},
      this.parkingListSuccess,
      this.errorCallback,
      {
        userId: getCookie('userId'),
        pageCount: this.state.pageCount,
        sizeCount : 10,
        filterStatus: filterStatus || ""
      },
    );
  };

  parkingListSuccess = jsonResult => {
    let backTrigger = sessionStorage.getItem("backTrigger");
    let prev = sessionStorage.getItem("prevPagenumber");
    if(backTrigger) {
      this.curPageSetter(prev)
      sessionStorage.removeItem("backTrigger");
    }
    this.setState({
      // isLoading: !this.state.isLoading,
      isLoading: false
    });
    this.setState({
      parkingList: jsonResult.data._embedded.results,
      totalCount: jsonResult.data.page.totalElements,
    });
    this.props.updateFilterData(
      jsonResult.data._embedded.results || [],
    );
  };

  errorCallback = () => { };

  filter = () => {
    this.setState({
      showDiv: !this.state.showDiv,
    });
  };

  applyFilter = status => {
    let statusData = [...this.state.filter];
    let index = statusData.indexOf(status);
    index > -1
      ? statusData.splice(index, 1)
      : statusData.push(status);
    this.setState({
      filter: [...statusData],
      status: status
    });
  };

  submitFilter = () => {
    let pageSize = this.state.pageSize || 10;
    let pageNumber = this.state.pageNumber;
    this.pageCountHandler(pageNumber,pageSize);
    
    this.setState({
      filterActive: true,
    });
    const { updateAppliedFilterData, filterData } = this.props;
    const { filter } = this.state;
    if (!filter.length) {
      updateAppliedFilterData([...filterData]);
      this.setState({
        parkingList: [...filterData],
      });
    } else {
      let result = [];
      for (let i = 0; i < filter.length; i++) {
        let data = filterData.filter(item => {
          return (
            item.currentStatus.name.toUpperCase() === filter[i].toUpperCase()
          );
        });
        result = [...result, ...data];
      }
      this.props.updateAppliedFilterData([...result]);
      this.setState({
        parkingList: [...result],
      });
    }
    this.setState({
      showDiv: !this.state.showDiv,
    });
  };

  changeHandler = id => {
    if (id === 'one') {
      this.setState({
        card: true,
        list: false,
        map: false,
      });
      this.state.showDiv &&
        this.setState({
          showDiv: !this.state.showDiv,
        });
    }

    if (id === 'two') {
      this.setState({
        card: false,
        map: false,
        list: true,
      });
      this.state.showDiv &&
        this.setState({
          showDiv: !this.state.showDiv,
        });
    }

    if (id === 'three') {
      this.setState({
        card: false,
        list: false,
        map: true,
      });
      this.state.showDiv &&
        this.setState({
          showDiv: !this.state.showDiv,
        });
    }
  };

  handleChange = evt => {
    this.setState({
      searchKey: evt.target.value,
    });
  };
  // callback function for search pagination size
  handleSearchSize = (number) => {
    let pageNumber = this.state.pageNumber;
    let pageSize = number || 10;
    let searchData = this.state.searchKey;
    httpRequest.get(
      'parkingListSearch',
      {},
      this.parkingListSuccess,
      this.errorCallback,
      {
        userId: getCookie('userId'),
        pageCount: pageNumber,
        sizeCount: pageSize,
        searchText: searchData
      },
    );
  }
  // callback function to handle search pagination 
  handleSearchPagination = (number) => {
    let pageNumber = number;
    let pageSize = this.state.pageSize || 10;
    let searchData = this.state.searchKey;
    httpRequest.get(
      'parkingListSearch',
      {},
      this.parkingListSuccess,
      this.errorCallback,
      {
        userId: getCookie('userId'),
        pageCount: pageNumber,
        sizeCount: pageSize,
        searchText: searchData
      },
    );
  }
  // callback function for searchFlag
  searchFlagHandler = (value) => {
    this.setState({
      searchFlag: value
    })
  }
  // function to handle search feature
  handleSearchKeyChange = e => {
    let searchFlagValue = false
    let pageValue = 0
    if(e.target.value) {
      searchFlagValue = true
      this.curPageSetter(1)
    }
    if(!e.target.value) {
      searchFlagValue = false;
      this.curPageSetter(1)
    }  
    classData = e.target.value;
    
    let searchValue = e.target.value;
    let searchData = searchValue || "";
    let pageSize = this.state.pageSize || 10;
    let pageNumber = this.state.pageNumber
    httpRequest.get(
      'parkingListSearch',
      {},
      this.parkingListSuccess,
      this.errorCallback,
      {
        userId: getCookie('userId'),
        pageCount: pageNumber,
        sizeCount: pageSize,
        searchText: searchData
      },
    );
    this.setState({
      searchFlag: searchFlagValue,
      searchKey: e.target.value,
      pageNumber: pageValue
    })
    // this.setState({ searchPs: e.target.value });
    // const { filterData } = this.props;

    // if (!e.target.value) {
    //   this.setState({
    //     parkingList: [...filterData],
    //   });
    // } else {
    //   let result = this.state.parkingList.filter(item => {
    //     return item.name
    //       .toLowerCase()
    //       .includes(e.target.value.toLowerCase());
    //   });
    //   // console.log("result is: ",result);
    //   this.setState({
    //     parkingList: [...result],
    //   });
    // }
  };
  // function for clearing search
  clearSerach = () => {
    this.getParkingList()
    this.curPageSetter(1)
    const { filterData } = this.props;
    classData = '';
    this.setState({ searchPs: '' });
    this.setState({
      // parkingList: [...filterData],
      searchFlag: false,
      searchKey: ""
    });
  };

  // function for rows per page
  showSelectedNavCards = (e) => {
    this.setState({ postsPerPage: e.target.value });
    let pageNumber = this.state.pageNumber;
    let size = e.target.value;
    if(!this.state.searchFlag) {
      this.pageCountHandler(pageNumber,size);
    }    
    if(this.state.searchFlag) {
      this.handleSearchSize(size)
    }

  };


  render() {
    // console.log(this.state.searchPs);
    let { card, list, map, filter } = this.state;

    /*  this.state.parkingList.sort((a, b) =>
       a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
     ); */
    const print = () => {
      if (card === true) {
        return (
          <ParkingSpace
            cardData={this.state.parkingList}
            filterStatus={this.state.filter}
          />
        );
      }
      if (list === true) {
        return (
          <ParkingSpaceListView
            cardData={this.state.parkingList}
            totalCount={this.state.totalCount}
            filterStatus={this.state.filter}
            pageCountHandler={this.pageCountHandler}
            postsPerPage={this.state.postsPerPage}
            searchFlag={this.state.searchFlag}
            handleSearchPagination={this.handleSearchPagination}
            searchFlagHandler={this.searchFlagHandler}
            pageNumber={this.state.pageNumber}
            curPageSetter={this.curPageSetter}
            curPage={this.state.curPage}
          />
        );
      }
      if (map === true) {
        return (
          <ParkingSpaceMapView cardData={this.state.parkingList} />
        );
      }
    };

    return (
      <Fragment>
        <div className="ParkingOverview-navbar">
          <div className="ParkingOverview-navbar__wrapper">
            <div className="ParkingOverview-navbar__wrapper_nav">
              <div className="ParkingOverview-navbar__wrapper_nav_left">
                <div className="ParkingOverview-navbar__wrapper_nav_left_searchicon sIconC">
                  {classData === '' && <SearchIcon />}
                </div>
                <div className="ParkingOverview-navbar__wrapper_nav_left_search">
                  <input
                    placeholder="Search Parking Space"
                    value={this.state.searchKey}
                    onChange={this.handleSearchKeyChange.bind(this)}
                  />

                  <p
                    className={
                      classData === '' ? 'editcross' : 'input__cross'
                    }
                    onClick={this.clearSerach}
                  >
                    <ClearIcon />
                  </p>
                </div>
              {/* start */}
              <div  style={{marginTop:"1em",marginLeft:"0.5em"}}>
                <label>Rows Per Page</label>
                <select
                  // disabled={this.state.searchFlag}
                  name="selectNavcards"
                  id="selectNavcards"
                  onChange={this.showSelectedNavCards}
                  style={{marginLeft:"1em"}}
                >
                  <option value={10}>10</option>
                  <option disabled={this.state.totalCount<10} value={20}>20</option>
                  <option disabled={this.state.totalCount<20} value={30}>30</option>
                  <option disabled={this.state.totalCount<30} value={40}>40</option>
                  <option disabled={this.state.totalCount<40} value={50}>50</option>
                </select>
              </div>
              {/* end */}
              </div>
             
              <div className="ParkingOverview-navbar__wrapper_nav_right">
                 
                <Link
                  to={'/portal/addparkingspace'}
                  style={{ textDecoration: 'none', 'visibility': 'hidden' }}
                >
                  <Button

                    variant="contained"
                    color="primary"
                    className="parkingmodule-navbar__wrapper_nav_addparking"
                  >
                    List A Parking Space &nbsp; +
                  </Button>
                </Link>
                
                <ButtonGroup
                  className="ParkingOverview-navbar__wrapper_button"
                  color="secondary"
                  aria-label="outlined secondary button group"
                  
                >
                  
                  <Button className="filter-icon" >
                    <img
                      src={
                        this.state.filter.length
                          ? Clickedfilter
                          : Filtericon
                      }
                      alt="Filtericon"
                      onClick={() => {
                        this.filter();
                      }}
                    />
                    <div
                      className="filter-box"
                      style={{
                        display: this.state.showDiv
                          ? 'block'
                          : 'none',
                      }}
                    >
                      <li>
                        <Checkbox
                          color="default"
                          type="checkbox"
                          id="all"
                          data-target="filter"
                          onChange={() =>
                            this.applyFilter('DEACTIVATED')
                          }
                          checked={filter.indexOf('DEACTIVATED') > -1}
                        />
                        <label>Deactived</label>
                      </li>
                      <li>
                        <Checkbox
                          color="default"
                          type="checkbox"
                          id="all"
                          data-target="filter"
                          onChange={() =>
                            this.applyFilter('REQUEST_REJECTED')
                          }
                          checked={
                            filter.indexOf('REQUEST_REJECTED') > -1
                          }
                        />
                        <label>Request Rejected</label>
                      </li>

                      <li>
                        <Checkbox
                          color="default"
                          type="checkbox"
                          id="all"
                          data-target="filter"
                          onChange={() =>
                            this.applyFilter('APPROVED')
                          }
                          checked={filter.indexOf('APPROVED') > -1}
                        />
                        <label>Approved</label>
                      </li>
                      <li>
                        <Checkbox
                          color="default"
                          type="checkbox"
                          id="all"
                          data-target="filter"
                          onChange={() =>
                            this.applyFilter('REQUEST_SUBMITTED')
                          }
                          checked={
                            filter.indexOf('REQUEST_SUBMITTED') > -1
                          }
                        />
                        <label>Request Submitted</label>
                      </li>
                      <li>
                        <Checkbox
                          color="default"
                          type="checkbox"
                          id="all"
                          data-target="filter"
                          onChange={() =>
                            this.applyFilter('ADDITIONAL_INFO_REQUIRED')
                          }
                          checked={
                            filter.indexOf('ADDITIONAL_INFO_REQUIRED') > -1
                          }
                        />
                        <label>Request Info</label>
                      </li>

                      <hr />

                      <Button
                        onClick={() => {
                          this.submitFilter();
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  </Button>
                  <Button
                    id="one"
                    style={{ 'visibility': 'hidden' }}
                    className="ParkingOverview-navbar__wrapper_button_active"
                    visibility="hidden"
                    value={this.state.card}
                    onClick={() => this.changeHandler('one')}
                  >
                    <img
                      alt="cardviewicon"
                      src={
                        this.state.card
                          ? cardviewIconActive
                          : cardviewIcon
                      }
                    />
                  </Button>
                  <Button
                    id="two"
                    value={this.state.list}
                    onClick={() => this.changeHandler('two')}
                  >
                    <img
                      alt="listviewicon"
                      src={
                        this.state.list
                          ? listviewIconActive
                          : listviewIcon
                      }
                    />
                  </Button>
                  <Button
                    style={{ 'visibility': 'hidden' }}
                    id="three"
                    value={this.state.map}
                    onClick={() => this.changeHandler('three')}
                  >
                    <img
                      alt="mapviewicon"
                      src={
                        this.state.map
                          ? mapviewIconActive
                          : mapviewIcon
                      }
                    />
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>

        {this.state.isLoading && <Loader />}
        {!this.state.parkingList.length && !this.state.isLoading ? (
          this.state.filterActive ? (
            <h2
              style={{
                textAlign: 'center',
                paddingBottom: '180px',
                paddingTop: '100px',
              }}
            >
              No Parkings found
            </h2>
          ) : (
              <NoParking />
            )
        ) : (

            // console.log(this.state.parkingList),
            print()
          )}

        <div className="parkingoverview_legend" style={{ 'visibility': 'hidden' }}>
          <div>
            <img alt="live" src={live} />
            <p>Live and Open</p>
          </div>
          <div>
            <img alt="outside" src={outside} />
            <p>Outside Operating Hours</p>
          </div>
          <div>
            <img alt="offline" src={offline} />
            <p>Offline / Deactivated</p>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    auth: state.app.auth,
    filterData: state.app.filterData,
    appliedFilterData: state.app.appliedFilterData,
  }),
  { updateBreadcrumbs, updateFilterData, updateAppliedFilterData },
)(ParkingOverview);