// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Dialog,
  DialogContent,
  Button,
  IconButton,
  FormControl,
  Checkbox,
  DialogActions,
  DialogTitle,
  DialogContentText
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import httpRequest from './../../../../../Utils/httpRequest';
import { updateToasterConfig } from './../../../../../../src/redux/modules/app/actions';
import './style.scss';
import gatewayUseStyles from './style'
import Loader from '../../../../../Components/Preloader';
import deleteIcon from '../../../../../Assets/icons/deleteIcon.png'
import routerIcon from '../../../../../Assets/icons/router.png'

//@component
//Device Gateway
function DeviceLog(props){
  const classes = gatewayUseStyles()
  // const theme = useTheme()
  // const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
  // const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))

  const [ open,setOpen ] = useState(false)
  // const [ msgOpen,setMsgOpen ] = useState(false)
  const [ deviceList,setDeviceList ] = useState([])
  const [ deviceDetails,setDeviceDetails ] = useState([])
  // const [ responseData,setResponseData ] = useState("")
  // const [ responseType,setResponseType ] = useState("")
  // const [ dialogTitle,setDialogTitle ] = useState("")
  const [ isLoading, setIsLoading ] = useState(false)
  const [ deviceId,setDeviceId ] = useState("")
  const [ confirmOpen,setConfirmOpen ] = useState("")
  // const [ refreshCall,setRefreshCall ] = useState(false)
  const [ deleteId,setDeleteId ] = useState("")
  const [ openAlert,setOpenAlert ] = useState(false)
  const [ severity,setSeverity ] = useState("")
  const [ alertMessage,setAlertMessage ] = useState("") 

  //alert toaster function
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  //function for alert toaster while user clicks away from alert
  const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
      return;
      }
      setOpenAlert(false)
  } 

  //api call to delete device  
  const deleteHandler = ()=>{
    setIsLoading(true)
    setConfirmOpen(false)
    httpRequest.post(
      'parkingDeviceDecoupled',
      {},
      {
        'Content-Type': 'application/json'       
      },
      successCallbackDelete,
      errorCallbackDelete,
      {
        deviceId: deleteId,
        parkingId: props.parkDetails.parkingId
      },
    )    
  }
  //error callback to delete device
  const successCallbackDelete = jsonResponse => {
    if (jsonResponse) {
      setIsLoading(false)
      setOpenAlert(true)
      setSeverity("Success")
      setAlertMessage("Device Deleted Successfully.") 
      setDeviceDetails([])
      getDeviceAssigned() 
    }   
  } 

  //api call function to assign device
  const getDeviceAssigned = () => {  
  setIsLoading(true)
  httpRequest
    .get(
      'parkingDeviceAssigned',
      {},
      UserDataStatusSuccess,
      errorCallbackInitial,
      {
        parkingId: props.parkDetails.parkingId
      },
    )    
    const timeoutID = window.setTimeout(() => {
      getDeviceAssigned()
  }, 300000);

  return () => window.clearTimeout(timeoutID );
  };
   //success callback for assign device
  const UserDataStatusSuccess = jsonResult => {
    if (jsonResult){
      setIsLoading(false)
      setDeviceDetails(jsonResult.data._embedded.results||[])
    }
  }
  //error callback for both initial component render and select element list
  const errorCallbackInitial = error => {
    if (error){
      setIsLoading(false)
      setOpenAlert(true)
      setSeverity("Success")
      setAlertMessage("No device assigned yet")
    }
  }
  //error callback for deleting device
  const errorCallbackDelete = error => {
    if (error){
      setIsLoading(false)
      setOpenAlert(true)
      setSeverity("Error")
      setAlertMessage(error.message)
    }
  }
  
  //api call to assign device 
  const assignHandler = ()=>{
    setIsLoading(true)
    setOpen(false)
    httpRequest.post(
      'parkingDeviceCoupled',
      {},
      {
        'Content-Type': 'application/json'       
      },
      successCallbackAssign,
      errorCallback1,
      {
        deviceId: deviceId,
        parkingId: props.parkDetails.parkingId
      },
    )
        
  }
  //success call back function for assign api call
  const successCallbackAssign = jsonResponse => {
    if (jsonResponse) { 
      setIsLoading(false)     
      setOpenAlert(true)
      setSeverity("Success")
      setAlertMessage("Device Assigned Successfully")
      setDeviceId("") 
      getDeviceAssigned()           
    }                    
  }
  //error call back function for assign api call
  const errorCallback1 = error => {
    //console.log("dsadsadsadsadad",error.messag))
    if (error) {
      setIsLoading(false)
      setIsLoading(false)
      setOpenAlert(true)
      setSeverity("Error")
      setAlertMessage(error.message)
            
    }                    
  }

  //api call to generate list for select element
  const getListHandler = ()=>{
    setIsLoading(true)
    httpRequest.get(
      'parkingDeviceProvisioned',
      {
        'Content-Type': 'application/json'       
      },
      successCallbackList,
      errorCallback,
      {},
    )
  }
  //success callback for getting select element list
  const successCallbackList = jsonResponse => {
    if (jsonResponse) {
      setIsLoading(false)
      setDeviceList(jsonResponse.data._embedded.results)
    }
  }
  //error callback for both initial component render and select element list
  const errorCallback = error => {
    if (error){
      setIsLoading(false)
      setOpenAlert(true)
      setSeverity("Error")
      setAlertMessage(error.message)
    }
  }

  //function to get-set selected deviceId 
  //from select element in assign device popup
  //here value is setting to state
  const handleChange = (event) => {
    setDeviceId(event.target.value);
  }
  //function to handle open/close for delete confirmation popup
  const handleClose = () => {
    setConfirmOpen(false);
  };

  //function to open popup onClick of delete icon button
  const deleteButtonHandler = (id) => {
    setDeleteId(id)
    setConfirmOpen(true)
  }
  //function to call device list initially on component load
  useEffect(()=>{
    getDeviceAssigned();
  },[])

  return (
    <>
    {isLoading && <Loader />}
    <Card raised={true} className="details_card" style={{marginBottom:"15em"}}>
      <CardHeader
        title="Coupled Gateways" titleTypographyProps={{ variant: 'h6' }} />
        <CardContent className="details_info">
          <Grid item>
            <Grid container 
              direction="column"
            >
              <Grid item>
                {
                  deviceDetails.map((device,index)=> {
                    return(
                      <React.Fragment key={index}>
                          <Grid container
                            direction="row"
                            justify="space-around"
                            className={classes.InputRow}
                          >
                            <Grid item container direction="column" justify="center" className={classes.gridDeviceLogo}>
                              <img style={{width:"3em"}} src={routerIcon} alt="router_icon"/>
                            </Grid>
                            <Grid item>
                              <Grid container 
                                direction="column"
                                style={{marginLeft:"50px"}}
                              >
                                <Grid item style={{marginBottom:"1em"}}>
                                  <p className={classes.gateway_main}>{device.deviceName?device.deviceName:""}</p>
                                </Grid>
                                <Grid item >
                                  <Grid container direction="row" style={{marginBottom:"1em"}}>
                                    <p className={classes.title}>Device ID</p>
                                    <p style={{marginLeft:"1em",marginTop:"-0.2em"}}>{device.deviceId?device.deviceId:""}</p>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Grid container direction="row" style={{marginBottom:"1em"}}>
                                    <p className={classes.title}>Device Status</p>
                                    <p style={{marginLeft:"1em",marginTop:"-0.2em"}}>{device.status?device.status.displayName:""}</p>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid container 
                                direction="column"
                                style={{marginLeft:"1px"}}
                              >
                                <Grid item>
                                  <Grid container direction="row" style={{marginBottom:"1em"}}>
                                    <p className={classes.title}>Device Heartbeat</p>
                                    <p style={{marginLeft:"1em",color:"#01C0C8",marginTop:"-0.2em"}}>{device.isGatewayOutOfSync===true?'inactive':'active'}</p>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Grid container direction="row" style={{marginBottom:"1em"}}>
                                    <p className={classes.title} style={{marginTop:"2.5px"}}>Date of Commision</p>
                                    <p style={{marginLeft:"5px"}}>{
                                      moment(device.comissionedDate).format ('DD-MMM-YYYY')
                                    
                                    }</p>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <IconButton onClick={()=>deleteButtonHandler(device.deviceId?device.deviceId:"")} >
                                <img style={{width:"1em"}} src={deleteIcon} alt="delete_icon" />
                              </IconButton>
                            </Grid>                                                   
                        </Grid>
                        
                      </React.Fragment>
                    )})
                }
                </Grid>      
              <Grid item container  
                direction="row"
                justify="flex-end"
                style={{marginBottom:"3em"}}
              >
                <Button
                onClick={() => { getListHandler(); setOpen(true) }}
                variant="contained"
                style={{
                  fontSize: "10px",
                  borderRadius: 50,
                  backgroundColor: "#e46a76",
                  color: "#fff",
                  height: "3em",
                  width: "17em"
                }}
                   // className={classes.assignNewGateway}
                >
                    Assign New Gateway +
                </Button>
              </Grid>
            </Grid>
          </Grid>        
        </CardContent>
        {/* new assign popup */}
        <Dialog 
          open={open}
          onClose={()=>setOpen(false)}
          PaperProps={{style: {
            width:"50%",
            height:"50%"
          }}}
        >
            <MuiDialogTitle >
              <p className={classes.assignDialogTitle}>Assign Parking Gateway</p>
              {/* <p className={classes.assignSubtitle}>Please give reason about reject of parking Space.</p> */}
            </MuiDialogTitle>            
            <DialogContent>
              <Grid item>
                  <Grid container
                  direction="column"
                  >
                    <Grid item >                    
                      <FormControl >
                        <label style={{fontWeight:600,fontSize:"0.75rem"}}>Device Select</label>
                        <select
                          variant="standard"
                          defaultValue="Select Parking Space"
                          value={deviceId}
                          onChange={handleChange}
                          className={classes.selectInput}
                        >
                          <option value="">
                            Select Parking Gateway
                          </option>
                          {
                            deviceList.map((list,index)=>{
                              return(
                                <option key={index} value={list.deviceId?list.deviceId:""}>{list.deviceName?list.deviceName:""}</option>                        
                              )
                            })
                          }
                        </select>
                      </FormControl>                        
                    </Grid>
                    <Grid item>
                      <Grid container
                        direaction="row"
                        style={{marginTop:"1em"}}
                      >
                        <Grid item>
                          <Checkbox
                            checked
                            disabled
                            color="primary"
                            inputProps={{ 'aria-label': 'disabled checked checkbox' }}
                            style={{padding:0}}
                          />
                        </Grid>
                        <Grid item style={{marginLeft:"1em"}}>
                          <label>Notify Technician</label>
                        </Grid>
                      </Grid>
                    </Grid>
                    
                    <Grid item container
                      direction="row"
                      style={{marginTop: "1rem"}}
                    > 
                      <Button
                        variant="outlined" 
                        className={classes.assignButton} 
                        onClick={()=>setOpen(false)}
                      >
                        Cancel
                      </Button>
                      <Button 
                        style={{marginLeft:"1em"}}
                        variant="outlined" 
                        className={classes.assignButton} 
                        onClick={assignHandler}
                      >
                        Assign
                      </Button>
                    </Grid>
                  </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
          {/* delete confirm popup */}
          <Dialog open={confirmOpen} onClose={handleClose} >
            <DialogTitle>Alert</DialogTitle>
              <DialogContent>
                <DialogContentText>
                De Couple the Hardware gateway device to delete parking space?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={()=>setConfirmOpen(false)} color="primary">
                  Cancel
                </Button>
                <Button onClick={()=>deleteHandler()}   color="primary">
                  Delete
                </Button>
              </DialogActions>
        </Dialog> 
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity={severity}>
                {alertMessage}
            </Alert>
          </Snackbar>                  
      </Card>
      </>
  );  
}

export default connect(null, { updateToasterConfig})(DeviceLog);
