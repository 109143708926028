import { Promise } from 'es6-promise';
import httpRequest from '../../../Utils/httpRequest';


export const getOrgList = async (page) => {
    return new Promise((resolve, reject) => {
        const successCallback = (jsonResponse) => {
            resolve(jsonResponse.data)
        }
        const errorCallback = (error) => {
            reject(error)
        }
        console.log("http call page",page)
        httpRequest.get(
            'orgList', {}, successCallback, errorCallback,
            {
                page: page,
                size: 10
            }
        )
    })
}