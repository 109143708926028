/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import './style.scss';
const daysArray = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];
const applyALl = (props, data) => {
  let result = {};
  let keys = Object.keys(props.data.timings);
  for (let i = 0; i < keys.length; i++) {
    result[keys[i]] = JSON.parse(JSON.stringify(data));
  }
  props.updateChildrenState(
    'step2',
    'timings',
    JSON.parse(JSON.stringify(result)),
  );
};
const OperationalTimings = (props) => {
  const [checkAll, setCheckAll] = useState(true);
  const [applyAllCheck, setApplyAllCheck] = useState(false);
  const [applyAll, setApplyAll] = useState(false);
  useEffect(() => {
    checkedAllMethod();
  }, []);
  useEffect(() => {
    if (props.data.timings) {
      props.data.timings.MONDAY.isChecked &&
        props.data.timings.TUESDAY.isChecked &&
        props.data.timings.WEDNESDAY.isChecked &&
        props.data.timings.THURSDAY.isChecked &&
        props.data.timings.FRIDAY.isChecked &&
        props.data.timings.SATURDAY.isChecked &&
        props.data.timings.SUNDAY.isChecked
        ? setApplyAll(true)
        : setApplyAll(false);
    }
  }, [applyAllCheck]);
  const checkedAllMethod = () => {
    let obj = { ...props.data.timings.applyForAll };
    setCheckAll(!checkAll);
    obj.isChecked = checkAll ? true : false;
    applyALl(props, { ...obj });
  };

  return (
    <div className="OperationalTimings">
      <div className="OperationalTimings__wrapper">
        <div className="OperationalTimings__wrapper_form parent">
          <div className="OperationalTimings__wrapper_form_checkside">
            <div className="OperationalTimings__wrapper_form_checkside_checkbox">
              <Checkbox
                checked={
                  applyAll
                    ? true
                    : props.data.timings.applyForAll.isChecked
                }
                onChange={checkedAllMethod}
                color="default"
                inputProps={{
                  'aria-label': 'checkbox with default color',
                }}
              />
            </div>
            <div className="OperationalTimings__wrapper_form_checkside_text">
              <span style={{ 'font-style': 'italic' }}>
                Apply to All
              </span>
            </div>
          </div>
          <div className="OperationalTimings__wrapper_form_timeside">
            <div className="OperationalTimings__wrapper_form_timeside_opening">
              <input
                type="time"
                className="OperationalTimings__wrapper_form_timeside_opening_input"
                defaultValue="09:00:00"
                value={
                  props.data.timings.applyForAll.time.openingTime
                }
                onChange={(evt) => {
                  let obj = { ...props.data.timings };
                  for (var days in obj) {
                    // console.log(days);
                    obj[days].time.openingTime =
                      evt.target.value + ':00';
                  }
                  props.updateChildrenState('step2', 'timings', obj);
                }}
              />
            </div>
            <span>.</span>
            <div className="OperationalTimings__wrapper_form_timeside_closing">
              <input
                type="time"
                className="OperationalTimings__wrapper_form_timeside_closing_input"
                defaultValue="21:00:00"
                value={
                  props.data.timings.applyForAll.time.closingTime
                }
                onChange={(evt) => {
                  let obj = { ...props.data.timings };
                  for (var days in obj) {
                    // console.log(days);
                    obj[days].time.closingTime =
                      evt.target.value + ':00';
                  }
                  props.updateChildrenState('step2', 'timings', obj);
                }}
              />
            </div>
          </div>
        </div>

        {daysArray.map((day, index) => {
          return (
            <div
              className="OperationalTimings__wrapper_form"
              key={index}
            >
              <div className="OperationalTimings__wrapper_form_checkside">
                <div className="OperationalTimings__wrapper_form_checkside_checkbox">
                  <Checkbox
                    checked={props.data.timings[day].isChecked}
                    color="default"
                    inputProps={{
                      'aria-label': 'checkbox with default color',
                    }}
                    onChange={() => {
                      setApplyAllCheck(!applyAllCheck);
                      let obj = { ...props.data.timings[day] };
                      obj.isChecked = !props.data.timings[day]
                        .isChecked;
                      props.updateParentState(
                        'timings',
                        day,
                        obj,
                        props,
                      );
                    }}
                  />
                </div>
                <div className="OperationalTimings__wrapper_form_checkside_text">
                  <span style={{ 'font-weight': 'bold' }}>{day}</span>
                </div>
              </div>

              <div className="OperationalTimings__wrapper_form_timeside">
                <div className="OperationalTimings__wrapper_form_timeside_opening">
                  <input
                    type="time"
                    className="OperationalTimings__wrapper_form_timeside_opening_input"
                    defaultValue="08:00:00"
                    value={props.data.timings[day].time.openingTime}
                    onChange={(evt) => {
                      let obj = { ...props.data.timings };
                      obj[day].time.openingTime =
                        evt.target.value + ':00';
                      props.updateChildrenState(
                        'step2',
                        'timings',
                        obj,
                      );
                    }}
                  />
                </div>
                <span>.</span>
                <div className="OperationalTimings__wrapper_form_timeside_closing">
                  <input
                    type="time"
                    className="OperationalTimings__wrapper_form_timeside_closing_input"
                    defaultValue="22:00:00"
                    value={props.data.timings[day].time.closingTime}
                    onChange={(evt) => {
                      let obj = { ...props.data.timings };
                      obj[day].time.closingTime =
                        evt.target.value + ':00';
                      props.updateChildrenState(
                        'step2',
                        'timings',
                        obj,
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default OperationalTimings;
