// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. 
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { updateBreadcrumbs, updateToasterConfig } from "../../../redux/modules/app/actions";
import "./style.scss";
import httpRequest from '../../../Utils/httpRequest';
import { Paper } from "@material-ui/core";
import { getCookie } from "../../../Utils/cookies";
import Loader from '../../../Components/Preloader/index'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Divider from '@material-ui/core/Divider';
import Multiselect from 'multiselect-react-dropdown';


//@component
//Couple device
function CoupleDevice(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [orgName, setOrgName] = useState("");
    const [orgNameError, setOrgNameError] = useState("");
    const [orgNameList, setOrgNameList] = useState([]);
    const [deviceName, setDeviceName] = useState("");
    const [deviceNameError, setDeviceNameError] = useState("");
    const [deviceNameList, setDeviceNameList] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [parkingSpaceName, setParkingSpaceName] = useState([]);
    const [parkspaceList, setParkspaceList] = useState([]);
    const [parkingSpaceNameError, setParkingSpaceNameError] = useState("");
    const [parkingSpaceNameList, setParkingSpaceNameList] = useState([]);
    const [orgId, setOrgId] = useState('');
    const [deviceId, setDeviceId] = useState('');
    const [allParkingNotCoupled, setallParkingNotCoupled] = useState(true)

    // function for couple device api
    const coupleDeviceAPI = () => {
        setIsLoading(true);
        setDialogOpen(false);
       // console.log("request",deviceId,parkingSpaceName )
        let requestbody = {
            "parkingIds": 
               parkingSpaceName.map((data)=>{
                return ({
                    "parkingId" : `${data}`
                })
               })
            ,
            "allParkingNotCoupled": allParkingNotCoupled
        }
       console.log("erwrwrwer",requestbody)

        httpRequest.post(
            'coupleDevices',
           requestbody,
            {
                'Content-Type': 'application/json',
            },
            successCallbackCouple,
            errorCallbackCouple,
            {
                deviceId: deviceId,
            },
        )
        // }    
    }
    // function for create security success callback
    const successCallbackCouple = jsonResponse => {
        console.log(jsonResponse)
        if (jsonResponse) {
            const modifiedName = orgName.replace(/[^a-zA-Z ]/g, "");
            setIsLoading(false);
            setDialogOpen(false);
            clearHandler();
            props.history.push("/portal/devicemanagement")
            props.dispatch(updateToasterConfig({
                show: true,
                message: `${modifiedName} has been successfully coupled with parking spaces`,
                color: 'green'
            }))
        }
    }
    // function for create security error callback
    const errorCallbackCouple = error => {
        if (error) {
            setIsLoading(false);
            setDialogOpen(false);
            props.dispatch(updateToasterConfig({
                show: true,
                message: error.error_description || error.message || error.error,
                color: 'red'
            }))
        }
    }
    //function to clear fields after creating account
    const clearHandler = () => {
        setOrgName("");
        setDeviceName("");
        setParkingSpaceName("");
    };
    //variable to handle mediaquery and theme
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    //function for dialog confirm button  
    const submitDetailsConfirm = () => {
        setDialogOpen(true);
    };
    //function to close dialog confirm
    const handleClose = () => {
        setDialogOpen(false);
    };
    //function for pre-details submit api call
    const submitDetails = () => {
        coupleDeviceAPI(orgName, deviceName, parkingSpaceName);
    };

    // after selecting parking space to store value
    const selectOrgHandler = event => {
        //console.log("org name event", event.target.options[event.target.selectedIndex].text)
        if (event.target.value) {
           // console.log(event.target)
            setOrgId(event.target.value);
            setOrgName(event.target.options[event.target.selectedIndex].text)
            setOrgNameError("");
            getSelectListHandler(event.target.value);
        }
        if (!event.target.value) {
            setOrgNameError("Please select one organization");
        }
    }
    //api call when component loads first time on page
    const getSelectOrgListHandler = () => {
        (getCookie('role') === 'MULTITENANT_ADMIN') ? (
            httpRequest.get(
                'mtaOrgList',
                {
                    'Content-Type': 'application/json'
                },
                successCallbackSelectOrgList,
                errorCallbackSelectOrgList,
                {

                },
            ))
            : (
                httpRequest.get(
                    'mtaOrgList',
                    {
                        'Content-Type': 'application/json'
                    },
                    successCallbackSelectOrgList,
                    errorCallbackSelectOrgList,
                    {

                    },
                )
            )
    }
    // success callback for component page load api
    const successCallbackSelectOrgList = jsonResponse => {

        setOrgNameList(jsonResponse.data._embedded.results)
    }
    // error callback for component page load api
    const errorCallbackSelectOrgList = error => {
        setIsLoading(false)
        if (error.message !== "Internal Server Error, Please try again later") {
            // any error message
        }
    }
    // after selecting parking space to store value
    const selectDeviceHandler = event => {
        if (event.target.value) {
            setDeviceId(event.target.value);
            setDeviceNameError("");
        }
        if (!event.target.value) {
            setDeviceNameError("Please select one device");
        }
    }
    //api call when component loads first time on page
    const getSelectDeviceListHandler = () => {
        httpRequest.get(
            'regDeviceList',
            {
                'Content-Type': 'application/json'
            },
            successCallbackSelectDeviceList,
            errorCallbackSelectDeviceList,
            {

            },
        )
    }
    // success callback for component page load api
    const successCallbackSelectDeviceList = jsonResponse => {
        setDeviceNameList(jsonResponse.data._embedded.results)
    }
    // error callback for component page load api
    const errorCallbackSelectDeviceList = error => {
        setIsLoading(false)
        if (error.message !== "Internal Server Error, Please try again later") {
            // any error message
        }
    }
    // after selecting parking space to store value
    const selectHandler = (event, orgId) => {

        if (event.target.value) {
            console.log(event.target.value)
            setParkingSpaceName(event.target.value);
            setParkingSpaceNameError("");

        }
        if (!event.target.value) {
            setParkingSpaceNameError("Please select one role");
        }
    }
    //api call when component loads first time on page
    const getSelectListHandler = (orgId) => {

       // console.log(orgId)
        httpRequest.get(
            'approvedParkingList',
            {
                'Content-Type': 'application/json'
            },
            successCallbackSelectList,
            errorCallbackSelectList,
            {
                orgId: orgId,
            },
        );
    };
    // success callback for component page load api
    const successCallbackSelectList = jsonResponse => {
        // console.log(jsonResponse.data.parkQuickDtoList)
        setParkingSpaceNameList(jsonResponse.data?.parkQuickDtoList)
        setallParkingNotCoupled(jsonResponse.data?.allParkingNotCoupled)
    }
    // error callback for component page load api
    const errorCallbackSelectList = error => {
        setIsLoading(false)
        if (error.message !== "Internal Server Error, Please try again later") {
            // any error message
        }
    }

    //function call on component render/re-render
    useEffect(() => {
        props.dispatch(updateBreadcrumbs("coupleDevice"));
        // getSelectListHandler();
        getSelectOrgListHandler();
        getSelectDeviceListHandler();
    }, [])

    const selectParkingList = (event) => {
       // console.log("select list", event)
        let selectedlist = []
        let selectednameslist = []
        event.map((parking) => {
            selectedlist.push(parking.cat)
            selectednameslist.push(parking.key)
        })
      //  console.log("select cat list", selectednameslist)
        setParkingSpaceName(selectedlist)
        setParkspaceList(selectednameslist)
        setParkingSpaceNameError("")
    }

    return (
        <>
            {isLoading && <Loader />}
            <div className="security-root">
                <Grid container>
                    <Grid item xs={12}>
                        <Paper elevation={5} className="container">
                            <div className="header">
                                <h2 className="heading-text">Couple Device</h2>
                            </div>
                            <form className="form-field" style={{ padding: 0 }}>
                                <div className="contactdetails__wrapper__container">
                                    <div className="contactdetails__wrapper__container__leftside">
                                        <div>
                                            <label>Organization Name*</label><br />
                                            <FormControl style={{ marginTop: '10px' }}>
                                                <select
                                                    variant="standard"
                                                    defaultValue="Select an organization"
                                                    onChange={selectOrgHandler}
                                                    className="selectInput"
                                                    style={{ width: '145%' }}
                                                >
                                                    <option value="">Select an organization</option>
                                                    {orgNameList?.map((list, index) => {
                                                        return (
                                                            <option style={{ fontSize: '16px' }} key={index} value={list.orgId}>{list.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </FormControl>

                                        </div>
                                        <div>
                                            <label>Parking Space Name*</label><br />
                                            <FormControl style={{ marginTop: '10px' }}>


                                                <Multiselect
                                                    isObject={true}
                                                    //showArrow={true}
                                                    displayValue="key"
                                                    className="selectMInput"
                                                    placeholder="Click to select a Parking Space"
                                                    avoidHighlightFirstOption='true'
                                                    
                                                    emptyRecordMsg="select organization to generate parking list"
                                                    // onKeyPressFn={function noRefCheck(){}}
                                                    onRemove={selectParkingList}
                                                    onSelect={selectParkingList}
                                                    options={parkingSpaceNameList?.map((list, index) => {
                                                        return (
                                                            {
                                                                cat: list.parkingId,
                                                                key: list.name+" ("+ list.address + ")"
                                                            }
                                                        )
                                                    })}
                                                    showCheckbox
                                                    style={{
                                                        multiselectContainer: {
                                                           width:'410px'
                                                          },
                                                        searchBox: {
                                                            border: 'none',
                                                            padding: '0',
                                                            //height: '70px'
                                                            

                                                        },
                                                       
                                                       
                                                    }}
                                                />

                                            </FormControl>

                                        </div>
                                    </div>
                                    <div className="contactdetails__wrapper__container__leftside">
                                        <label>Device Name*</label><br />
                                        <FormControl style={{ marginTop: '10px' }}>
                                            <select
                                                variant="standard"
                                                defaultValue="Select a device"
                                                onChange={selectDeviceHandler}
                                                className="selectInput"
                                                style={{ width: '145%' }}
                                            >
                                                <option value="">Select a device</option>
                                                {deviceNameList?.map((list, index) => {
                                                    return (
                                                        <option style={{ fontSize: '16px' }} key={index} value={list.deviceId}>{list.deviceName}</option>
                                                    )
                                                })}
                                            </select>
                                        </FormControl>

                                    </div>
                                </div>
                                <div className="button-section">
                                    <button
                                        type="button"
                                        className="form-button-submit"
                                        onClick={submitDetailsConfirm}
                                        disabled={
                                            orgId.length === 0 ||
                                            orgNameError.length > 0 ||
                                            deviceId.length === 0 ||
                                            deviceNameError.length > 0 ||
                                            parkingSpaceName.length === 0 ||
                                            parkingSpaceNameError.length > 0
                                        }
                                        style={{
                                            backgroundColor:
                                                orgId.length === 0 ||
                                                    orgNameError.length > 0 ||
                                                    deviceId.length === 0 ||
                                                    deviceNameError.length > 0 ||
                                                    parkingSpaceName.length === 0 ||
                                                    parkingSpaceNameError.length > 0 ?
                                                    "#707070" :
                                                    undefined
                                        }}
                                    >
                                        Couple
                                    </button>


                                </div>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <Dialog
                fullScreen={fullScreen}
                open={dialogOpen}
                PaperProps={{
                    style: {
                        padding: "1% 2%",
                    },
                }}
                onClose={handleClose}
                aria-labelledby="create-security-admin-dialog"
            >
                <DialogTitle
                    className="dialog-title"
                    id="create admin dialog-header"
                    style={{ paddingBottom: "0px", paddingTop: "2px", paddingLeft: "2px", paddingRight: "2px" }}
                >
                    <Grid container justify="space-between">
                        <Grid item>
                            <span className="dialog-create-user-text">Couple Device</span>
                        </Grid>
                        {/* <Grid item>
                    <CloseIcon className="dialog-close-icon"  onClick={handleClose} />
                    </Grid> */}
                    </Grid>
                </DialogTitle>
                <Divider />
                <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px" }} className="dialog-content">
                    <p className="dialog-content-text">Are you sure you want to couple <span style={{fontWeight:"bold"}}>{deviceId}</span> with <br /><br />
                    {parkspaceList.map((park)=>{return <>
                    <span style={{fontWeight:"bold"}}>{park}</span><br />
                    {parkingSpaceNameList.map((addr)=>{
                       if( addr.name===park) {
                        
                       return  <span >{addr.address}</span>}
                    })}
                    <br /><br />
                    </>})}
                    </p>
                </DialogContent>
                <DialogActions className="dialog-actions">
                    <button type="button" onClick={submitDetails} className="confirm-button" >
                        Confirm
                    </button>
                    <button type="button" onClick={handleClose} className="cancel-button" >
                        Cancel
                    </button>
                </DialogActions>
            </Dialog>
        </>
    )
}
//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs,
    updateToasterConfig,
    dispatch,
});
//method to connect react with redux
export default withRouter(
    connect((state) => {
        return {};
    })(CoupleDevice)
);