/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { useState } from 'react';
import { Button } from '@material-ui/core/';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import OprationalTimmings from './../OperationalTimings';
import CustomAvailability from './../CustomRule';
import Tip from '../../../../../Assets/Images/info.svg';
import TwoWheeler from '../../../../../Assets/Images/2 Wheeler.svg';
import FourWheeler from '../../../../../Assets/Images/4 Wheeler.svg';
import Fleet from '../../../../../Assets/Images/Fleet.svg';
import CarA from '../../../../../Assets/Images/carA.png';
import MotorA from '../../../../../Assets/Images/motorA.svg';
import FleetA from '../../../../../Assets/Images/vanA.png';
import './style.scss';
import error from '../../../../../Assets/Images/error.png';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const styles = {
  opacity: '0.5',
  pointerEvents: 'none',
};

const activeFieldStyle = {
  opacity: '1',
  pointerEvents: 'all',
};

const tabs = ['twoWheeler', 'fourWheeler', 'fleet'];

const updateParentState = (parentKey, childKey, value, props) => {
  let obj = { ...props.data[parentKey] };

  obj[childKey] = value;
  props.updateChildrenState('step2', parentKey, obj);
};
const changeTerms = (parentKey, childKey, value, props) => {
  let obj = { ...props.data[parentKey] };

  if (parentKey === 'twoWheeler') {
    if (childKey === 'pricePerDay') {
      obj.pricePerDay = value;
      obj.pricePerMonth = 0;
      obj.pricePerWeek = 0;
      props.updateChildrenState('step2', parentKey, obj);
    }
    if (childKey === 'pricePerWeek') {
      obj.pricePerDay = 0;
      obj.pricePerMonth = 0;
      obj.pricePerWeek = value;
      props.updateChildrenState('step2', parentKey, obj);
    }
    if (childKey === 'pricePerMonth') {
      obj.pricePerDay = 0;
      obj.pricePerMonth = value;
      obj.pricePerWeek = 0;
      props.updateChildrenState('step2', parentKey, obj);
    }
  }
  if (parentKey === 'fourWheeler') {
    if (childKey === 'pricePerDay') {
      obj.pricePerDay = value;
      obj.pricePerMonth = 0;
      obj.pricePerWeek = 0;
      props.updateChildrenState('step2', parentKey, obj);
    }
    if (childKey === 'pricePerWeek') {
      obj.pricePerDay = 0;
      obj.pricePerMonth = 0;
      obj.pricePerWeek = value;
      props.updateChildrenState('step2', parentKey, obj);
    }
    if (childKey === 'pricePerMonth') {
      obj.pricePerDay = 0;
      obj.pricePerMonth = value;
      obj.pricePerWeek = 0;
      props.updateChildrenState('step2', parentKey, obj);
    }
  }
  if (parentKey === 'fleet') {
    if (childKey === 'pricePerDay') {
      obj.pricePerDay = value;
      obj.pricePerMonth = 0;
      obj.pricePerWeek = 0;
      props.updateChildrenState('step2', parentKey, obj);
    }
    if (childKey === 'pricePerWeek') {
      obj.pricePerDay = 0;
      obj.pricePerMonth = 0;
      obj.pricePerWeek = value;
      props.updateChildrenState('step2', parentKey, obj);
    }
    if (childKey === 'pricePerMonth') {
      obj.pricePerDay = 0;
      obj.pricePerMonth = value;
      obj.pricePerWeek = 0;
      props.updateChildrenState('step2', parentKey, obj);
    }
  }
};
const handleCheckRadio = (key, props) => {
  let obj = { ...props.data[key] };
  obj.paid = !obj.paid;
  props.updateChildrenState('step2', key, obj);
};

const AddParkingStepTwo = props => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [activeTab, setActiveTab] = useState('twoWheeler');
  const [showTheDiv, setTheDiv] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };
  const updateActiveTab = tabName => {
    setActiveTab(tabName);
  };
  const showCustomRule = () => {
    setTheDiv(showTheDiv + 1);
  };

  props.updateRootState(1, 2);
  return (
    <div className="tariffdetails">
      <div className="tariffdetails__wrapper">
        <div className="tariffdetails__wrapper__container">
          <div className="tariffdetails__wrapper__container__leftside">
            <div className="tariffdetails__wrapper__heading">
              <p className="tariffdetails__wrapper__heading__title">
                Slot Count and Tariff Details
              </p>
            </div>
            <div className="tariffdetails__wrapper__container__leftside_content">
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  className="vehicle_tab"
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab
                    icon={
                      <img
                        alt="twowheeler"
                        src={
                          [activeTab] == 'twoWheeler'
                            ? MotorA
                            : TwoWheeler
                        }
                      />
                    }
                    label="2 Wheeler"
                    {...a11yProps(0)}
                    onClick={() => updateActiveTab('twoWheeler')}
                  />
                  <Tab
                    icon={
                      <img
                        alt="fourwheeler"
                        src={
                          [activeTab] == 'fourWheeler'
                            ? CarA
                            : FourWheeler
                        }
                      />
                    }
                    label="4 Wheeler"
                    {...a11yProps(1)}
                    onClick={() => updateActiveTab('fourWheeler')}
                  />
                  <Tab
                    icon={
                      <img
                        alt="fleet"
                        src={[activeTab] == 'fleet' ? FleetA : Fleet}
                      />
                    }
                    label="Fleet"
                    {...a11yProps(2)}
                    onClick={() => updateActiveTab('fleet')}
                  />
                </Tabs>
              </AppBar>

              <div className="tariffdetails__wrapper__container__leftside_twowheeler">
                <SwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  {tabs.map((tab, index) => {
                    return (
                      <TabPanel
                        key={tab}
                        value={value}
                        index={index}
                        dir={theme.direction}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <InputLabel htmlFor="filled-adornment-amount">
                              No of Slots
                              <img
                                src={Tip}
                                style={{
                                  marginBottom: '-3px',
                                  marginTop: '10px',
                                  marginLeft: '10px',
                                  width: '15px',
                                  display: 'inline',
                                }}
                                alt="tip"
                              />
                            </InputLabel>
                            <button
                              className="decrese btn"
                              disabled={
                                props.data[activeTab].totalSlots <= 0
                              }
                              onClick={() => {
                                let data = {
                                  ...props.data[activeTab],
                                };
                                data.totalSlots = data.totalSlots - 1;
                                updateParentState(
                                  activeTab,
                                  'totalSlots',
                                  data.totalSlots,
                                  props,
                                );
                              }}
                            >
                              <span>-</span>
                            </button>

                            <input
                              type="number"
                              min={0}
                              className="tariffdetails__wrapper__container__leftside_twowheeler_numoflots"
                              placeholder="100"
                              value={props.data[activeTab].totalSlots}
                              onChange={evt => {
                                let value = parseInt(
                                  evt.target.value,
                                );

                                value >= 1 &&
                                  updateParentState(
                                    activeTab,
                                    'totalSlots',
                                    parseInt(evt.target.value),
                                    props,
                                  );
                              }}
                            />
                            <button
                              className="increse btn"
                              disable={
                                props.data[activeTab].totalSlots <= 0
                              }
                              onClick={() => {
                                let data = {
                                  ...props.data[activeTab],
                                };
                                data.totalSlots = data.totalSlots + 1;
                                updateParentState(
                                  activeTab,
                                  'totalSlots',
                                  data.totalSlots,
                                  props,
                                );
                              }}
                            >
                              <span>+</span>
                            </button>

                            {props.search !== '' ? (
                              <p className="notedit">
                                <div className="error-block">
                                  <img src={error} alt="error" />
                                  <span>
                                    This Information change will be
                                    subjected to approval
                                  </span>
                                </div>
                              </p>
                            ) : (
                                ''
                              )}
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel htmlFor="filled-adornment-amount">
                              Tariff
                              <img
                                src={Tip}
                                style={{
                                  marginBottom: '-3px',
                                  marginTop: '10px',
                                  marginLeft: '10px',
                                  width: '15px',
                                  display: 'inline',
                                }}
                                alt=""
                              />
                            </InputLabel>

                            <span>Free</span>
                            <Switch
                              //value="checkedC"
                              checked={props.data[activeTab].paid}
                              inputProps={{
                                'aria-label': 'primary checkbox',
                              }}
                              onClick={() =>
                                handleCheckRadio(activeTab, props)
                              }
                              id="myCheckOne"
                            />
                            <span>Paid</span>

                            <div
                              id="bike-tariff"
                              className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif"
                              style={
                                props.data[activeTab].paid
                                  ? activeFieldStyle
                                  : styles
                              }
                            >
                              <InputLabel htmlFor="filled-adornment-amount">
                                Base Price
                              </InputLabel>
                              <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_baseprice">
                                <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_baseprice_left">
                                  <select
                                    id="baseHour"
                                    value={
                                      props.data[activeTab].baseHour
                                    }
                                    onChange={evt => {
                                      updateParentState(
                                        activeTab,
                                        'baseHour',
                                        evt.target.value,
                                        props,
                                      );
                                    }}
                                  >
                                    <option value="1">
                                      0-1 Hour
                                    </option>
                                    <option value="2">
                                      0-2 Hour
                                    </option>
                                    <option value="3">
                                      0-3 Hour
                                    </option>
                                  </select>
                                </div>
                                <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_baseprice_right">
                                  {/* <span style=';'>&#8377;</span> */}
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      paddingRight: '5px',
                                    }}
                                  >
                                    &#8377;
                                  </span>
                                  <input
                                    type="number"
                                    min={0}
                                    onWheel={event =>
                                      event.currentTarget.blur()
                                    }
                                    value={
                                      props.data[activeTab].basePrice
                                    }
                                    //min="10.00" step="10.00" max="2500" value="20.00"
                                    placeholder="10.00"
                                    onChange={evt =>
                                      updateParentState(
                                        activeTab,
                                        'basePrice',
                                        parseFloat(
                                          1 * evt.target.value,
                                        ),
                                        props,
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                {props.data[activeTab].paid ===
                                  true &&
                                  (props.data[activeTab].basePrice <=
                                    0 ||
                                    props.data[activeTab].basePrice ===
                                    '') ? (
                                    <p className="errorClass">
                                      Base price should be more then 0
                                    </p>
                                  ) : (
                                    ''
                                  )}
                              </div>
                              <InputLabel htmlFor="filled-adornment-amount">
                                Additional Hour
                              </InputLabel>
                              <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_additionalHour">
                                <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_additionalHour_left">
                                  <select
                                    id="additionalHour"
                                    value={
                                      props.data[activeTab]
                                        .additionalHour
                                    }
                                    onChange={evt =>
                                      updateParentState(
                                        activeTab,
                                        'additionalHour',
                                        evt.target.value,
                                        props,
                                      )
                                    }
                                  >
                                    <option value="1">+1 Hour</option>
                                    <option value="2">+2 Hour</option>
                                    <option value="3">+3 Hour</option>
                                  </select>
                                </div>
                                <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_additionalHour_right">
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      paddingRight: '5px',
                                    }}
                                  >
                                    &#8377;
                                  </span>
                                  <input
                                    type="number"
                                    min={0}
                                    onWheel={event =>
                                      event.currentTarget.blur()
                                    }
                                    placeholder="10.00"
                                    value={
                                      props.data[activeTab]
                                        .additionalPrice
                                    }
                                    onChange={evt =>
                                      updateParentState(
                                        activeTab,
                                        'additionalPrice',
                                        parseFloat(
                                          1 * evt.target.value,
                                        ),
                                        props,
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                {props.data[activeTab].paid ===
                                  true &&
                                  (props.data[activeTab]
                                    .additionalPrice <= 0 ||
                                    props.data[activeTab]
                                      .additionalPrice === '') ? (
                                    <p className="errorClass">
                                      Additional price should be more
                                      then 0
                                    </p>
                                  ) : (
                                    ''
                                  )}
                              </div>
                              <InputLabel htmlFor="filled-adornment-amount">
                                Long term
                              </InputLabel>
                              <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_longterm">
                                <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_longterm_left">
                                  <select
                                    id="longtermHour"
                                    value={
                                      props.data[activeTab]
                                        .selectedLongterm
                                    }
                                    onChange={evt => {
                                      updateParentState(
                                        activeTab,
                                        'selectedLongterm',
                                        evt.target.value,
                                        props,
                                      );
                                    }}
                                  >
                                    <option value="pricePerDay">
                                      Price/Day
                                    </option>
                                    <option value="pricePerWeek">
                                      Price/Week
                                    </option>
                                    <option value="pricePerMonth">
                                      Price/Month
                                    </option>
                                  </select>
                                </div>
                                <div className="tariffdetails__wrapper__container__leftside_twowheeler_tarrif_longterm_right">
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      paddingRight: '5px',
                                    }}
                                  >
                                    &#8377;
                                  </span>

                                  {props.data[activeTab]
                                    .selectedLongterm ===
                                    'pricePerDay' && (
                                      <input
                                        type="number"
                                        min={0}
                                        onWheel={event =>
                                          event.currentTarget.blur()
                                        }
                                        placeholder="10.00"
                                        value={
                                          props.data[activeTab]
                                            .pricePerDay
                                        }
                                        onChange={evt => {
                                          changeTerms(
                                            activeTab,
                                            'pricePerDay',
                                            parseFloat(
                                              1 * evt.target.value,
                                            ),
                                            props,
                                          );
                                        }}
                                      />
                                    )}
                                  {props.data[activeTab]
                                    .selectedLongterm ===
                                    'pricePerWeek' && (
                                      <input
                                        type="number"
                                        min={0}
                                        onWheel={event =>
                                          event.currentTarget.blur()
                                        }
                                        placeholder="10.00"
                                        value={
                                          props.data[activeTab]
                                            .pricePerWeek
                                        }
                                        onChange={evt => {
                                          changeTerms(
                                            activeTab,
                                            'pricePerWeek',
                                            parseFloat(
                                              1 * evt.target.value,
                                            ),
                                            props,
                                          );
                                        }}
                                      />
                                    )}
                                  {props.data[activeTab]
                                    .selectedLongterm ===
                                    'pricePerMonth' && (
                                      <input
                                        type="number"
                                        min={0}
                                        placeholder="10.00"
                                        onWheel={event =>
                                          event.currentTarget.blur()
                                        }
                                        value={
                                          props.data[activeTab]
                                            .pricePerMonth
                                        }
                                        onChange={evt => {
                                          changeTerms(
                                            activeTab,
                                            'pricePerMonth',
                                            parseFloat(
                                              1 * evt.target.value,
                                            ),
                                            props,
                                          );
                                        }}
                                      />
                                    )}
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </TabPanel>
                    );
                  })}
                </SwipeableViews>
              </div>
            </div>
          </div>
          <div className="tariffdetails__wrapper__container__rightside">
            <div className="tariffdetails__wrapper__heading">
              <p className="tariffdetails__wrapper__heading__title">
                Operational Timings
              </p>
            </div>
            <div className="tariffdetails__wrapper__container__rightside__heading">
              <p>
                <b>Standard Operating Hours </b>
                <img
                  src={Tip}
                  style={{
                    marginBottom: '-3px',
                    marginTop: '10px',
                    marginLeft: '10px',
                    width: '15px',
                    display: 'inline',
                  }}
                  alt=""
                />
              </p>
              <div>
                {props.data.timings.FRIDAY.isChecked ||
                  props.data.timings.SATURDAY.isChecked ||
                  props.data.timings.SUNDAY.isChecked ||
                  props.data.timings.MONDAY.isChecked ||
                  props.data.timings.TUESDAY.isChecked ||
                  props.data.timings.WEDNESDAY.isChecked ||
                  props.data.timings.THURSDAY.isChecked ? (
                    ''
                  ) : (
                    <p className="errorClass">
                      Please select atleast one Operating Hours
                    </p>
                  )}
              </div>
            </div>
            <OprationalTimmings
              data={props.data}
              updateRootState={props.updateRootState}
              updateChildrenState={props.updateChildrenState}
              updateParentState={updateParentState}
            />
            <div className="customRule">
              <p className="tariffdetails__wrapper__heading__title">
                Custom Availability
              </p>
              <img
                src={Tip}
                style={{
                  marginBottom: '5px',
                  marginTop: '10px',
                  marginLeft: '10px',
                  width: '15px',
                  display: 'inline',
                }}
                alt=""
              />

              <Button
                variant="contained"
                color="#E46A76"
                onClick={showCustomRule}
              >
                Add Custom Rule
              </Button>
            </div>

            {showTheDiv > 0 && (
              <CustomAvailability
                data={props.data}
                count={showTheDiv}
                updateRootState={props.updateRootState}
                updateChildrenState={props.updateChildrenState}
                updateParentState={updateParentState}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddParkingStepTwo;
