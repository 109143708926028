// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import {
  updateBreadcrumbs,
  updateToasterConfig,
} from "../../../redux/modules/app/actions";
import detailsUseStyles from "./styles";
import httpRequest from "../../../Utils/httpRequest";
import Loader from "../../../Components/Preloader/index";
import editIcon from "../../../Assets/icons/edit_white.png";
import deleteIcon from "../../../Assets/icons/delete_white.png";
import detailsIcon from "../../../Assets/icons/viewdetailsicon.png";
import { useLocation } from "react-router-dom";
//@component
//ViewDetails
const ViewAccDetails = React.memo((props) => {
  const classes = detailsUseStyles();
  const location = useLocation();
  console.log(location);
  const [isLoading, setIsLoading] = useState(false);
  const [filterList, setFilterList] = useState({});
  const [orgDetails, setOrgDetails] = useState({});
  const [userCount, setUserCount] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);
  // const [deactivateAlert, setdeactivateAlert] = useState(false);

  //const [activateAlert, setactivateAlert] = useState(false);

  const [subStatus, setSubStatus] = useState(false);

  const [severity, setSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [userId, setUserId] = useState([props.location.state.id || props.location.state.viewId]);
  const [orgId, setOrgId] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [res, setres] = useState(false);
  const [adminType, setAdminType] = useState("");
  const [subDetails, setsubDetails] = useState({});
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  //alert toaster function
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  //function for alert toaster
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  //function to close delete confirm popup
  const handleClose = () => {
    setShowDialog(false);
  };

  // const HandledeactivateOpen = () => {
  //   setdeactivateAlert(true);
  // };

  // const handleDeactivateAlert = () => {
  //   setdeactivateAlert(false);
  // };

  // const handleActivateOpen = () => {
  //   setactivateAlert(true);
  // };

  // const handleActivateClose = () => {
  //   setactivateAlert(false);
  // };

  const handleSubscriptionStatus = () => {
    if (status === "ACTIVE") {
      setSubStatus(false);
      setIsLoading(true);

      httpRequest.patch(
        "deactivateSubscription",
        {
          organizationId: props.location.state?.orgId,
          roleName: "MULTITENANT_ADMIN",
          subscriptionId: subDetails?.subscriptionId,
        },
        {
          "Content-Type": "application/json",
        },
        deactivateSuccess,
        deactivateError,
        {}
      );
    } else {
      setSubStatus(false);
      setIsLoading(true);
      //setactivateAlert(false);
      httpRequest.patch(
        "activateSubscription",
        {
          organizationId: props.location.state?.orgId,
          roleName: "MULTITENANT_ADMIN",
          subscriptionId: subDetails?.subscriptionId,
        },
        {
          "Content-Type": "application/json",
        },
        activateSuccess,
        activateError,
        {}
      );
    }
  };

  const activateSuccess = () => {
    setIsLoading(false);
    setStatus("ACTIVE");
    props.dispatch(
      updateToasterConfig({
        show: true,
        message: `This account and users under it are activated successfully`,
        color: "green",
      })
    );

    //window.location.reload();
  };

  const activateError = () => {
    setIsLoading(false);
  };
  // const handleActivate = () => {

  // };

  const deactivateSuccess = () => {
    setIsLoading(false);
    setStatus("DEACTIVATED");
    props.dispatch(
      updateToasterConfig({
        show: true,
        message: `This account and users under it are deactivated successfully`,
        color: "green",
      })
    );

    //window.location.reload();
  };
  const deactivateError = () => {
    setIsLoading(false);
  };
  // const handleDeactivate = () => {

  // };
  //api for data filter list as per view user Id
  const generateListHandler = () => {
    setIsLoading(true);
    if (props.location.state) {
      httpRequest.get(
        "admindetails",
        {
          "Content-Type": "application/json",
        },
        successCallbackList,
        errorCallback,
        {
          userId: props.location.state.id || props.location.state.viewId,
        }
      );
    }
  };

  const dateFormatter = (date) => {
    const data = date.split(" ");
    console.log(data);
    return data[0];
  };
  //success callback for list handler
  const successCallbackList = (jsonResponse) => {
    setIsLoading(false);
    if (jsonResponse) {
      setFilterList(jsonResponse.data);
      if (
        jsonResponse.data?.role?.name === "RESIDENTIAL_GROUP_PARKING_PROVIDER"
      ) {
        setres(true);
        setAdminType("RESIDENTIAL_GROUP_PARKING_PROVIDER");
      }
      if (jsonResponse.data?.role?.name === "CLOSED_GROUP_PARKING_PROVIDER") {
        setres(false);
        setAdminType("CLOSED_GROUP_PARKING_PROVIDER");
      }
      if (jsonResponse.data?.role?.name === "MULTITENANT_ADMIN") {
        setres(false);
        setAdminType("MULTITENANT_ADMIN");
      }
    }
  };

  //error callback
  const errorCallback = (error) => {
    if (error) {
      setIsLoading(false);
    }
  };

  //function for user count details by orgid
  const generateUserCount = () => {
    setIsLoading(true);
    if (props.location.state) {
      httpRequest.get(
        "orgUserCount",
        {
          "Content-Type": "application/json",
        },
        successCallbackCount,
        errorCallbackCount,
        {
          orgId: props.location.state.orgId || props.location.state.editOrgId,
        }
      );
    }
  };

  //success callback for user count details by orgid
  const successCallbackCount = (jsonResponse) => {
    // console.log(jsonResponse);
    setIsLoading(false);
    if (jsonResponse) {
      jsonResponse.data.map((countValue) => {
        countValue.userCount.map((roleValue) => {
          if (roleValue.role.name === "CLOSED_GROUP_END_USER") {
            setUserCount(roleValue?.active);
          }
          if (roleValue.role.name === "RESIDENTIAL_GROUP_END_USER") {
            setUserCount(roleValue?.active);
          }
          if (roleValue.role.name === "MULTITENANT_ADMIN") {
            setUserCount(roleValue?.active);
          }
        });
      });
    }
  };

  //error callback for user count details by orgid
  const errorCallbackCount = (error) => {
    setIsLoading(false);
    if (error) {
      // error message if any
    }
  };

  //function for api call org details by id
  const generateDatatHandlerOrg = () => {
    if (props.location.state) {
      httpRequest.get(
        "orgDetailsbyId",
        {
          "Content-Type": "application/json",
        },
        successCallbackOrgDetails,
        errorCallbackOrgDetails,
        {
          orgId: props.location.state.orgId || props.location.state.editOrgId,
        }
      );
    }
  };

  //success callback for org api call
  const successCallbackOrgDetails = (jsonResponse) => {
    //console.log(jsonResponse);
    //setIsLoading(false)
    if (jsonResponse) {
      setOrgDetails(jsonResponse.data);
    }
  };

  //error callback for org api call
  const errorCallbackOrgDetails = (error) => {
    if (error) {
      // error message if any
    }
  };
  //function for back button
  const backHandler = () => {
    if (props.location.state) {
      props.history.push({
        pathname: "/portal/viewaccount",
        state: {
          currentPage: props.location.state.currentPage,
          pressed: true,
        },
      });
    }
  };
  //function for edit handler
  const editHandler = (id) => {
    props.history.push({
      pathname: "/portal/editaccount",
      state: {
        id: `${id}` || props.location.state.viewId,
        solOrgId: props.location.state.orgId || props.location.state.editOrgId,
        source: "edit",
      },
    });
  };

  //function for edit account alert
  const editAlert = () => {
    setOpenAlert(true);
    setAlertMessage("Account updated successfully");
    setSeverity("Success");
  };

  //api call to delete account
  const deleteHandler = () => {
    setIsLoading(true);
    if (props.location.state) {
      httpRequest.deleteAdmin(
        "adminDelete",
        {
          userIds: userId,
          adminRemarks: "left the company",

          roles: [adminType],
          org: {
            orgId: props.location.state.orgId,
          },
        },
        {
          "Content-Type": "application/json",
        },
        successCallbackDelete,
        errorCallbackDelete,
        {}
      );
    }
  };
  //error callback to delete account
  const successCallbackDelete = async (jsonResponse) => {
    if (jsonResponse) {
      setIsLoading(false);
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: `User deleted successfully`,
          color: "green",
        })
      );
      props.history.push({
        pathname: "/portal/viewaccount",
        state: {
          trigger: "delete",
        },
      });
    }
  };

  //error callback for deleting account
  const errorCallbackDelete = (error) => {
    setIsLoading(false);
    setShowDialog(false);
    if (error.message !== "Internal Server Error, Please try again later") {
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: error.error_description || error.message || error.error,
          color: "red",
        })
      );
    }
  };

  const deleteButtonHandler = (data1, data2) => {
    let tempId = [];
    setShowDialog(true);
    setUserId(tempId.concat(data1));
    setOrgId(data2);
  };

  const viewSuccess = (json) => {
    setsubDetails(json.data[0]);
    //console.log(json.data[0]);
    setStatus(json?.data[0]?.status?.name);
  };

  const viewError = (err) => {
    console.log(err);
  };
  //adding component to breadcrumb and displaying filtered data as per view user

  //deactivate subscription

  useEffect(() => {
    console.log(props.location);
    httpRequest.get(
      "superAdminView",
      {
        "Content-Type": "application/json",
      },
      viewSuccess,
      viewError,
      {
        orgId: props.location.state.orgId,
        role: "CLOSED_GROUP_PARKING_PROVIDER",
      }
    );

    props.dispatch(updateBreadcrumbs("viewaccdetails"));
    generateListHandler();
    generateDatatHandlerOrg();
    generateUserCount();
    if (props.location.state) {
      if (props.location.state.destination === "edit") {
        editAlert();
      }
    }
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <Grid container direction="column" className={classes.container}>
        {/* View Account Details */}
        <Card className={classes.detailsCard} style={{ marginBottom: "2em" }}>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Grid
                  container
                  direction="row"
                  style={{ backgroundColor: "#E5E2EF", height: "3.5em" }}
                >
                  <Grid item style={{ marginTop: "1em", marginLeft: "2em" }}>
                    <Typography style={{ fontWeight: "600" }}>
                      View Account Details
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    justify="flex-end"
                    style={{ width: "80%" }}
                  >
                    <Grid item style={{ marginTop: "1em", marginRight: "1em" }}>
                      <Button
                        className={classes.editButton}
                        variant="contained"
                        onClick={() => {
                          deleteButtonHandler(
                            props.location.state && props.location.state.id,
                            props.location.state && props.location.state.orgId
                          );
                        }}
                      >
                        <img
                          style={{ width: "1.5em", marginRight: "0.5em" }}
                          src={deleteIcon}
                          alt="edit-icon"
                        />
                        Delete
                      </Button>
                    </Grid>
                    <Grid item style={{ marginTop: "1em", marginRight: "2em" }}>
                      <Button
                        className={classes.editButton}
                        variant="contained"
                        onClick={() =>
                          editHandler(
                            filterList.userId ? filterList.userId : ""
                          )
                        }
                      >
                        <imgdateFormatter
                          style={{ width: "1.5em", marginRight: "0.5em" }}
                          src={editIcon}
                          alt="edit-icon"
                        />
                        Edit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" justify="space-around">
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      style={{ marginTop: "2em", marginLeft: "1em" }}
                    >
                      <img
                        src={detailsIcon}
                        style={{ width: "15em", height: "15em" }}
                        alt="admin"
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      style={{ marginBottom: "2em", marginLeft: "1em" }}
                    >
                      <Grid item className={classes.labelMargin}>
                        <p
                          style={{
                            fontWeight: "700",
                            fontSize: "0.90rem",
                            color: "darkblue",
                          }}
                        >
                          Account Information{" "}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          Solution Admin Name{" "}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          Tenant Type{" "}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          Admin Type{" "}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          Email Address
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          Contact Number
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          Alt Contact Number{" "}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          REALM Name{" "}
                        </p>
                      </Grid>
                      {!res && (
                        <Grid item className={classes.labelMargin}>
                          <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                            No. of End Users{" "}
                          </p>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      style={{ marginTop: "20px" }}
                    >
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontSize: "0.80rem" }}></p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontSize: "0.80rem" }}>
                          {filterList.firstName
                            ? filterList.firstName + " "
                            : "--"}
                          {filterList.lastName ? filterList.lastName : "--"}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontSize: "0.80rem" }}>
                          {filterList.adminTypeDto?.adminTypes
                            ? filterList.adminTypeDto?.adminTypes
                            : "--"}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontSize: "0.80rem" }}>
                          {filterList.role?.name ===
                          "RESIDENTIAL_GROUP_PARKING_PROVIDER"
                            ? "Residential Parking Admin"
                            : filterList.role?.name ===
                              "CLOSED_GROUP_PARKING_PROVIDER"
                            ? "Parking Admin"
                            : filterList.role?.name === "MULTITENANT_ADMIN"
                            ? "Multi-Tenant Parking Admin"
                            : "--"}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontSize: "0.80rem" }}>
                          {filterList.corpEmailId
                            ? filterList.corpEmailId
                            : "--"}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontSize: "0.80rem" }}>
                          {filterList.mobileNumber
                            ? filterList.mobileNumber
                            : "--"}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontSize: "0.80rem" }}>
                          {filterList.altContactNumber
                            ? filterList.altContactNumber
                            : "--"}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontSize: "0.80rem" }}>
                          {orgDetails.tenant
                            ? orgDetails.tenant.realmName
                            : "--"}
                        </p>
                      </Grid>
                      {!res && (
                        <Grid item className={classes.labelMargin}>
                          <p style={{ fontSize: "0.80rem" }}>
                            {userCount ? userCount : "--"}
                          </p>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      style={{ marginBottom: "2em", marginLeft: "1em" }}
                    >
                      <Grid item className={classes.labelMargin}>
                        <p
                          style={{
                            fontWeight: "700",
                            fontSize: "0.90rem",
                            color: "darkblue",
                          }}
                        >
                          Other Information{" "}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          Organization Name{" "}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          Organization Acronym
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          Alt Contact Person
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          Whitelist Domains{" "}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          Status{" "}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          Google Maps Activation{" "}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      style={{ marginRight: "20px", marginTop: "20px" }}
                    >
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontSize: "0.80rem" }}></p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontSize: "0.80rem" }}>
                          {orgDetails.name ? orgDetails.name : "--"}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontSize: "0.80rem" }}>
                          {orgDetails.orgAcronym ? orgDetails.orgAcronym : "--"}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontSize: "0.80rem" }}>
                          {filterList.altContactPerson
                            ? filterList.altContactPerson
                            : "--"}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontSize: "0.80rem" }}>
                          {orgDetails.emailDomains
                            ? orgDetails.emailDomains.join()
                            : "--"}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontSize: "0.80rem" }}>
                          {filterList.org
                            ? filterList.org.active
                              ? "Active"
                              : "Inactive"
                            : "--"}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontSize: "0.80rem" }}>
                          {orgDetails.isGMapOpted ? "Yes" : "No"}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        {/* Address Details Information */}
        <Card className={classes.detailsCard} style={{ marginBottom: "2em" }}>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  style={{ backgroundColor: "#E5E2EF", height: "3.5em" }}
                >
                  <Grid item style={{ marginTop: "1em", marginLeft: "2em" }}>
                    <Typography style={{ fontWeight: "600" }}>
                      Address Details Information
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  // justify="space-around"
                  style={{ marginLeft: "2em" }}
                >
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      style={{ marginBottom: "2em" }}
                    >
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          Address{" "}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p
                          style={{
                            fontWeight: "700",
                            fontSize: "0.80rem",
                            color: "darkblue",
                          }}
                        >
                          Other Address Details{" "}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          Country
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          State
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          City{" "}
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          Postal Code{" "}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      style={{ marginRight: "50px" }}
                    >
                      <Grid
                        item
                        style={{ marginLeft: "2em" }}
                        className={classes.labelMargin}
                      >
                        <p style={{ fontSize: "0.80rem" }}>
                          {filterList.addressDetails
                            ? filterList.addressDetails.addressLine1 +
                              " " +
                              filterList.addressDetails.addressLine2 +
                              " "
                            : "--"}
                        </p>
                      </Grid>
                      <Grid
                        item
                        style={{ marginLeft: "2em", marginTop: "38px" }}
                        className={classes.labelMargin}
                      >
                        <p style={{ fontSize: "0.80rem" }}></p>
                      </Grid>
                      <Grid
                        item
                        style={{ marginLeft: "2em" }}
                        className={classes.labelMargin}
                      >
                        <p style={{ fontSize: "0.80rem" }}>
                          {filterList.addressDetails
                            ? filterList.addressDetails.country
                            : "--"}
                        </p>
                      </Grid>
                      <Grid
                        item
                        style={{ marginLeft: "2em" }}
                        className={classes.labelMargin}
                      >
                        <p style={{ fontSize: "0.80rem" }}>
                          {filterList.addressDetails
                            ? filterList.addressDetails.state
                            : "--"}
                        </p>
                      </Grid>
                      <Grid
                        item
                        style={{ marginLeft: "2em" }}
                        className={classes.labelMargin}
                      >
                        <p style={{ fontSize: "0.80rem" }}>
                          {filterList.addressDetails
                            ? filterList.addressDetails.city
                            : "--"}
                        </p>
                      </Grid>
                      <Grid
                        item
                        style={{ marginLeft: "2em" }}
                        className={classes.labelMargin}
                      >
                        <p style={{ fontSize: "0.80rem" }}>
                          {filterList.addressDetails
                            ? filterList.addressDetails.pincode
                            : "--"}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>

        {/*subscription details */}
        <Card className={classes.detailsCard} style={{ marginBottom: "2em" }}>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  style={{ backgroundColor: "#E5E2EF", height: "3.5em" }}
                >
                  <Grid item style={{ marginTop: "1em", marginLeft: "2em" }}>
                    <Typography style={{ fontWeight: "600" }}>
                      Subscription Details
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  // justify="space-around"
                  style={{ marginLeft: "2em" }}
                >
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      style={{ marginBottom: "2em" }}
                    >
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          Subscription Id
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p
                          style={{
                            fontWeight: "700",
                            fontSize: "0.80rem",
                          }}
                        >
                          Duration
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          Start Date
                        </p>
                      </Grid>
                      <Grid item className={classes.labelMargin}>
                        <p style={{ fontWeight: "600", fontSize: "0.80rem" }}>
                          End Date
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      style={{ marginRight: "50px" }}
                    >
                      <Grid
                        item
                        style={{ marginLeft: "2em" }}
                        className={classes.labelMargin}
                      >
                        <p
                          style={{
                            fontSize: "0.80rem",
                            display: "inline",
                            marginLeft: "2px",
                          }}
                        >
                          {/* {filterList.addressDetails
                            ? filterList.addressDetails.addressLine1 +
                              " " +
                              filterList.addressDetails.addressLine2 +
                              " "
                            : "--"} */
                          `${
                            subDetails?.subscriptionId
                              ? subDetails?.subscriptionId
                              : "--"
                          }`}
                        </p>
                      </Grid>
                      <Grid
                        item
                        style={{ marginLeft: "2em" }}
                        className={classes.labelMargin}
                      >
                        <p
                          style={{
                            fontSize: "0.80rem",
                          }}
                        >
                          {`${
                            subDetails?.subscriptionTypeDto?.duration
                              ? subDetails?.subscriptionTypeDto?.duration
                              : "--"
                          } Months`}
                        </p>
                      </Grid>
                      <Grid
                        item
                        style={{ marginLeft: "2em" }}
                        className={classes.labelMargin}
                      >
                        <p
                          style={{
                            fontSize: "0.80rem",
                          }}
                        >
                          {/* {filterList.addressDetails
                            ? filterList.addressDetails.country
                            : "--"} */
                          //dateFormatter(subDetails?.subscriptionStartDate)}

                          `${
                            subDetails?.subscriptionStartDate
                              ? subDetails?.subscriptionStartDate
                                  ?.split(" ")[0]
                                  .split("-")[2]
                              : "--"
                          }/${
                            subDetails?.subscriptionStartDate
                              ? subDetails?.subscriptionStartDate
                                  ?.split(" ")[0]
                                  .split("-")[1]
                              : "--"
                          }/${
                            subDetails?.subscriptionStartDate
                              ? subDetails?.subscriptionStartDate
                                  ?.split(" ")[0]
                                  .split("-")[0]
                              : "--"
                          }`}
                        </p>
                      </Grid>
                      <Grid
                        item
                        style={{ marginLeft: "2em" }}
                        className={classes.labelMargin}
                      >
                        <p
                          style={{
                            fontSize: "0.80rem",
                          }}
                        >
                          {/* {filterList.addressDetails
                            ? filterList.addressDetails.state
                            : "--"} */
                          // subDetails?.subscriptionEndDate

                          `${
                            subDetails?.subscriptionEndDate
                              ? subDetails?.subscriptionEndDate
                                  ?.split(" ")[0]
                                  .split("-")[2]
                              : "--"
                          }/${
                            subDetails?.subscriptionEndDate
                              ? subDetails?.subscriptionEndDate
                                  ?.split(" ")[0]
                                  .split("-")[1]
                              : "--"
                          }/${
                            subDetails?.subscriptionEndDate
                              ? subDetails?.subscriptionEndDate
                                  ?.split(" ")[0]
                                  .split("-")[0]
                              : "--"
                          }`}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item style={{ width: "70%" }}>
                    <Grid
                      container
                      direction="column"
                      style={{ marginRight: "50px", marginTop: "15px" }}
                    >
                      <div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "3fr 1fr",
                            marginLeft: "10vw",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "40%",
                            }}
                          >
                            <p
                              style={{
                                fontWeight: "bold",
                                paddingRight: "2px",
                              }}
                            >
                              Status:{" "}
                            </p>

                            <p
                              style={{
                                color: status === "ACTIVE" ? "green" : "red",
                              }}
                            >
                              {status ? status : "--"}
                            </p>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Button
                              variant="outlined"
                              style={{
                                borderRadius: 35,
                                borderColor: "skyblue",
                                color: "skyblue",
                                marginBottom: "10px",
                              }}
                              // disabled={
                              //   subDetails?.status?.name === "DEACTIVATE"
                              // }
                              disabled={
                                subDetails?.subscriptionId ? false : true
                              }
                              onClick={() => setSubStatus(true)}
                            >
                              {status === "ACTIVE" ? "DEACTIVATE" : "ACTIVATE"}
                            </Button>
                            <Button
                              style={{
                                borderRadius: 35,

                                borderColor: "black",
                                color: "gray",
                              }}
                              onClick={() => {}}
                              // disabled={subDetails?.status?.name === "ACTIVE"}
                            >
                              Renew Subscription
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>

        <Grid
          item
          style={{
            marginLeft: "1.8em",
            marginBottom: "10em",
            visibility: "hidden",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.backButton}
            onClick={backHandler}
          >
            <p>Back</p>
          </Button>
        </Grid>
        <Dialog
          // fullScreen={fullScreen}
          open={showDialog}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title dialog-header"
            style={{ borderBottom: "1px solid #000" }}
          >
            Delete Account
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this account?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => deleteHandler()}
              variant="contained"
              className="confirm-button"
              autoFocus
            >
              Confirm
            </Button>
            <Button
              autoFocus
              variant="contained"
              onClick={handleClose}
              className="cancel-button"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* {deactivate account popup} */}
        <>
          <Dialog
            // fullScreen={fullScreen}
            open={subStatus}
            onClose={() => setSubStatus(false)}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle
              id="responsive-dialog-title dialog-header"
              style={{ borderBottom: "1px solid #000" }}
            >
              {status === "ACTIVE"
                ? "Deactivate Subscription"
                : "Activate Subscription"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ color: "airtel" }}>
                {status === "ACTIVE"
                  ? "All the users under this account will be deactivated. Do you want to deactivate the subscription?"
                  : "Do you want to activate the subscription?"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleSubscriptionStatus}
                variant="contained"
                className="confirm-button"
                autoFocus
              >
                Confirm
              </Button>
              <Button
                autoFocus
                variant="contained"
                onClick={() => {
                  setSubStatus(false);
                }}
                className="cancel-button"
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </>

        {/* activate subscription 

        <Dialog
          // fullScreen={fullScreen}
          open={activateAlert}
          onClose={handleActivateClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title dialog-header"
            style={{ borderBottom: "1px solid #000" }}
          ></DialogTitle>
          <DialogContent>
            <DialogContentText style={{ color: "airtel" }}>
              Do you want to activate the subscription?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleActivate}
              variant="contained"
              className="confirm-button"
              autoFocus
            >
              Confirm
            </Button>
            <Button
              autoFocus
              variant="contained"
              onClick={handleActivateClose}
              className="cancel-button"
            >
              Cancel
            </Button>
          </DialogActions>
            </Dialog>*/}
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert onClose={handleCloseAlert} severity={severity}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </>
  );
});

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  dispatch,
});
//method to connect react with redux
export default withRouter(
  connect((state) => {
    return {};
  })(ViewAccDetails)
);
