/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import { makeStyles } from '@material-ui/core/styles'

const userManagementUseStyles = makeStyles(()=>({
    selectNavCard:{
        height:"4em",width:"5em",border:0,borderRadius:"22px",
    },
    tableGrid:{
        marginTop:"2em",marginBottom:"2em",width:"96%",height:"50%",marginLeft:"1.5em"
    },
    table: {
        minWidth: 650,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    tableHeader: {
        fontSize: "12px",
        fontWeight: "bolder",
        // width: 17%;
    },
    pagination:{
        background: "#ffffff 0% 0% no-repeat padding-box",
        borderRadius: "22px",
        opacity: "1",
        height: "50px",
        float: "right",
        marginRight: "20px",
    },
}));

export default userManagementUseStyles;