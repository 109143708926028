// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import httpRequest from '../../../Utils/httpRequest'
import { updateBreadcrumbs } from '../../../redux/modules/app/actions'
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';


import "./style.scss";


//@component
//Add organization
function AddOrg(props){
  const [IsdialogOpen, setIsdialogOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [name, setName] = useState("")
  const [acr, setAcr] = useState("")
  const [openAlert, setOpenAlert] = useState(false)
  const [severity, setSeverity] = useState("")
  const [alertMessage, setAlertMessage] = useState("")

  //alert toaster function
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //function for alert toaster
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false)
  }

  //breadcrumbs
  useEffect(() => {

    props.dispatch(updateBreadcrumbs("addorganization"))

  }, [])

  //function for add org api call
  const addOrg = () => {

    httpRequest.post(
      'addOrg',
      {
        "name": name,
        "orgAcronym": acr

      },


      {
        'Content-Type': 'application/json',
      },
      successCallBackCreate,
      errorCallBackCreate,
      {

      },
    )

  }
  const successCallBackCreate = (jsonResponse) => {
    if (jsonResponse) {

      setIsdialogOpen(false);
      setOpenAlert(true)
      setAlertMessage("Organization Added")
      setSeverity("Success");

      setTimeout(() => {
        window.location.href = "vieworganizations"

      }, 2000);
    }
  }
  const errorCallBackCreate = (error) => {
    if (error.message !== "Internal Server Error, Please try again later") {
      setIsdialogOpen(false);
      setOpenAlert(true)
      setAlertMessage(error.message)
      setSeverity("Error")

    }
  }
  //function to open Dialog
  const submitOrg = () => {
    setIsdialogOpen(true);
  }


//function to close dialog
  const handleClose = () => {
    setIsdialogOpen(false);
  };

  //function to set name
  const nameHandle = (event) => {
    setName(event.target.value);
  };

  //Functionto set acr
  const acrHandle = (event) => {
    setAcr(event.target.value);
  };

  return (
    <div className="root">
      <Grid container>
        <Grid item xs={12}>
          <Paper elevation={5} className="container">
            <div className="header">
              <h2 className="heading-text">Add Organization Account</h2>
            </div>
            <form className="form-field">
              <Grid container>
                <Grid item xs={6}>
                  <label>Organization Name*</label>
                  <br />
                  <TextField type="text"  id='firstInput' className="text-input" fullWidth style={{ width: '95%' }}
                    value={name}
                    onChange={nameHandle}
                  />
                </Grid>
                <Grid item xs={6}>
                  <label>Organization Acronym *</label>
                  <br />
                  <TextField type="text"  id='lastInput' className="text-input" fullWidth style={{ width: '95%' }}
                    value={acr}
                    onChange={acrHandle} />
                </Grid>
              </Grid>
              <div className="button-section">
                <Button
                data-testid="select-service-btn"
                id= "submitButton"
                  disabled={
                    name.length === 0 ||
                    acr.length === 0
                  }
                  variant="contained"
                  className="form-button submit"
                  onClick={submitOrg}
                >
                  Submit
                </Button>
                <Button
                id ="cancelButton"
                data-testid="cancel-button"
                  onClick={() => props.history.push("/portal/vieworganizations")}
                  variant="contained"
                  className="form-button cancel"
                >
                  Cancel
                </Button>

                <Dialog
                  fullScreen={fullScreen}
                  open={IsdialogOpen}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"


                >
                  <DialogTitle id="responsive-dialog-title dialog-header" style={{ borderBottom: '1px solid #000' }}>Add Organisation</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Do you want to Add Organization?
          </DialogContentText>
                  </DialogContent>
                  <DialogActions>

                    <Button onClick={addOrg} variant="contained" data-testid="confirm-button" className="confirm-button" autoFocus>
                      Confirm
          </Button>
                    <Button autoFocus variant="contained" id="cancelPopup" data-testid="cancel-popup"  onClick={handleClose} className="cancel-button">
                      Cancel
          </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar open={openAlert} autoHideDuration={10000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={severity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,

  dispatch,
});

//method to connect react with redux
export default withRouter(
  connect(null,mapDispatchToProps)(AddOrg),
);
