/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ParkingDetails from "./../Pages/SolutionAdmin/ParkingManagement/ParkingSpace/ParkingDetails";
import ParkingOverview from "./../Pages/SolutionAdmin/ParkingManagement/ParkingOverview/ParkingOverview";
import Breadcrumbs from "../Components/Breadcrumbs";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import AddParking from "./../Pages/SolutionAdmin/ParkingManagement/AddParkingModule";
import EditParking from "./../Pages/SolutionAdmin/ParkingManagement/EditParkingModule";
import RequestSubmitted from "./../Pages/SolutionAdmin/ParkingManagement/AddParkingModule/RequestSubmitted";
import Profile from "../Pages/Profile/Profile";
import Edit from "../Pages/Profile/EditProfile";
import RoleManagement from "./../Pages/RoleManagement/RoleManagementOverview";
import BulkUpload from "../Pages/BulkUpload/BulkUpload";
import UserManagement from "../Pages/UserManagement/UserManagement";
import Complaint from "../Pages/Complaint/Complaint";
import ComplaintDetails from "../Pages/ComplaintDetails/Details";
import ForceUpdate from "../Pages/ForceUpdate/ForceUpdate";
import ViewAccount from "../Pages/ViewAccount/View/View";
import ViewAccDetails from "../Pages/ViewAccount/ViewDetails/Details";
import CreateAccount from "../Pages/ViewAccount/CreateAccount";
import EditAccount from "../Pages/ViewAccount/EditAccount";
import AddOrg from "../Pages/OrgManagement/AddOrg/AddOrg";
import ViewOrg from "../Pages/OrgManagement/ViewOrg/ViewOrg";
import EditOrg from "../Pages/OrgManagement/EditOrg/EditOrg";
import CoupleDevice from "../Pages/DeviceManagement/CoupleDevice/CoupleDevice";
import CoupleDeviceListView from "../Pages/DeviceManagement/DeviceManagement";

class AdminRoutes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      setIsOpen: false,
    };
  }

  toggleDrawer = (props) => {
    this.setState((prevState) => ({
      setIsOpen: !prevState.setIsOpen,
    }));
  };
  render() {
    return (
      <Fragment>
        <Router>
          <Header toggleMenu={this.toggleDrawer} />
          <div className="block" style={{ display: "flex" }}>
            <Sidebar openState={this.state.setIsOpen} />
            <div
              className="box-screens"
              style={{ width: "100%", backgroundColor: "#f2f2f0" }}
            >
              <Breadcrumbs />
              <Switch>
                <Route
                  path="/portal/parkingmanagment"
                  component={ParkingOverview}
                />
                <Route
                  path="/portal/parking-details/:id"
                  exact
                  component={ParkingDetails}
                />
                <Route path="/portal/addparkingspace" component={AddParking} />
                <Route
                  path="/portal/editparkingspace"
                  component={EditParking}
                />
                <Route path="/portal/role" component={RoleManagement} />
                <Route path="/portal/bulkUpload" component={BulkUpload} />
                <Route
                  path="/portal/userManagement"
                  component={UserManagement}
                />
                <Route path="/portal/complaint" component={Complaint} />
                <Route
                  exact
                  path="/portal/complaintdetails"
                  component={ComplaintDetails}
                />
                <Route
                  path="/portal/devicemanagement"
                  component={CoupleDeviceListView}
                />
                <Route path="/portal/coupledevice" component={CoupleDevice} />
                <Route path="/portal/forceUpdate" component={ForceUpdate} />
                <Route path="/portal/viewaccount" component={ViewAccount} />
                <Route path="/portal/createaccount" component={CreateAccount} />
                <Route path="/portal/editaccount" component={EditAccount} />
                <Route
                  exact
                  path="/portal/viewaccountdetails"
                  component={ViewAccDetails}
                />
                <Route path="/portal/addorganization" component={AddOrg} />
                <Route path="/portal/vieworganizations" component={ViewOrg} />
                <Route path="/portal/editorganization" component={EditOrg} />
                <Route path="/portal/profile" component={Profile} />
                <Route path="/portal/profile/edit" component={Edit} />
                <Route path="/portal/success" component={RequestSubmitted} />=
              </Switch>
            </div>
          </div>
        </Router>
      </Fragment>
    );
  }
}
export default AdminRoutes;
