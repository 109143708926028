/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { Component} from 'react';
import moment from 'moment'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  FormControlLabel,
  Switch,
  Checkbox,
} from '@material-ui/core';
import httpRequest from './../../../../../Utils/httpRequest';
import { connect } from 'react-redux';
import {
  getCookie
} from '../../../../../Utils/cookies';
import { updateToasterConfig } from './../../../../../../src/redux/modules/app/actions';
import verifyTag from '../../../../../Assets/Images/verified tag.png';
import Loader from '../../../../../Components/Preloader'
import './style.scss';

class StatusLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingPop:false,
      passPop: false,
      passPopV:false,
      setopen: false,
      parkStatusDetails: '',
      header: '',
      bstate: '',
      changeState: '',
      buttonName: '',
      remarks: '',
      checked: this.props.verified,
      statusName: this.props.statusName.name,
      parentStatus:this.props.parentStatusName,
      statusDisplay: this.props.statusDisplay,
      isActive: this.props.isActive,
      buttonDisable:true,
    };
  }

  

  getStatusDetails = () => {
    this.setState({isLoading:true})
    //console.log(this.props.statusName);
    //console.log("daasdasdasda",this.props)

    httpRequest
      .get(
        'parkingRequestStatusFlow',
        {},
        this.UserDataStatusSuccess,
        this.errorstatusCallback,
        {
          parkingId: this.props.parkingId
          //userId: getCookie('userId'),
        },
      )
      .then(() => { })
      .catch(() => { });
  };

  UserDataStatusSuccess = jsonResult => {
    
    this.setState({
      parkStatusDetails: jsonResult?.data,
      isLoading:false

    });

  }

  errorstatusCallback = error => {
    if (error) {
      this.setState({isLoading:false})
    }
   };


  componentDidMount = () => {
    this.getStatusDetails()

  };

  shouldComponentUpdate(prevState) {
    

    if (prevState.parkStatusDetails === this.state.parkStatusDetails && prevState.statusDisplay === this.state.statusDisplay) {

      return false;

    } else if (prevState.isActive === true) {

      return true;

    }
    else {
      return true;
    }
  }

  errorCallback = () => { };
  statusClickCancel = () => {
    this.setState({
      passPop: !this.state.passPop,
      remarks:''
    });
  };
  handleRemark = (e) => {
    this.setState({
      remarks: e.target.value,
      buttonDisable:(e.target.value.length>500)||(e.target.value.length<2)?true:false
    });
  };


  statusChangeClick = () => {
    // console.log(this.state.changeState)

    httpRequest
      .patch(
        'parkingStatus',
        {
          "status": {
            "name": this.state.changeState
          },
          "adminRemarks": this.state.remarks
        },
        {},
        this.statusChangeSuccess,
        this.errorCallback,
        {
          parkingId: this.props.parkingId,
          userId: getCookie('userId'),
        },
      )
      .then(() => { })
      .catch(() => { });

  };
  statusChange = (id, bstate, bname, stateChange) => {
    this.setState({
      passPop: !this.state.passPop,
      header: bstate,
      buttonName: bname,
      changeState: stateChange
    });
    this.props.displayStatusHandler(stateChange)
    
  };
  statusChangeSuccess = () => {
    this.getStatusDetails();
   
    this.props.handler(this.state.statusDisplay);
    setTimeout(() => {
      this.props.updateToasterConfig({
        show: true,
        message: 'The Parking space status has been changed!',
        color: '#03A9F3',
      });
    }, 3000);
    this.setState({
      passPop: !this.state.passPop,
      parkStatusDetails: this.state.parkStatusDetails,
      statusName: this.state.changeState,
      statusDisplay: this.state.statusDisplay
    })
    //this.props.history.push(`/portal/parking-details/${this.props.parkingId}`);
    
    window.location.href='/portal/parking-details/'+this.props.parkingId;
  };
  verifyParking = () => {
    this.setState({
      passPopV: !this.state.passPopV,
    });
  };
  verifyClickCancel = () => {
    this.setState({
      passPopV: !this.state.passPopV,
    });
  };
  handleVerify = () => {
    this.setState({
      isLoadingPop: !this.state.isLoadingPop,
    });
    
    httpRequest
      .patch(
        'updateVerifyParking',
        {

        },
        {},
        this.verifyChangeSuccess,
        this.errorCallback,
        {
          parkingId: this.props.parkingId,
          valueA: !this.state.checked
        },

      )
      .then(() => { })
      .catch(() => { this.props.updateToasterConfig({
        show: true,
        message: 'Something went wrong, please try again!!',
        color: '#FA8F78',
      });
      this.setState({
        passPopV: !this.state.passPopV,
        isLoadingPop: !this.state.isLoadingPop,
      }) 
    });


  };
  verifyChangeSuccess = () => {
    setTimeout(() => {
      this.props.updateToasterConfig({
        show: true,
        message: 'The Parking space verification status has been changed!',
        color: '#03A9F3',
      });
      this.setState({
        isLoadingPop: !this.state.isLoadingPop,
        checked: !this.state.checked,
        passPopV: !this.state.passPopV
        
      })
    }, 
    3000);
    

  };
  render() {

    /*  console.log("kjdasdkjhkjhsakjdhsakjdahsdjahsdjahsdkjahdhadkjahdkjahsdjsahdjsahdjahdjahdkjahdkjahdkjahdjsahdjsahdjahsdkjahdkjahsdkjahdkahdjsa")
     console.log(this.state.isActive) */

    /*   if(this.state.isActive)
    {
      console.log("dkjhakjdhsakjdhakjdhakjdhakjdhaskjdhakjdhakjdhakjdhasdhakd");
    }; */
    return (

      <div className="statuslog" style={{ paddingTop: '8px' }}>
        {this.state.isLoading && <Loader />}
        <Card raised={true} className="details_card">
        
          <CardHeader
            title="Status Logs" titleTypographyProps={{ variant: 'h6' }} />

          <CardContent className="details_info">
            <Grid container>
              <Grid item md={3} style={{ paddingRight: '10px' }}>
                <div style={{ marginBottom: '10px' }}>
                  <p
                    style={{
                      display: 'inline',
                      fontWeight: '700',
                      color: '#707070',
                      paddingLeft: '15px',
                    }}
                  >
                    <b>Current Status</b>
                  </p>

                  
                </div>
                <div style={{ marginBottom: '10px' }}>
                  <p
                    style={{
                      display: 'inline',
                      fontWeight: '700',
                      color: '#707070',
                      paddingLeft: '15px',
                      wordBreak: 'break-word'
                    }}
                  >
                    <b>


                      {this.state.statusName ===
                        'REQUEST_SUBMITTED' ? (
                          <span style={{ color: 'blue' }}>
                            REQUEST SUBMITTED
                          </span>
                        ) : this.state.statusName ===
                          'APPROVED' ? (
                            <span style={{ color: 'green' }}>
                              APPROVED
                            </span>
                          ) : this.state.statusName ===
                            'REQUEST_REJECTED' ? (
                              <span
                                style={{ color: 'brown' }}
                              >
                                REQUEST REJECTED
                              </span>
                            ) : this.state.statusName ===
                              'DEACTIVATED' ? (
                                <span
                                  style={{ color: 'red' }}
                                >
                                  DEACTIVATED
                                </span>
                              ) : this.state.statusName ===
                                'ADDITIONAL_INFO_REQUIRED' ? (
                                  <span
                                    style={{ color: 'yellow' }}
                                  >
                                    ADDITIONAL INFO REQUIRED
                                   
                                  </span>

                                ) : (
                                  <span style={{ color: '#A2A2A2' }}>

                                  </span>
                                )}</b>
                  </p>


                </div>



              </Grid>

              <Grid item md={9}>
                <div style={{ marginBottom: '10px',textAlign:'right' }}>

                  <Button
                    id={"approve"}
                    disabled={this.state.statusName === 'APPROVED' || this.state.statusName === 'REQUEST_REJECTED' || this.state.statusName === 'ADDITIONAL_INFO_REQUIRED' || this.state.statusDisplay === 'Deactivated'}
                    className="statebtnapprove"
                    variant="contained"
                    color={this.props.statusName.color}
                    onClick={() =>
                      this.statusChange(this.props.parkingId, 'Approve Parking Space', 'Approve', 'APPROVED')
                    }

                  >

                    Approve
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    id="reject"
                    disabled={this.state.statusName === 'APPROVED' || this.state.statusName === 'REQUEST_REJECTED' || this.state.statusDisplay === 'Deactivated'}
                    variant="contained"
                    className="statebtnreject"
                    color={this.props.statusName.color}
                    onClick={() =>
                      this.statusChange(this.props.parkingId, 'Reject Parking Space', 'Reject', 'REQUEST_REJECTED')
                    }
                  >

                    Reject
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    id="pending"
                    variant="contained"
                    disabled={this.state.statusName === 'APPROVED' || this.state.statusName === 'REQUEST_REJECTED' || this.state.statusName === 'ADDITIONAL_INFO_REQUIRED' || this.state.statusDisplay === 'Deactivated'}
                    className="statebtnmore"
                    color='#43a047'
                    onClick={() =>
                      this.statusChange(this.props.parkingId, 'Request More Information', 'Submit', 'ADDITIONAL_INFO_REQUIRED')

                    }


                  >

                    Request more info
                  </Button>
                  {this.state.passPop && (
                    <div className="deletepup-wrapper">
                      <div className="password-popup">
                        <div className="heading">

                          <p className="popup-heading">{this.state.header}</p>
                          <span
                            className="cross"
                            onClick={() => this.statusClickCancel()}
                          >
                            X
                </span>
                        </div>
                        <br></br>

                        <textarea 
                        maxLength="501"
                        value={this.state.remarks} 
                        onChange={this.handleRemark}
                        rows="9" cols="32" placeholder=
                          
                          {this.state.buttonName === 'Approve' ?
                            "OK - Approved"
                            : this.state.buttonName === 'Reject' ?
                                "Please give reason about rejecting the parking space"
                              : "Please give more information about parking space"}>
                        </textarea>
                        {(this.state.remarks.length===1||this.state.remarks.length>500) &&<span style={{color:'red'}}>Minimum 2 and maximum 500 characters are allowed </span>}
                        <div className="popup-button" style={{paddingTop:'15px'}}>
                          <Button
                            className="cancel"
                            variant="contained"
                            onClick={() => this.statusClickCancel()}
                          >
                            Cancel
                </Button>
                          <Button
                            className="reset"
                            variant="contained"
                            onClick={() => this.statusChangeClick(this.state.changeState, this.state.remarks)}
                            disabled={this.state.buttonDisable}
                          >
                            {this.state.buttonName}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid container>
            
              <Grid item md={12} style={{ paddingRight: '10px' }}>
                <div style={{ marginBottom: '10px' }}>
                 
                  <TableContainer >
                    <Table aria-label="simple table" >
                      <TableHead >
                        <TableRow >
                          <TableCell style={{ width: '5%' }}>

                          </TableCell>
                          <TableCell style={{ width: '12%' }}>
                            Status Log
                      </TableCell>
                          <TableCell style={{ width: '19%' }}>
                            Date & Time
                      </TableCell>
                          <TableCell style={{ width: '12%' }}>
                            Remarks
                      </TableCell>
                        </TableRow>
                      </TableHead>
                      
                      {

                        <TableBody>
                          {this.state.parkStatusDetails.length && this.state.parkStatusDetails.map((parking, index) => (


                            <TableRow
                              key={index}>

                              <TableCell align="right" className="request_date">

                                <Checkbox
                                  color="default"
                                  type="checkbox"
                                  checked="true"
                                />
                              </TableCell>
                              <TableCell align="left" className="request_date">
                                {parking.status.displayName}
                              </TableCell>
                              <TableCell align="left" className="request_date">
                                {moment(parking.lastUpdatedDate).format("LLL")}
                              </TableCell>
                              <TableCell align="left" className="request_date">
                                {parking.adminRemarks}

                              </TableCell>
                            </TableRow>

                          ))}
                        </TableBody>}
                    </Table>
                  </TableContainer>


                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card raised={true} className="details_card" style={{ marginBottom: "10em" }}>
          <CardHeader
            title={"Verification Status of Parking Space"} titleTypographyProps={{ variant: 'h6' }} />

          <CardContent className="details_infov" >
            <Grid container>
              <Grid item md={4} style={{ paddingRight: '10px' }}>
                <div style={{ marginBottom: '10px' }}>
                  <p
                    style={{
                      display: 'inline',
                      fontWeight: '700',
                      color: '#707070',
                      paddingLeft: '15px',
                    }}
                  >
                    <b>Is this Parking Space Verified ?</b>
                  </p>
                </div>
                <div style={{ marginBottom: '10px', paddingLeft: '15px' }}>
                  {(this.state.parentStatus.name === 'APPROVED' && this.state.parentStatus.name !== 'DEACTIVATED') ? (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.checked}
                          onChange={this.verifyParking}
                          name="checked"
                          color="primary"
                        />
                      }

                    />
                  ) : (
                      null
                    )
                  }
                </div>
                <div style={{ marginBottom: '10px', paddingLeft: '15px' }}>
                  {(this.state.checked === true && this.state.parentStatus.name !== 'APPROVED') ? (
                   <> <img src={verifyTag} alt="Verified" /><br />
                    <b>Verification status of only approved parking spaces can be changed</b></>
                    
                  ) :(this.state.checked === true && this.state.parentStatus.name === 'APPROVED') ? (
                    <img src={verifyTag} alt="Verified" />
                  ) : ( 
                      <img src={verifyTag} alt="Not Verified" className="blackandwhite" />)
                  }
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        
        {this.state.passPopV && (
          <div className="deletepup-wrapper">
             {this.state.isLoadingPop && <Loader />}
            <div className="password-popup">
              <div className="heading">
                <p className="popup-heading">Verification Status</p>
                <span
                  className="cross"
                 onClick={() => this.verifyClickCancel()}
                >
                  X
                </span>
               
              </div>
              
              <p className="popup-text">
                Do you really want to change the <span><b>verification status</b></span>?
              </p>
              <div>
             
              </div>
              <div className="popup-button">
                <Button
                  className="cancel"
                  variant="contained"
                  onClick={() => this.verifyClickCancel()}
                >
                  Cancel
                </Button>
                <Button
                disabled={this.state.isLoadingPop===true}
                  className="reset"
                  variant="contained"
                  onClick={() => this.handleVerify()}
                >
                  Change
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    

    );
  }
}
export default connect(null, { updateToasterConfig })(StatusLog)