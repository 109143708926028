// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. 
import React,{ useState,useEffect,useCallback } from 'react'
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';
import moment from 'moment'
import PropTypes from 'prop-types';
import {Chip}  from "@material-ui/core";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { updateBreadcrumbs } from '../../redux/modules/app/actions';
import FlexiText from '../../Components/FlexiText/index';
import complaintUseStyles from './styles'
import Loader from '../../Components/Preloader'
import PaginationComponent from './Pagination/index'
import httpRequest from '../../Utils/httpRequest'



//@component
//Complaint Management 
function Complaint(props) {
  const classes = complaintUseStyles()

  const [ complaintList, setComplaintList ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)
  const [ tableDataNotFound,setTableDataNotFound ] = useState("")
  const [ responseDataNotFound,setResponseDataNotFound ] = useState("")
  const [ currentPage, setCurrentPage ] = useState(1)
  const [ postsPerPage] = useState(50)
  const [ order, setOrder ] = React.useState('asc');
  const [ orderBy, setOrderBy ] = React.useState('datetime');
  const [ sortFlag,setSortFlag ] = useState(false)
  const [ sortStatus,setSortStatus ] = useState("ASC") 
  const [ complaintTable] = useState(true)
  const [ complaintDetails] = useState(false)

  //api call to sort list 
  const generateSortHandler = ()=>{ 
    // console.log("sort is: ",sortStatus)  
    httpRequest.get(
      'complaintSort',
      {
        'Content-Type': 'application/json' ,     
      },
      successCallbackSort,
      errorCallback,
      {
        status: sortStatus
      },
    )
    if (complaintList.length === 0) {
      setTableDataNotFound("")
    }
  }
  //success callback for sort list
  const successCallbackSort = jsonResponse => {
    if (jsonResponse) {
      
      setComplaintList(jsonResponse.data._embedded.results)
    }
    setIsLoading(false)   
  }
 
  //api call to generate complaint list  
  const generateListHandler = ()=>{ 
    // console.log("currentPage is: ",currentPage)  
    httpRequest.get(
      'complaint',
      {
        'Content-Type': 'application/json' ,     
      },
      successCallbackList,
      errorCallback,
      {
        pageNumber: currentPage-1
      },
    )
  }
  //success callback for complaint list
  const successCallbackList = jsonResponse => {
    if (jsonResponse) {
      if (jsonResponse.data?._embedded.results.length === 0) {
        setComplaintList([])
      }
      setComplaintList(jsonResponse.data._embedded.results)
    }
    setIsLoading(false)   
  }
  
   //error callback
   const errorCallback = () => {
    setIsLoading(false)
    setResponseDataNotFound("Refresh page and try again!")        
  }

  //to perform pagination
  const paginate = useCallback(pageNumber => {
    setCurrentPage(pageNumber)
  },[]);
  //to set array reference connect for pagination table
  const indexOfLastPage =
    currentPage * postsPerPage;
  const indexOfFirstPage =
    indexOfLastPage - postsPerPage;
    const currentPost = complaintList.slice(
      indexOfFirstPage,
      indexOfLastPage,
    );
  //updating breadcrumb and list function call
  useEffect(() => {
    if (complaintTable === true) {
      props.dispatch(updateBreadcrumbs("complaint"))
      setIsLoading(true)
      // paginate(props.location.state?props.location.state.currentPage:1)
      generateListHandler()
      
    } 
    if (complaintDetails === true) {
      props.dispatch(updateBreadcrumbs("complaintdetails"))
      setIsLoading(true)
    } 
    props.dispatch(updateBreadcrumbs("complaint"))
    setIsLoading(true)
    if (props.location.state&&props.location.state.pressed) {
      paginate(props.location.state?props.location.state.currentPage:1)
      props.history.push({
        pressed:false
      })
    }
    
    generateListHandler()
  },[])

  
  //data for row header
  const headCells = [
    { id: 'mobile', labelPlain: 'Email/Name/Mobile' },
    { id: 'datetime', label: 'Complaint Date' },
    { id: 'space', labelPlain: 'Parking Space Name' },
    { id: 'text', labelPlain: 'Description' },
    { id: 'status', labelPlain: 'Status' }, 
    { id: 'action', labelPlain: 'Actions' },  
  ]

  //function to toggle sort in ascending and descending order
  const sortToggleHandler = () => {
    setSortFlag(!sortFlag)
      if (sortFlag === false){
        setSortStatus("DESC")
      }
      if (sortFlag === true) {
        setSortStatus("ASC")
      }
  }
 //function for table header arrow functionality and datestamp sort api call
  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
      sortToggleHandler()
      // console.log("sort status :",sortStatus)
      generateSortHandler()
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
            style={{fontWeight:'bold',fontSize:"0.70rem",padding:"5px",backgroundColor: "rgba(226, 220, 242, 0.38)"}}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
            {headCell.labelPlain}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
  
  //propTypes for table header arrow function
  EnhancedTableHead.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  //table header arrow sub-function
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  //function to capture and pass complaint id between components
  const viewHandler = (id) => {
    // console.log("complaint Id: ",id)
    props.history.push({ 
      pathname: '/portal/complaintdetails',
      state: {
        id:`${id}`,
        currentPage: currentPage
      }
     });
  } 
    
  return ( 
    <>
    {isLoading && <Loader />}  
    <Grid container
      direction="column"
      className={classes.container}
    >
    
    {
      responseDataNotFound.length===0 ?
      <React.Fragment>
        <Grid item>
          <Grid container
            direction="row"
            justify="center"
            className={classes.tableGrid}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="Complaint table">
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
                <TableBody>                    
                  {                      
                    currentPost.length !==0?
                    currentPost.map((row,index) => {
                        return(
                          <TableRow key={index} >
                            <TableCell  align="left" style={{paddingLeft:34,fontSize:"0.70rem",width:"19%"}}>{row.user?row.user.corpEmailId?row.user.corpEmailId:row.user.mobileNumber:"--"}</TableCell>
                            <TableCell  align="left" style={{paddingLeft:4,fontSize:"0.70rem",width:"24%"}}>{moment(row.createdDate?row.createdDate:"", "x").format("DD MMM YYYY hh:mm a")||"--"}</TableCell>
                            <TableCell  align="left" style={{paddingLeft:28,fontSize:"0.70rem",width:"20%"}}>{row.parking?row.parking.name:"--"}</TableCell>
                            <TableCell style={{width:"15%",paddingLeft:29}}  align="left" >
                              <FlexiText text={row.complaint?row.complaint:"--"} length={40}/>
                            </TableCell>
                            <TableCell  
                              align="left" style={{paddingLeft:27}}>
                                <Chip  
                                  size="small"  
                                  style={{ 
                                    backgroundColor: `${
                                      row.status.displayName ===
                                      "RESOLVED"
                                        ? "#28a745"
                                        : row.status.displayName ===
                                          "INVALID"
                                        ? "#dc3545"
                                        : row.status.displayName ===
                                          "OPEN"
                                        ? "#ffc107"
                                        : "blue"
                                    }`,
                                    color:'#fff',
                                    fontSize:"0.70rem"
                                  }} 
                                  label={row.status?row.status.displayName:"" }
                                />
                            </TableCell>
                            <TableCell align="center">
                              <Button 
                                className={classes.viewButton}
                                onClick={()=>viewHandler(row.complaintId?row.complaintId:"")}
                              >
                                View
                              </Button>
                            </TableCell>
                            {/* <TableCell component="th" scope="row">{row.active||""}</TableCell> */}
                          </TableRow>
                        )
                      })
                    :                        
                      <Grid item>
                        <Grid container
                          direction="row"
                          justify="center"
                        >
                          <Typography variant="body1">{tableDataNotFound}</Typography>
                        </Grid>
                      </Grid>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid item style={{marginBottom:"10em"}}> 
          <Grid container
            direction="row"
            justify="flex-end"
          >
            {/* <div style={{marginRight:"2em"}}>
              <select
                name="selectNavcards"
                id="selectNavcards"
                className={classes.selectNavCard}
                onChange={showSelectedNavCards}
              >
               <option value={9}>9</option>
                    <option value={18}>18</option>
                    <option value={27}>27</option>
                    <option value={36}>36</option>
                    <option value={45}>45</option>
              </select>
            </div> */}
            <div className={classes.divCurrentPage}>
              <Grid item container 
                direction="row"
              >
                <Grid item style={{marginRight:"0.5em"}}>
                  <p>Current Page:</p>
                </Grid>
                <Grid item className={classes.styleCurrentPage}>
                  <p>{currentPage}</p>
                </Grid>

              </Grid>
            </div>
            <PaginationComponent
              postsPerPage={postsPerPage}
              // totalPosts={this.props.cardData.length}
              totalPosts={complaintList.length}
              paginate={paginate}
              currentPage={currentPage}
              generateListHandler={generateListHandler}
            />
          </Grid>
        </Grid>
        </React.Fragment>
        :
        <Grid item>
          <Grid container
            direction="row"
            justify="center"
          >
            <Typography variant="body1">{responseDataNotFound}</Typography>
          </Grid>
        </Grid>
      }
        
    </Grid>
    </>
   )
  }
  //redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  dispatch,
});
//method to connect react with redux   
export default withRouter(
  connect(null,mapDispatchToProps)(Complaint),
);