// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. 
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Chip } from "@material-ui/core";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { updateBreadcrumbs, updateToasterConfig } from '../../redux/modules/app/actions';
import listviewUseStyles from './style';
import Loader from '../../Components/Preloader/index';
import httpRequest from '../../Utils/httpRequest';
import { coupleDeviceListHeadCell } from '../../Data/report-page';
import Pagination from '@material-ui/lab/Pagination';
import deleteIcon from '../../Assets/icons/deleteIcon.png';
import { withStyles } from '@material-ui/core';

//@component
//CoupleDeviceListView 
function CoupleDeviceListView(props) {

  const classes = listviewUseStyles()

  const [viewList, setViewList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [tableDataNotFound, setTableDataNotFound] = useState("")
  const [responseDataNotFound, setResponseDataNotFound] = useState("")
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('datetime');
  const [pageNumber, setPagenumber] = useState(0)
  const [totalElements, setTotalElements] = useState(0)
  const [totalPages, setTotalPages] = useState(0);
  const [internetFlag, setInternetFlag] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [pname, setPname] = useState("");
  const [pid, setPid] = useState("");
  // const [ dialogRole,setDialogRole ] = useState("");


  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //mui custom styling
  const DialogActions = withStyles(theme => ({
    root: {
      padding: '8px 0px !important'
    }
  }))(MuiDialogActions);

  // while component is loading this api is called
  const getCoupleDeviceList = () => {
    httpRequest.get(
      'coupleDeviceListView',
      {
        'Content-Type': 'application/json',
      },
      successCallbackList,
      errorCallbackList,
      {
        pageNumber: pageNumber
      }
    )
  }
  // success callback for get list
  const successCallbackList = jsonResponse => {
   
    if (!jsonResponse || jsonResponse.data?.length === 0 ) {
      setTableDataNotFound("No data here");
      setResponseDataNotFound("No data here");
      setViewList([]);
      setInternetFlag(true);
    }
   else {
    setTableDataNotFound("");
     setPagenumber(jsonResponse.data.page.number);
    setViewList(jsonResponse.data._embedded.results);
     setTotalPages(jsonResponse.data.page.totalPages);
     setTotalElements(jsonResponse.data.page.totalElements);
    setInternetFlag(false);
   }
    setIsLoading(false);
   
  }

  // error callback for get list
  const errorCallbackList = error => {
    if (error) {
      setIsLoading(false);
      setTableDataNotFound("No data here");
      setResponseDataNotFound("No data here");
      setViewList([]);
    }
  }
  // callback api call when pagination button is clicked 
  const pageHandler = (number) => {
    let pageNumber = number || 0;
    setPagenumber(number);
    httpRequest.get(
      'coupleDeviceListView',
      {
        'Content-Type': 'application/json',
      },
      successCallbackList,
      errorCallbackList,
      {
        pageNumber: pageNumber
      },
    )
  }
  // when pagination button is clicked here page number is set and api callback is triggered
  const handleChange = (event, value) => {
    let currpage
    currpage = value - 1
    pageHandler(currpage);
  }
  // when component loading it updates breadcrumb and get list callback
  useEffect(() => {
    setIsLoading(true)
    props.dispatch(updateBreadcrumbs("deviceManagement"));
    getCoupleDeviceList();

    // setViewList(data)
    // if (viewList.length === 0) {
    //   setTableDataNotFound("No data here");
    //   setInternetFlag(true);
    // }
  }, [])

  //function for table header arrow functionality and datestamp sort api call
  function EnhancedTableHead(props) {
    const { classes } = props;
    // const createSortHandler = (property) => (event) => {
    //   onRequestSort(event, property);
    // };
    return (
      <TableHead>
        <TableRow>
          {coupleDeviceListHeadCell.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.id === 'sno' ? 'center' : "left"}
              width={headCell.width}
              className={classes.thData}>
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  //propTypes for table header arrow function
  EnhancedTableHead.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  //table header arrow sub-function
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  // on clicking create user button it redirects to create page
  const createHandler = () => {
    props.history.push({
      pathname: '/portal/coupledevice'
    });
  }


  // when we click on confirm button in delete popup
  const submitDetails = () => {
    setIsLoading(true)
    httpRequest.post(
      'decoupleParking',
      {

      },
      {
        'Content-Type': 'application/json',
      },
      successCallbackDelete,
      errorCallbackDelete,
      {
        "deviceId": deleteId,
        "parkingId": pid
      }
    )
    setDialogOpen(false);
  };
  // user delete success callback
  const successCallbackDelete = jsonResponse => {
    if (jsonResponse) {
      setViewList([]);
      getCoupleDeviceList();
      props.dispatch(updateToasterConfig({
        show: true,
        message: `${pname} has been succesfully decoupled with ${deleteId}`,
        color: 'green'
      }))
    }
    setIsLoading(false)
  }
  // user delete error callback
  const errorCallbackDelete = error => {
    if (error) {
      props.dispatch(updateToasterConfig({
        show: true,
        message: error.error_description || error.message || error.error,
        color: 'red'
      }))

    }
    setIsLoading(false)
  }
  // when we click on delete icon
  const deleteHandler = async (did, pid, pname) => {
    setDeleteId(did);
    setPname(pname);
    setPid(pid)
    setDialogOpen(true);
    console.log(did, pname)
  }

  return (
    <>
      {
        isLoading && <Loader />}
      <ClickAwayListener >
        <Grid container
          direction="column"
          className={classes.container}
        >

          <ClickAwayListener >
            <Grid container
              direction="row"
              xs={12} sm={12} md={12} lg={12}
              className={classes.header}
            >
              <Grid item xs={10} sm={10} md={10} lg={10}>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <button
                  type='button'
                  className={classes.createuserButton}
                  onClick={createHandler}
                >
                  + Couple Device
                </button>
              </Grid>
            </Grid>
          </ClickAwayListener>

          {
            responseDataNotFound?.length === 0 &&  viewList?.length !== 0  ?
              <React.Fragment>
                <Grid item className={classes.aboveTableGrid}>
                  <Grid container
                    direction="row"
                    justify="center"
                    className={classes.tableGrid}
                  >
                    <TableContainer className={classes.paper} component={Paper}>
                      <Table className={classes.table} aria-label="View table">
                        <EnhancedTableHead
                          classes={classes}
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                          {
                           
                              viewList.map((row, index) => {
                                let no = index;
                                function formatNo(no) {
                                  return no < 10 ? + '0' + pageNumber+1 : (pageNumber + 1) 
                                }
                                return (
                                  <TableRow key={index} >
                                    <TableCell className={classes.tableCell} align="center">
                                      {/* {formatNo(no + 1)} */}
                                      {pageNumber<=9?'0'+(pageNumber+1):(pageNumber+1)}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align='left' >
                                      <span style={{ fontWeight: "bold" }}> {row?.parentOrgName ? row?.parentOrgName : "--"}</span>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align='left' >
                                      {
                                        row.parkDevCoupledList.length !== 0 ?
                                          row.parkDevCoupledList?.map((data, key) => {
                                            return <> <div style={{
                                              height: '100px',
                                              display: 'grid',
                                              alignItems: 'center'
                                            }}>

                                              <span style={{ fontWeight: "bold" }}>{data.deviceId}</span>
                                            </div>
                                            </>
                                          })
                                          :
                                          ""
                                      }
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align='left' >
                                      {
                                        row.parkDevCoupledList.length !== 0 ?
                                          row.parkDevCoupledList?.map((data, key) => {
                                            return (<><div style={{
                                              height: '100px',
                                              display: 'flex',
                                              flexDirection: 'column',
                                              justifyContent: 'center'
                                            }}><span style={{ fontWeight: "bold" }}>{data.parkingName}</span>
                                              <span>{data.parkingAddres}</span>
                                            </div></>)
                                          })
                                          :
                                          "--"
                                      }
                                    </TableCell>

                                    <TableCell className={classes.tableCell} align='left' >
                                      {
                                        row.parkDevCoupledList.length !== 0 ?
                                          row.parkDevCoupledList?.map((data, key) => {
                                            return (<div style={{
                                              height: '100px',
                                              display: 'grid',
                                              alignItems: 'center'
                                            }}>
                                              <Chip
                                                size="small"
                                                // label={data.status==="true"?"Coupled":"Decoupled"}
                                                // style={{
                                                //   backgroundColor: `${
                                                //     data.status ===
                                                //     "true"
                                                //       ? "#28a745"
                                                //       : "#dc3545"
                                                //   }`,
                                                //   color: "#fff",
                                                // }}
                                                label="Coupled"
                                                style={{
                                                  backgroundColor: '#28a745',
                                                  color: 'white'
                                                }}
                                              />
                                            </div>)
                                          })
                                          :
                                          "--"
                                      }
                                    </TableCell>

                                    <TableCell className={classes.viewTableCell}>
                                      {

                                        row.parkDevCoupledList.length !== 0 ?
                                          row.parkDevCoupledList?.map((data, key) => {
                                            return <> <div style={{
                                              height: '100px',
                                              display: 'grid',
                                              alignItems: 'center'
                                            }}><img
                                                alt="delete_icon"
                                                src={deleteIcon}

                                                className={isLoading ? classes.wait : classes.delete_icon}
                                                onClick={() => isLoading ? '' : deleteHandler(data?.deviceId, data?.parkingId, data?.parkingName)}

                                              />
                                                
                                            </div>
                                             
                                            </>
                                          })
                                          :
                                          ""
                                      }



                                    </TableCell>
                                  </TableRow>
                                )
                              })
                             
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>


                <Grid container
                  direction="row"
                  justify="flex-end"
                  className={classes.paginationOuterGrid}
                >
                  <Grid item className={classes.paginationGrid}>
                    <Grid container
                      direction="row"
                      justify="flex-end"
                      className={classes.paginationStyle}
                    >

                      <Grid item>
                        <Pagination count={totalPages} className={classes.root} page={pageNumber + 1} size="small" onChange={handleChange} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </React.Fragment>
              :
              <>
              <Grid item className={classes.aboveTableGrid}>
                <Grid container
                  direction="row"
                  justify="center"
                  className={classes.tableGrid}
                >
                  <TableContainer className={classes.paper} component={Paper}>
                    <Table className={classes.table} aria-label="View table">
                      <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />
                 
                
                </Table>
                <Grid xs={12} style ={{textAlign: 'center',padding:'5%'}} >
                    <Typography variant="body1">{responseDataNotFound}</Typography>
                  </Grid>
                </TableContainer>
               
                </Grid>
                </Grid>
                </>
          }
          <Dialog
            open={dialogOpen}
            onClose={() => {
              setDialogOpen(false)
            }}
            PaperProps={{
              style: {
                padding: "1.5% 3%",
              },
            }}
            className={classes.dialog}
          >

            <MuiDialogTitle style={{ paddingBottom: "8px", paddingTop: "2px", paddingLeft: "2px", paddingRight: "2px" }}>

              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <span className={classes.dialogHeader}>Decouple Parking Space</span>
                </Grid>

              </Grid>

            </MuiDialogTitle>
            <Divider />
            <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Grid item>
                <Grid container direction="column" justify="center">
                  <Grid item>
                    <Typography className={classes.dialogSubHeader}>Are you sure you want to decouple
                      <span className={classes.dialogSubText}> {pname}</span> from <span className={classes.dialogSubText}>{deleteId}</span>?</Typography>
                  </Grid >
                  {/* <Grid item>
                  <p>
                    <span className={classes.dialogSubTitle}>Name:</span> <span className={classes.dialogSubText}>{dialogTitle}</span>
                  </p>
                </Grid> */}

                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid item>
                <Grid container
                  direction="row"
                  justify="flex-end"
                  className={classes.dialogActionGrid}
                >
                  <Grid item className={classes.dialogCancelGrid}>
                    <button type="button" onClick={() => setDialogOpen(false)} className={classes.dialogCancel}>
                      Cancel
                    </button>
                  </Grid>
                  <Grid item className={classes.dialogConfirmGrid}>
                    <button type="button" className={classes.dialogConfirm} onClick={submitDetails}>
                      Confirm
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </Grid>
      </ClickAwayListener>
    </>
  )
}
//  redux state method
// const mapStateToProps = (state) => {
//   return {
//       pageRedirect : state.app.pageRedirect
//   }
// } 

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  dispatch,
});

export default withRouter(connect(null, mapDispatchToProps)((CoupleDeviceListView)));