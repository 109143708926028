/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import {
  UPDATE_AUTH,
  UPDATE_MODAL,
  UPDATE_TOASTER_CONFIG,
  UPDATE_BREADCRUMBS,
  UPDATE_APPLIED_FILTER_DATA,
  UPDATE_FILTER_DATA,
} from './types';

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_AUTH:
      return {
        ...state,
        auth: action.response,
      };
    case UPDATE_MODAL:
      return {
        ...state,
        showModal: action.response,
      };
    case UPDATE_TOASTER_CONFIG:
      return {
        ...state,
        toasterConfig: { ...action.response },
      };
    case UPDATE_BREADCRUMBS:
      return {
        ...state,
        breadcrumbsKey: action.response,
      };
    case UPDATE_APPLIED_FILTER_DATA:
      return {
        ...state,
        appliedFilterData: action.response,
      };
    case UPDATE_FILTER_DATA:
      return {
        ...state,
        filterData: action.response,
      };
    default: {
      return state;
    }
  }
};
