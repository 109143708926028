// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import httpRequest from "../../../Utils/httpRequest";
import { updateBreadcrumbs } from "../../../redux/modules/app/actions";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import "./style.scss";

//@component
//Edit organization
const EditOrg = (props) => {
  const [IsdialogOpen, setIsdialogOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [name, setName] = useState("");
  const [acr, setAcr] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const orgId =
    props && props.location && props.location.state && props.location.state.id;

  //alert toaster function
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //function for alert toaster
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  //function to api call org detail by id
  const generateDatatHandler = () => {
    httpRequest.get(
      "orgDetailsbyId",
      {
        "Content-Type": "application/json",
      },
      successCallbackList,
      errorCallback,
      {
        orgId: orgId,
      }
    );
  };

  //success callback for list handler
  const successCallbackList = (jsonResponse) => {
    //setIsLoading(false)
    if (jsonResponse) {
      setName(jsonResponse.data.name);
      setAcr(jsonResponse.data.orgAcronym);
    }
  };

  //error callback
  const errorCallback = (error) => {
    if (error) {
      //setIsLoading(false)
    }
  };
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("editorganization"));
    generateDatatHandler();
  }, []);

  //function to api call edit org
  const EditOrg = () => {

    httpRequest.patch(
      "orgUpdate",
      {
        name: name,

        orgAcronym: acr,
      },
      {
        "Content-Type": "application/json",
      },
      successCallBackCreate,
      errorCallBackCreate,
      {
        orgId: orgId,
      }
    );
  };

  //success callback
  const successCallBackCreate = (jsonResponse) => {
    if (jsonResponse) {
      setIsdialogOpen(false);
      setOpenAlert(true);
      setAlertMessage("Organization Details Changed Successfully");
      setSeverity("Success");
      setTimeout(() => {
        props.history.push({
          pathname: "/portal/vieworganizations",
        });
      }, 2000);
    }
  };
  //error callback
  const errorCallBackCreate = (error) => {
    if (error.message) {
      setIsdialogOpen(false);
      setOpenAlert(true);
      setAlertMessage(error.message);
      setSeverity("Error");
    }
  };
  //function to open dialog
  const submitOrg = () => {
    setIsdialogOpen(true);
  };

  //function to close dialog
  const handleClose = () => {
    setIsdialogOpen(false);
  };
  //function to set name
  const nameHandle = (event) => {
    setName(event.target.value);
  };

  //function to set arc
  const acrHandle = (event) => {
    setAcr(event.target.value);
  };

  return (
    <div className="root">
      <Grid container>
        <Grid item xs={12}>
          <Paper elevation={5} className="container">
            <div className="header">
              <h2 className="heading-text">Edit Organization Account</h2>
            </div>
            <form className="form-field">
              <Grid container>
                <Grid item xs={6}>
                  <label>Organization Name*</label>
                  <br />
                  <TextField
                    type="text"
                    className="text-input"
                    value={name}
                    onChange={nameHandle}
                    fullWidth
                    style={{ width: "95%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <label>Organization Acronym*</label>
                  <br />
                  <TextField
                    type="text"
                    className="text-input"
                    value={acr}
                    onChange={acrHandle}
                    disabled
                    fullWidth
                    style={{ width: "95%" }}
                  />
                </Grid>
              </Grid>
              <div className="button-section">
                <Button
                  disabled={name.length === 0}
                  variant="contained"
                  className="form-button submit"
                  onClick={submitOrg}
                >
                  Save
                </Button>
                <Button
                  onClick={() =>
                    props.history.push("/portal/vieworganizations")
                  }
                  variant="contained"
                  className="form-button cancel"
                >
                  Cancel
                </Button>

                <Dialog
                  fullScreen={fullScreen}
                  open={IsdialogOpen}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle
                    id="responsive-dialog-title dialog-header"
                    style={{ borderBottom: "1px solid #000" }}
                  >
                    Update Organisation
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Do you want to Edit Organization?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={EditOrg}
                      variant="contained"
                      className="confirm-button"
                      autoFocus
                    >
                      Confirm
                    </Button>
                    <Button
                      autoFocus
                      variant="contained"
                      onClick={handleClose}
                      className="cancel-button"
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        open={openAlert}
        autoHideDuration={10000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={severity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,

  dispatch,
});

//method to connect react with redux
export default withRouter(
  connect(null,mapDispatchToProps)(EditOrg),
);
