/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import { createMuiTheme } from '@material-ui/core/styles'
// const loginBlue = "#008ECF"

export default createMuiTheme({
    palette: {

    },
    typography: {
    },
    overrides: {
       MuiSelect: {
           root: {
               width:"16em",
               borderBottom:"none"
           }
       },
    //    MuiDialogContent: {
    //        root: {
    //            paddingLeft:"0px",
    //            paddingRight:"0px",
    //        }
    //    },
    //    MuiDialogTitle: {
    //        root: {
    //            paddingLeft:"0px",
    //        }
    //    },
       MuiFormControl: {
           root:{
               width:"20em",
           }
       },
       MuiInputBase:{
           input:{
               font:"menu"
           }
        },
        MuiOutlinedInput:{
            inputMultiline:{
                padding:"0px 1px"
            },
            multiline:{
                padding: "12.5px 14px"
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: "75px"
            }
        }
    } 
})