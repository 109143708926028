/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */import axios from './axiosInterceptor';
import UrlConfig from '../Constants/apiUrls';
import { getCookie } from './cookies';
import TokenStorage from './tokenstorage';

export async function getRefreshToken() {
  
  await axios
    .post(
      UrlConfig('loginUrl'),
      'refresh_token=' +
        TokenStorage.getRefreshToken() +
        '&grant_type=refresh_token&client_id='+ getCookie('cliId')+'&client_secret=' +
        getCookie('cliSec'),
      { 'content-type': 'application/x-www-form-urlencoded' },
    )
    .then(response => {
      TokenStorage.storeToken(response.data.access_token);
      TokenStorage.storeRefreshToken(response.data.refresh_token);
      let dt = new Date();
      dt.setSeconds(
        dt.getSeconds() + (response.data.expires_in - 60),
      );
      TokenStorage.setTokenExpirationTime(dt);
    })
    .catch(()=> {});
}
