/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { deleteAllCookies } from "../../Utils/cookies";
import FileViewer from "../pdf/FileViewer";
import PZ_NoticeKit from "../../Assets/pdf/PZ_Data_Protection_Notice_Common_V2.3_15122023.pdf";
import "./style.scss";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function PrivacySettingDialog(props) {
  const displayPage = props.displayPage;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDisagree = () => {
    deleteAllCookies();
    handleClose();
  };
  const handleAgree = () => {
    handleClose();
  };

  return (
    <div>
      {displayPage === "footer" ? (
        <a
          style={{ color: "white", textDecoration: "none", cursor: "pointer" }}
          onClick={handleClickOpen}
        >
          &nbsp;Data Protection Notice <span>|</span>&nbsp;
        </a>
      ) : (
        <a
          onClick={handleClickOpen}
          className="underline_hover"
          style={{ color: "#0A65A8", cursor: "pointer" }}
        >
          <small>&nbsp;data protection notice, </small>
        </a>
      )}
      <Dialog
        onClose={handleClose}
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="Cookie Agreement"
        aria-describedby="Cookie Agreement-description"
      >
        <DialogTitle id="Cookie Agreement">Data Protection Notice</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <pre style={{ overflow: "hidden" }}>
              <FileViewer pdfFile={PZ_NoticeKit} />
            </pre>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} color="primary">
            DISAGREE
          </Button>
          <div
            style={{
              backgroundColor: "#00C4FF",
              color: "white",
              padding: "5px",
              borderRadius: "15px",
              cursor: "pointer",
            }}
            onClick={handleAgree}
          >
            I Agree
          </div>
          {/* <Button autoFocus variant="contained" onClick={handleAgree}>
            I AGREE
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
