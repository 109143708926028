/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { Component } from "react";
import { pdfjs, Document, Page } from "react-pdf";
// const pdfjsVersion = "2.0.305";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class FileViewer extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    return (
      <div>
        <Document
          file={this.props.pdfFile}
          onLoadSuccess={this.onDocumentLoadSuccess}
          style={{ width: "900px" }}
        >
          {Array.apply(null, Array(this.state.numPages))
            .map((x, i) => i + 1)
            .map((page) => (
              <Page pageNumber={page} key={page} />
            ))}
        </Document>
      </div>
    );
  }
}
