/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Slider from '@material-ui/core/Slider';
import httpRequest from './../../../../../Utils/httpRequest';
import Input from '@material-ui/core/Input';
import { Scrollbars } from 'react-custom-scrollbars';

import './style.scss';
var servicesArray = [];
var distance = '';
const getDefaultValue = (data, uid) => {
  const result = data.filter(item => item.uid == uid);
  distance = result.length ? result[0].distanceInMeters : 250;
  return result.length ? result[0].distanceInMeters : 250;
};

const isCheckboxSelected = (data, uid) => {
  const result = data.filter(item => item == uid);
  return result.length ? true : false;
};

const ServicesNearBy = props => {
  const servicesSuccess = jsonResult => {
    setService(jsonResult.data.services.results);
  };
  const errorCallback = () => { };
  const [services, setService] = useState([]);
  const [switchData, setSwitch] = useState(false);
  const [showMessage, setMessage] = useState(false);

  useEffect(() => {
    httpRequest.get('services', {}, servicesSuccess, errorCallback);
  }, []);
  useEffect(() => {
    if (props.data.services.length > 0) {
      var lessThanOne = props.data.services.some(function (e) {
        return e.distanceInMeters < 1;
      });
      lessThanOne ? setMessage(true) : setMessage(false);
    }
  }, [switchData]);
  return (
    <div className="ServicesNearBy">
      <div className="ServicesNearBy__wrapper">
        <div className="ServicesNearBy__wrapper_heading">
          <p className="sNearby"> Services Near By</p>
        </div>
        {showMessage && (
          <p className="errorClass">
            Distance should be greater then 0 meter
          </p>
        )}
        <Scrollbars style={{ height: 503 }}>
          {services.map((item, index) => {
            return (
              <div
                key={index}
                className="ServicesNearBy__wrapper_box"
                style={{
                  opacity: isCheckboxSelected(
                    props.data.selectedCheckbox,
                    index + 1,
                  )
                    ? 1
                    : 0.5,
                }}
              >
                <Checkbox
                  color="default"
                  inputProps={{
                    'aria-label': 'checkbox with default color',
                  }}
                  checked={isCheckboxSelected(
                    props.data.selectedCheckbox,
                    index + 1,
                  )}
                  onClick={() => {
                    const selectedCheckbox = [
                      ...props.data.selectedCheckbox,
                    ];
                    let itemIndex = selectedCheckbox.findIndex(
                      item => item == index + 1,
                    );
                    if (itemIndex > -1) {
                      selectedCheckbox.splice(itemIndex, 1);
                      servicesArray.splice(itemIndex, 1);
                    } else {
                      selectedCheckbox.push(index + 1);
                      servicesArray.push({
                        uid: index + 1,
                        distanceInMeters: distance,
                      });
                    }
                    // console.log(selectedCheckbox, servicesArray);
                    props.updateChildrenState(
                      'step3',
                      'services',
                      servicesArray,
                    );
                    props.updateChildrenState(
                      'step3',
                      'selectedCheckbox',
                      selectedCheckbox,
                    );
                  }}
                />
                <img
                  src={item._links.image.href}
                  alt="facilityicon"
                />
                <p className="ServicesNearBy__wrapper_box_text">
                  {item.name}
                </p>
                <span className="distance">0</span>
                <Slider
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={100}
                  marks
                  min={0}
                  max={1000}
                  defaultValue={getDefaultValue(
                    props.data.services,
                    index + 1,
                  )}
                  disabled={
                    !isCheckboxSelected(
                      props.data.selectedCheckbox,
                      index + 1,
                    )
                  }
                  onChange={(evt, newValue) => {
                    let service = [...props.data.services];
                    let itemIndex = service.findIndex(
                      item => item.uid == index + 1,
                    );
                    if (itemIndex > -1) {
                      service[itemIndex].distanceInMeters = newValue;
                    } else {
                      service.push({
                        uid: index + 1,
                        distanceInMeters: newValue,
                      });
                    }
                    setSwitch(!switchData);

                    props.updateChildrenState(
                      'step3',
                      'services',
                      service,
                    );
                  }}
                />
                <span className="distance">1KM</span>
                <Input
                  value={
                    getDefaultValue(props.data.services, index + 1) +
                    ' Meters'
                  }
                />
              </div>
            );
          })}
        </Scrollbars>
      </div>
      <p
        style={{
          color: '#03A9F3',
          textDecoration: 'underline',
          fontSize: '16px',
          fontFamily: 'Poppins',
          paddingTop: '15px',
        }}
      >
        My Parking Service is not listed here
      </p>
    </div>
  );
};
export default ServicesNearBy;
