// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. 
import React,{ useState,useEffect,useCallback } from 'react'
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';

import { useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import { updateBreadcrumbs } from '../../redux/modules/app/actions';
import bulkUploadUseStyles from './style'
import FileUploader from '../../Components/FileUploader/FileUploader'
import httpRequest from '../../Utils/httpRequest'
import Loader from '../../Components/Preloader';
import Template from '../../Assets/pdf/sample_template.xlsx'
import Excel from '../../Assets/icons/Excel.png'

//@component
//BulkUpload 
const BulkUpload = props => {
    const classes = bulkUploadUseStyles()
    const theme = useTheme()
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
    
    const [ addFiles,setAddFiles ] = useState([])
    const [ responseData,setResponseData ] = useState("")
    const [ open,setOpen ] = useState(false)
    const [ responseType,setResponseType ] = useState("")
    const [ dialogTitle,setDialogTitle ] = useState("")
    const [ isLoading, setIsLoading ] = useState(false)

    //function to add files to state array after file selection
    function addFileHandler(files) {
        setAddFiles(files)
    }

    //api call to generate reports  
    const bulkUploadHandler = ()=>{
        const formData = new FormData()
        addFiles.forEach(file=>{
            formData.append("file",file)
        });
        setIsLoading(true)
        httpRequest.post(
            'bulkUpload',
            formData,           
            {
                'Content-Type': 'multipart/form-data'       
            },
            successCallback,
            errorCallback,
            {},
        )
    }
    //success callback for report
    const successCallback = useCallback(jsonResponse => {
        if (jsonResponse) {
            setIsLoading(false)
            setOpen(true)
            setResponseType("Success")
            setResponseData(jsonResponse.data.numOfParkingUploaded+" parking spaces are uploaded successfully.")
            setDialogTitle("Bulk Upload Successful")
                        
        }      
    },[])
    //error callback
    const errorCallback = error => {
        if (error.message !== "Internal Server Error, Please try again later") {
            // any error message
            setIsLoading(false)
            setOpen(true)
            setResponseType("Error")
            setResponseData(error.message||"")
            setDialogTitle("Bulk Upload Error")
        }
        if (error) {
            setIsLoading(false)
            setOpen(true)
            setResponseType("Error")
            setResponseData(error.message||"")
            setDialogTitle("Bulk Upload Error")
        } 
    } 
    
    //function to handler dialog close
    const dialogCloseHandler = () =>{
        setOpen(false);
        setAddFiles([])
    }

    //adding component to breadcrumb
    useEffect(() => {
        props.dispatch(updateBreadcrumbs("bulkUpload"))
    },[addFiles])

    return (
        <>
        {isLoading && <Loader />}
        <Grid container
            direction="column"
            alignItems="center"
            className={classes.container}
        >
            
            <Card className={classes.bulkUploadCard}>
                <Grid item >
                    <Grid container
                        direction="row"
                        justify="center"
                        className={classes.bulkUploadTopRow}
                    >                        
                    </Grid>
                </Grid>
                
                <Grid item>
                    <Grid container
                        direction="row"
                        justify="center"
                        
                    >
                        <Grid item container direction="row" justify="center" className={classes.InputRow}>
                            <FileUploader fileHandler={addFileHandler}/>
                        </Grid>                           
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container
                        direction="row"
                        style={{marginLeft:"3.5em"}}
                    >
                        <Grid item>
                            {addFiles.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div key={index}>
                                            <img
                                                src={Excel}
                                                alt={item.name}
                                            />
                                            <p >{item.name}</p>
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container
                        direction="column"
                        className={classes.ButtonRow}
                    >
                        <Grid item container justify="flex-end" style={{marginBottom:"1rem"}}>
                            <Button
                                disabled={addFiles.length === 0}
                                onClick={bulkUploadHandler}
                                //className={classes.fileUpload}
                                variant="contained"
                                style={{marginBottom:matchesSM?"2em":undefined,fontSize:"0.71rem",color:"#fff",backgroundColor:"#e46a76",borderRadius: '20px',width:"20em"}}
                            >
                                Upload
                            </Button>
                        </Grid>
                        <Grid item container justify="flex-end">
                            <a 
                                style={{textDecoration:"none",fontSize:"1rem",color:"#008ECF"}}  
                                // onClick={()=>window.location.href="C:\Users\DNP4KOR\Desktop\latest_template_17.xlsx"}
                                target="_blank" rel="noopener noreferrer" href={Template} download
                            >
                                Download Sample Template
                            </a>                            
                           
                        </Grid>
                    </Grid>
                </Grid> 
            </Card>
            <CardContent></CardContent>
            <Dialog 
              open={open}
              onClose={()=>setOpen(false)}
              PaperProps={{style: {
                  padding:matchesXS?"8px":matchesMD?"16px":"32px",
              }}}
              style={{zIndex: 0}}
            >
                {isLoading && <Loader/>}
            <MuiDialogTitle className={classes.uploadDialogTitle}>
                <Grid item>
                    <Grid container 
                        direction="row"
                        
                    >
                        <Grid item>
                            {dialogTitle}
                        </Grid>
                        <Grid item style={{position:"absolute",right:"0px"}}>
                            
                            <Button onClick={dialogCloseHandler}>X</Button>
                        </Grid>    
                    </Grid>
                </Grid>                
            </MuiDialogTitle>
            <DialogContent>
                <Grid item>
                    <Grid container
                      direction="column"
                      justify="center"
                      alignItems="center"
                    >
                        <Grid item>
                            <Typography style={{cursor:"pointer"}} variant="caption" color={responseType==="Success"?"#808080":"secondary"}>
                                {responseData}
                            </Typography>
                        </Grid>
                        <Grid item style={{marginTop: "1rem"}}> 
                            <Button variant="outlined" onClick={dialogCloseHandler}>OK</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
          </Dialog>
        </Grid>
        </>
    )
}

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs,
    dispatch,
  });
    
  //method to connect react with redux
  export default withRouter(
    connect(null,mapDispatchToProps)(BulkUpload),
  );
