/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
} from "react-google-maps";
import "./style.scss";
import Geocode from "react-geocode";
import LocationSearchInput from "../auto";
Geocode.setApiKey("AIzaSyAYYH3O5Ce4tb6IYuy8UQhYGGGJIkq63nI");
Geocode.enableDebug();

class Map extends React.Component {
  state = {
    address: "",
    city: "",
    area: "",
    state: "",
    mapPosition: {
      lat: this.props.center.lat,
      lng: this.props.center.lng,
    },
    markerPosition: {
      lat: this.props.center.lat,
      lng: this.props.center.lng,
    },
  };

  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    Geocode.fromLatLng(
      this.state.mapPosition.lat,
      this.state.mapPosition.lng
    ).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);

        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }
  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.address !== nextState.address ||
      this.state.city !== nextState.city ||
      this.state.area !== nextState.area ||
      this.state.state !== nextState.state
    ) {
      return true;
    } else if (this.props.center.lat === nextProps.center.lat) {
      return false;
    }
  }
  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };
  /**
   * And function for city,state and address input
   * @param event
   */
  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = () => { };
  /**
   * When the user types an address in the search box
   * @param place
   */
  onPlaceSelected = (place) => {
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng(),
      pinCode =
        place.address_components[place.address_components.length - 1].long_name;
    // Set these values in the state.

    this.props.updateRootState("addressDetails", {
      addressLine1: address,
      addressLine2: "",
      city: city,
      state: state,
      pincode: pinCode,
    });
    this.props.updateRootState("location", {
      lat: latValue,
      lng: lngValue,
    });
    this.setState({
      address: address ? address : "",
      area: area ? area : "",
      city: city ? city : "",
      state: state ? state : "",
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
    });
  };
  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng()
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray),
          pinCode =
            response.results[0].address_components[
              response.results[0].address_components.length - 1
            ].long_name;
        this.props.updateRootState("addressDetails", {
          addressLine1: address,
          addressLine2: "",
          city: city,
          state: state,
          pincode: pinCode,
        });
        this.props.updateRootState("location", {
          ...response.results[0].geometry.location,
        });
        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };
  render() {
    const AsyncMap = withScriptjs(
      withGoogleMap(() => (
        <GoogleMap
          google={this.props.google}
          defaultZoom={this.props.zoom}
          defaultCenter={{
            lat: this.state.mapPosition.lat,
            lng: this.state.mapPosition.lng,
          }}
          options={{ streetViewControl: false }}
        >
          {/* For Auto complete Search Box */}
          {/* <Autocomplete
            style={{
              width: '400px',
              height: '30px',
              paddingLeft: '16px',
              marginTop: '2px',
              marginBottom: '0px',
              position: 'absolute',
              borderRadius: '15px',
              top: '55px',
              outline: 'none',
              left: '12px',
              boxShadow: '0px 3px 6px #00000029',
              border: 'none',
              id="AIzaSyAYYH3O5Ce4tb6IYuy8UQhYGGGJIkq63nI"
            }}
            onPlaceSelected={this.onPlaceSelected}
            types={['']}
          /> */}
          {/*Marker*/}
          <LocationSearchInput
            onPlaceSelected={this.onPlaceSelected}
          ></LocationSearchInput>
          <Marker
            google={this.props.google}
            name={"Dolores park"}
            draggable={true}
            onDragEnd={this.onMarkerDragEnd}
            position={{
              lat: this.props.center.lat,
              lng: this.props.center.lng,
            }}
          />
          <Marker />
          {/* InfoWindow on top of marker */}
          <InfoWindow
            onClose={this.onInfoWindowClose}
            position={{
              lat: this.props.center.lat + 0.0018,
              lng: this.props.center.lng,
            }}
          >
            <div>
              <span
                style={{
                  padding: 0,
                  margin: 0,
                }}
              >
                {this.state.address}
              </span>
            </div>
          </InfoWindow>
        </GoogleMap>
      ))
    );
    let map;
    if (this.props.center.lat !== undefined) {
      map = (
        <div>
          <div style={{ display: "none" }}>
            <div className="form-group">
              <label htmlFor=""> City </label>
              <input
                type="text"
                name="city"
                className="form-control"
                onChange={this.onChange}
                readOnly="readOnly"
                value={this.state.city}
              />
            </div>
            <div className="form-group">
              <label htmlFor=""> Area </label>
              <input
                type="text"
                name="area"
                className="form-control"
                onChange={this.onChange}
                readOnly="readOnly"
                value={this.state.area}
              />
            </div>
            <div className="form-group">
              <label htmlFor=""> State </label>
              <input
                type="text"
                name="state"
                className="form-control"
                onChange={this.onChange}
                readOnly="readOnly"
                value={this.state.state}
              />
            </div>
            <div className="form-group">
              <label htmlFor=""> Address </label>
              <input
                type="text"
                name="address"
                className="form-control"
                onChange={this.onChange}
                readOnly="readOnly"
                value={this.state.address}
              />
            </div>
          </div>
          <AsyncMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAYYH3O5Ce4tb6IYuy8UQhYGGGJIkq63nI&libraries=places"
            loadingElement={
              <div
                style={{
                  height: "100%",
                }}
              />
            }
            containerElement={
              <div
                style={{
                  height: this.props.height,
                }}
              />
            }
            mapElement={
              <div
                style={{
                  height: "100%",
                }}
              />
            }
          />
        </div>
      );
    } else {
      map = (
        <div
          style={{
            height: this.props.height,
          }}
        />
      );
    }
    return map;
  }
}
export default Map;
