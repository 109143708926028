// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction,
// editing, distribution, as well as in the event of applications for industrial property rights.
import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import {
  updateBreadcrumbs,
  updateToasterConfig,
} from "../../../redux/modules/app/actions";
import "./style.scss";
import httpRequest from "../../../Utils/httpRequest";
import Loader from "../../../Components/Preloader/index";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

//@component
//Create Account
const CreateAccount = (props) => {
  const [mapsEnable, setMapsEnable] = useState("true");
  const [isLoading, setIsLoading] = useState(false);
  const [realms, setRealms] = useState([]);
  const [tenantId, setTenantId] = useState("");
  const [orgs, setOrgs] = useState([]);
  const [orgName, setOrgName] = useState("");
  const [orgId, setOrgId] = useState("");
  const [orgAcr, setOrgAcr] = useState("");
  const [orgAdr, setOrgAdr] = useState("");
  const [adrError, setadrError] = useState("");
  const [accType, setAccType] = useState("");

  const [solFName, setSolFName] = useState("");
  const [fnError, setfnError] = useState("");
  const [solLName, setSolLName] = useState("");
  const [lnError, setlnError] = useState("");
  const [altName, setAltName] = useState(null);
  const [altNameError, setAltNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setemailError] = useState("");
  const [emailDisable, setEmailDisable] = useState("");
  const [contact, setContact] = useState(null);
  const [contactError, setcontactError] = useState("");
  const [contactDisable, setContactDisable] = useState("");
  const [altContact, setAltContact] = useState(null);
  const [altcontactError, setaltcontactError] = useState("");
  const [whitelist, setWhitelist] = useState("");
  const [domainError, setDomainError] = useState("");
  const [domainDisable, setDomainDisable] = useState("");
  const [country, setCountry] = useState("India");
  const [city, setCity] = useState("");
  const [stateName, setStateName] = useState("");
  const [pincode, setPinCode] = useState("");
  const [pinError, setpinError] = useState("");
  const [pinDisable, setPinDisable] = useState("");
  const [IsdialogOpen, setIsdialogOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [mtAdmin, setMtAdmin] = useState(false);
  const [adminType, setadminType] = useState("CLOSED_GROUP_PARKING_PROVIDER");
  const [corporate, setCorporate] = useState(true);
  const [subscription, setsubscription] = useState([]);
  const [subType, setSubType] = useState("3");
  const [subMasterId, setSubMasterId] = useState(
    "64a40dec-a4f3-4168-bd18-fb7e3c5a8a50"
  );

  //function to clear fields after creating account
  const clearHandler = () => {
    setPinCode("");
    setStateName("");
    setCity("");
    setCountry("");
    setWhitelist("");
    setAltContact("");
    setContact("");
    setEmail("");
    setAltName("");
    setSolLName("");
    setSolFName("");
    setOrgAdr("");
    setOrgAcr("");
    setOrgName("");
    setOrgId("");
    setSolFName("");
    setSolLName("");
    setAccType("");
  };
  // //Global Variable declaration
  // const updateParentState = (parentKey, childKey, value, props) => {
  //   let obj = { ...props.data[parentKey] };
  //   obj[childKey] = value;
  //   props.updateChildrenState('step1', parentKey, obj);
  // };
  //alert toaster function
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //function for alert toaster
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  //variable to handle mediaquery and theme
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //function for dialog confirm button
  const submitDetailsConfirm = () => {
    setIsdialogOpen(true);
  };
  //function to close dialog confirm
  const handleClose = () => {
    setIsdialogOpen(false);
  };

  //api call to get realm list.
  const realmListHandler = useCallback(() => {
    httpRequest.get(
      "realmList",
      {
        "Content-Type": "application/json",
      },
      successCallBackRealm,
      errorCallBackRealm,
      {}
    );
  }, []);
  //success callback
  const successCallBackRealm = (jsonResponse) => {
    if (jsonResponse) {
      setRealms(jsonResponse.data._embedded.results);
    }
  };
  //error callback
  const errorCallBackRealm = (error) => {
    if (error) {
      //error message here if any
    }
  };
  //function for org api call
  const orgListHandler = useCallback(() => {
    httpRequest.get(
      "orgListdropdown",
      {
        "Content-Type": "application/json",
      },
      successCallBackOrg,
      errorCallBackOrg,
      {}
    );
  }, []);
  //success callback

  const HandleSubChange = (e) => {
    // console.log(e.target.value);
    const myObject = JSON.parse(e.target.value);
    console.log(myObject);
    console.log(e.target.value);
    setSubType(myObject.duration);
    setSubMasterId(myObject.subscriptionMasterId);
  };

  const subscriptionListHandler = async () => {
    // const { data } = await axios.get(
    //   "https://pr-beta.parkzeus.in/subscription-service/api/v1/parking/subscription"
    // );

    // console.log(data);
    // setsubscription(data);

    httpRequest.get(
      "subListdropdown",
      {
        "Content-Type": "application/json",
      },
      successCallBackSub,
      errorCallBackSub,
      {}
    );

    // setsubscription(data.data);
    // console.log(subscription);
  };

  const successCallBackSub = (jsonResponse) => {
    //console.log(jsonResponse);
    console.log(jsonResponse);
    setsubscription(jsonResponse.data);
    console.log(subscription);
  };

  const errorCallBackSub = () => {};

  const successCallBackOrg = (jsonResponse) => {
    if (jsonResponse) {
      console.log(jsonResponse);
      // const json = jsonResponse.data?._embedded.results.sort(
      //   (a, b) => a.name - b.name
      // );
      // console.log(json);

      const orgs = jsonResponse.data?._embedded.results.sort((a, b) => {
        let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      console.log(orgs);
      setOrgs(orgs);
    }
  };
  //error callback
  const errorCallBackOrg = (e) => {
    //error message here if any
  };
  //function for pre-details submit api call
  const submitDetails = () => {
    setIsLoading(true);
    httpRequest.patch(
      "orgUpdate",
      {
        orgId: orgId,
        name: orgName,
        isGMapOpted: mapsEnable,
        orgAcronym: orgAcr,
        emailDomains: whitelist,
        tenant: {
          tenantId: tenantId,
        },
      },
      {
        "Content-Type": "application/json",
      },
      successCallBackCreate1,
      errorCallBackCreate1,
      {
        orgId: orgId,
      }
    );
  };
  //success callback
  const successCallBackCreate1 = (jsonResponse) => {
    if (jsonResponse) {
      setIsLoading(false);
      setIsdialogOpen(false);
      createPost();
    }
  };
  //error callback
  const errorCallBackCreate1 = (error) => {
    setIsLoading(false);
    if (error.message !== "Internal Server Error, Please try again later") {
      setIsdialogOpen(false);
      setOpenAlert(true);
      setAlertMessage(error.message);
      setSeverity("Error");
    }
  };
  //api call to Create Solution Admin.

  const createPost = () => {
    setIsLoading(true);

    //---------save subscription

    mtAdmin
      ? httpRequest.post(
          "createAdmin",
          {
            mobileNumber: contact,
            firstName: solFName,
            lastName: solLName,
            corpEmailId: email,
            altContactPerson: altName,
            altContactNumber: altContact,
            addressDetails: {
              addressLine1: orgAdr,
              addressLine2: "",
              city: city,
              state: stateName,
              pincode: pincode,
              country: country,
            },
            org: {
              orgId: orgId,
            },

            role: {
              name: "MULTITENANT_ADMIN",
            },
            adminTypeDto: {
              adminId: "9feb42c4-700f-483b-83fd-e0c7627c146b",
              adminTypes: "Multitenant",
            },
            subscriptionTypeDto: {
              subscriptionMasterId: subMasterId,
              duration: subType,
            },
          },
          {
            "Content-Type": "application/json",
          },
          successCallBackCreate,
          errorCallBackCreate,
          {}
        )
      : // console.log('admin type',adminType)
        httpRequest.post(
          "createAdmin",
          {
            mobileNumber: contact,
            firstName: solFName,
            lastName: solLName,
            corpEmailId: email,
            altContactPerson: altName,
            altContactNumber: altContact,

            addressDetails: {
              addressLine1: orgAdr,
              addressLine2: "",
              city: city,
              state: stateName,
              pincode: pincode,
              country: country,
            },
            org: {
              orgId: orgId,
            },
            subscriptionTypeDto: {
              subscriptionMasterId: subMasterId,
              duration: subType,
            },
            role: {
              name: adminType,
            },
            adminTypeDto: {
              adminId: "29ae1c11-1d43-41d0-a0af-c9efcf95fe3b",
              adminTypes: "Singletenant",
            },
          },
          {
            "Content-Type": "application/json",
          },
          successCallBackCreate,
          errorCallBackCreate,
          {}
        );
  };
  //success callback
  const successCallBackCreate = (jsonResponse) => {
    if (jsonResponse) {
      setIsLoading(false);
      setOrgs(jsonResponse.data?._embedded.results);
      clearHandler();
      props.dispatch(
        updateToasterConfig({
          show: true,
          message: `User Account Created Successfully, Login credential sent through email.`,
          color: "green",
        })
      );
      props.history.push({
        pathname: "/portal/viewaccount",
        state: {
          source: "create",
        },
      });
      setOpenAlert(true);
      setAlertMessage(
        "Solution Admin Account Created Successfully, Login credential sent through email."
      );
      setSeverity("Success");
    }
  };
  //error callback
  const errorCallBackCreate = (error) => {
    setIsLoading(false);
    if (error.message !== "Internal Server Error, Please try again later") {
      setOpenAlert(true);
      setAlertMessage(error.message);
      setSeverity("Error");
    }
    if (error.message === "Internal Server Error, Please try again later") {
      clearHandler();
      props.history.push({
        pathname: "/portal/viewaccount",
        state: {
          source: "create",
        },
      });
      setOpenAlert(true);
      setAlertMessage(
        "Solution Admin Account Created Successfully, Login credential sent through email."
      );
      setSeverity("Success");
    }
  };

  //function call on component render/re-render
  useEffect(() => {
    props.dispatch(updateBreadcrumbs("createaccount"));
    realmListHandler();
    orgListHandler();
    subscriptionListHandler();
  }, []);

  //functions to set state individually
  const mapEnableChange = (event) => {
    setMapsEnable(event.target.value);
  };
  //functions to set state individually
  const adminTypeChange = (event) => {
    setadminType(event.target.value);
  };
  //functions to set state individually
  const accountFNamehandle = (event) => {
    if (event.target.value.length > 50) {
      setfnError("Max.Char length is 50");
    }
    if (event.target.value.length <= 50) {
      setfnError("");
      setSolFName(event.target.value);
    }
  };
  //functions to set state individually
  const accountLNamehandle = (event) => {
    if (event.target.value.length > 50) {
      setlnError("Max.Char length is 50");
    }
    if (event.target.value.length <= 50) {
      setlnError("");
      setSolLName(event.target.value);
    }
  };
  //function for generic email regex validation
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  //function for generic domain regex validation
  const validateDomain = (domain) => {
    const re = /@(.*)\.com|.co.in|.in|.ai$/i;
    return re.test(String(domain).toLowerCase());
  };
  //functions to set state individually
  const emailHandle = (event) => {
    if (event.target.value.length > 100) {
      setemailError("Enter less than 100 characters");
      setEmailDisable("");
    }
    if (event.target.value.length <= 100) {
      if (validateEmail(event.target.value)) {
        setEmailDisable("");
        setemailError("");
        setEmail(event.target.value);
      }
      if (!validateEmail(event.target.value)) {
        setemailError("Enter valid Email Id");
        setEmailDisable("");
        setEmail(event.target.value);
      }
      if (event.target.value.length < 1) {
        setemailError("");
        setEmailDisable("disable");
        setEmail(event.target.value);
      }
    }
  };

  //functions to set state individually
  const contactHandle = (event) => {
    if (event.target.value.length < 10) {
      setcontactError("Enter 10 numbers only");
      setContactDisable("");
      setContact(event.target.value);
    }
    if (event.target.value.length > 10) {
      setcontactError("Enter 10 numbers only");
      setContactDisable("");
    }
    if (event.target.value.length === 10) {
      setcontactError("");
      setContact(event.target.value);
      setContactDisable("");
    }
    if (event.target.value.length < 1) {
      setcontactError("");
      setContactDisable("disable");
      setContact(event.target.value);
    }
  };
  //functions to set state individually

  const altContactHandle = (event) => {
    if (event.target.value.length < 10) {
      setaltcontactError("Enter 10 numbers only");
      setAltContact(event.target.value);
    }
    if (event.target.value.length > 10) {
      setaltcontactError("Enter 10 numbers only");
    }
    if (event.target.value.length === 10) {
      setaltcontactError("");
      setAltContact(event.target.value);
    }
    if (event.target.value.length < 1) {
      setaltcontactError("");
      setAltContact(null);
    }
  };
  //functions to set state individually
  const altNameHandle = (event) => {
    if (event.target.value.length > 50) {
      setAltNameError("Max.Char length is 50");
    }
    if (event.target.value.length <= 50) {
      setAltNameError("");
      setAltName(event.target.value);
    }
    if (event.target.value.length < 1) {
      setAltNameError("");
      setAltName(null);
    }
  };
  //functions to set state individually
  const postalHandle = (event) => {
    if (event.target.value.length < 6) {
      setpinError("Min. 6 and Max. 8 characters allowed");
      setPinDisable("");
      setPinCode(event.target.value);
    }
    if (event.target.value.length > 8) {
      setpinError("Min. 6 and Max. 8 characters allowed");
      setPinDisable("");
    }
    if (event.target.value.length < 1) {
      setpinError("Min. 6 and Max. 8 characters allowed");
      setPinDisable("disable");
      setPinCode(event.target.value);
    }
    if (event.target.value.length >= 6 && event.target.value.length <= 8) {
      setpinError("");
      setPinDisable("");
      setPinCode(event.target.value);
    }
  };
  //functions to set state individually
  const orgAdrHandle = (event) => {
    if (event.target.value.length > 100) {
      setadrError("Enter less than 100 characters");
    }
    if (event.target.value.length <= 100) {
      setadrError("");
      setOrgAdr(event.target.value);
    }
  };
  //functions to set state individually
  const countryHandle = (event) => {
    setCountry(event.target.value);
  };
  //functions to set state individually
  const stateHandle = (event) => {
    setStateName(event.target.value);
  };
  //functions to set state individually
  const cityHandle = (event) => {
    setCity(event.target.value);
  };
  //functions to set state individually
  const tenantHandle = (event) => {
    setTenantId(event.target.value);
  };
  //functions to set state individually
  const whitelistHandler = (event) => {
    let splitArr = [];
    let holder = [];
    holder = splitArr.concat(event.target.value.split(","));
    let whiteArr = [];
    if (event.target.value.length === 0) {
      setDomainDisable("disable");
      setWhitelist(whiteArr.concat(event.target.value.split(",")));
      setDomainError("");
    }
    if (event.target.value.length > 0) {
      holder.forEach((value) => {
        if (validateDomain(value)) {
          setWhitelist(whiteArr.concat(event.target.value.split(",")));
          setDomainDisable("");
          setDomainError("");
        }
        if (!validateDomain(value)) {
          setDomainError("Enter valid whitelist domain Id");
        }
      });
    }
  };
  //functions to set state individually
  const orgHandle = (event) => {
    if(event.target.value!=="")
    {
      setOrgName(event.target.value);

      orgs.map((option) => {
        if (option.name === event.target.value) {
          setOrgAcr(option.orgAcronym);
          setOrgId(option.orgId);
        }
      });
    }
    else
    {
      setOrgAcr("")
      setOrgId("")
      setOrgName("")
    }
    
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="root">
        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={5} className="container">
              <div className="header">
                <h2 className="heading-text">Create Account Details</h2>
              </div>
              <form className="form-field" style={{ padding: 0 }}>
                <div className="contactdetails__wrapper__container">
                  <div className="contactdetails__wrapper__container__leftside">
                    <label>Select Admin Type*</label>
                    <br />
                    <Button
                      variant="outlined"
                      data-testid="single-tenant-btn"
                      className="admin-type"
                      onClick={() => {
                        setadminType("CLOSED_GROUP_PARKING_PROVIDER");
                        setCorporate(true);
                      }}
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        padding: "5px 15px",
                        marginRight: "1em",
                        marginTop: "0.5em",
                        marginBottom: "1em",
                        width: "30%",
                        height: "6%",
                        backgroundColor: corporate ? "#01c0cb" : "",
                        color: corporate ? "white" : "black",
                      }}
                    >
                      Corporate
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setadminType("RESIDENTIAL_GROUP_PARKING_PROVIDER");
                        setCorporate(false);
                        setMtAdmin(false);
                      }}
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        padding: "5px 15px",
                        marginRight: "1em",
                        marginTop: "0.5em",
                        marginBottom: "1em",
                        width: "30%",
                        height: "6%",
                        backgroundColor:
                          adminType === "RESIDENTIAL_GROUP_PARKING_PROVIDER"
                            ? "#01c0cb"
                            : "",
                        color:
                          adminType === "RESIDENTIAL_GROUP_PARKING_PROVIDER"
                            ? "white"
                            : "black",
                      }}
                    >
                      Residential
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setadminType("CLOSED_GROUP_PARKING_PROVIDER");
                        setCorporate(false);
                      }}
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        padding: "5px 15px",
                        marginRight: "1em",
                        marginTop: "0.5em",
                        marginBottom: "1em",
                        width: "30%",
                        height: "6%",
                        backgroundColor:
                          !corporate &&
                          adminType === "CLOSED_GROUP_PARKING_PROVIDER"
                            ? "#01c0cb"
                            : "",
                        color:
                          !corporate &&
                          adminType === "CLOSED_GROUP_PARKING_PROVIDER"
                            ? "white"
                            : "black",
                      }}
                    >
                      Commercial
                    </Button>
                    <br />

                    <label>Select Tenant Type*</label>
                    <div className="button__wrapper_left">
                      <Button
                        variant="outlined"
                        onClick={() => setMtAdmin(false)}
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                          padding: "5px 15px",
                          marginRight: "1em",
                          marginTop: "0.5em",
                          marginBottom: "1em",
                          width: "30%",
                          height: "50px",
                          backgroundColor: !mtAdmin ? "#01c0cb" : "",
                          color: !mtAdmin ? "white" : "black",
                        }}
                      >
                        Single Tenant
                      </Button>
                      {adminType === "CLOSED_GROUP_PARKING_PROVIDER" && (
                        <Button
                          variant="outlined"
                          onClick={() => setMtAdmin(true)}
                          style={{
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            padding: "5px 15px",
                            marginRight: "1em",
                            marginTop: "0.5em",
                            marginBottom: "1em",
                            width: "30%",
                            height: "50px",
                            backgroundColor: mtAdmin ? "#01c0cb" : "",
                            color: mtAdmin ? "white" : "black",
                          }}
                        >
                          Multi Tenant
                        </Button>
                      )}

                      {/* <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="adminType"
                          id="adminType"
                          value={adminType}
                          style={{ flexDirection:'row', justifyContent:'space-between' }}
                          onChange={adminTypeChange}
                        >
                          <FormControlLabel
                            value="CLOSED_GROUP_PARKING_PROVIDER"
                            labelPlacement="end"
                            control={<Radio color="primary" />}
                            label="Commercial"
                          />
                          <FormControlLabel
                            value="RESIDENTIAL_GROUP_PARKING_PROVIDER"
                            labelPlacement="end"
                            control={<Radio color="primary" />}
                            label="Residential"
                          />
                        </RadioGroup>
                      </FormControl> */}
                    </div>

                    <label>Solution Admin First Name*</label>

                    <TextField
                      fullWidth
                      type="text"
                      id="accountFname"
                      placeholder="Admin First Name"
                      value={solFName}
                      onChange={accountFNamehandle}
                    />
                    <p>
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {fnError}
                      </span>
                    </p>

                    <label>Solution Admin Last Name*</label>

                    <TextField
                      fullWidth
                      type="text"
                      id="accountLName"
                      placeholder="Admin Last Name"
                      value={solLName}
                      onChange={accountLNamehandle}
                    />
                    <p>
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {lnError}
                      </span>
                    </p>

                    <label>Alternate Contact Person</label>
                    <TextField
                      fullWidth
                      type="text"
                      value={altName}
                      id="altName"
                      placeholder="Contact Phone Name"
                      onChange={altNameHandle}
                    />
                    <p>
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {altNameError}
                      </span>
                    </p>
                    <label>Contact Email*</label>
                    <TextField
                      fullWidth
                      type="email"
                      value={email}
                      autoComplete="off"
                      id="contactEmail"
                      placeholder="Email Address"
                      onChange={emailHandle}
                    />
                    <p>
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {emailError}
                      </span>
                    </p>
                    <label>Contact Number*</label>
                    <TextField
                      fullWidth
                      type="number"
                      value={contact}
                      className="phone_field"
                      id="contactNumber"
                      placeholder="Contact Number"
                      onChange={contactHandle}
                      onWheel={(event) => event.target.blur()}
                    />
                    <p>
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {contactError}
                      </span>
                    </p>
                    <label>Alternate Contact Number</label>
                    <TextField
                      fullWidth
                      type="number"
                      id="altNumber"
                      value={altContact}
                      placeholder="Alt Contact Number"
                      onChange={altContactHandle}
                      onWheel={(event) => event.target.blur()}
                    />
                    <p>
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {altcontactError}
                      </span>
                    </p>
                    <label style={{ display: "block" }}>Realm Name*</label>
                    <select
                      style={{
                        display: "block",
                        height: "45px",
                        width: "102%",
                        borderRadius: "10px",
                        marginTop: "9px",
                        marginBottom: "9px",
                      }}
                      id="realm"
                      onChange={tenantHandle}
                    >
                      {realms.map((option) => (
                        <option key={option.tenantId} value={option.tenantId}>
                          {option.realmName}
                        </option>
                      ))}
                    </select>
                    <label>Google Maps Activation</label>
                    <div className="button__wrapper_left">
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="googleMaps"
                          id="googleMaps"
                          value={mapsEnable}
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "185px",
                          }}
                          onChange={mapEnableChange}
                        >
                          <FormControlLabel
                            value="true"
                            labelPlacement="end"
                            control={<Radio color="primary" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="false"
                            labelPlacement="end"
                            control={<Radio color="primary" />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  <div className="contactdetails__wrapper__container__leftside">
                    <label>Whitlist Domains*</label>
                    <TextField
                      fullWidth
                      type="text"
                      id="whiteList"
                      placeholder="@bosch.com, @bosch.in, …."
                      onChange={whitelistHandler}
                    />
                    <p>
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {domainError}
                      </span>
                    </p>

                    <label style={{ display: "block", marginBottom: "10px" }}>
                      Organization Name*
                    </label>

                    <select
                      fullWidth
                      type="text"
                      data-testid="orgName"
                      select
                      onChange={orgHandle}
                      style={{
                        display: "block",
                        width: "102%",
                        height: "45px",
                        borderRadius: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <option value="">Select Organization</option>
                      {orgs.map((option) => (
                        <option
                          data-testid="orgNameSel"
                          key={option.orgId}
                          value={option.name}
                        >
                          {option.name}
                        </option>
                      ))}
                    </select>

                    <label>Organization Acronym</label>
                    <TextField
                      fullWidth
                      type="text"
                      id="orgAcr"
                      value={orgAcr}
                      disabled
                    />
                    {}
                    <label>Organization Address*</label>
                    <TextField
                      fullWidth
                      type="text"
                      id="orgAdr"
                      value={orgAdr}
                      placeholder="Enter Org Address"
                      onChange={orgAdrHandle}
                    />
                    <p
                      style={{
                        borderTop: adrError ? "1px solid red" : undefined,
                      }}
                    >
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {adrError}
                      </span>
                    </p>
                    <label>Country*</label>
                    <TextField
                      fullWidth
                      disabled
                      type="text"
                      id="country"
                      value="India"
                      onChange={countryHandle}
                    />

                    <label>State*</label>
                    <TextField
                      fullWidth
                      type="text"
                      id="state"
                      onChange={stateHandle}
                    />
                    <label>City*</label>
                    <TextField
                      fullWidth
                      type="text"
                      id="city"
                      onChange={cityHandle}
                    />
                    <label>Postal Code*</label>
                    <TextField
                      fullWidth
                      type="number"
                      value={pincode}
                      id="postalCode"
                      placeholder="Postal Code"
                      onChange={postalHandle}
                      style={{ marginBottom: "22px" }}
                    />
                    <p>
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {pinError}
                      </span>
                    </p>

                    {/* <label style={{ display: "block" }}>Subscription*</label> */}

                    {/* <select
                      // data-testid="orgName"
                      style={{
                        height: "45px",
                        width: "102%",
                        borderRadius: "10px",
                        marginTop: "9px",
                      }}
                      // InputProps={{
                      //   endAdornment: (
                      //     <InputAdornment>
                      //
                      //     </InputAdornment>
                      //   ),
                      // }}
                      onChange={HandleSubChange}
                    >
                      {subscription?.map((p) => (
                        <option
                          // data-testid="orgNameSel"
                          key={p.subscriptionMasterId}
                          value={JSON.stringify(p)}
                        >
                          {`${p.duration} Months`}
                        </option>
                      ))}
                    </select> */}

                    {/* <Box display="flex" alignItems="center">
                      <TextField
                        id="outlined-basic"
                        label="22Keyword"
                        defaultValue={"test123"}
                        select
                      />
                      <IconButton aria-label="delete">
                        <SearchIcon />
                      </IconButton>
                    </Box> */}
                  </div>
                </div>
                <div className="button-section">
                  <Button
                    variant="contained"
                    data-testid="select-service-btn"
                    className="form-button submit"
                    onClick={submitDetailsConfirm}
                    disabled={
                      whitelist.length === 0 ||
                      pincode.length === 0 ||
                      stateName.length === 0 ||
                      city.length === 0 ||
                      country.length === 0 ||
                      domainDisable.length !== 0 ||
                      contactDisable.length !== 0 ||
                      emailDisable.length !== 0 ||
                      solLName.length === 0 ||
                      solFName.length === 0 ||
                      orgAdr.length === 0 ||
                      orgAcr.length === 0 ||
                      orgName.length === 0 ||
                      orgId.length === 0 ||
                      adrError.length !== 0 ||
                      pinError.length !== 0 ||
                      fnError.length !== 0 ||
                      lnError.length !== 0 ||
                      emailError.length !== 0 ||
                      contactError.length !== 0 ||
                      altcontactError.length !== 0 ||
                      domainError.length !== 0 ||
                      altNameError.length !== 0
                    }
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    id="cancelButton"
                    className="form-button cancel"
                    onClick={() => props.history.push("/portal/viewaccount")}
                  >
                    Cancel
                  </Button>

                  <Dialog
                    fullScreen={fullScreen}
                    open={IsdialogOpen}
                    onClose={handleClose}
                    aria-labelledby="create admin"
                  >
                    <DialogTitle
                      id="create admin dialog-header"
                      style={{ borderBottom: "1px solid #000" }}
                    >
                      Create Admin
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Do you want to create this Admin?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={submitDetails}
                        variant="contained"
                        className="confirm-button"
                        autoFocus
                      >
                        Confirm
                      </Button>
                      <Button
                        autoFocus
                        variant="contained"
                        onClick={handleClose}
                        className="cancel-button"
                      >
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Snackbar
                    open={openAlert}
                    autoHideDuration={10000}
                    onClose={handleCloseAlert}
                  >
                    <Alert onClose={handleCloseAlert} severity={severity}>
                      {alertMessage}
                    </Alert>
                  </Snackbar>
                </div>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  dispatch,
});
//method to connect react with redux
export default withRouter(
  connect((state) => {
    return {};
  })(CreateAccount)
);
