// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. 
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { updateBreadcrumbs, updateToasterConfig } from '../../../redux/modules/app/actions';
import listviewUseStyles from './styles';
import Loader from '../../../Components/Preloader/index';
import httpRequest from '../../../Utils/httpRequest';
import { adminListHeaderCell } from '../../../Data/report-page';
import Pagination from '@material-ui/lab/Pagination';
import deleteIcon from '../../../Assets/icons/deleteIcon.png';
import EditIcon from '../../../Assets/Images/edit.png';
import { withStyles } from '@material-ui/core';
import {getAdminList} from '../api/accountApis'

//@component
//FacilityManagerListView 
function ViewAccount(props) {
  const classes = listviewUseStyles()

  const [viewList, setViewList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [tableDataNotFound, setTableDataNotFound] = useState("")
  const [responseDataNotFound, setResponseDataNotFound] = useState("")
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('datetime');
  const [pageNumber, setPagenumber] = useState(0)
  const [totalElements, setTotalElements] = useState(0)
  const [postsPerPage, setPostsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0);
  const [internetFlag, setInternetFlag] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [orgId, setOrgId] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogEmail, setDialogEmail] = useState("");
  const [dialogRole, setDialogRole] = useState("");

  // const fullScren = useMediaQuery(theme.breakpoints.down("sm"));

  //mui custom styling
  const DialogActions = withStyles(theme => ({
    root: {
      padding: '8px 0px !important'
    }
  }))(MuiDialogActions);

  async function fetchAdminList() {
    try {
      let data = await getAdminList(pageNumber);
      console.log("json", data)
      setTableDataNotFound("");
    setPagenumber(data.page.number);
    setViewList(data._embedded.results);
    setTotalPages(data.page.totalPages);
    setTotalElements(data.page.totalElements);
    setInternetFlag(false);
    setIsLoading(false);
    } catch (error) {
      setTableDataNotFound("data not found");
      setIsLoading(false);
      console.log("error calling list", error)
    }
  }

  // while component is loading this api is called
  // const getFacilityManagerList = async () => {
  //   httpRequest.get(
  //     'soladminlist',
  //     {
  //       'Content-Type': 'application/json',
  //     },
  //     successCallbackList,
  //     errorCallbackList,
  //     {
  //       page: pageNumber,
  //       size:postsPerPage
  //     }
  //   )
  // }
  // success callback for get list
  // const successCallbackList = jsonResponse => {
  //   console.log(jsonResponse);
  //   if (!jsonResponse) {
  //     setTableDataNotFound("No data here");
  //     setResponseDataNotFound("No data here");
  //     setInternetFlag(true);
  //     setIsLoading(false);
  //   }
  //   if (jsonResponse.data._embedded.results.length === 0) {

  //     setTableDataNotFound("No data here");
  //     setResponseDataNotFound("No data here");
  //     setInternetFlag(true);
  //     setIsLoading(false);
  //   }

  //   setTableDataNotFound("");
  //   setPagenumber(jsonResponse.data.page.number);
  //   setViewList(jsonResponse.data._embedded.results);
  //   setTotalPages(jsonResponse.data.page.totalPages);
  //   setTotalElements(jsonResponse.data.page.totalElements);
  //   setInternetFlag(false);
  //   setIsLoading(false);
  // }

  // error callback for get list
  // const errorCallbackList = error => {
  //   if (error) {
  //     setIsLoading(false);
  //   }
  // }
  // callback api call when pagination button is clicked 
  const pageHandler = async (number) => {  
   // let pagenumber = number || 0;
   // console.log("page number",pagenumber)

    try {
      let data = await getAdminList(number);
      console.log("json", data)
      setTableDataNotFound("");
    setPagenumber(data.page.number);
    setViewList(data._embedded.results);
    setTotalPages(data.page.totalPages);
    setTotalElements(data.page.totalElements);
    setInternetFlag(false);
    setIsLoading(false);
    } catch (error) {
      setTableDataNotFound("data not found");
      setIsLoading(false);
      console.log("error calling list", error)
    }
  }
  // when pagination button is clicked here page number is set and api callback is triggered
  const handleChange = (event,value) => {
    let currpage
    currpage = value-1
    pageHandler(currpage);
  }
  // when component loading it updates breadcrumb and get list callback
  useEffect(() => {
    setIsLoading(true);

    props.dispatch(updateBreadcrumbs("viewaccount"));
   fetchAdminList(pageNumber);
    // if(viewList.length===0){
    //   setTableDataNotFound("No data here");
    //   setInternetFlag(true);
    // }
    
  }, [])

  //function for table header arrow functionality and datestamp sort api call
  function EnhancedTableHead(props) {
    const { classes } = props;
    // const createSortHandler = (property) => (event) => {
    //   onRequestSort(event, property);
    // };
    return (
      <TableHead>
        <TableRow>
          {adminListHeaderCell.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={(headCell.id === 'sno'  ) ? 'center' : "left"}
              width={headCell.width}
              className={classes.thData}>
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  //propTypes for table header arrow function
  EnhancedTableHead.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  //table header arrow sub-function
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  // on clicking create user button it redirects to create page
  const createHandler = () => {
    props.history.push({
      pathname: '/portal/createaccount'
    });
  }


  // when we click on confirm button in delete popup
  const submitDetails = () => {
     if(dialogRole==='Multitenant') {
    httpRequest.deleteAdmin(
      'adminDelete',
      {
        "userIds": [deleteId],
        "adminRemarks": "left the company",

        "roles": ["MULTITENANT_ADMIN"],

        "org": {
          "orgId": orgId
        }
      },
      {
        'Content-Type': 'application/json'
      },
      successCallbackDelete,
      errorCallbackDelete,
      {},
    )
     } else {
      httpRequest.deleteAdmin(
        'adminDelete',
        {
          "userIds": [deleteId],
          "adminRemarks": "left the company",
  
          "roles": ["CLOSED_GROUP_PARKING_PROVIDER"],
  
          "org": {
            "orgId": orgId
          }
        },
        {
          'Content-Type': 'application/json'
        },
        successCallbackDelete,
        errorCallbackDelete,
        {},
      )
     }
    
    setDialogOpen(false);
  };
  // user delete success callback
  const successCallbackDelete = jsonResponse => {
    if (jsonResponse) {
      setViewList([]);
     fetchAdminList(pageNumber)
      props.dispatch(updateToasterConfig({
        show: true,
        message: `User deleted successfully`,
        color: 'green'
      }))
    }
  }
  // user delete error callback
  const errorCallbackDelete = error => {
    if (error) {
      props.dispatch(updateToasterConfig({
        show: true,
        message: error.error_description || error.message || error.error,
        color: 'red'
      }))

    }
  }
  // when we click on delete icon
  const deleteHandler = (id, orgId,type, email, orgName) => {
    setDeleteId(id);
    setOrgId(orgId)
    setDialogOpen(true);
    setDialogTitle(orgName);
    setDialogEmail(email);
    setDialogRole(type);
  }
   //function to capture and pass user id between components
   const viewHandler = (data1, data2) => {
    props.history.push({
      pathname: '/portal/viewaccountdetails',
      state: {
        id: `${data1}`,
        orgId: `${data2}`,
      //  currentPage: currentPage
      }
    });
  }


  return (
    <>
      {
        isLoading && <Loader />}
      <ClickAwayListener >
        <Grid container
          direction="column"
          className={classes.container}
        >

          <ClickAwayListener >
            <Grid container
              direction="row"
              xs={12} sm={12} md={12} lg={12}
              className={classes.header}
            >
              <Grid item xs={10} sm={10} md={10} lg={10}>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <button
                  type='button'
                  id="addButton"
                  className={classes.createuserButton}
                  onClick={createHandler}
                >
                  Create User+
                </button>
              </Grid>
            </Grid>
          </ClickAwayListener>

          {
            responseDataNotFound.length === 0 ?
              <React.Fragment>
                <Grid item className={classes.aboveTableGrid}>
                  <Grid container
                    direction="row"
                    justify="center"
                    className={classes.tableGrid}
                  >
                    <TableContainer className={classes.paper} component={Paper}>
                      <Table className={classes.table} aria-label="View table">
                        <EnhancedTableHead
                          classes={classes}
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                          {
                            viewList.length !== 0 && internetFlag === false
                              ?
                              viewList.map((row, index) => {
                                let no = index;
                                function formatNo(no) {
                                  return no < 10 ? (pageNumber) + '' + no : (pageNumber + 1) * no
                                }
                                return (
                                  <TableRow key={index} >
                                    <TableCell className={classes.tableCell} align="center">
                                      {formatNo(no + 1)}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} data-testId="nameColumn" align='left' >
                                      {(row?.firstName ? row?.firstName : "--") + " " +(row?.lastName ? row?.lastName : "")}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align='left' >
                                    {row?.adminTypeDto?.adminTypes?row.adminTypeDto.adminTypes==='Singletenant'?'Single-Tenant':'Multi-Tenant':'--'}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align='left' >
                                    {row?.org?.name?row.org.name: "--"}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align='left' >
                                    {row?.addressDetails?
                                    row.addressDetails?.addressLine1+","+row.addressDetails?.city+","+
                                    row.addressDetails?.state+","+
                                    row.addressDetails?.country+","+
                                    row.addressDetails?.pincode:"--"}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} 
                                    data-testId={`fm-item-${index}`}
                                     align='left' >
                                      {row?.corpEmailId?row?.corpEmailId : "--"}
                                    </TableCell>
                                   
                                    <TableCell className={classes.tableCell} align="left">
                                    {row?.mobileNumber?row?.mobileNumber : "--"}

                                    </TableCell>  
                                    <TableCell className={classes.tableCell} align="left">
                                    {row?.active?"Active" : "Inactive"}

                                    </TableCell>

                                    <TableCell className={classes.viewTableCell}>

                                      <img
                                        alt="edit_icon"
                                        src={EditIcon}
                                        data-testId="editButton"
                                        className={classes.edit_icon}
                                        onClick={() => {
                                          props.history.push({
                                            pathname: "/portal/editaccount",
                                            state: {
                                              id: row.userId,
                                            //  currentPage: currentPage,
                                              solOrgId: row.org?.orgId

                                            },
                                          });
                                        }}
                                      />
                                      <img
                                        alt="delete_icon"
                                        src={deleteIcon}
                                        data-testId={`fm-del-item-${index}`}
                                        className={classes.delete_icon}
                                        onClick={() => deleteHandler(
                                          row.userId ? row.userId : "",
                                          row.org ? row.org.orgId : "",
                                          row?.adminTypeDto?.adminTypes,
                                          row?.corpEmailId,
                                          row?.org?.name
                                        )}
                                      />
                                       <Button
                                      className={classes.viewButton}
                                      onClick={() => viewHandler(row.userId ? row.userId : "", row.org ? row.org.orgId : "")}
                                    >
                                      View
                              </Button>
                                    </TableCell>
                                  </TableRow>
                                )
                              })
                              :
                              <TableRow className={classes.tableRowNoImage}>
                                <TableCell>
                                  <Grid container
                                    direction="column"
                                    className={classes.gridContainerNoImage}
                                    alignItems="center"
                                  >
                                    <Grid item className={classes.typographyGridNoImage}>
                                      <Typography variant="body1">{tableDataNotFound}</Typography>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              </TableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>


                <Grid container
                  direction="row"
                  justify="flex-end"
                  className={classes.paginationOuterGrid}
                >
                  <Grid item className={classes.paginationGrid}>
                    <Grid container
                      direction="row"
                      justify="flex-end"
                      className={classes.paginationStyle}
                    >

                      <Grid item>
                        <Pagination count={totalPages}  className={classes.root}  size="small" onChange={handleChange} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </React.Fragment>
              :
              <Grid item className={classes.notFoundGrid}>
                <Grid container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="body1">{responseDataNotFound}{tableDataNotFound}</Typography>
                  </Grid>
                </Grid>
              </Grid>
          }
          <Dialog
            open={dialogOpen}
            onClose={() => {
              setDialogOpen(false)
            }}
            PaperProps={{
              style: {
                padding: "1.5% 3%",
              },
            }}
            className={classes.dialog}
          >

            <MuiDialogTitle style={{ paddingBottom: "8px", paddingTop: "2px", paddingLeft: "2px", paddingRight: "2px" }}>

              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <span className={classes.dialogHeader}>Delete User Details</span>
                </Grid>

              </Grid>

            </MuiDialogTitle>
            <Divider />
            <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Grid item>
                <Grid container direction="column" justify="center">
                  <Grid item>
                    <Typography className={classes.dialogSubHeader}>Are you sure You want to delete the selected user Account?</Typography>
                  </Grid >
                  <Grid item>
                    <p>
                      <span className={classes.dialogSubTitle}>Org Name:</span> <span className={classes.dialogSubText}>{dialogTitle}</span>
                    </p>
                  </Grid>
                  <Grid item>
                    <p>
                      <span className={classes.dialogSubTitle}>Email Id:</span> <span className={classes.dialogSubText}>{dialogEmail}</span>
                    </p>
                  </Grid>
                  <Grid item>
                    <p>
                      <span className={classes.dialogSubTitle}>Admin Type:</span> <span className={classes.dialogSubText}>{dialogRole}</span>
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid item>
                <Grid container
                  direction="row"
                  justify="flex-end"
                  className={classes.dialogActionGrid}
                >
                  <Grid item className={classes.dialogCancelGrid}>
                    <button type="button" onClick={() => setDialogOpen(false)} className={classes.dialogCancel}>
                      Cancel
                    </button>
                  </Grid>
                  <Grid item className={classes.dialogConfirmGrid}>
                    <button type="button" className={classes.dialogConfirm} onClick={submitDetails}>
                      Confirm
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </Grid>
      </ClickAwayListener>
    </>
  )
}
//  redux state method
// const mapStateToProps = (state) => {
//   return {
//       pageRedirect : state.app.pageRedirect
//   }
// } 

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  updateToasterConfig,
  dispatch,
});

export default withRouter(connect(null, mapDispatchToProps)((ViewAccount)));