/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { updateToasterConfig } from '../../redux/modules/app/actions';

class Toster extends Component {
  componentDidMount = () => {
    setTimeout(() => {
      this.hideToaster();
    }, 7000);
  };
  hideToaster = () => {
    this.props.updateToasterConfig({
      show: false,
      message: '',
      color: '',
    });
  };
  render() {
    //;
    return (
      <div className="toster" id="toster">
        <div
          className="toster__wrapper"
          style={{ backgroundColor: this.props.toasterConfig.color }}
        >
          <p>{this.props.toasterConfig.message}</p>
          <button className="btn" onClick={this.hideToaster}>
            &#10006;
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    toasterConfig: state.app.toasterConfig,
  }),
  {
    updateToasterConfig,
  },
)(Toster);
