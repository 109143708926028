/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React from 'react';
import { Button } from '@material-ui/core/';
import ImageUploader from 'react-images-upload';
import './../MainForm.scss';


import uploadImageService from '../../../../../Utils/uploadImageService';
import UploadIcon from '../../../../../Assets/Images/cloud-computing.svg';

import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import error from '../../../../../Assets/Images/error.png';

import './style.scss';

// var storeEditData = [];
var newData = [];
var deleteImageList = [];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const handleDragOver = evt => {
  evt.preventDefault();
  return false;
};

const handleDrop = (evt, props) => {
  evt.preventDefault();
  evt.stopPropagation();
  if (evt.dataTransfer.files[0].size > 2084000) {
    document.getElementById('myDialog').showModal();
    setTimeout(() => {
      document.getElementById('myDialog').close();
    }, 3000);
  } else {
    let imagesData = evt.dataTransfer.files;

    uploadImageService.uploadImage(
      imagesData,
      uploadImageServiceCallBack,
      props,
    );
  }
};
const removeImage = (imageData, props) => {
  if (imageData[0].file === undefined) {
    deleteImageList.push(imageData[0]);
  }
  props.updateChildrenState(
    'step1',
    'deleteImagesFromSpace',
    deleteImageList,
  );
};
const uploadImageServiceCallBack = (imageData, props) => {
  if (props.data.step1.images.length >= 5) {
    return false;
  }

  newData = [imageData, ...props.data.step1.images];
  const result = [];
  const duplicatesIndices = [];
  newData.forEach((current, index) => {
    if (duplicatesIndices.includes(index)) return;

    result.push(current);

    for (
      let comparisonIndex = index + 1;
      comparisonIndex < newData.length;
      comparisonIndex++
    ) {
      const comparison = newData[comparisonIndex];
      const currentKeys = Object.keys(current);
      const comparisonKeys = Object.keys(comparison);

      if (currentKeys.length !== comparisonKeys.length) continue;
      const currentKeysString = currentKeys
        .sort()
        .join('')
        .toLowerCase();
      const comparisonKeysString = comparisonKeys
        .sort()
        .join('')
        .toLowerCase();
      if (currentKeysString !== comparisonKeysString) continue;

      let valuesEqual = true;
      for (let i = 0; i < currentKeys.length; i++) {
        const key = currentKeys[i];
        if (current[key] !== comparison[key]) {
          valuesEqual = false;
          break;
        }
      }
      if (valuesEqual) duplicatesIndices.push(comparisonIndex);
    }
  });

  props.updateChildrenState('step1', 'images', result);
};

const EditParkingStepOne = props => {

  // const theme = useTheme();
  // const [value, setValue] = React.useState(0);
  // const [isChecked, setChecked] = useState(false);
  // const [activeTab, setActiveTab] = useState('twoWheeler');
  // const [showTheDiv, setTheDiv] = useState(0);
  props.updateRootState(0, 1);

  const onDrop = (evt, props) => {
    let imagesData = evt.slice(-1);
    if (imagesData.length === 0 || imagesData[0].size > 2084000) {
      document.getElementById('myDialog').showModal();
      setTimeout(() => {
        document.getElementById('myDialog').close();
      }, 3000);
    } else {
      uploadImageService.uploadImage(
        imagesData,
        uploadImageServiceCallBack,
        props,
      );
    }
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  // const handleChangeIndex = index => {
  //   setValue(index);
  // };
  // const updateActiveTab = tabName => {
  //   setActiveTab(tabName);
  // };
  // const showCustomRule = () => {
  //   setTheDiv(showTheDiv + 1);
  // };
  props.updateRootState(1, 2);
  // storeEditData = props.data;
  newData = [...props.data.step1.images];

  //console.log("djlkasjdkajdlkajdlkajdlkajdlkajdlkajd",props)
  // console.log("check props", props.parkingId)

  return (
    <div className="contactdetails">
      
        <div className="contactdetails__wrapper">
          <div className="contactdetails__wrapper__heading">
            <p className="contactdetails__wrapper__heading__title">
              { //Parking Space and Contact Details
              }
              Upload Parking Images
              {props.search !== '' ? (
                <span className="notedit" style={{ visibility: 'hidden' }}>
                  <img src={error} alt="error" />
                  This Information change will be subjected to
                  approval
                </span>
              ) : (
                  ''
                )}
            </p>

          </div>
          <div className="contactdetails__wrapper__container">



            <div className="contactdetails__wrapper__container__rightside">
              {/* <label>Add Parki Photos</label> */}
              <dialog id="myDialog">Image size should be less than 2 mb !!</dialog>
              <div className="contactdetails__wrapper__container__rightside__box">
                <div
                  className="drag__and_drop"
                  onDragOver={evt => handleDragOver(evt)}
                  onDrop={evt => handleDrop(evt, props)}
                >
                  <p className="contactdetails__wrapper__container__rightside__box_upimg">
                    <ImageUploader
                      withIcon={true}
                      buttonText="Choose images"
                      onChange={evt => onDrop(evt, props)}
                      imgExtension={['.jpg', '.gif', '.png']}
                    ></ImageUploader>
                    <img
                      src={UploadIcon}
                      alt="UploadIcon"
                      className="UploadIcon"
                    />
                  </p>
                  <p className="contactdetails__wrapper__container__rightside__box_text">
                    Drag and Drop a file here or click
                  </p>
                  <p className="contactdetails__wrapper__container__rightside__box_text1">
                    You can upload upto 5 photos each should be less
                    than 2mb
                  </p>
                </div>

                <div className="contactdetails__wrapper__container__rightside__box_imagesbox">
                  {newData.map((item, index) => {
                    return (
                      <div className="images" key={index}>
                        <img
                          src={
                            item.newImg
                              ? item.src
                              : `data:image/png;base64,${item}`
                          }
                          alt="imgSrc"
                        />

                        <span
                          className="delete-img"
                          onClick={() => {
                            var dataImage = newData.splice(index, 1);
                            props.updateChildrenState(
                              'step1',
                              'images',
                              newData,
                            );
                            removeImage(dataImage, props);
                          }}
                        >
                          X
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="Editparking__wrapper_bottombuttons">
                <div className="next" style={{ visibility: 'hidden' }}>
                  <Button

                    className="previous"
                    onClick={() => {
                      // console.log("back props: ", props)
                      props.history.push(
                        '/portal/parking-details/' +
                        props.addParkinSteps.step1.parkingId,
                      );
                    }}
                  // className={classes.button}
                  >
                    Back
                    </Button>
                </div>
                <div className="submitBtn">
                  {/* <Button
                      variant="contained"
                      color="primary"
                     onClick={handleImages}
                      disabled={true}
                      //className={classes.button}
                    >
                      Upload
                    </Button> */}
                </div>
              </div>
            </div>
          </div>

        </div>

    </div>

  );
};

export default EditParkingStepOne;
