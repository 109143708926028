import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import '../Pagination/style.scss';


var indexValue = '';
 
const PaginationComponent = ({
  postsPerPage,
  totalPosts,
  paginate,
  currentPage,
  pageHandler,
  searchFlag,
  searchHandler,
  searchFlagHandler,
  pageNumber,
  curPage,
  curPageSetter
}) => {

  
  indexValue = curPage;
  // let backTrigger = sessionStorage.getItem("backTrigger");
  // let prevPagenumber = sessionStorage.getItem("prevPagenumber");


  // if(backTrigger) {
  //   console.log("prevNumber: ",prevPagenumber)
  //   pageHandler(prevPagenumber - 1)
  //   sessionStorage.removeItem("backTrigger");
  // }
  const pageNumbers = [];  
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    // for (let i = 1; i <= totalPages; i++) {
    if (i <= 9) {
      pageNumbers.push('0' + i);
    } else {
      pageNumbers.push(i);
    }
  }

  const pageChangeHandler = e => {
    let totalPages = Math.floor(pageNumbers.length)
    if(e.target.value < 0) {
      e.target.value = 1
    }
    if(e.target.value > totalPages) {
      e.target.value = totalPages
    }
    if(e.target.value===0) {
      e.target.value=1
    }
    
    let incomingValue = e.target.value;
    
    curPageSetter(incomingValue)
    if(searchFlag) {
      searchHandler(incomingValue - 1)
    }
    if(!searchFlag) {
      pageHandler(incomingValue - 1)
      searchFlagHandler(false)
    }  
    
 }

 useEffect(() => {
    curPageSetter(curPage);
    pageHandler(curPage - 1)
    paginate(currentPage);
    indexValue = curPage;     
    [...document.querySelectorAll('.page-link')].forEach(function(
      item,
    ) {
      item.classList.remove('active');
      if (indexValue == item.innerHTML) {
        item.classList.add('active');
        styleDeleteHandler()
      }
      // item.addEventListener('click', function(elem) {
      //   var getElemWithClass = document.querySelector('.active');
      //   if (getElemWithClass !== null) {
      //     getElemWithClass.classList.remove('active');
      //   }
      //   item.classList.add('active');
      // });
    });
  },[]);

  //function to delete page number style during onClick
  //Vanilla Js is used here to get element id and then delete each style property which was set initially
  const styleDeleteHandler = () => {
    var element = document.getElementById('itemDiv');
    element.style.color = null;
    element.style.backgroundColor = null;
    element.style.borderRadius = null;
    element.style.fontSize = null;
    element.style.width = null;
    element.style.height = null;
    element.style.padding = null;
    element.style.alignItems = null;
    element.style.textAlign = null;
  }

  //function for back button
  const getBack = () => {    
    indexValue--;

    const storeNumber = ["01","02","03","04","05","06","07","08","09"];
    let initialStore = indexValue;
    let temp = "";
    
    curPageSetter(initialStore)
    storeNumber.forEach(item=>{
      if(initialStore === item) {
        temp = initialStore.substring(1)
      } else {
        temp = initialStore
      }
    });

    if(searchFlag) {
      // console.log("initial store: ",initialStore-1)
      searchHandler(initialStore-1)
    }

    if(!searchFlag) {
      pageHandler(initialStore - 1);
      searchFlagHandler(false)
    }
    
    if (indexValue >= 1) {
      paginate(indexValue);
      [...document.querySelectorAll('.page-link')].forEach(function(
        item,
      ) {
          styleDeleteHandler() 
        item.classList.remove('active');
        if (indexValue == item.innerHTML) {
          item.classList.add('active');
          styleDeleteHandler()
        }
      });
    }
  };

  //function for forward button
  const getForward = () => {    
    indexValue++;
    const storeNumber = ["01","02","03","04","05","06","07","08","09"];
    let initialStore = indexValue;
    let temp = "";
    
    curPageSetter(initialStore)
    storeNumber.forEach(item=>{
      if(initialStore === item) {
        temp = initialStore.substring(1)
      } else {
        temp = initialStore
      }
    });
    if(searchFlag) {
      searchHandler(initialStore-1)
    }
    if(!searchFlag) {
      pageHandler(initialStore - 1);
      searchFlagHandler(false)
    }
    
    if (indexValue <= pageNumbers.length) {
      paginate(indexValue);
      [...document.querySelectorAll('.page-link')].forEach(function(
        item,
      ) {
        styleDeleteHandler()
        item.classList.remove('active');
        if (indexValue == item.innerHTML) {
          item.classList.add('active');
          styleDeleteHandler()
        }
      });
    }
  };
  // defining pagination number object
  

  //function for onClick of page number
  const ClickOnNumbers = (idNumber) => {
    const storeNumber = ["01","02","03","04","05","06","07","08","09"];
    let initialStore = idNumber;
    let temp = "";
    
    storeNumber.forEach(item=>{
      if(initialStore === item) {
        temp = initialStore.substring(1)
      } else {
        temp = initialStore
      }
    });
    
    // if (idNumber==="01" || 
    //     idNumber==="02" || 
    //     idNumber==="03" ||
    //     idNumber==="04" ||
    //     idNumber==="05" ||
    //     idNumber==="06" ||
    //     idNumber==="07" ||
    //     idNumber==="08" ||
    //     idNumber==="09" 
    //   ) 
    //   {
    //   temp = initialStore.substring(1)
    // } else {
    //   temp = idNumber
    // }
    pageHandler(temp - 1);
    paginate(idNumber);
    indexValue = idNumber;
    
    [...document.querySelectorAll('.page-link')].forEach(item=>
     {
      styleDeleteHandler()
      item.classList.remove('active');
      if (indexValue == item.innerHTML) {
        item.classList.add('active');
        styleDeleteHandler()
      }
      // console.log("item is: ",item)
      // item.addEventListener('click', function(elem) {
      //   var getElemWithClass = document.querySelector('.active');
      //   if (getElemWithClass !== null) {
      //     getElemWithClass.classList.remove('active');
      //     var element = document.getElementById('itemDiv');
      //     element.style.color = null;
      //   }
      //   var element = document.getElementById('itemDiv');
      //     element.style.color = null;
      //   item.classList.add('active');
      // });
    });
  };

  return (
    <nav className="pagiNation_parkingspace" style={{width:"75%"}}>
      <ul className="pagination" >
        {
          indexValue > 1?
          <a onClick={getBack} className="page-prev">
            <Grid item>
              <Grid container 
                direction="row"
                style={{marginRight:"1em"}}
              >
                <Grid item>
                  &lt;
                </Grid>
                <Grid item >
                  <p style={{fontSize:'14px'}}>Previous</p>
                </Grid>
              </Grid>
            </Grid>           
          </a>
          :
          null
        }
        <Grid item container 
                direction="row"
              >
                <Grid item style={{marginRight:"0.1em"}}>
                  <p style={{fontSize:"14px",marginTop:"0.6em"}}>Page</p>
                </Grid>
                <Grid item 
                  style={{
                    marginTop:"0.9em",
                    // backgroundColor: "#e46a76",
                    // color: "white",
                    fontSize: "10px",
                    borderRadius: "20%",
                    width: "75px",
                    height: "30px",
                    // padding: "5px 10px 5px 10px",
                    alignItems: "center",
                    textAlign: "center"
                  }} 
                >
                  <input
                    type="number"
                    min="1"
                    value={curPage}
                    style={{width:"38%",fontSize:"14px",border:"none"}}
                    onChange={e=>pageChangeHandler(e)}
                  /><span style={{fontSize:"13px"}}>of {Math.floor(pageNumbers.length)}</span> <p style={{fontSize:"0.7rem"}}></p>
                  
                </Grid>
              </Grid>
        {/* {pageNumbers.map((item, index) => {
          
          return (
            <>
              
              <li key={index} className="page-item" style={{visibility:"hidden"}}>
              <a
                
                id="itemDiv"
                onClick={() => ClickOnNumbers(item)}
                className="page-link"
                style={{
                  backgroundColor: item==="01"?"#e46a76":null,
                  color: item==="01"?"white":null,
                  fontSize: item==="01"?"18px":null,
                  borderRadius: item==="01"?"50%":null,
                  width: item==="01"?"65px":null,
                  height: item==="01"?"65px":null,
                  padding: item==="01"?"5px 10px 5px 10px":null,
                  alignItems: item==="01"?"center":null,
                  textAlign: item==="01"?"center":null,
                }}
              >
                {item}
              </a>
            </li>
            </>
          );
        })} */}
        {
          indexValue < pageNumbers.length?
          <a onClick={getForward} className="page-next">
            <Grid item>
              <Grid container 
                direction="row"
                justify="flex-end"
              >
                <Grid item style={{marginRight:"2px",marginLeft:"1em"}}>
                  <p style={{fontSize:'14px'}}>Next</p>
                </Grid>
                <Grid item >
                  &gt;
                </Grid>
              </Grid>
            </Grid>
          </a>
        :
        null
        }
      </ul>
    </nav>
  );
};

export default PaginationComponent;