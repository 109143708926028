/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, List } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';

import SidebarMenuItem from './SidebarMenuItem';
import { getSVG } from '../../Utils/SvgGenerator';

const drawerWidth = 225;

const appMenuItems = [
  /* {
    name: 'Dashboard',
    link: '/portal/dashboard',
    Icon: getSVG('dashboard'),
    depth: '0',
    Icon_collapsed: getSVG('dashboardCol'),
  }, */
  {
    name: 'Account Management',
    // Icon: 'notification',
    Icon: getSVG('accountManagement'),
    depth: '1',
    Icon_collapsed: getSVG('accountCol'),
    items: [
      {
        name: 'Create Account',
        link: '/portal/createaccount',
        depth: '10',
        // Icon: IconBarChart,
      },
      {
        name: 'View Account',
        link: '/portal/viewaccount',
        depth: '11',
        // Icon: IconBarChart,
      },
      {
        name: 'Add Organization',
        link: '/portal/addorganization',
        depth: '12',
        // Icon: IconBarChart,
      },
      {
        name: 'View Organizations',
        link: '/portal/vieworganizations',
        depth: '13',
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: 'Parking Management',
    link: '/portal/parkingmanagment',
    // Icon: 'notification',
    Icon: getSVG('parkingManagement'),
    depth: '2',
    Icon_collapsed: getSVG('parkingCol'),
    items: [
      {
        name: 'Parking Space Overview',
        link: '/portal/parkingmanagment',
        depth: '10',
        // Icon: IconBarChart,
      },
      {
        name: 'Bulk Upload',
        link: '/portal/bulkUpload',
        depth: '11',
        // Icon: IconBarChart,
      }
      // ,{
      //   name: 'Complaints Management',
      //   link: '/portal/addparkingspace',
      //   depth: '12',
      //   // Icon: IconBarChart,
      // },
    ],
  },
  {
    name: 'Device Management',
    link: '/portal/devicemanagement',
    Icon: getSVG('deviceManagement'),
    depth: '3',
    Icon_collapsed: getSVG('deviceCol'),
  }, 
  {
    name: 'User Management',
    Icon: getSVG('userManagement'),
    depth: '4',
    Icon_collapsed: getSVG('userCol'),
    items: [
      {
        name: 'End User Details',
        link: '/portal/userManagement',
        depth: '20',
        // Icon: IconBarChart,
      },
    ],
  },
  {
    name: 'Mobile App Management',
    link: '/portal/forceupdate',
    Icon: getSVG('roleManagement'),
    depth: '5',
    Icon_collapsed: getSVG('roleCol'),
  }, 
   {
    name: 'Complaint Management',
    Icon: getSVG('serviceRequest'),
    link: '/portal/complaint',
    depth: '6',
    Icon_collapsed: getSVG('serviceCol'),
  },
  /*{
    name: 'Reports',
    Icon: getSVG('reports'),
    link: '/portal/report',
    depth: '5',
    Icon_collapsed: getSVG('reportCol'),
  }, */
  /* {
    name: 'Payments',
    Icon: getSVG('payments'),
    link: '/portal/payment',
    depth: '6',
    Icon_collapsed: getSVG('paymentCol'),
  }, */

  /* {
    name: 'Settings',
    Icon: getSVG('settings'),
    link: '/portal/setting',
    depth: '7',
    Icon_collapsed: getSVG('settingCol'),
  }, */
];

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',

    backgroundColor: 'rgb(242, 242, 240)',
    zIndex:0,
  },
  appMenu: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    position: 'relative',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    position: 'relative',
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
}));

function Sidebar(props) {
  const classes = useStyles();

  // toggle sidebar drawer
  const open = props.openState;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: !open,
          [classes.drawerClose]: open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: !open,
            [classes.drawerClose]: open,
          }),
        }}
      >
        <List
          component="nav"
          style={{ paddingTop: '0px', paddingBottom: '0px' }}
        >
          {appMenuItems.map((item, index) => (
            <SidebarMenuItem {...item} key={index} close={!open} />
          ))}
        </List>
      </Drawer>
    </div>
  );
}

export default Sidebar;
