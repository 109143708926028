/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
// import React, { Component } from 'react'
// import PropTypes from 'prop-types';

// import { fade, makeStyles } from '@material-ui/core/styles';
// import SearchIcon from '@material-ui/icons/Search';
// import InputBase from '@material-ui/core/InputBase';
// import {getCookie, setCookie, deleteCookie} from '../../Utils/cookies';
// import httpRequest from '../../Utils/httpRequest';
// import {updateAuth} from '../../redux/modules/app/actions';
// import { withRouter } from 'react-router';
// import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';

// import './Header.scss'

// const search = {
//     position: 'relative',
//     borderRadius: '20px',
//     backgroundColor: 'rgba(93, 206, 215, 0.52)',
//     '&:hover': {
//       backgroundColor: 'blue',
//     },
//     marginRight: '20px',
//     marginLeft: 0,
//     width: '100%',
// }

// const inputField = {
//     padding: '6px 20px 7px',
//     width: '100%',
//     color: 'white',
// }

// const searchIcon = {
//     // width: theme.spacing(7),
//     height: '100%',
//     position: 'absolute',
//     right: '10px',
//     pointerEvents: 'none',
//     display: 'inline-flex',
//     alignItems: 'center',
//     justifyContent: 'center',
// }

// class HeaderSearch extends Component {

//     static propTypes = {
//         options: PropTypes.instanceOf(Array).isRequired
//       };
//       state = {
//         activeOption: 0,
//         filteredOptions: [],
//         showOptions: false,
//         userInput: '',
//         parkings: []
//       };

//       UserDataSuccess = jsonResult =>{
//         this.setState({parkings : jsonResult.data._embedded.results});
//         // console.log(this.state.parkings)
//     }
//     errorCallback = error => {
//         ;

//     }

//     componentDidMount(){
//         httpRequest.get('parkingList', {},
//             this.UserDataSuccess, this.errorCallback,
//             {
//                 userId: getCookie('userId')
//             })
//     }

//     parkingSelectHandeler = (id) => {
//       ;
//         console.log("selectedParkingId",this.state.selectedParkingId)
//         this.setState({selectedParkingId : id});
//         this.props.history.push('/portal/parking-details/' +id);
//     }

//       onChange = (e) => {
//         // ;
//       const result = this.state.parkings.map(({ parkingId, name }) => ({parkingId, name}));
//       console.log("Result is", result)

//       const  options  = this.state.parkings.map((name)=>{
//           return name.parkingId
//         });
//       console.log("Options", options)

//         const userInput = e.currentTarget.value;

//         const filteredOptions = options.filter(
//           (optionName) =>
//             optionName.toLowerCase().indexOf(userInput.toLowerCase()) > -1
//         );

//         this.setState({
//           activeOption: 0,
//           filteredOptions,
//           showOptions: true,
//           userInput: e.currentTarget.value
//         });
//       };

//       onClick = (e) => {
//         this.setState({
//           activeOption: 0,
//           filteredOptions: [],
//           showOptions: false,
//           userInput: e.currentTarget.innerText
//         });
//       };
//       onKeyDown = (e) => {
//         const { activeOption, filteredOptions } = this.state;

//         if (e.keyCode === 13) {
//           this.setState({
//             activeOption: 0,
//             showOptions: false,
//             userInput: filteredOptions[activeOption]
//           });
//         } else if (e.keyCode === 38) {
//           if (activeOption === 0) {
//             return;
//           }
//           this.setState({ activeOption: activeOption - 1 });
//         } else if (e.keyCode === 40) {
//           if (activeOption === filteredOptions.length - 1) {
//             console.log(activeOption);
//             return;
//           }
//           this.setState({ activeOption: activeOption + 1 });
//         }
//       };

//     render() {
//         const {
//             onChange,
//             onClick,
//             onKeyDown,

//             state: { activeOption, filteredOptions, showOptions, userInput }
//           } = this;
//           let optionList;
//           if (showOptions && userInput) {
//             if (filteredOptions.length) {
//               optionList = (
//                 <ul className="options">
//                   {filteredOptions.map((optionName, index) => {
//                     // ;
//                     let className;
//                     if (index === activeOption) {
//                       className = 'option-active';
//                     }
//                     return (
//                       <li
//                         className={className}
//                         key={optionName}
//                         onClick={() =>{
//                           this.parkingSelectHandeler(optionName)
//                         }}>
//                         {optionName}
//                       </li>
//                     );
//                   })}
//                 </ul>
//               );
//             } else {
//               optionList = (
//                 <div className="no-options">
//                   <em>No Option!</em>
//                 </div>
//               );
//             }
//           }
//           return (
//             <div style={{width:'50%'}}>
//                 <div style={search}>
//                     <InputBase
//                         placeholder="Search"
//                         style={inputField}
//                         onChange={onChange}
//                         onKeyDown={onKeyDown}
//                         value={userInput}
//                         inputProps={{ 'aria-label': 'search' }}
//                     />
//                     <div style={searchIcon}>
//                     <SearchIcon />
//                     </div>
//                     <div className="optionsContainer">
//                         {optionList}
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }

// export default withRouter(connect(state => ({
//   auth: state.app.auth
// }), { updateAuth })(HeaderSearch));

import React, { Component } from 'react';

import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { updateAuth } from '../../redux/modules/app/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import './Header.scss';

const search = {
  position: 'relative',
  borderRadius: '20px',
  backgroundColor: 'rgba(93, 206, 215, 0.52)',
  '&:hover': {
    backgroundColor: 'blue',
  },
  marginRight: '20px',
  marginLeft: '20px',
  width: '100%',
};
const optionsContaineroverlay = {
  position: 'fixed',
  left: 0,
  top: 0,
  backgroundColor: '#0e0a0a',
  opacity: 0.6,
  width: '100%',
  height: '100vh',
  bottom: 0,
  right: 0,
  zindex: 9,
};
const inputField = {
  padding: '6px 20px 7px',
  width: '100%',
  color: 'white',
};

const searchIcon = {
  // width: theme.spacing(7),
  height: '100%',
  position: 'absolute',
  right: '10px',
  pointerEvents: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
};

class HeaderSearch extends Component {
  constructor(props) {
    super(props);
    this.optionPopupClose = this.optionPopupClose.bind(this);
  }

  // static propTypes = {
  //     options: PropTypes.instanceOf(Array).isRequired
  //   };
  state = {
    activeOption: 0,
    filteredOptions: [],
    showOptions: false,
    userInput: '',
    parkings: [],
  };

  UserDataSuccess = (jsonResult) => {
    this.setState({ parkings: jsonResult.data._embedded.results });
    // console.log(this.state.parkings)
  };
  errorCallback = () => {
    // ;
  };

  componentDidMount() {
    // httpRequest.get('parkingList', {},
    //     this.UserDataSuccess, this.errorCallback,
    //     {
    //         userId: getCookie('userId')
    //     })
  }

  parkingSelectHandeler = (id) => {
    this.setState({ selectedParkingId: id });
    this.props.history.push('/portal/parking-details/' + id);
  };

  onChange = (e) => {
    // ;
    const result = this.state.parkings.map(({ parkingId, name }) => ({
      parkingId,
      name,
    }));
    // Setting data to search filter option //
    this.setState({
      parkerDetails: result,
    });
    const options = this.state.parkings.map((name) => {
      return name.parkingId;
    });

    const userInput = e.currentTarget.value;

    const filteredOptions = options.filter(
      (optionName) =>
        optionName.toLowerCase().indexOf(userInput.toLowerCase()) >
        -1,
    );

    this.setState({
      activeOption: 0,
      filteredOptions,
      showOptions: true,
      userInput: e.currentTarget.value,
    });
  };

  onClick = (e) => {
    this.setState({
      activeOption: 0,
      filteredOptions: [],
      showOptions: false,
      userInput: e.currentTarget.innerText,
    });
  };
  onKeyDown = (e) => {
    const { activeOption, filteredOptions } = this.state;

    if (e.keyCode === 13) {
      this.setState({
        activeOption: 0,
        showOptions: false,
        userInput: filteredOptions[activeOption],
      });
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      this.setState({ activeOption: activeOption - 1 });
    } else if (e.keyCode === 40) {
      if (activeOption === filteredOptions.length - 1) {
        return;
      }
      this.setState({ activeOption: activeOption + 1 });
    }
  };

  optionPopupClose() {
    this.setState({
      showOptions: false,
    });
  }

  render() {
    const {
      onChange,
      onKeyDown,

      state: {
        activeOption,
        filteredOptions,
        showOptions,
        userInput,
      },
    } = this;
    let optionList;
    if (showOptions && userInput) {
      if (filteredOptions.length) {
        optionList = (
          <ul className="options">
            {this.state.parkerDetails.map(
              (parkerDetailsList, index) => {
                let className;
                if (index === activeOption) {
                  className = 'option-active';
                }
                return (
                  <li
                    className={className}
                    key={parkerDetailsList.name}
                    onClick={() => {
                      this.parkingSelectHandeler(
                        parkerDetailsList.name,
                      );
                    }}
                  >
                    {parkerDetailsList.name}
                  </li>
                );
              },
            )}
          </ul>
        );
      } else {
        optionList = (
          <div className="no-options">
            <em>No Option!</em>
          </div>
        );
      }
    }
    return (
      <React.Fragment>
        <div className="searchBoxStyle"
        style ={{visibility:'hidden'}}>
          <div style={search}>
            <InputBase
              disabled
              placeholder="Search"
              style={inputField}
              onChange={onChange}
              onKeyDown={onKeyDown}
              value={userInput}
              inputProps={{ 'aria-label': 'search' }}
            />
            <div style={searchIcon}>
              <SearchIcon />
            </div>
            <div className="optionsContainer">{optionList}</div>
          </div>
        </div>
        {this.state.showOptions && (
          <div
            onClick={this.optionPopupClose}
            style={optionsContaineroverlay}
          ></div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      auth: state.app.auth,
    }),
    { updateAuth },
  )(HeaderSearch),
);
