/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import { deleteCookie, getCookie, setCookie } from './cookies';

export default {
  storeToken: (accessToken) => {
    setCookie('auth', accessToken);
  },
  storeRefreshToken: (refreshToken) => {
    setCookie('refresh', refreshToken);
  },
  clear(){
    deleteCookie('auth');
    deleteCookie('refresh');
    deleteCookie('userId');
    deleteCookie('realmName');
    deleteCookie('cliSec');
    deleteCookie('cliId')
   
    
  },
  getRefreshToken() {
    return getCookie('refresh');
  },
  getToken() {
    return getCookie('auth');
  },

  setTokenExpirationTime(expTime) {
    setCookie('tokenExpTime', expTime);
  },
  getTokenExpirationTime() {
    return getCookie('tokenExpTime');
  },
};
