/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React from 'react';
import { connect } from 'react-redux';
import { updateBreadcrumbs } from '../../redux/modules/app/actions';
import { withRouter } from 'react-router-dom';
import './roleMgmtStyles.css';

class RoleManagementOverview extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch(updateBreadcrumbs('roleManagement'));
  }

  render() {
    return (
      <div className="roleMgmtOverview">
        {/*<Card className="rm-card" variant="outlined">
          <CardContent>
            <CreateNewRole/>
          </CardContent>
        </Card>*/}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  updateBreadcrumbs,
  dispatch,
});

export default withRouter(
  connect(null,mapDispatchToProps)(RoleManagementOverview),
);
