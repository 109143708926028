// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.
import { makeStyles} from '@material-ui/core/styles'

//@component
//reportUseStyles
const listviewUseStyles = makeStyles(()=>({
  textArea:{
    width:"100%",
    height:"100px",
    borderRadius:"5px"
  },
  header :{
    width:'97%',
    marginLeft:'1.5%',
    padding:'0.5%',
  },
  textareaGrid:{
    width: "100%",
    marginTop:"1%"
  },
  delete_icon:{
    cursor: 'pointer', 
    height:'13px !important',
    fontSize: '3px !important',
    marginLeft:'15%'
  },
  createuserButton:{
    fontFamily: '\'Poppins\' !important',
    cursor: 'pointer',
    borderRadius: '90px',
    border:'none',
    padding: '9px 27px !important',
    width:'90%',
    height: '100%',
    fontSize: '11px',
    fontWeight: 700,
    backgroundColor: '#03A9F3',
    color: '#fff',
    boxShadow: '0px 3px 6px #00000029 !important',
    float: 'right',
  },
  labelStyle:{
    paddingTop: "12px"
  },
  liStyle:{
    display: "flex"
  },
  applyButtonFilter: {
    width: "60%",
    color: "#fff",
    background: "#e46767",
    borderRadius: "20px",
    padding: "5px 10px",
    fontSize: "10px",
    marginTop:"4px",
    '&:hover': {
      width: "60%",
      color: "#fff",
      background: "#e46767",
      borderRadius: "20px",
      padding: "5px 10px",
      textDecoration: "none",
      fontSize: "0.7rem",
      marginTop:"4px",
    },
    '.MuiButton-outlinedSecondary:hover': {
      border: "none"
    }
    
  },
  hrStyling:{
    marginTop:"20px"
  },
  liGrid: {
    height:"25px"
  },
  filter_box: {
    right:"0.0001%",
    width: "180px",
    listStyle: "none",
    backgroundColor: "#fff",
    position: "absolute",
    top: "98%",
    zIndex: 1,
    color: "#000",
    fontSize: "10px",
    paddingBottom: "20%",
    borderRadius: "3%",
    border: "1px #A9A9A9 solid",
    '.MuiButton-outlinedSecondary': {
      border: "none"
    },
    '& li': {
      display: "flex",
      marginRight: "10px",
      marginLeft: "10px"
    },
    '& label': {
      paddingTop: "12px"
    }
  },
  filter_image: {
    width: "65%"
  },
  filter_button: {
    position: "relative",
    border: "none",
    '&:hover': {
      border: "none"
    },
    '& hr': {
      marginLeft: "23.8px",
      marginRight: "23.8px"
    }
  },
  dialog:{
    zIndex: 0
  },
  dialogActionGrid:{
    marginTop:"0.5em",
    paddingBottom:'0',
  },
  topRowGrid:{
    // marginTop: "1px"
  },
  notFoundGrid:{
    marginTop: "5%"
  },
  viewTableCell:{
    
    textAlign: 'left',
    width: '1px',
    height: '1px'
  },
  viewSpan:{
    color: '#03A9F3', 
    fontWeight: '400',
    fontSize:'0.6rem'
  },
  statusRow:{
     maxWidth:"10%",
     fontSize:'0.75rem',
     fontWeight: 600
  },
  tableRowNoImage:{
    height:"1%"
  },
  gridContainerNoImage:{
    position:"absolute",paddingBottom:"1%",marginBottom:"10px"
  },
  typographyGridNoImage:{
    marginRight:"20%"
  },
  paginationOuterGrid:{
    marginBottom:"6%",
    marginTop:"1.5%"
    
  },
  paginationGrid:{
      
    marginRight:"2%"
  },
  paginationStyle:{
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '90px',
    paddingLeft: '15px',
    boxShadow: '0px 3px 6px #00000029 !important',
  },
  pageTextGrid:{
    marginTop:"6%",
    marginRight:"20px"
  },
  tableCell:{
    fontFamily:'\'Poppins\' !important',
    fontSize:"12px",    
    wordBreak:'break-word',
    fontWeight:'500',
    padding:'1%',
  },
  editCross:{
    visibility:"hidden"
  },
  input_cross:{
    // marginLeft:"1em"
  },
  topRowGridParent:{
    height:"50px",
    marginTop:'1%',
    marginBottom:'0.5%',
  },
  selectNavcards:{
      width: "30%",
      height: "80%",
      textAlign: "center",
      boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
      marginRight: "5%",
      border: "none",
      borderRadius: "6px",
      background: "#ffffff",
      padding: "0 1px",
      paddingRight: "2%",
      marginTop: "2%",
  },
  aboveRowsGrid:{
    height:"40px"
  },
  aboveTableGrid:{
    
  },
  rowsLabel:{
      fontSize:"0.7rem",
      marginRight:"3%",
      fontWeight: "bold"
  },
  dropdownNumber: {
      border: "none",
      color: "#a2a2a2",
      outline: "none",
      marginTop:"5%"
  },
    root: {
      '& .Mui-selected': {
        backgroundColor: '#e46a76 !important',
        color: '#ffffff',
        fontWeight:'900',
       },
       '& .MuiPaginationItem-root' : {
       fontFamily: '\'Poppins\' !important',
       fontSize: '14px',
       },
      
    },
      dialogSubHeader:{
        fontFamily: '\'Poppins\' !important',
        fontSize: '12px',
        letterSpacing: '0.5px'
      },
      dialogSubTitle: {
        fontFamily: '\'Poppins\' !important',
        fontSize: '12px',
        letterSpacing: '1px'

      },
      dialogSubText: {
        fontWeight:'bold',
        fontFamily: '\'Poppins\' !important',
        fontSize: '11px',
        letterSpacing: '1px'
      },
      dialogCloseIcon:{
        cursor:"pointer",
        fontSize: "12px"
      },
      dialogConfirm:{
        fontFamily: '\'Poppins\' !important',
        borderRadius:'90px',
        cursor: 'pointer',
        border:"none",
        fontSize:"10px",
        fontWeight: "900",
        color:"#fff",
        backgroundColor:"#03A9F3",
        boxShadow:"0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
        minWidth: "64px",
        boxSizing: "border-box",
        lineHeight: "1.75",
        letterSpacing: "1px",
        padding: "9px 27px",
        textDecoration: "none",
        verticalAlign: "middle"
      },
      dialogCancel:{
        fontFamily: '\'Poppins\' !important',
        borderRadius: '90px',
        cursor: 'pointer',
        border:"none",
        fontSize:"10px",
        fontWeight: "900",
        color:"#fff",
        backgroundColor:"#707070",
        boxShadow:"0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
        minWidth: "64px",
        boxSizing: "border-box",
        lineHeight: "1.75",
        letterSpacing: "1px",
        padding: "9px 27px",
        textDecoration: "none",
        verticalAlign: "middle",
        marginRight: "20px",
      },
      dialogCancelGrid: {
        textAlignLast:"right"
      },
      dialogHeader:{
        fontFamily: '\'Poppins\' !important',
        fontSize: "14px",
        letterSpacing: "0.5px",
        fontWeight:900,
       
      },
      input: {
        width: '100%',
        borderRadius: '20px',
        border: 'none',
        outline: 'none',
        padding: '10px',
        backgroundColor: 'lightgrey',
      },
      iconButton: {
        padding: 5,
        color:"black",
        marginRight:"0.2%"
      },
    tableGrid:{
        marginTop:"1%",
        width:"96%",
        maxHeight:"30%",
        marginLeft:"2%",
       // boxShadow:'0px 3px 6px #00000029 !important',
    },

    table: {
       // minWidth: 650,
       
    },
    container: {
        display: 'flex',
        flexWrap: 'nowrap',
    },
    thData:{
      fontFamily: '\'Poppins\' !important',
      fontWeight: '700',
      fontSize: '12px',
      padding: '1%',
      backgroundColor: 'rgba(226, 220, 242, 0.38)',
      height:'70px',
      wordWrap: 'break-word',
      letterSpacing: '0.6px',
    },
    paper:{
      boxShadow:'0px 3px 6px #00000029 !important',
    },
    wait:{
      cursor:'wait',
      height:'13px !important',
      fontSize: '3px !important',
      marginLeft:'15%'
    },
   
}));

export default listviewUseStyles;