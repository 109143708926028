import React, { Component, Fragment } from "react";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Card, Chip } from "@material-ui/core";
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import "./style.scss";
import { withRouter } from "react-router";
import PaginationComponent from "./Pagination";
var TempArray = [];
/* let weekdays = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
]; */
// var today = new Date().getDay();
// var curTime =
//   new Date().getHours() +
//   ":" +
//   new Date().getMinutes() +
//   ":" +
//   new Date().getSeconds();
// var list = [];
// var iconChange = [];

class ParkingSpaceListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parkings: [],
      setopen: false,
      currentPage: 1,
      postsPerPage: props.postsPerPage,
      pageNumber: 0
    };

    //this.displayTariff = this.displayTariff.bind(this);
  }
  UNSAFE_componentWillMount = () => {
    // this.paginate(this.props.location.state?this.props.location.state:1)
    for (var i in this.props.cardData) {
      if (this.props.cardData[i].status.name === "APPROVED") {
        TempArray.push({
          Parkingname: this.props.cardData[i].name,
          //daysOfApproved: this.props.cardData[i].operatingHoursInfo,
        });
      }
    }
  };

  

  paginate = (pageNumber) => {
    
    this.setState({ currentPage: pageNumber });
  };
  // callback for pagination BE integration
  pageHandler = (number) => {
    let size = this.state.postsPerPage || 10;
    this.props.pageCountHandler(number,size)
    this.setState({
      pageNumber: number
    })
  }
  // function for pagination page change
  pageChangeHandler = () => {
    // console.log("page change: ",e.target.value)
  }
  // callback for search pagination
  searchHandler = (number) => {
    this.props.handleSearchPagination(number)
  }


  render() {
    // console.log("props: ",this.props)
   
    let pageTotalCount = this.props.totalCount / this.state.postsPerPage;
    // var mainObject = "";
    // iconChange = list.filter(function(i) {
    //   return i.icon === "live";
    // });
    // let statusColor;
    const indexOfLastPage = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPage = indexOfLastPage - this.state.postsPerPage;

    const currentPost = this.props.cardData.slice(
      indexOfFirstPage,
      indexOfLastPage
    );

    return (
      <Fragment>
        <div className="listview">
          <div className="listview__wrapper">
            <Card style={{ borderRadius: "8px" }}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead className="listview__wrapper_header">
                    <TableRow>
                      <TableCell style={{ width: "12%" }}>
                        Request Date
                      </TableCell>
                      <TableCell style={{ width: "18%" }} align="left">
                        Parking Space Name
                      </TableCell>
                      <TableCell style={{ width: "10%" }} align="left">
                        Type
                      </TableCell>
                      <TableCell style={{ width: "18%" }} align="left">
                        Organization Name
                      </TableCell>
                      <TableCell style={{ width: "5%" }} align="left">
                        Status
                      </TableCell>
                      {/* <TableCell
                        style={{ width: '13%' }}
                        align="left"
                      >
                        Address
                      </TableCell> */}
                      <TableCell style={{ width: "8%" }} align="left">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {
                    <TableBody>
                      {this.props.cardData.map((parking, index) => (
                        <TableRow id={parking.parkingId} key={index}>
                          <TableCell align="left" className="request_date">
                            {parking.currentStatus.name === 'REQUEST_SUBMITTED' ?(
                              moment(parking.requestSubmittedDate).format ('DD-MMM-YYYY')
                            ):(moment(parking.requestApprovedDate).format ('DD-MMM-YYYY'))}
                          </TableCell>

                          <TableCell className="parking_name" scope="row">
                            {parking.name}
                          </TableCell>

                          <TableCell align="left" className="parking_type">
                            {parking.parkingBusinessType.name}
                          </TableCell>
                          <TableCell align="left" className="org_name">
                            
                            {Object.keys(parking.org).length!== 0?(<span>{parking.org.name}</span>):(<span>-</span>)}
                          </TableCell>
                          <TableCell align="left" className="status">
                            <Chip
                              size="small"
                              label={parking.currentStatus.displayName}
                              style={{
                                backgroundColor: `${
                                  parking.currentStatus.displayName ===
                                  "Approved"
                                    ? "#28a745"
                                    : parking.currentStatus.displayName ===
                                      "Deactivated"
                                    ? "#dc3545"
                                    : parking.currentStatus.displayName ===
                                      "Additional info required"
                                    ? "#ffc107"
                                    : parking.currentStatus.displayName ===
                                    'Request Rejected'
                                    ? "brown"
                                    : parking.currentStatus.displayName ===
                                    'Draft'
                                    ? "gray"
                                    : parking.currentStatus.displayName ===
                                    'Request submitted'
                                    ? "blue"
                                    : "coral"
                                }`,
                                color: "#fff",
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              color: "blue",
                              textAlign: "center",
                            }}
                          >
                            <Button
                              className="View_button"
                              // to={
                              //   "/portal/parking-details/" + parking.parkingId
                              // }
                              style={{
                                textDecoration: "none",
                                color: "rgb(3, 169, 243)",
                                fontSize: "13px",
                                fontWeight: "700",
                                cursor: "pointer",
                              }}
                              onClick={
                                ()=>{
                                  sessionStorage.setItem("prevPagenumber", this.props.pageNumber + 1);
                                  this.props.history.push({ 
                                    pathname: `/portal/parking-details/${parking.parkingId}`,
                                    state: {
                                      pageNumber: this.props.pageNumber,
                                      searchFlag: this.props.searchFlag
                                    }
                                  });
                                }
                              }
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  }
                </Table>
              </TableContainer>
            </Card>
          </div>
        </div>

        <div className="navConfig">
          
          <div className="divCurrentPage">  
              <Grid item container 
                direction="row"
                style={{visibility:"hidden"}}
              >
                <Grid item style={{marginRight:"0.5em"}}>
                  <p>Page</p>
                </Grid>
                <Grid item className="styleCurrentPage">
                  
                    
                      <input
                        value={this.state.currentPage}
                        onChange={this.pageChangeHandler}
                      >
                        
                      </input>
                     
                    / 
                    <p>{Math.floor(pageTotalCount)}</p>
                </Grid>

              </Grid>
            </div>
          <PaginationComponent
            postsPerPage={this.props.postsPerPage}
            // totalPosts={this.props.cardData.length}
            totalPosts={this.props.totalCount}
            paginate={this.paginate}
            currentPage={this.state.currentPage}
            pageHandler={this.pageHandler}
            searchFlag={this.props.searchFlag}
            searchHandler={this.searchHandler}
            searchFlagHandler={this.props.searchFlagHandler}
            pageNumber={this.props.pageNumber}
            curPageSetter={this.props.curPageSetter}
            curPage={this.props.curPage}
          />
        </div>
      </Fragment>
    );
  }
}

export default withRouter(ParkingSpaceListView);