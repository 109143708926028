/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { Fragment } from 'react';
import './style.scss';
import { breadcrumbs } from '../../Constants/appConstants';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const Breadcrumbs = (props) => {
  const arr = breadcrumbs[props.breadcrumbsKey] || [];
  return (
    <Fragment>
      {breadcrumbs[props.breadcrumbsKey] && (
        <div className="breadcrumbs">
          <h3>{arr.length ? arr[arr.length - 1].name : ''}</h3>
          <ul className="breadcrumbs__wrapper">
            {breadcrumbs[props.breadcrumbsKey].map((item, index) => {
              return (
                <li key={index}>
                  {item.link ? (
                    <Link to={item.link}>{item.name}</Link>
                  ) : (
                    <p>{item.name.split('-')[0]}</p>
                  )}
                  {item.link && <span> &gt; </span>}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </Fragment>
  );
};

export default connect(
  (state) => ({
    breadcrumbsKey: state.app.breadcrumbsKey,
  }),
  null,
)(Breadcrumbs);
