// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. 
import { makeStyles } from '@material-ui/core/styles'

const detailsUseStyles = makeStyles(theme=>({
    selectNavCard:{
        height:"4em",width:"5em",border:0,borderRadius:"22px",
    },
    tableGrid:{
        marginTop:"2em",marginBottom:"2em",width:"96%",height:"50%",marginLeft:"1.5em"
    },
    table: {
        minWidth: 650,
    },
    editButton:{
        ...theme.typography.view,
        borderRadius: 50,
        height: "2.5em",
        width: "6em",
        fontSize:"0.61rem",
        border:"none",
        fontWeight:"200",
        textTransform:"none",
        backgroundColor:"#e46a76",
        color:"#fff"
      },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    tableHeader: {
        fontSize: "12px",
        fontWeight: "bolder",
        // width: 17%;
    },
    detailsCard: {
        zIndex: 0,
        boxShadow: theme.shadows[10],
        marginTop:"16px",
        width: "96%",
        height: "50%",
        marginLeft:"2em"
    },
    statusChangeButton:{
        ...theme.typography.view,
        borderRadius: 50,
        height: "2.5em",
        width: "10em",
        fontSize:"0.71rem",
        border:"none",
        fontWeight:"600",
    }, 
    backButton:{
       color:'#fff',
       borderRadius:'20px',
       backgroundColor:'#02a6dc '
    },
    dialogButton:{
        ...theme.typography.view,
        borderRadius: 50,
        color: "#fff",
        height: "2.5em",
        width: "7em",
        fontSize:"0.71rem",
        border:"none",
        fontWeight:"600",
        marginBottom:"2em"
    },
    uploadDialogTitle:{
        fontWeight:"700",borderBottom:"1px solid #808080",textAlign:"left",
    },
    superAdminComment: {
        fontWeight:"600",fontSize:"0.60rem"
    },
    superAdminCommentMargin:{
        marginTop:"3.5em",marginBottom:"2em"
    },
    labelMargin:{
        marginTop:"1.5em"
    },
    createdDateGrid: {
        marginLeft:"2em",marginTop:"1.5em"
    },
    createdDatep:{
        fontSize:"0.65rem"
    },
    textArea:{
        width:"100%",height:"10em",borderRadius:"5px"
    },
    statusText:{
        fontWeight:"600",fontSize:"0.95rem",marginLeft:"2em",color:"#F49D3E",marginTop:"1em"
    },
    statusLabel:{
        fontWeight:"600",fontSize:"0.60rem",marginLeft:"2em"
    },
    complaintDescription: {
        fontSize:"0.65rem",
        maxWidth:"12em",
        height:"4em",
        overflowX: "auto",
        overflowY: "auto"
    },
    gridComplaintDescription:{
        marginLeft:"2em",width:"50%",marginTop:"1.5em"
    },
}));

export default detailsUseStyles;