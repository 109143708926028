/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import './style.scss';

var storeEditData = [
  {
    startDate: '0000-00-00',
    endDate: '0000-00-00',
    openingTime: '09:00:00',
    closingTime: '21:00:00',
  },
];
var counting = 1;
const CustomAvailability = (props) => {
  const [arraySplice, setSplice] = useState(false);

  useEffect(() => { }, [arraySplice]);
  var appendedArray = [
    {
      startDate: '0000-00-00',
      endDate: '0000-00-00',
      openingTime: '09:00:00',
      closingTime: '21:00:00',
    },
  ];

  if (storeEditData.length < 5 && props.count > 0) {
    for (var i in appendedArray) {
      if (storeEditData.length < 5) {
        counting = counting + 1;
        storeEditData.push(appendedArray[i]);
      }
    }
  }
  if (counting > props.count) {
    storeEditData.pop();
    counting = counting - 1;
  }

  const deleteDiv = (e) => {
    setSplice(!arraySplice);
    if (e.target.id > -1) {
      storeEditData.splice(e.target.id, 1);
    }
    props.updateChildrenState('step2', 'customData', storeEditData);
    return storeEditData;
  };
  // console.log(storeEditData);
  return (
    <div className="CustomAvailability">
      {storeEditData.map((item, index) => {
        var startDate = item.startDate.split('-').reverse().join('-');
        var endDate = item.endDate.split('-').reverse().join('-');
        return (
          <div key={index}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell >
                      <input
                        type="date"
                        className="CustomAvailability__wrapper_form_timeside_opening_input"
                        value={startDate}
                        onChange={(evt) => {
                          let obj = [...storeEditData];
                          obj[
                            index
                          ].startDate = startDate = evt.target.value
                            .split('-')
                            .reverse()
                            .join('-');
                          // console.log(obj);
                          props.updateChildrenState(
                            'step2',
                            'customData',
                            obj,
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <span>to</span>
                    </TableCell>
                    <TableCell >
                      <input
                        type="date"
                        className="CustomAvailability__wrapper_form_timeside_opening_input"
                        value={endDate}
                        onChange={(evt) => {
                          let obj = [...storeEditData];
                          obj[
                            index
                          ].endDate = endDate = evt.target.value
                            .split('-')
                            .reverse()
                            .join('-');
                          // console.log(obj);
                          props.updateChildrenState(
                            'step2',
                            'customData',
                            obj,
                          );
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <input
                        type="time"
                        className="OperationalTimings__wrapper_form_timeside_opening_input"
                        defaultValue="09:00:00"
                        value={item.openingTime}
                        onChange={(evt) => {
                          let obj = [...storeEditData];
                          obj[index].openingTime =
                            evt.target.value + ':00';
                          props.updateChildrenState(
                            'step2',
                            'customData',
                            obj,
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell >
                      <span>to</span>
                    </TableCell>
                    <TableCell >
                      <input
                        type="time"
                        defaultValue="09:00:00"
                        className="CustomAvailability__wrapper_form_timeside_closing_input"
                        value={item.closingTime}
                        onChange={(evt) => {
                          let obj = [...storeEditData];
                          obj[index].closingTime =
                            evt.target.value + ':00';
                          props.updateChildrenState(
                            'step2',
                            'customData',
                            obj,
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell >
                      <input
                        className="deleteBtn"
                        type="submit"
                        name="delete"
                        value="delete"
                        id={index}
                        onClick={deleteDiv}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      })}
    </div>
  );
};
export default CustomAvailability;
