// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights.

import React from 'react';

const FlexiText = ({ text, length = 20 }) => {
    const [showLess, setShowLess] = React.useState(true);
  
    if (text.length < length) {
      return <p style={{fontSize:"0.75rem",fontWeight:500}}>{text}</p>;
    }
  
    return (
      <div>
        <span
          style={{
            
            fontSize:"0.75rem",
            fontWeight:500,
            wordBreak:'break-all'
          }}
          dangerouslySetInnerHTML={{
            __html: showLess ? `${text.slice(0, length)}...` : text,
          }}
        ></span>
        <a
          style={{ 
            color: "blue", 
            cursor: "pointer", 
            fontSize:"0.75rem", 
            fontWeight:500 
          }}
          onClick={() => setShowLess(!showLess)}
        >
          &nbsp;{showLess ? "More" : "Less"}
        </a>
      </div>
    );
  };
  
  export default FlexiText;