import React, { Component } from 'react';
import './style.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ParkingFacilitiesServices from './../ParkingFacilitiesServices';
import SlotCount from './../SlotCount';
import OperationTimings from './../OperationTimings';
import SolAdminDetails from'./../SolAdminDetails';
import { updateAuth } from '../../../../../redux/modules/app/actions';
import { withRouter } from 'react-router';
import httpRequest from './../../../../../Utils/httpRequest';
import { connect } from 'react-redux';
import { updateBreadcrumbs } from '../../../../../redux/modules/app/actions';
import {
  getCookie
} from '../../../../../Utils/cookies';
import { updateToasterConfig } from './../../../../../../src/redux/modules/app/actions';

// let parkingId = null;

class ParkingDetailsTab extends Component {

  constructor(props) {
    super(props);
    this.state = {
      imgLink: '',
      parkingDetail: { name: '' },
      pageImg: [],
      imgData: [],
      passPop: false,
      setopen: false,
    };
  }




  gatewayDisplaySuccess = () => {
    // console.log(gateWayresponse);
  };


  activatePSMethod = () => {
    // parkingId = this.props.match.params.id;
    httpRequest
      .patch(
        'activatePS',
        {
          Authorization: 'Bearer ' + getCookie('auth'),
          realmname: getCookie('realmName'),
        },
        this.props.updateToasterConfig({
          show: true,
          message: 'The Parking has been activated',
          color: 'rgb(103, 181, 97)',
        }),
        // console.log(this.props.match.params.id),
        this.errorCallback,

        {
          parkingId: this.props.match.params.id,
        },
      )
      .then(() => { })
      .catch(() => { });
  };

  // updateMe = () => {
  //   this.interval = setInterval(() => {
  //     this.getDetails();
  //   }, 10000);
  // };

  componentDidMount() {
    // window.scrollTo(0, 0);
    this.props.updateBreadcrumbs('parkingDetails');
  }

  // componentWillUnmount = () => {
  //   clearInterval(this.interval);
  // };

  

  render() {
    return (
      <div className="DetailParking">
                  <SolAdminDetails
                  address={this.props.address}
                  solAdminId ={this.props.solAdminId}
                  />
                  <SlotCount
                  parkingId={this.props.parkingId}
                  gateway={this.props.gateway}
                  vehicleTypeInfo={this.props.vehicleTypeInfo}
                   />
                <OperationTimings
                  timings={this.props.timings}
                  customData={this.props.customData}
                  statusName={this.props.statusName}
                 />
                <ParkingFacilitiesServices
                  facilities={this.props.facilities}
                  services={this.props.services}
                  /> 
              {/*   <ServicesNearBy
                  parkingId={this.props.match.params.id}
                  rating={parkingDetail?.rating}
                />   */}
             
          
       
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      auth: state.app.auth,
    }),
    { updateAuth, updateBreadcrumbs, updateToasterConfig },
  )(ParkingDetailsTab),
);
