//data for row header for admin list
export const adminListHeaderCell = [
    { id: 'sno', label: 'S.no', width: '5%' },
    { id: 'name', label: 'Solution Admin Name', width: '13%' },
    {id: 'admin_type', label: 'Admin Type', width: '9%'},
    { id: 'orgname', label: 'ORG Name', width: '12%' },
    { id: 'address', label: 'Address', width: '17%' },
    { id: 'email', label: 'Email ID', width: '15%' },
    { id: 'contact_no', label: 'Contact',width: '10%' },
    { id: 'status', label: 'Status',width: '7%' },
    { id: 'action', label: 'Actions',width: '12%' }
];


  //data for row header for Org list
  export const orgListHeadCells = [
    { id: "sno", label: "S.No", width: '10%' },
    { id: "organization_ame", label: "Organization Name", width: '30%' },
    { id: "organization_acronym", label: "Organization Acronym", width: '15%' },
    { id: "created_by", label: "Created By", width: '30%' },
    { id: "actions", label: "Actions", width: '15%' }
  ];

  //data for row header for device coupling
  export const coupleDeviceListHeadCell = [
    { id: "sno", label: "S.No", width: '8%' },
    { id: "organization_name", label: "Organization Name", width: '20%' },
    { id: "device_name", label: "Device Name", width: '20%' },
    { id: "parking_space_name", label: "Parking Space Name", width: '30%' },
    { id: "device_status", label: "Device Status", width: '12%' },
    { id: "actions", label: "Actions", width: '10%' }
  ];