import React from "react";
import "./dataPrivacy.scss";
import PropTypes from "prop-types";
import { Container, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import ribbon from "../../Assets/icons/ribbon.svg";
import bosch_logo from "../../Assets/icons/bosch_logo.svg";
import FileViewer from "../../Components/pdf/FileViewer";
import PZ_NoticeKit from "../../Assets/pdf/PZ_Data_Protection_Notice_Common_V2.3_15122023.pdf";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    // paddingBottom:'3.1rem'
  },
  ribbonImage: {
    backgroundImage: `url(${ribbon})`,
    width: "100%",
    height: "0.9em",
    backgroundPosition: "center",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
  },
  indicator: {
    backgroundColor: "hsl(204, 100%, 28%)",
  },
  colorPrimary: {
    color: "#000",
    backgroundColor: "#fff",
    boxShadow: "none",
    borderBottom: "1px solid hsl(0, 0%, 87%)",
    marginTop: "1rem",
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //   useEffect(() => {
  //     document.querySelector("link[rel*='icon']").href = "bosch-brand-logo.png";
  //     document.title = "Data Protection Note";
  //     document.querySelector('meta[name="theme-color"]').setAttribute('content','#D3D3D3');
  //    },[])

  return (
    <div className={classes.root}>
      <div className={classes.ribbonImage} style={{ position: "fixed" }}></div>
      <Paper>
        <Container>
          <Grid>
            <div className="logo">
              <img
                src={bosch_logo}
                alt="Logo"
                style={{
                  maxWidth: "140px",
                  maxHeight: "140px",
                  overflowX: "hidden",
                }}
              />
            </div>
          </Grid>
          <AppBar position="static" className={classes.colorPrimary}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              classes={{
                indicator: classes.indicator,
              }}
            >
              <Tab label="Corporate Information" {...a11yProps(0)} />
              <Tab label="Data Protection Notice" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <p style={{ left: "12px" }} className="tab-heading">
              Corporate Information
            </p>
            <br />

            <pre>
              <strong>Name and address</strong>
              <div>
                {`
    Automobility Services and Solutions Private Limited 

    Responsible for content
    Automobility Services and Solutions Private Limited 

    Telephone number
    +91 80 22 22 00 88

    Your contact to Bosch
    `}
                <a
                  target="_blank"
                  href="mailto:bosch.corporate@in.bosch.com"
                  rel="noopener noreferrer"
                >
                  bosch.corporate@in.bosch.com
                </a>
                {`

    Register Entries
    U50102KA2016FTC086337`}
              </div>
            </pre>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FileViewer pdfFile={PZ_NoticeKit} />

            {/* <p className="tab-heading">Data Protection Notice</p><br/>
      <h2>1. Data Protection Notice</h2>
      <p>Bosch Ltd (Hereinafter “Bosch”) welcomes you to our internet pages and mobile
applications (together also referred to as “Online Offers”). We thank you for your interest
in our company and our products.</p><br/>
<h2>2. Bosch respects your privacy</h2><br/>
<p>The protection of your privacy throughout the course of processing personal data as
well as the security of all business data are important concerns to us. We process
personal data that was gathered during your visit of our Online Offers confidentially and
only in accordance with statutory regulations.<br/>
Data protection and information security are included in our corporate policy.</p><br/>
<h2>3. Controller</h2>
<p>Bosch is the controller responsible for the processing of your data; exceptions are
outlined in this data protection notice.<br/><br/>
Our contact details are as follows:<br/><br/>
Bosch Ltd,<br/>
P.O Box 3000,<br/>
Hosur Road,<br/>
Adugodi,<br/>
Bengaluru - 560030,<br/>
Karnataka,<br/>
India.</p><br/>
<h2>4. Collection, processing and usage of personal data</h2>
        <h2>4.1 Processed categories of data</h2>The following categories of data are processed:
        <p> The following categories of data are processed:</p>
        <ul>
            <li> 
                Personal data (First Name, Last Name, Mobile Number, Device ID, current
                location, Email-ID, Address, Photo)
            </li>
            <li>Miscellaneous: User Location Information</li>
            <li>Communication and notification</li>
            
        </ul>

        <h2>4.2 Principles</h2>
        <p>
            Personal data consists of all information related to an identified or identifiable natural
            person, this includes, e.g. names, addresses, phone numbers, email addresses,
            contractual master data, contract accounting and payment data, which is an expression
            of a person&apos;s identity.
        </p>
        <p>
            We collect, process and use personal data (including IP addresses) only when there is
            either a statutory legal basis to do so or if you have given your consent to the
            processing or use of personal data concerning this matter, e.g. by means of registration.
        </p><br/>


        <h2>4.3 Processing purposes and legal basis</h2>
        <p>
            We as well as the service providers commissioned by us; process your personal data
            for the following processing purposes:
        </p>
        <p>4.3.2 Provision of these Online Offers</p>
        <p>
            Legal basis: Fulfillment of contractual obligations. Additionally digital consent obtained
            during first login by every data subject.
        </p>
        <p>4.3.6 Resolving service disruptions as well as for security reasons.</p>
        <p>
            Legal basis: Fulfillment of our legal obligations within the scope of data security and
            legitimate interest in resolving service disruptions as well as in the protection of our
            offers.
        </p><br/>

        <h2>4.4 Registration</h2>
        <p>
            If you wish to use or get access to benefits requiring to enter into the fulfillment of a
            contract, we request your registration. With your registration we collect personal data
            necessary for entering into the fulfillment of the contract (e.g. First Name, Last Name,
            Mobile Number, Device ID, current location, Email-ID, Address, Photo, if applicable) as
            well as further data on voluntary basis, if applicable. Mandatory information is marked
            with an *.
        </p><br/>

        <h2>4.5 Log files</h2>

        <p>
            Each time you use the internet, your browser is transmitting certain information which
            we store in so-called log files.
        </p>
        <p>
            We store log files to determine service disruptions and for security reasons (e.g., to
            investigate attack attempts) for a period of 6 months and delete them afterwards. Log
            files which need to be maintained for evidence purposes are excluded from deletion
            until the respective incident is resolved and may, on a case-by-case basis, be passed
            on to investigating authorities.
        </p>
        <p>
            Log files are also used for analysis purposes (without the IP address or without the
            complete IP address)
        </p>
        <p>In log files, the following information is saved:</p>
        <ul>
            <li>
                IP address (internet protocol address) of the terminal device used to access the
                Online Offer;
            </li>
            <li>
                Internet address of the website from which the Online Offer is accessed (socalled
                URL of origin or referrer URL);
            </li>
            <li>Name of the service provider which was used to access the Online Offer;</li>
            <li>Name of the files or information accessed;</li>
            <li>Date and time as well as duration of recalling the data;</li>
            <li>Amount of data transferred;</li>
            <li>
                Operating system and information on the internet browser used, including addons
                installed (e.g., Flash Player);
            </li>
            <li>http status code (e.g., “Request successful” or “File requested not found”).</li>

        </ul>
        
        <h2>4.7 Children</h2>
        <p>This Online Offer is not meant for children under 16 years of age.</p><br/>
        <h2>4.9.1 Service providers (general)</h2>
        
        <p>
            We involve external service providers with tasks such as sales and marketing services,
            contract management, payment handling, programming, data hosting and hotline
            services. We have chosen those service providers carefully and monitor them on a
            regular basis, especially regarding their diligent handling of and protection of the data
            that they store. All service providers are obliged to maintain confidentiality and to
            comply with the statutory provisions. Service providers may also be other Bosch group
            companies.
        </p><br/>

        <h2>4.11 Duration of storage, retention periods</h2>

        <p>
            Principally, we store your data for as long as it is necessary to render our Online Offers
            and connected services or for as long as we have a legitimate interest in storing the
            data (e.g. we might still have a legitimate interest in postal mail marketing after
            fulfillment of our contractual obligations). In all other cases we delete your personal data
            with the exception of data we are obliged to store for the fulfillment of legal obligations
            (e.g. due to retention periods under the tax and commercial codes we are obliged to
            have documents such as contracts and invoices available for a certain period of time).
        </p><br/>
        <h2>8. Usage of Cookies</h2>
        <p>
            In the context of our online service, cookies and tracking mechanisms may be used.
            Cookies are small text files that may be stored on your device when visiting our online
            service.
        </p>
        <p>
            Tracking is possible using different technologies. In particular, we process information
            using pixel technology and/or during log file analysis.
        </p><br/>
        <h2>8.1 Categories</h2>
        <p>
            We distinguish between cookies that are mandatorily required for the technical functions
            of the online service and such cookies and tracking mechanisms that are not
            mandatorily required for the technical function of the online service.
        </p>
        <p>
            It is generally possible to use the online service without any cookies 
            that serve nontechnical purposes.
        </p><br/>

        <h2>8.1.1 Technically required cookies</h2>
        <p>
            By technically required cookies we mean cookies without those the technical provision
            of the online service cannot be ensured. These include e.g. cookies that store data to
            ensure smooth reproduction of video or audio footage.
        </p>
        <p>
            Such cookies will be deleted when you leave the website/webportal which are
            mentioned below.
        </p>
        <ol>
            <li>Auth: Access Token will be stored for the session purpose</li>
            <li>Refresh: Refresh Token will be stored for the session purpose</li>
            <li>UserID: User identification will be stored for the better performance</li>
            <li>TokenExpTime: Token expiry time stored to validate the session</li>
            <li>cliId : Client ID technical cookie stored to validate the session</li>
            <li>cliSec: Client secrete ID technical cookie stored to validate the session</li>

        </ol>

        <h2>8.3.2 Name: Google Analytics</h2>
        <p>Provider: Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA</p>
        <p>
            Function: Analysis of user behavior (page retrievals, number of visitors and visits,
            downloads), creation of pseudonymous user profiles based on cross-device
            information of logged-in Google users (cross-device tracking), enrichment of
            pseudonymous user data with target group-specific information provided by
            Google, retargeting, UX testing, conversion tracking and retargeting in
            conjunction with Google Ads
        </p><br/>

        <h2>8.4 Management of cookies and tracking mechanisms</h2>
        <p>
            You can manage your cookie and tracking mechanism settings in the browser and/or
            our privacy settings.
        </p>
        <p>Note: The settings you have made refer only to the browser used in each case.</p><br/>

        <h2>8.4.1 Deactivation of all cookies</h2>
        <p>
            If you wish to deactivate all cookies, please deactivate cookies in your browser settings.
            Please note that this may affect the functionality of the website.
        </p><br/>

        <h2>9. Usage of our mobile applications</h2>
        <p>
            In addition to our Online Offers, we offer mobile applications (&quot;Apps&quot;), which you can
            download to your mobile device. Beyond the data collected on websites, we collect
            additional personal data through our apps that specifically result from the usage of a
            mobile device. This is subject to your prior consent.
            Additional document: Declaration of consent
        </p><br/>

        <h2>9.1 Processing of your location data</h2>
        <p>
            Our offers also include so-called location based services through which we provide you
            with special offers that are tailored to your respective location. To offer this app function,
            we collect the last three GPS locations delivered by the mobile device and your IP
            address when you consent. We do not create a movement profile. You can deactivate
            this function in the respective app&apos;s settings or in the settings of the operating system of
            your mobile device or pause it temporarily without affecting the basic functionality of the
            app.
        </p>

        <p>
            Prior to using location data, it must be ruled out that this is not a telecommunications
            service (TC service). This is necessary as TC services are subject to the special
            restrictions of s. 98 of the German Telecommunications Act (TKG) (Processing only for
            services with additional purpose and only upon separate, explicit and written consent).
        </p>
        <p>Additional document: Declaration of consent</p>
        <p>
            Thus, the usage of this module requires an examination by the decentralized subject
            matter experts group with approval by the central department responsible.
        </p><br/>

        <h2> 9.3.1 Google Analytics for mobile applications</h2>
        <p>
            Google Analytics is provided by Google Inc., 1600 Amphitheatre Parkway, Mountain
            View, CA 94043, USA (&quot;Google&quot;). We use Google Analytics with the additional function
            offered by Google to anonymize IP addresses. While doing so, Google already shortens
            IPs within the EU in most cases and only does so in the United States in exceptional
            cases, while always saving shortened IPs only.
        </p>
        
        <h2>Google Maps & API Services for mobile applications</h2>
        <p>
            Google Maps is provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View,
            CA 94043, USA (&quot;Google&quot;). We use Google Map services as installed on your mobile
            phone and linked to your phone number, to display the map, display the parking spaces
            , searching places , latitude longitude to physical address conversion, route , distance,
            ETA from source and destination. The Google Maps Services are subject to applicable
            Google Maps Terms of Use in the version available at the time of use of Google Maps
            Services on your mobile phone.
        </p><br/>
        <h2>Poppins font for Web application</h2>
        <p>
            Poppins font is provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View,
            CA 94043, USA (&quot;Google&quot;).
        </p>
        <a href="https://fonts.google.com/specimen/Poppins?query=pop#standard-styles" target="_blank" rel="noopener noreferrer">
            https://fonts.google.com/specimen/Poppins?query=pop#standard-styles
        </a>
        <p>We use Fonts services to display the text on web browsers</p><br/>

        <h2>Firebase crash analysis</h2>
        <p>
            Bosch uses Firebase application for performing crash analysis, whenever it occurs. For
            performing such crash analysis, Firebase may access, process, transfer, store and
            reproduce certain User Data. The type of data that is collected and processed and the
            purposes for which it is used is detailed in the following links
        </p>

        <a href="https://support.google.com/firebase/answer/6318039?hl=en" target="_blank" rel="noopener noreferrer">
            https://support.google.com/firebase/answer/6318039?hl=en
        </a>
        <p>and</p>
        <a href="https://support.google.com/firebase/answer/6317486" target="_blank" rel="noopener noreferrer">
            https://support.google.com/firebase/answer/6317486.
        </a>

        <p>
            User hereby permits Firebase acess and use of your User Data for the purpose of
            performing crash analysis.
        </p><br/>

        <h2>9.4 App Store</h2>
        <h2>Data processing by App Store operators</h2>
        <p>
            We do not collect data, and it is beyond our responsibility, when data, such as
            username, email address and individual device identifier are transferred to an app store
            (e.g., Google Play by Google, App Store by Apple, Galaxy Apps Store by Samsung)
            when downloading the respective application. We are unable to influence this data
            collection and further processing by the App Store as controller.
        </p><br/>
        
        <h2>16. External links</h2>
        <p>
            Our Online Offers may contain links to internet pages of third parties, in particular
            providers who are not related to us. Upon clicking on the link, we have no influence on
            the collecting, processing and use of personal data possibly transmitted by clicking on
            the link to the third party (such as the IP address or the URL of the site on which the link
            is located) as the conduct of third parties is naturally beyond our control. We do not
            assume responsibility for the processing of personal data by third parties.
        </p><br/>

        <h2>17. Security</h2>
        <p>
            Our employees and the companies providing services on our behalf, are obliged to
            confidentiality and to compliance with the applicable data protection laws.
            We take all necessary technical and organizational measures to ensure an appropriate
            level of security and to protect your data that are administrated by us especially from the
            risks of unintended or unlawful destruction, manipulation, loss, change or unauthorized
            disclosure or unauthorized access. Our security measures are, pursuant to
            technological progress, constantly being improved.
        </p><br/>

        <h2>18. User rights</h2>
        <p>
            To enforce your rights, please use the details provided in the Menu Support section.
            In doing so, please ensure that an unambiguous identification of your person is
            possible.
        </p>

        <h2>Right to information and access</h2>
        <p>
            You have the right to obtain confirmation from us about whether or not your personal
            data is being processed, and, if this is the case, access to your personal data.
            </p>
        <h2>Right to correction and deletion</h2>
        <p>
            You have the right to obtain the rectification of inaccurate personal data. As far as
            statutory requirements are fulfilled, you have the right to obtain the completion or
            deletion of your data.
        </p>
        <p>
            This does not apply to data which is necessary for billing or accounting purposes or
            which is subject to a statutory retention period. If access to such data is not required,
            however, its processing is restricted (see the following).
        </p>

        <h2>Restriction of processing</h2>
        <p>
            As far as statutory requirements are fulfilled you have the right to demand for restriction
            of the processing of your data.
        </p>
        <h2>Data portability</h2>
        <p>
            As far as statutory requirements are fulfilled you may request to receive data that you
            have provided to us in a structured, commonly used and machine-readable format or –
            if technically feasible –that we transfer those data to a third party.
        </p>

        <h2>Objection to data processing based on the legal basis of “legitimate interest”</h2>
        <p>
            In addition, you have the right to object to the processing of your personal data at any
            time, insofar as this is based on “legitimate interest”. We will then terminate the
            processing of your data, unless we demonstrate compelling legitimate grounds
            according to legal requirements which override your rights.
        </p>
        <h2>Withdrawal of consent</h2>

        <p>
            In case you consented to the processing of your data, you have the right to revoke this
            consent at any time with effect for the future. The lawfulness of data processing prior to
            your withdrawal remains unchanged.
        </p>

        <h2>19. Right to lodge complaint with supervisory authority:</h2>
        <p>
            You have the right to lodge a complaint with a supervisory authority. You can appeal to
            the supervisory authority which is responsible for your place of residence or your state
            of residency or to the supervisory authority responsible for us. This is:</p>
        <p>
            DPO.India@in.bosch.com<br></br>
            Data Protection Officer Bosch India<br></br>
            Post Box No 3000 Hosur Road Adugodi<br></br>
            Bengaluru, Karnataka – 560 030<br></br>
            India
        </p>

        <h2>20. Changes to the Data Protection Notice</h2>
        <p>
            We reserve the right to change our security and data protection measures. In such
            cases, we will amend our data protection notice accordingly. Please, therefore, notice
            the current version of our data protection notice, as this is subject to changes.
        </p>
        <h2>21. Contact</h2>
        <p>If you wish to contact us, please find us at the address stated in the &quot;Controller&quot; section.</p>
        <p>To assert your rights and to notify data protection incidents please use the following link:</p>
        <a href="https://www.bkms-system.net/bosch-dataprotection" target="_blank" rel="noopener noreferrer">
            https://www.bkms-system.net/bosch-dataprotection
        </a>

        <p>
            For suggestions and complaints regarding the processing of your personal data we
            recommend that you contact our data protection officer:
        </p>
        <p>
            Data Protection Officer<br></br>
            Information Security and Privacy (C/ISP)<br></br>
            Robert Bosch GmbH<br></br>
            P.O. Box 30 02 20<br></br>
            70442 Stuttgart, GERMANY
        </p>
        <p>or</p>
        <p>
            mailto: DPO@bosch.com<br></br>
            <b>Effective date: [2020.12.18]</b><br></br>
            <b>Document version: PZ_Data_Protection_NoticeV1.1</b>
        </p> */}
          </TabPanel>
        </Container>
      </Paper>
      <div className={classes.ribbonImage}></div>
    </div>
  );
}
