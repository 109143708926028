// Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, 
// editing, distribution, as well as in the event of applications for industrial property rights. 
import React,{ useState,useEffect,useCallback } from 'react'
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';

import { useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { updateBreadcrumbs } from '../../redux/modules/app/actions';
import forceUpdateUseStyles from './style'
import httpRequest from '../../Utils/httpRequest'
import Loader from '../../Components/Preloader';
import { Container } from '@material-ui/core';

//@component
//ForceUpdate 
function ForceUpdate(props) {
    const classes = forceUpdateUseStyles()
    const theme = useTheme()
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
    
    const [ open,setOpen ] = useState(false)
    const [ isLoading, setIsLoading ] = useState(false)
    const [ select1,setSelect1 ] = useState("")
    const [ select2,setSelect2 ] = useState("")
    const [ select3,setSelect3 ] = useState("")
    const [ selectedVersion,setSelectedVersion ] = useState("")
    const [ radioValue,setRadioValue] = useState("");
    const [ currentAndroid,setCurrentAndroid ] = useState(0)
    const [ currentiOS,setCurrentiOS ] = useState(0)
    const [ openAlert,setOpenAlert ] = useState(false)
    const [ severity,setSeverity ] = useState("")
    const [ alertMessage,setAlertMessage ] = useState("") 

    //alert toaster function
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

      //function for alert toaster
      const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            setOpen(false);
            setSelect1("");setSelect2("");setSelect3("");
            setRadioValue("")
          return;
        }
        setOpenAlert(false)
        setOpen(false);
        setSelect1("");setSelect2("");setSelect3("");
        setRadioValue("")
      }

    // function for current version getter
    const currentVersionGetter = () => {  
        setIsLoading(true)
        httpRequest
          .get(
            'currentVersionGetter',
            {
                'content-type': 'application/json', 
            },
            currentVersionSuccess,
            errorCallback,
            {
              
            },
          )
    };
    //success callback for versiongetter
    const currentVersionSuccess = useCallback(jsonResponse => {
        if (jsonResponse) {
            // console.log(jsonResponse.data)
            setIsLoading(false)
            jsonResponse.data.map((data)=>{
                if (data.realmName === "Rlm_ROBOIN_Parking_B2CApp_IN") {
                    setCurrentAndroid(data.currentAndroidVersion)
                    setCurrentiOS(data.currentIOSVersion)
                }
            })  
        }      
    },[])

    //api call to force update  
    const forceUpdateHandler = ()=>{
        setOpen(false)
            if (radioValue === "Android") {
                setIsLoading(true)
                httpRequest.post(
                    'currentVersionGetter',
                        [
                            {
                                realmName:"Rlm_ROBOIN_Parking_B2CApp_IN",                    
                                "currentAndroidVersion":`${select1}.${select2}.${select3}`,
                            }
                        ],                 
                    {},
                    successCallback,
                    errorCallback,
                    {},
                )
            } 
            if (radioValue === "iOS") {
                setIsLoading(true)
                httpRequest.post(
                    'currentVersionGetter',
                        [
                            {
                                realmName:"Rlm_ROBOIN_Parking_B2CApp_IN",                    
                                "currentIOSVersion":`${select1}.${select2}.${select3}`,
                            }
                        ],                 
                    {},
                    successCallback,
                    errorCallback,
                    {},
                )
            }
        setSelect1("");setSelect2("");setSelect3("");
        setRadioValue("")    
    }
    //success callback for force update
    const successCallback = jsonResponse => {
        if (jsonResponse) {
            setIsLoading(false)
            setOpenAlert(true)
            setSeverity("success")
            setAlertMessage("Successfully force updated.")
            setCurrentAndroid(0)
            setCurrentiOS(0)
            setIsLoading(true)
            currentVersionGetter()
        }      
    }
    //error callback for api calls
    const errorCallback = error => {
        if (error) {
        setIsLoading(false)
        setOpenAlert(true)
        setOpen(false)
        setSeverity("error")
        setAlertMessage("Error updating.Try again!:",error)
        }
    }

    //function for radio button handler
    const radioHandler = (event) => {
        setRadioValue(event.target.value);
        setSelectedVersion(select1+"."+select2+"."+select3)
    };

    //version value array
    const values = [0,1,2,3,4,5,6,7,8,9]  
    
    //function for popup handler
    const cancelHandler = () => {
        setOpen(false);
    }

    //function to handle submit button checks
    const submitHandler = () => {
        if (selectedVersion === currentAndroid && radioValue === "Android") {
            setOpenAlert(true)
            setOpen(false)
            setSeverity("Error")
            setAlertMessage("Invalid version! Selected Android version equal to current version")
        } 
        if (selectedVersion === currentiOS && radioValue === "iOS") {
            setOpenAlert(true)
            setOpen(false)
            setSeverity("Error")
            setAlertMessage("Invalid version! Selected iOS version equal to current version")
        }
        if ( selectedVersion !== "0.0.0" && selectedVersion !== currentAndroid && radioValue === "Android") {
            setOpen(true)
        }
        if ( selectedVersion !== "0.0.0" && selectedVersion !== currentiOS && radioValue === "iOS") {
            setOpen(true)
        }
        if (selectedVersion === "0.0.0") {
            setOpenAlert(true)
            setOpen(false)
            setSeverity("Error")
            setAlertMessage("Invalid version! Selected version is 0.0.0")
        }
    }

    //adding component to breadcrumb and getting current mobile device version
    useEffect(() => {
        props.dispatch(updateBreadcrumbs("forceUpdate"))
        currentVersionGetter()
        
    },[])

    return (
        <>
        {isLoading && <Loader />}
        <Grid container
            direction="column"
            alignItems="center"
            className={classes.container}
        >
            
            <Card className={classes.bulkUploadCard}>               
                <Grid item>
                    <Grid container
                        direction="row"
                        justify="space-around"
                        className={classes.gridSelectList}
                        
                    >
                        
                        <Grid item>
                            <FormControl>
                                <label style={{fontWeight:600,fontSize:"0.75rem"}}>Please select newer app version</label>
                                <select
                                    labelId="demo-simple-select-label"
                                    id="select1"
                                    variant="standard"
                                    defaultValue="Select Parking Space"
                                    value={select1}
                                    onChange={(event)=>setSelect1(event.target.value)}
                                    className={classes.selectInput}
                                >
                                <option value="">
                                    Please select
                                </option>
                                {
                                    values.map((value,index)=>{
                                    return(
                                        <option key={index} value={value}>{value}</option>                        
                                    )
                                    })
                                }
                                </select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl>
                                <label style={{fontWeight:600,fontSize:"0.75rem"}}>Please select newer app version</label>
                                <select
                                    labelId="demo-simple-select-label"
                                    id="select2"
                                    variant="standard"
                                    defaultValue="Select Parking Space"
                                    value={select2}
                                    onChange={(event)=>setSelect2(event.target.value)}
                                    className={classes.selectInput}
                                >
                                <option value="">
                                    Please select
                                </option>
                                {
                                    values.map((value,index)=>{
                                    return(
                                        <option key={index} value={value}>{value}</option>                        
                                    )
                                    })
                                }
                                </select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl>
                                <label style={{fontWeight:600,fontSize:"0.75rem"}}>Please select newer app version</label>
                                <select
                                    labelId="demo-simple-select-label"
                                    id="select3"
                                    variant="standard"
                                    defaultValue="Select Parking Space"
                                    value={select3}
                                    onChange={(event)=>setSelect3(event.target.value)}
                                    className={classes.selectInput}
                                >
                                <option value="">
                                    Please select
                                </option>
                                {
                                    values.map((value,index)=>{
                                    return(
                                        <option key={index} value={value}>{value}</option>                        
                                    )
                                    })
                                }
                                </select>
                            </FormControl>
                        </Grid>                                                     
                    </Grid>
                </Grid>
                <Container>
                <Grid item container direction="row" className={classes.gridRadioDisplayText}>
                   
                    <FormControl component="fieldset">
                        <Typography  style={{fontWeight:"700"}}>OS Type</Typography>
                        <RadioGroup name="OS Type" value={radioValue} onChange={radioHandler}>
                            <Grid item>
                                <Grid container
                                    direction="row"
                                >
                                    <Grid item>
                                        <FormControlLabel value="Android" control={<Radio />} label="Android" />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel value="iOS" control={<Radio />} label="iOS" />
                                    </Grid>    
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                    
                </Grid> 
                <Grid item container direction="column" className={classes.gridRadioDisplayText}>
                    <Grid container
                        direction="row"
                    >
                        <Grid item>
                            <p>Android Current Version: </p>
                        </Grid>
                        <Grid item style={{marginLeft:"1em",fontWeight:"bolder"}}>
                            <p>{currentAndroid}</p>
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        style={{marginTop:"1em"}}
                    >
                        <Grid item>
                            <p>iOS Current Version: </p>
                        </Grid>
                        <Grid item style={{marginLeft:"1em",fontWeight:"bolder"}}>
                            <p>{currentiOS}</p>
                        </Grid>
                    </Grid>                    
                </Grid>
                <Grid item container justify="flex-end" className={classes.gridSubmitButton}>
                        <Button
                            disabled={
                                select1.length === 0 ||
                                select2.length === 0 ||
                                select3.length === 0 ||
                                radioValue.length === 0 
                                // select1 === "0" && select2 === "0" && select3 === "0" 
                                
                            }
                            //onClick={bulkUploadHandler}
                            className={classes.fileUpload}
                            variant="contained"
                            style={{marginBottom:matchesSM?"2em":undefined,fontSize:"0.71rem"}}
                            onClick={submitHandler}
                        >
                            Submit
                        </Button>
                    </Grid>
                    </Container>
            </Card>
            <Dialog 
              open={open}
              onClose={()=>setOpen(false)}
              PaperProps={{style: {
                  padding:matchesXS?"8px":matchesMD?"16px":"32px",
              }}}
              style={{zIndex: 1301}}
            >
                <MuiDialogTitle className={classes.uploadDialogTitle}>
                    Alert!
                </MuiDialogTitle>
                <DialogContent>
                    <Grid item container direction="row" justify="center">
                        <Typography variant="caption" color="#808080">
                            Are you sure you want to Force update the {radioValue} 
                            from <span style={{marginRight:"0.5em",fontWeight:"bolder"}}>{radioValue==="Android"?currentAndroid:radioValue==="iOS"?currentiOS:null}</span> 
                            to <span style={{fontWeight:"bolder"}}>{select1}.{select2}.{select3}</span> ? 
                        </Typography>
                    </Grid>
                    <Grid item container 
                        direction="row" 
                        justify="space-around" 
                        className={classes.gridPopupButton}
                    > 
                        <Button variant="outlined" onClick={forceUpdateHandler}>Agree</Button>
                        <Button variant="outlined" onClick={cancelHandler}>Cancel</Button>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Snackbar open={openAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={severity}>
                {alertMessage}
                </Alert>
            </Snackbar>
        </Grid>
        </>
    )
}

//redux dispatch method
const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs,
    dispatch,
  });
    
  //method to connect react with redux
  export default withRouter(
    connect(null,mapDispatchToProps)(ForceUpdate),
  );
