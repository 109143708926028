import React, { Component } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import profile from '../../../../../Assets/Images/user@2x.png'
import httpRequest  from '../../../../../Utils/httpRequest';
import './style.scss';

export default class SolAdminDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setopen: false,
      userImg: '',
      userFName: '',
      userLName: '',
      address1: '',
      address2: '',
      city: '',
      pincode: '',
      userOrg: '',
      userEmail: '',
      profileImage: '',
      imgLink: '',
      userId: '',
      imgData: '',
      userPhone:'',
      orgName:'',

    };
  }
  componentDidMount = () => {

    

     httpRequest.get(
      'userProfile',
      {},
      this.UserDataSuccess,
      this.errorCallback,
      {
        userId: this.props.solAdminId
      },
    );
   
  };
  UserDataSuccess = (jsonResult) => {
    this.setState({
      imgLink: jsonResult.data._links.profileImage.href,
      userId: jsonResult.data.userId,
    });
    httpRequest.get(
      'userProfile',
      {},
      this.imageSuccess,
      this.errorCallback,
      {
        userId: this.props.solAdminId
      },
    );
    this.setState({
      userFName: jsonResult.data.firstName,
      userLName: jsonResult.data.lastName,
      userOrg: jsonResult.data.org.orgAcronym,
      userEmail: jsonResult.data.corpEmailId,
      userImg: jsonResult.data._links.profileImage.href,
      
      userPhone: jsonResult.data.mobileNumber,
      address1: jsonResult.data.addressDetails.addressLine1,
      address2:jsonResult.data.addressDetails.addressLine2,
      city:jsonResult.data.addressDetails.city,
      pincode:jsonResult.data.addressDetails.pincode,
      orgName: jsonResult.data.org.name,
      stateName: jsonResult.data.addressDetails.state

    });
  };
  imageSuccess = (jsonResult) => {
    httpRequest.get(
      'detailsImgTwo',
      {
        
      },
      this.imageSuccessTwo,
      this.errorCallback,
      {
        detailsImgTwo:
          jsonResult.data._links.profileImage.href +
          '&responseType=BASE64_ENCODE',
      },
    );
  };
  imageSuccessTwo = (jsonResult) => {
    this.setState({
      imgData: jsonResult.data,
    });
  };
  errorCallback = () => {};
  render() {
    
    return (
      <div className="SolAdminDetails" style={{ paddingTop: '8px' }}>
      <Card raised={true} className="details_card">
        <CardHeader
          title="Parking Solution Admin Details"
         
          titleTypographyProps={{ variant: 'h6' }}
        />

        <CardContent className="details_info">
          <Grid container>
            <Grid item md={3} style={{ paddingRight: '10px', }}>
              <div style={{ marginBottom: '10px', textAlign: '-webkit-center' }}>
              <Avatar
                      style={{width:"10em",height:"10em"}}
                      src={profile}
                      className="picture"
                    ></Avatar>
                
              </div>
             
                             
            </Grid>
            <Grid item md={5} style={{ marginBottom: '1em' }}>
              <div
                style={{
                  marginBottom: '10px',
                  marginLeft: '30px',
                }}
              >
                 <p
                  style={{
                    display: 'inline',
                    fontWeight: 'bold',
                   
                    paddingLeft: '15px',
                  }}
                >
                  Solution Admin Name
                </p>
                
              </div>
              <div
                style={{
                  marginBottom: '40px',
                  marginLeft: '30px',
                }}
              >
                 <p
                  style={{
                    display: 'inline',
                   
                    
                    paddingLeft: '15px',
                  }}
                >
               {(this.state.userFName!==undefined?this.state.userFName:"--") +" "+ (this.state.userLName!==undefined?this.state.userLName:"")}
                </p>
                
              </div>
              <div
                style={{
                  marginBottom: '10px',
                  marginLeft: '30px',
                }}
              >
                 <p
                  style={{
                    display: 'inline',
                    fontWeight: 'bold',
                   
                    paddingLeft: '15px',
                  }}
                >
                  Organization Name
                </p>
                
              </div>
              <div
                style={{
                  marginBottom: '40px',
                  marginLeft: '30px',
                }}
              >
                 <p
                  style={{
                    display: 'block',
                   
                    
                    paddingLeft: '15px',
                  }}
                >
                  {this.state.orgName!==""?(this.state.orgName): "-"}

                  
              
                  
                </p>
                
              </div>
              <div
                style={{
                  marginBottom: '10px',
                  marginLeft: '30px',
                }}
              >
                 <p
                  style={{
                    display: 'inline',
                    fontWeight: 'bold',
                   
                    paddingLeft: '15px',
                  }}
                >
                  Organization Address
                </p>
                
              </div>
              <div
                style={{
                  marginBottom: '40px',
                  marginLeft: '30px',
                }}
              >
                 <p
                  style={{
                    display: 'inline-block',
                   
                    
                    paddingLeft: '15px',
                  }}
                >
                  {this.state.address1}&nbsp;
                  {this.state.address2}&nbsp;
                  
                  {this.state.city}&nbsp;{this.state.pincode}
                  <br />
                  {this.state.stateName}
                  
                </p>
                
              </div>
             
            </Grid>
            <Grid item md={4} style={{ marginBottom: '1em' }}>
            <div
                style={{
                  marginBottom: '10px',
                  marginLeft: '30px',
                }}
              >
                 <p
                  style={{
                    display: 'inline',
                    fontWeight: 'bold',
                   
                    paddingLeft: '15px',
                  }}
                >
                  Solution Admin Login Id
                </p>
                
              </div>
              <div
                style={{
                  marginBottom: '40px',
                  marginLeft: '30px',
                }}
              >
                 <p
                  style={{
                   
                   
                    
                    paddingLeft: '15px',
                  }}
                >
                  {this.state.userEmail}
                </p>
                
              </div>
              <div
                style={{
                  marginBottom: '10px',
                  marginLeft: '30px',
                }}
              >
                 <p
                  style={{
                 
                    fontWeight: 'bold',
                   
                    paddingLeft: '15px',
                  }}
                >
                  Contact Number
                </p>
                
              </div>
              <div
                style={{
                  marginBottom: '40px',
                  marginLeft: '30px',
                }}
              >
                 <p
                  style={{
                    display: 'inline',
                   
                    
                    paddingLeft: '15px',
                  }}
                >
                    {this.state.userPhone!==""?(this.state.userPhone): "-"}
            
                </p>
                
              </div>
              <div
                style={{
                  marginBottom: '10px',
                  marginLeft: '30px',
                }}
              >
                 <p
                  style={{
                    display: 'inline',
                    fontWeight: 'bold',
                   
                    paddingLeft: '15px',
                  }}
                >
                  Email Address
                </p>
                
              </div>
              <div
                style={{
                  marginBottom: '40px',
                  marginLeft: '30px',
                }}
              >
                 <p
                  style={{
                 
                   
                    
                    paddingLeft: '15px',
                  }}
                >
                  {this.state.userEmail}
                </p>
                
              </div>
             
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      </div>
    );
  }
}
