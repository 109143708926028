/*
 * Copyright Robert Bosch GmbH. Copyright Robert Bosch GmbH. All rights reserved, also regarding any disposal, exploration, reproduction, editing, distribution, as well as in the event of applications for industrial property rights.
 */
import React, { Component } from "react";
import "./Login.scss";
import logo from "../../../Assets/Images/brand_logo.png";
import { Card, Checkbox, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import httpRequest from "../../../Utils/httpRequest";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import {
  updateAuth,
  updateToasterConfig,
} from "./../../../../src/redux/modules/app/actions";
import { setCookie,getCookie } from "./../../../Utils/cookies";
import Icon from "../../../Assets/Images/Password.png";
import Icon1 from "../../../Assets/Images/hide.png";
import TokenStorage from "./../../../Utils/tokenstorage";
import tokenstorage from "./../../../Utils/tokenstorage";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      privacyCheck: false,
      error: "",
      token: "",
      cliSec:"",
      cliId:"",
      toasterConfig: {
        message: "",
        color: "",
        show: false,
      },
    };

    this.handlePassChange = this.handlePassChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissError = this.dismissError.bind(this);
  }

componentDidMount(){
  tokenstorage.clear()
  httpRequest.getPublic(
    "configUrl",
    {},
    this.configSuccessLoad,
    this.configError,

  );
  
}
  handleSubmit1(evt){
    
    if(getCookie('cliSec')==="" || getCookie('cliSec') === undefined || getCookie('cliSec') === null ){
      // console.log("with cookie");
      httpRequest.getPublic(
        "configUrl",
        {},
        this.configSuccess,
        this.configError,
  
      );

    } else {
      this.handleSubmit(evt);
      // console.log("without cookie");
    }
    

  }
  dismissError() {
    this.setState({ error: "" });
  }

  loginSuccess = (jsonResult) => {
    this.setState({ token: jsonResult.data.access_token });
    setCookie("auth", jsonResult.data.access_token, 1);
    setCookie("refresh", jsonResult.data.refresh_token, 1);
    TokenStorage.storeRefreshToken(jsonResult.data.refresh_token);
    TokenStorage.storeToken(jsonResult.data.access_token);

    let dt = new Date();
    dt.setSeconds(dt.getSeconds() + (jsonResult.data.expires_in - 60));
    TokenStorage.setTokenExpirationTime(dt);
    this.props.updateAuth({ token: jsonResult.data.access_token });
    httpRequest.get(
      "createUser",
      {},
      this.createUserSuccess,
      this.errorCallback
    );
  };

  createUserSuccess = (jsonResult) => {
    let newAuth = { ...this.props.auth };
    newAuth["userId"] = jsonResult.data.userId;

    setCookie("userId", jsonResult.data.userId, 1);
    this.props.updateAuth(newAuth);
    sessionStorage.setItem("auth", JSON.stringify(newAuth));

    if (jsonResult.data.roles[0] === "BOSCH_SUPER_ADMIN") {
      this.props.updateToasterConfig({
        show: true,
        message: "Successfully logged In",
        color: "#02C194",
      });
      this.props.history.push("portal/parkingmanagment");
    }
    else {

      //console.log("***********************************************chcking************")


      this.props.updateToasterConfig({
        show: true,
        message: "Solution Admin cannot use this portal",
        color: "#FF0000",
      });
      TokenStorage.clear();
      this.props.history.push("/login");



    }

  
   
  };

  errorCallback = () => {
    this.props.history.push("/login");
  };
  detectKeyPress = (evt) => {
    if (evt.charCode === 13) {
      this.handleSubmit();
    }
  };
  removeReadOnly = () => {
    document.getElementById("username").removeAttribute("readOnly");
    document.getElementById("password").removeAttribute("readOnly");
  };

  configSuccessLoad = (jsonResult) =>
  {
    if(jsonResult.data.platformClientSecret && jsonResult.data.platformClientID){
    this.setState({ 
      cliSec: jsonResult.data.platformClientSecret,
      cliId: jsonResult.data.platformClientID
    
    });
    setCookie("cliSec", jsonResult.data.platformClientSecret, 1);
    setCookie("cliId",jsonResult.data.platformClientID, 1);
    setCookie('realmName',jsonResult.data.platformRealm,1)
  }
  else {
    this.props.updateToasterConfig({
      show: true,
      message: "Server not responding, please refresh page and try again!",
      color: "#FF0000",
    });


  }
    

    
   
  };
 
  configSuccess = (jsonResult,event) =>
  {
    
    this.setState({ 
      cliSec: jsonResult.data.platformClientSecret,
      cliId: jsonResult.data.platformClientID
    
    });
    setCookie("cliSec", jsonResult.data.platformClientSecret, 1);
    setCookie("cliId",jsonResult.data.platformClientID, 1);

    this.handleSubmit(event);
   
  };
  configError = () =>
  {
    this.props.updateToasterConfig({
      show: true,
      message: "Server not responding, please refresh and try again!",
      color: "#FF0000",
      duration:10000000000,
    });
  };

  


  handleSubmit=() => { 
  
  

    if (!this.state.username) {
      return this.setState({ error: "Username is required" });
    }

    if (!this.state.password) {
      return this.setState({ error: "Password is required" });
    }

    if (!this.state.privacyCheck) {
      return this.setState({ error: "Terms and conditions not checked" });
    }

   
    
    this.props.updateToasterConfig({
      show: true,
      message: "Processing...",
      color: "#ffae42",
    });
    httpRequest.auth(
      "loginUrl",
      {
        data:
          "username=" +
          this.state.username.trim() +
          "&password=" +
          this.state.password.trim() +
          "&grant_type=password&client_id="+getCookie('cliId')+"&client_secret=" +
          getCookie('cliSec'),
      },
      {},
      this.loginSuccess,
      (error) => {
        if (error.error_description) {
          this.props.updateToasterConfig({
            show: true,
            message: error.error_description,
            color: "#FF0000",
          });
        }
      }
    );

    return this.setState({ error: "" });
  }
  myFunction() {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
    this.setState({
      show: !this.state.show,
    });
  }
  handleUserChange(evt) {
    this.setState({
      username: evt.target.value,
    });
  }

  handlePassChange(evt) {
    this.setState({
      password: evt.target.value,
    });
  }
  handlePrivacy = (evt) => {
    this.setState({
      privacyCheck: evt.target.checked,
    });
  };



  render() {
    return (
      <div className="Login">
        <div className="Login__wrapper">
          <Card>
            <div className="Login__wrapper_box">
              <div className="Login__wrapper_box_header">
                <div className="Login__wrapper_box_header_logo">
                  <img src={logo} alt="header-logo" />
                </div>
                <div className="Login__wrapper_box_header_text">
                  <span>Super Admin Login</span>
                </div>
              </div>
              {this.state.error && (
                <h3
                  data-test="error"
                  className="error"
                  onClick={this.dismissError}
                >
                  <button onClick={this.dismissError}>✖</button>
                  {this.state.error}
                </h3>
              )}
              <label>Email Id *</label>
              <input
                placeholder="enter input details"
                type="text"
                data-test="username"
                value={this.state.username}
                onChange={this.handleUserChange}
                id="username"
                autoComplete="off"
                readOnly
                onFocus={this.removeReadOnly}
              />

              <label>Password *</label>
              <div className="password-box">
                <input
                  className="password"
                  type="password"
                  data-test="password"
                  onKeyPress={this.detectKeyPress}
                  value={this.state.password}
                  onChange={this.handlePassChange}
                  placeholder="****************"
                  id="password"
                  autoComplete="new-password"
                  readOnly
                  onFocus={this.removeReadOnly}
                />
                <div className="passwordIcon">
                  <img
                    src={this.state.show ? Icon : Icon1}
                    alt="Password-Icon"
                    onClick={(evt) => {
                      this.myFunction(evt);
                    }}
                  />
                </div>
              </div>
              <div>
                <Link
                  style={{ float: 'right', color: "#0A65A8", cursor: "pointer", textDecoration: 'none' }}
                  to={
                    '/forgot_password'
                  }
                >
                  <small> Forgot&nbsp;Password?</small>
                </Link>

              </div>

              <br />
              <br />
              <Grid container>
                <Grid item container
                  direction="row"
                  justify="flex-start"
                  // xs={12}
                >
                  <Grid item>
                    <Checkbox
                      inputProps={{ "aria-label": "Privacy checkbox" }}
                      value={this.state.privacyCheck}
                      onChange={this.handlePrivacy}
                      color="primary"
                    />
                  </Grid>
                  <Grid item style={{width:"80%",lineHeight:"1rem"}}>
                    <span style={{ fontSize: 13 + "px", paddingTop: 16 + "px" }}>
                  	I agree to abide by my <b>confidentiality obligations</b> and <b>User Manual </b>
                     
                    </span>
                  </Grid>
                </Grid>
                {/* <Grid item container direction="column" alignItems="flex-end" style={{ marginLeft: 132, display: 'flex',marginTop:"15px" }}>
                  <Grid item style={{marginTop:"5px"}}>
                    <ModelDialog displayPage="login" />
                  </Grid>
                  <Grid item style={{marginTop:"5px"}}>
                    <PrivacySetting displayPage="login" />
                  </Grid>
                  <Grid item style={{marginTop:"5px"}}>
                    <LegalDataProtection displayPage="login" />
                  </Grid>
                </Grid> */}
              </Grid>
              <Button
                variant="contained"
                color="primary"
                type="button"
                disabled={!(this.state.username && this.state.password && this.state.privacyCheck)}
                onClick={(evt) => {
                  this.handleSubmit1(evt);
                }}
                style={{marginTop:"20px",borderRadius:"45px"}}
              >
                Login
              </Button>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      auth: state.app.auth,
    }),
    { updateAuth, updateToasterConfig }
  )(Login)
);
