import React, { Component } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import logo from '../../../../../Assets/Images/info.svg';
import noCustomOperatingHrs from '../../../../../Assets/Images/no custom operating hours.png';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import './style.scss';

export default class OperationTimings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setopen: false,
    };
    if (typeof(this.props.customData)=='undefined')
    {
      this.state = {
        customData: [],
      } 
    }
    else {
      this.state = {
        customData: this.props.customData,
      } 
    }
  }
  componentDidMount = () => {
    var weekdays = [
      'SUNDAY',
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
    ];

    

    var today = new Date().getDay();
    var curTime =
      new Date().getHours() +
      ':' +
      new Date().getMinutes() +
      ':' +
      new Date().getSeconds();
    var days = [];
    var listOperatingHour = [];
    for (var i in this.props.timings) {
      days.push(i);
      listOperatingHour.push(this.props.timings[i]);
    }
    days.map((item, index) => {
      item === weekdays[today] &&
        curTime > listOperatingHour[index][0].openingTime &&
        curTime < listOperatingHour[index][0].closingTime &&
        this.setState({ setopen: true });
      return <div key={index}></div>;
    });
  };
  render() {
    
    return (
      <Card raised={true} className="details_card">
        <CardHeader
          title="Operation Timings"
         
          titleTypographyProps={{ variant: 'h6' }}
        />

        <CardContent className="details_info">
          <Grid container>
            <Grid item md={6} style={{ paddingRight: '10px' }}>
              <div style={{ marginBottom: '10px' }}>
                <p
                  style={{
                    display: 'inline',
                    fontWeight: '700',
                    color: '#707070',
                    paddingLeft: '15px',
                  }}
                >
                  Standard Operating Hours
                </p>
                <Tooltip
                  disableFocusListener
                  title="Operation Timings"
                  classes={{ popper: 'statusInfoTooltip' }}
                  arrow
                  placement="top-start"
                >
                  <Button className="iIconbtn">
                    <img
                      src={logo}
                      style={{
                        marginBottom: '-3px',
                        marginLeft: '10px',
                        width: '15px',
                        display: 'inline',
                      }}
                      alt=""
                    />
                  </Button>
                </Tooltip>
              </div>
              {Object.keys(this.props.timings).length ? (
                <TableContainer>
                  <Table
                    style={{
                      marginLeft: '15px',
                      width: '95%',
                    }}
                    aria-label="simple table"
                  >
                    <TableHead
                      style={{
                        background: '#e1dddd',
                        fontWeight: '700',
                      }}
                    >
                      <TableCell align="left">
                        Days of the Week
                      </TableCell>
                      <TableCell align="right">Open Time</TableCell>
                      <TableCell align="right">-</TableCell>
                      <TableCell align="right">Close Time</TableCell>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">Monday</TableCell>
                        {this.props && this.props.timings.MONDAY ? (
                          this.props.timings.MONDAY.map(
                            (item, index) => (
                              <>
                                <TableCell key={index} align="right">
                                  {item.openingTime}
                                </TableCell>
                                <TableCell align="right">-</TableCell>
                                <TableCell align="right">
                                  {item.closingTime}
                                </TableCell>
                              </>
                            ),
                          )
                        ) : (
                          <>
                            <TableCell
                              style={{
                                color: '#A01528',
                              }}
                              align="right"
                            >
                              Closed
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">Tuesday</TableCell>
                        {this.props && this.props.timings.TUESDAY ? (
                          this.props.timings.TUESDAY.map(
                            (item, index) => (
                              <>
                                <TableCell key={index} align="right">
                                  {item.openingTime}
                                </TableCell>
                                <TableCell align="right">-</TableCell>
                                <TableCell align="right">
                                  {item.closingTime}
                                </TableCell>
                              </>
                            ),
                          )
                        ) : (
                          <>
                            <TableCell
                              style={{
                                color: '#A01528',
                              }}
                              align="right"
                            >
                              Closed
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">Wednesday</TableCell>
                        {this.props &&
                        this.props.timings.WEDNESDAY ? (
                          this.props.timings.WEDNESDAY.map(
                            (item, index) => (
                              <>
                                <TableCell key={index} align="right">
                                  {item.openingTime}
                                </TableCell>
                                <TableCell align="right">-</TableCell>
                                <TableCell align="right">
                                  {item.closingTime}
                                </TableCell>
                              </>
                            ),
                          )
                        ) : (
                          <>
                            <TableCell
                              style={{
                                color: '#A01528',
                              }}
                              align="right"
                            >
                              Closed
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">Thursday</TableCell>
                        {this.props && this.props.timings.THURSDAY ? (
                          this.props.timings.THURSDAY.map(
                            (item, index) => (
                              <>
                                <TableCell key={index} align="right">
                                  {item.openingTime}
                                </TableCell>
                                <TableCell align="right">-</TableCell>
                                <TableCell align="right">
                                  {item.closingTime}
                                </TableCell>
                              </>
                            ),
                          )
                        ) : (
                          <>
                            <TableCell
                              style={{
                                color: '#A01528',
                              }}
                              align="right"
                            >
                              Closed
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">Friday</TableCell>
                        {this.props && this.props.timings.FRIDAY ? (
                          this.props.timings.FRIDAY.map(
                            (item, index) => (
                              <>
                                <TableCell key={index} align="right">
                                  {item.openingTime}
                                </TableCell>
                                <TableCell align="right">-</TableCell>
                                <TableCell align="right">
                                  {item.closingTime}
                                </TableCell>
                              </>
                            ),
                          )
                        ) : (
                          <>
                            <TableCell
                              style={{
                                color: '#A01528',
                              }}
                              align="right"
                            >
                              Closed
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">Saturday</TableCell>
                        {this.props && this.props.timings.SATURDAY ? (
                          this.props.timings.SATURDAY.map(
                            (item, index) => (
                              <>
                                <TableCell key={index} align="right">
                                  {item.openingTime}
                                </TableCell>
                                <TableCell align="right">-</TableCell>
                                <TableCell align="right">
                                  {item.closingTime}
                                </TableCell>
                              </>
                            ),
                          )
                        ) : (
                          <>
                            <TableCell
                              style={{
                                color: '#A01528',
                              }}
                              align="right"
                            >
                              Closed
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">Sunday</TableCell>
                        {this.props && this.props.timings.SUNDAY ? (
                          this.props.timings.SUNDAY.map(
                            (item, index) => (
                              <>
                                <TableCell key={index} align="right">
                                  {item.openingTime}
                                </TableCell>
                                <TableCell align="right">-</TableCell>
                                <TableCell align="right">
                                  {item.closingTime}
                                </TableCell>
                              </>
                            ),
                          )
                        ) : (
                          <>
                            <TableCell
                              style={{
                                color: '#A01528',
                              }}
                              align="right"
                            >
                              Closed
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <img
                  src={noCustomOperatingHrs}
                  alt="noCustomOperatingHrs"
                />
              )}
            </Grid>
            <Grid item md={6} style={{ marginBottom: '1em' }}>
              <div
                style={{
                  marginBottom: '10px',
                  marginLeft: '10px',
                }}
              >
                <p
                  style={{
                    display: 'inline',
                    color: '#707070',
                  }}
                >
                  <b>Custom Operating Hours</b>
                </p>
                <Tooltip
                  disableFocusListener
                  title="Custom Operating Hours"
                  classes={{ popper: 'statusInfoTooltip' }}
                  arrow
                  placement="top-start"
                >
                  <Button className="iIconbtn">
                    <img
                      src={logo}
                      style={{
                        marginBottom: '-3px',
                        marginLeft: '10px',
                        width: '15px',
                        display: 'inline',
                      }}
                      alt=""
                    />
                  </Button>
                </Tooltip>
              </div>
              <TableContainer>
                <div
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {this.state.customData.length === 0 ? (
                    <div>
                      <img
                        src={noCustomOperatingHrs}
                        alt="noCustomOperatingHrs"
                      />
                      <p
                        style={{
                          fontSize: '14px',
                          fontFamily: 'poppins',
                          paddingTop: '20px',
                        }}
                      >
                        No custom operating rule <br />
                        currently active for this parking space.
                      </p>
                    </div>
                  ) : (
                    <div>
                      {this.state.customData.map((item, index) => {
                        return (
                          <div key={index}>
                            <TableContainer>
                              <Table
                                style={{
                                  marginLeft: '15px',
                                  width: '95%',
                                  marginBottom: '15px',
                                }}
                                aria-label="simple table"
                              >
                                <TableHead
                                  style={{
                                    background: '#e1dddd',
                                    fontWeight: '700',
                                  }}
                                >
                                  <TableCell align="left">
                                    {item.startDate}
                                  </TableCell>
                                  <TableCell align="right">
                                    to
                                  </TableCell>
                                  <TableCell align="right">
                                    {item.endDate}
                                  </TableCell>
                                  <TableCell align="right">
                                    {item.openingTime}
                                  </TableCell>
                                  <TableCell align="right">
                                    -
                                  </TableCell>
                                  <TableCell align="right">
                                    {item.closingTime}
                                  </TableCell>
                                </TableHead>
                              </Table>
                            </TableContainer>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}
