import React, { Component } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  getCookie
} from '../../../../../Utils/cookies';
import httpRequest from './../../../../../Utils/httpRequest';
import './style.scss';
import Bike from '../../../../../Assets/Images/2 Wheeler.svg';
import Car from '../../../../../Assets/Images/4 Wheeler.svg';
import Fleet from '../../../../../Assets/Images/Fleet.svg';

export default class SlotCount extends Component {
  constructor(props) {
    super();
    this.state = {
      vehicleTypeInfo:{},
    }
  }
  componentDidMount () {
    httpRequest
    .get(
      'parkingdetailSlot',
      {},
      this.UserDataSuccessSlot,
      this.errorCallbackSlot,
      {
        parkingId: this.props.parkingId,
        userId: getCookie('userId'),
      },
    )
    this.updateMe();
    console.log(this.state.vehicleTypeInfo,'shubham')
  }
  updateMe = () => {
    this.interval = setInterval(() => {
      httpRequest
      .get(
        'parkingdetailSlot',
        {},
        this.UserDataSuccessSlot,
        this.errorCallbackSlot,
        {
          parkingId: this.props.parkingId,
          userId: getCookie('userId'),
        },
      )
    }, 10000);
  };

  UserDataSuccessSlot = jsonResult => {
    console.log("check dayta",jsonResult)
    this.setState({
     vehicleTypeInfo:jsonResult?.data.vehicleTypeInfo,
    });
    

   


  };
  componentWillUnmount = () => {
    clearInterval(this.interval);
  };
  render() 
  {
    return (
      <div className="slotcount" style={{ paddingTop: '8px' }}>
        <div className="slotcount__wrapper">
          <Grid
            container
            style={{ paddingBottom: '5px' }}
            spacing={2}
          >
            <Grid item md={6}>
              <Card
                raised={true}
                className="details_card"
                style={{ marginTop: '10px' }}
              >
                <CardHeader
                  title="Slot Count"
                  titleTypographyProps={{ variant: 'h6' }}
                />
                <CardContent className="details_info">
                  {this.state.vehicleTypeInfo.length ? (
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead
                        >
                          <TableRow
                            style={{
                              background: '#e1dddd',
                              fontWeight: '700',
                            }}
                          >
                            <TableCell align="left">
                              Type of Vehicle
                            </TableCell>
                            <TableCell
                              style={{ textAlign: 'center' }}
                              align="left"
                            >
                              Live Status
                            </TableCell>
                            <TableCell
                              style={{ textAlign: 'center' }}
                              align="left"
                            >
                              Total Slots
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.vehicleTypeInfo.map(
                            (item, index) => (
                              <TableRow key={index}>
                                <TableCell align="left">
                                  <img
                                    className="list-icon iconsAlignm"
                                    src={
                                      item.vehicleType.name === 'BIKE'
                                        ? Bike
                                        : item.vehicleType.name ===
                                          'CAR'
                                        ? Car
                                        : item.vehicleType.name ===
                                          'FLEET'
                                        ? Fleet
                                        : Bike
                                    }
                                    alt="iconimg"
                                  />
                                  {item.vehicleType.displayName
                                    ? item.vehicleType.displayName
                                    : '0'}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    color:
                                      this.props.gateway === false
                                        ? 'green'
                                        : 'orange',
                                    fontFamily: 'Poppins',
                                    fontWeight: '600',
                                    fontSize: '24px',
                                    textAlign: 'center',
                                  }}
                                >
                                  {item.availableSlots
                                    ? item.availableSlots
                                    : '0'}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    fontSize: '24px',
                                    color: '#102E35',
                                    fontFamily: 'Poppins',
                                    fontWeight: '600',
                                    textAlign: 'center',
                                  }}
                                >
                                  {item.totalSlots
                                    ? item.totalSlots
                                    : '0'}
                                </TableCell>
                              </TableRow>
                            ),
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <h4>No Data Found</h4>
                  )}
                </CardContent>
              </Card>
            </Grid>

            <Grid item md={6}>
              <Card
                raised={true}
                className="details_card"
                style={{ marginTop: '10px' }}
              >
                <CardHeader
                  title="Tariff Details"
                  titleTypographyProps={{ variant: 'h6' }}
                />

                <CardContent className="details_info">
                  {this.props.vehicleTypeInfo.length ? (
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead
                          style={{
                            background: '#E5E2EF',
                            fontWeight: '700',
                          }}
                        >
                          <TableCell align="left">
                            Type of Vehicle
                          </TableCell>
                          <TableCell align="left">
                            Base Price
                          </TableCell>
                          <TableCell align="left">
                            Standard Price
                          </TableCell>
                          <TableCell align="left">
                            Long Term
                          </TableCell>
                        </TableHead>
                        <TableBody>
                          {this.props.vehicleTypeInfo.map(
                            (item, index) => (
                              <TableRow key={index}>
                                <TableCell align="left">
                                  <img
                                    className="list-icon iconsAlignm"
                                    src={
                                      item.vehicleType.name === 'BIKE'
                                        ? Bike
                                        : item.vehicleType.name ===
                                          'CAR'
                                        ? Car
                                        : item.vehicleType.name ===
                                          'FLEET'
                                        ? Fleet
                                        : Bike
                                    }
                                    alt="iconimg"
                                  />
                                  {item.vehicleType.displayName
                                    ? item.vehicleType.displayName
                                    : '0'}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: '24px',
                                    color: '#102E35',
                                    fontFamily: 'Poppins',
                                    fontWeight: '600',
                                    textAlign: 'center',
                                  }}
                                  align="left"
                                >
                                  {Object.keys(item.priceInfo).length ? (
                                    <ul className="data-list">
                                      <li>
                                      <span style={{fontWeight:'200'}}>₹</span>{item.priceInfo.basePrice}
                                      </li>
                                      <li className="data_unit">
                                        0-{item.priceInfo.baseHour}
                                        Hr
                                      </li>
                                    </ul>
                                  ) : (
                                    '-'
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: '24px',
                                    color: '#102E35',
                                    fontFamily: 'Poppins',
                                    fontWeight: '600',
                                    textAlign: 'center',
                                  }}
                                  align="left"
                                >

                                  {Object.keys(item.priceInfo).length ? (
                                    <ul className="data-list">
                                      <li>
                                      <span style={{fontWeight:'200'}}>₹</span>
                                        {
                                          item.priceInfo
                                            .additionalPrice
                                        }
                                      </li>
                                      <li className="data_unit">
                                        per
                                        {' ' +
                                          item.priceInfo
                                            .additionalHour +
                                          ' '}
                                        Hr
                                      </li>
                                    </ul>
                                  ) : (
                                    '-'
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: '24px',
                                    color: '#102E35',
                                    fontFamily: 'Poppins',
                                    fontWeight: '600',
                                    textAlign: 'center',
                                  }}
                                  align="left"
                                >
                                  {Object.keys(item.priceInfo).length ? (
                                    <ul className="data-list">
                                      <li>
                                      {item.priceInfo
                                          .pricePerDay !== 0 ||
                                          item.priceInfo.pricePerWeek !==0 || item.priceInfo
                                          .pricePerMonth !== 0 ?(<span style={{fontWeight:'200'}}>₹</span>):('-')}
                                        {item.priceInfo
                                          .pricePerDay !== 0 &&
                                          item.priceInfo.pricePerDay}
                                        {item.priceInfo
                                          .pricePerWeek !== 0 &&
                                          item.priceInfo.pricePerWeek}
                                        {item.priceInfo
                                          .pricePerMonth !== 0 &&
                                          item.priceInfo
                                            .pricePerMonth}
                                      </li>
                                      <li className="data_unit">
                                        
                                        {item.priceInfo
                                          .pricePerDay !== 0 && (
                                          <span>per Day</span>
                                        )}
                                        {item.priceInfo
                                          .pricePerWeek !== 0 && (
                                          <span>per Week</span>
                                        )}
                                        {item.priceInfo
                                          .pricePerMonth !== 0 && (
                                          <span>per Month</span>
                                        )}
                                      </li>
                                    </ul>
                                  ) : (
                                    '-'
                                  )}
                                </TableCell>
                              </TableRow>
                            ),
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <h4>No Data Found</h4>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
